import { Injectable, Renderer2 } from '@angular/core';
import { ElementPostionKey } from '@shared/enums/keys.enum';
import { DomService } from './dom.service';

@Injectable({
  providedIn: 'root',
})
export class ElementPositionService {
  constructor(private domService: DomService) {}

  ridElementOfCrawledState(targetElement: Element, marginFromBoundPx = 10) {
    const overflowState = this.domService.getBlockOverflowState(targetElement);
    this.renderer.setStyle(targetElement, 'top', `${overflowState.boundingLeft}px`);
    this.renderer.setStyle(targetElement, 'left', `${overflowState.boundingRight}px`);
    this.renderer.setStyle(targetElement, 'bottom', `unset`);
    this.renderer.setStyle(targetElement, 'right', `unset`);

    let yAxis = overflowState.boundingTop;
    let xAxis = overflowState.boundingLeft;

    if (overflowState.bottomCrawledOut > 0) {
      overflowState.bottomCrawledOut += marginFromBoundPx;
      yAxis = overflowState.boundingTop - overflowState.bottomCrawledOut;
    }
    if (overflowState.topCrawledOut > 0) {
      overflowState.topCrawledOut += marginFromBoundPx;
      yAxis = overflowState.boundingTop + overflowState.topCrawledOut;
    }

    if (overflowState.rightCrawledOut > 0) {
      overflowState.rightCrawledOut += marginFromBoundPx;
      xAxis = overflowState.boundingLeft - overflowState.rightCrawledOut;
    }
    if (overflowState.leftCrawledOut > 0) {
      overflowState.leftCrawledOut += marginFromBoundPx;
      xAxis = overflowState.boundingLeft + overflowState.leftCrawledOut;
    }

    this.renderer.setStyle(targetElement, 'top', `${yAxis}px`);
    this.renderer.setStyle(targetElement, 'left', `${xAxis}px`);
  }

  setElementPositionRelativeToParent(
    targetElement: Element,
    targetPosition: ElementPostionKey,
    parentElement?: Element,
    sparePosition?: ElementPostionKey,
  ) {
    if (!targetElement || !targetPosition) {
      return;
    }

    if (!parentElement) {
      parentElement = targetElement.parentElement;
    }

    if (!sparePosition) {
      sparePosition = this._getOppositePosition(targetPosition);
    }

    this._getTargetCoordinatesAndSetToTargetElement(parentElement, targetElement, targetPosition);
    if (this.domService.isElementFitInContainer(targetElement)) {
      return;
    }

    this._getTargetCoordinatesAndSetToTargetElement(parentElement, targetElement, sparePosition);
    if (this.domService.isElementFitInContainer(targetElement)) {
      return;
    }

    this.ridElementOfCrawledState(targetElement);
  }

  private _getTargetCoordinatesAndSetToTargetElement(
    parentElement: Element,
    targetElement: Element,
    targetPosition: ElementPostionKey,
  ) {
    const { yAxis, xAxis } = this._getTargetCoordinatesByElementPosition(parentElement, targetElement, targetPosition);

    this.renderer.setStyle(targetElement, 'top', `${yAxis}px`);
    this.renderer.setStyle(targetElement, 'left', `${xAxis}px`);
  }

  private _getTargetCoordinatesByElementPosition(
    parentElement: Element,
    targetElement: Element,
    targetPosition: ElementPostionKey,
  ): { yAxis: number; xAxis: number } {
    const parentElementOverflowState = this.domService.getBlockOverflowState(parentElement);
    let yAxis = 0;
    let xAxis = 0;

    switch (targetPosition) {
      case ElementPostionKey.TopLeft:
      case ElementPostionKey.TopCenter:
      case ElementPostionKey.TopRight: {
        yAxis = parentElementOverflowState.boundingTop + parentElementOverflowState.height;
        break;
      }
      case ElementPostionKey.BottomLeft:
      case ElementPostionKey.BottomCenter:
      case ElementPostionKey.BottomRight: {
        yAxis = parentElementOverflowState.boundingBottom;
        break;
      }
    }

    switch (targetPosition) {
      case ElementPostionKey.TopLeft:
      case ElementPostionKey.BottomLeft: {
        xAxis = parentElementOverflowState.boundingLeft;
        break;
      }
      case ElementPostionKey.TopCenter:
      case ElementPostionKey.BottomCenter: {
        xAxis =
          parentElementOverflowState.boundingLeft +
          parentElementOverflowState.width / 2 -
          targetElement.clientWidth / 2;
        break;
      }
      case ElementPostionKey.TopRight:
      case ElementPostionKey.BottomRight: {
        xAxis = parentElementOverflowState.boundingRight - targetElement.clientWidth;
        break;
      }
    }

    return { yAxis, xAxis };
  }

  private _getOppositePosition(position: ElementPostionKey) {
    switch (position) {
      case ElementPostionKey.TopLeft: {
        return ElementPostionKey.TopRight;
      }
      case ElementPostionKey.TopCenter: {
        return ElementPostionKey.BottomCenter;
      }
      case ElementPostionKey.TopRight: {
        return ElementPostionKey.TopLeft;
      }
      case ElementPostionKey.BottomLeft: {
        return ElementPostionKey.BottomRight;
      }
      case ElementPostionKey.BottomCenter: {
        return ElementPostionKey.TopCenter;
      }
      case ElementPostionKey.BottomRight: {
        return ElementPostionKey.BottomLeft;
      }
    }
  }

  get renderer(): Renderer2 {
    return this.domService.renderer;
  }
}
