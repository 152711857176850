import { Component, OnInit } from '@angular/core';
import { AuthHttpService } from '@core/services/api/auth/auth-http.service';

@Component({
  selector: 'kp-redirect-to-store-page',
  templateUrl: './redirect-to-store-page.component.html',
  styleUrls: ['./redirect-to-store-page.component.scss'],
})
export class RedirectToStorePageComponent implements OnInit {
  userAgentSystem: string;

  constructor(private authHttpService: AuthHttpService) {}

  ngOnInit(): void {
    this.getMobileOperatingSystem();

    this.getLink(this.userAgentSystem);
  }

  getMobileOperatingSystem() {
    let userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return (this.userAgentSystem = 'Windows Phone');
    }

    if (/android/i.test(userAgent)) {
      return (this.userAgentSystem = 'android');
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return (this.userAgentSystem = 'ios');
    }
    return (this.userAgentSystem = 'unknown');
  }

  getLink(system: string) {
    const params = { store: system };

    this.authHttpService.getMobileStoreLink(params).subscribe((res: { link: string }) => {
      document.location.replace(res?.link);
    });
  }
}
