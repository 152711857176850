<div class="file-container">
  <div class="file" (click)="downloadFile()">
    <kp-svg
      [ngClass]="{ 'resize-icon': isFileInChat }"
      key="fileText"
      size="15px"
      class="file-text"
      cursor="default"
      *ngIf="showIcon"
    ></kp-svg>

    <div
      class="file-custom-upload"
      [ngStyle]="{
        'font-weight': fontWeight
      }"
    >
      <span>{{ file.filename }}</span>

      <br />

      <span class="size">{{ file.file_size | transformWordFileSize }} </span>
    </div>
  </div>

  <button type="button" class="button button--simple" (click)="downloadFile()" *ngIf="showDownloadText">Скачать</button>
</div>
