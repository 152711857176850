import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

/**
 * Приводит время к локальному значению по передаваемому часовому поясу
 */
@Pipe({
  name: 'transformToLocalTime',
})
export class transformToLocalTimePipe implements PipeTransform {
  constructor() {}

  transform(date: string, time_zone: string, isMoscowTime?: boolean) {
    if (isMoscowTime) {
      return moment(date).tz('Europe/Moscow').format('DD.M.yyyy, HH:mm');
    } else {
      return moment(date).tz(time_zone).format('DD.M.yyyy, HH:mm');
    }
  }
}
