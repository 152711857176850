<mat-radio-group
  class="radio-group"
  [ngClass]="{
    'radio-group--column': column,
    'radio-group--options-gap-lg': itemsGapLg
  }"
  color="primary"
  [disabled]="disabled"
  [(ngModel)]="selectedValue"
  [ngModelOptions]="{ standalone: true }"
>
  <mat-radio-button class="radio-button" *ngFor="let item of data" [value]="keyToSelect ? item[keyToSelect] : item">
    {{ keyToView ? item[keyToView] : item }}
  </mat-radio-button>
</mat-radio-group>
