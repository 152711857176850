import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ForumHttpService } from '@core/services/api/forums/forum-http.service';
import {
  COMMON_FORUMS_TABLE_MEMBER_DEFAULT_COLUMNS,
  COMMON_FORUMS_TABLE_STAFF_DEFAULT_COLUMNS,
  COMMON_FORUM_NEWSLINE_CONFIG,
  FORUMS_DEFAULT_FILTERS,
  FORUMS_FILTERS_IGNORE_URL,
  FORUMS_LIST_DEFAULT_LIMIT,
  FORUMS_PAGE_FILTERS,
  FORUMS_TABLE_COLUMNS,
  FORUM_AND_MEETING_CONFIG,
  FORUM_AND_MEETING_CONFIG_COMMON,
  FORUM_AND_MEETING_CONFIG_INDUSTRY,
  INDUSTRY_FORUMS_TABLE_MEMBER_DEFAULT_COLUMNS,
  INDUSTRY_FORUMS_TABLE_STAFF_DEFAULT_COLUMNS,
  INDUSTRY_FORUM_NEWSLINE_CONFIG,
} from '@modules/forums/constants/common.const';
import { FORUM_STATE_COLOR_KEY_TO_VIEW_NAME } from '@modules/forums/constants/mappers.const';
import {
  CANCELLED_FORUM_MENU_OPTIONS,
  COMMON_FORUMS_MODAL_FILTERS_OPTIONS_MEMBER,
  COMMON_FORUMS_MODAL_FILTERS_OPTIONS_STAFF,
  COMMON_FORUMS_TABLE_MEMBER_COLUMNS_OPTIONS,
  COMMON_FORUMS_TABLE_STAFF_COLUMNS_OPTIONS,
  DRAFT_FORUM_IN_LIST_MENU_OPTIONS_ADMIN,
  DRAFT_FORUM_MENU_OPTIONS,
  FORUM_IN_LIST_MENU_OPTIONS_MEMBER,
  FORUM_TYPE_OPTIONS,
  FORUM_TYPE_OPTIONS_MEMBER,
  FORUM_TYPE_OPTIONS_STAFF,
  INDUSTRY_FORUMS_MODAL_FILTERS_OPTIONS_MEMBER,
  INDUSTRY_FORUMS_MODAL_FILTERS_OPTIONS_STAFF,
  INDUSTRY_FORUMS_TABLE_MEMBER_COLUMNS_OPTIONS,
  INDUSTRY_FORUMS_TABLE_STAFF_COLUMNS_OPTIONS,
  NOT_DRAFT_FORUM_IN_LIST_MENU_OPTIONS_STAFF,
  PUBLISHED_FORUM_MENU_OPTIONS,
} from '@modules/forums/constants/select-options.const';
import { ForumOrMeetingType, ForumRelatedFieldKey, ForumStateKey } from '@modules/forums/enums/keys.enum';
import { BaseClass } from '@shared/components/base/base.class';
import { FilterDialogComponent } from '@shared/dialogs/filter-dialog/filter-dialog.component';
import { SelectDialogComponent } from '@shared/dialogs/select-dialog/select-dialog.component';
import { FilterType } from '@shared/enums/filter/filters-type.enum';
import { PageMode } from '@shared/enums/keys.enum';
import { SelectMode } from '@shared/enums/select/select-mode.enum';
import { IFile } from '@shared/models/file.model';
import { IFilter } from '@shared/models/filter/filter.model';
import { IForumDto } from '@shared/models/forums/dto/forum-dto.model';
import { IForumAndMeetingModulesConfig } from '@shared/models/forums/view/forum-meeting-config.model';
import { IForumMember } from '@shared/models/forums/view/forum-member.model';
import { IForum, IForumLink } from '@shared/models/forums/view/forum.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { ISelectOption } from '@shared/models/select.model';
import { ITableColumn } from '@shared/models/table/table-column.model';
import { ITableConfig } from '@shared/models/table/table-config.model';
import { ITableRow } from '@shared/models/table/table-row.model';
import { IUser } from '@shared/models/user/view/user.model';
import * as _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { NewslineUtilsService } from '../newsline/newsline-utils.service';
import { DateService } from '../utils/date.service';
import { FilterService } from '../utils/filter.service';
import { TableService } from '../utils/table.service';
import { ForumFileService } from './forum-file.service';
import { ForumLinkService } from './forum-link.service';

@Injectable({
  providedIn: 'root',
})
export class ForumService extends BaseClass {
  forums: IForum[] = [];
  forumsCount: number;
  publishedForumsCount: number;
  currentForum: IForum;
  isListForumsLoading = false;

  currentUserForumMemberProfile: IForumMember;

  tableConfig: ITableConfig;
  tableCols: ITableColumn[];

  currentCreateEditMode: PageMode;
  createEditPageTitle: string;

  forumsModalFiltersOptions: ISelectOption[];
  forumTypeOptions: ISelectOption[];

  draftForNewForum: IForum;

  constructor(
    private forumHttpService: ForumHttpService,
    private dateService: DateService,
    private dialog: MatDialog,
    private authService: AuthService,
    private tableService: TableService,
    private filterService: FilterService,
    private newslineUtilsService: NewslineUtilsService,
    private forumFileService: ForumFileService,
    private forumLinkService: ForumLinkService,
  ) {
    super();
  }

  getForumsWithAdditionalDataAndAddToTargetForums(
    targetForums?: IForum[],
    params?: KeyValue<string>,
    paramsString?: string,
    setForumsCount = true,
  ): Observable<ArrayPayload<IForum>> {
    return this.getForumsAndAddToTargetForums(targetForums, params, paramsString, setForumsCount).pipe(
      mergeMap((response) => {
        if (response.results.length) {
          const reqs: Array<Observable<ArrayPayload<IFile | IForumLink>>> = response.results.map((forum) =>
            this.forumFileService.getAllByTargetIdAndAddToTarget(forum.id, forum),
          );
          reqs.push(
            ...response.results.map((forum) => this.forumLinkService.getAllByTargetIdAndAddToTarget(forum.id, forum)),
          );

          return forkJoin(reqs).pipe(map(() => response));
        }

        return of(response);
      }),
    );
  }

  getForumsAndAddToTargetForums(
    targetForums?: IForum[],
    params?: KeyValue<string>,
    paramsString?: string,
    setForumsCount = true,
    type = this.forumAndMeetingConfig?.forumOrMeetingType,
  ): Observable<ArrayPayload<IForum>> {
    if (type !== undefined && type !== null) {
      const filter = { [ForumRelatedFieldKey.Type]: type };
      const filterInString = `${ForumRelatedFieldKey.Type}=${type}`;

      params = params ? { ...params, ...filter } : null;
      paramsString = paramsString ? `${paramsString}&${filterInString}` : filterInString;
    }

    return this.forumHttpService.getForums(params, paramsString).pipe(
      map((response) => {
        if (setForumsCount) {
          this.forumsCount = response.count;
        }

        if (!targetForums) {
          targetForums = [];
        }

        response.results.forEach((forum) => {
          targetForums.push(this.transformForumDtoToForumView(forum));
        });

        const result: ArrayPayload<IForum> = {
          results: targetForums,
          count: response.count,
        };

        return result;
      }),
    );
  }

  getAndSetPublishedForumsCount(): Observable<number> {
    return this.getForumsAndAddToTargetForums(null, { state: ForumStateKey.Published, limit: '0' }, null, false).pipe(
      map((response) => {
        this.publishedForumsCount = response.count;
        return this.publishedForumsCount;
      }),
    );
  }

  getForumById(id: number): Observable<IForum> {
    return this.forumHttpService.getForumById(id).pipe(
      map((dto) => {
        return this.transformForumDtoToForumView(dto);
      }),
    );
  }

  getForumByIdWithAdditionalInfo(id: number): Observable<IForum> {
    return this.getForumById(id).pipe(
      mergeMap((dto) => {
        if (dto) {
          const reqs: Array<Observable<ArrayPayload<IFile | IForumLink>>> = [];
          reqs.push(this.forumFileService.getAllByTargetIdAndAddToTarget(dto.id, dto));
          reqs.push(this.forumLinkService.getAllByTargetIdAndAddToTarget(dto.id, dto));

          return forkJoin(reqs).pipe(map(() => dto));
        }

        return of(dto);
      }),
    );
  }

  createForum(forum: Partial<IForumDto>): Observable<IForum> {
    return this.forumHttpService.createForum(forum).pipe(
      map((dto) => {
        return this.transformForumDtoToForumView(dto);
      }),
    );
  }

  patchForumById(id: number, forum: Partial<IForumDto> | FormData): Observable<IForum> {
    return this.forumHttpService.patchForumById(id, forum).pipe(
      map((dto) => {
        return this.transformForumDtoToForumView(dto);
      }),
    );
  }

  deleteForumByIdAndRemoveFromTargetForums(postId: number, targetForums?: IForum[]): Observable<void> {
    const itemToDeleteIdx = targetForums?.findIndex((p) => p.id === postId);
    if (targetForums && itemToDeleteIdx !== -1) {
      targetForums[itemToDeleteIdx].isDeleting = true;
    }

    return this.forumHttpService.deleteForumById(postId).pipe(
      tap(
        () => {
          if (targetForums && itemToDeleteIdx !== -1) {
            targetForums.splice(itemToDeleteIdx, 1);
          }
        },
        () => {
          if (targetForums && itemToDeleteIdx !== -1) {
            targetForums[itemToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }

  publishForum(id: number, targetForum?: IForum): Observable<IForum> {
    return this.patchForumById(id, { [ForumRelatedFieldKey.State]: +ForumStateKey.Published }).pipe(
      tap((forum) => {
        if (targetForum) {
          targetForum.state = forum.state;
          targetForum.menuOptionsInList = this.getForumMenuOptionsForListPage(targetForum);
          targetForum.menuOptionsOnProfilePage = this.getForumMenuOptionsForProfilePage(targetForum);
        }
      }),
    );
  }

  finishForum(id: number, targetForum?: IForum): Observable<IForum> {
    return this.patchForumById(id, { [ForumRelatedFieldKey.State]: +ForumStateKey.Finished }).pipe(
      tap((forum) => {
        if (targetForum) {
          targetForum.state = forum.state;
          targetForum.menuOptionsInList = this.getForumMenuOptionsForListPage(targetForum);
          targetForum.menuOptionsOnProfilePage = this.getForumMenuOptionsForProfilePage(targetForum);
        }
      }),
    );
  }

  cancelForum(id: number, targetForum?: IForum): Observable<IForum> {
    return this.patchForumById(id, { [ForumRelatedFieldKey.State]: +ForumStateKey.Cancelled }).pipe(
      tap((forum) => {
        if (targetForum) {
          targetForum.state = forum.state;
          targetForum.menuOptionsInList = this.getForumMenuOptionsForListPage(targetForum);
          targetForum.menuOptionsOnProfilePage = this.getForumMenuOptionsForProfilePage(targetForum);
        }
      }),
    );
  }

  setNewslineConfig() {
    const targetConfig = this.forumAndMeetingConfig.isCurrentTypeCommon
      ? COMMON_FORUM_NEWSLINE_CONFIG
      : INDUSTRY_FORUM_NEWSLINE_CONFIG;

    targetConfig.filters = [
      {
        field: 'forum',
        value: this.currentForum.id.toString(),
        isNotForUrl: true,
      },
    ];
    targetConfig.forumId = this.currentForum.id;
    this.newslineUtilsService.setNewslineModuleConfig(targetConfig);
  }

  setFilterFromTemplate(
    filterType: FilterType,
    option?: ISelectOption,
    filters?: IFilter | IFilter[],
    itemsCountTotal?: number,
    itemsCountCurrent?: number,
    offsetStep?: string,
  ): { needLoadItems: boolean } {
    const { needLoadItems } = this.filterService.setFilterFromTemplate(
      filterType,
      option,
      filters,
      itemsCountTotal,
      itemsCountCurrent,
      offsetStep,
    );

    if (needLoadItems) {
      switch (filterType) {
        case FilterType.DataFilters: {
          this.initPageFilters();
          this.initDefaultFiltersState();
          this.updateTableConfig();
        }
      }
      this.filterService.setFiltersToUrlParameters(FORUMS_FILTERS_IGNORE_URL);
    }
    return { needLoadItems };
  }

  initPageFilters() {
    const pageFilters = _.cloneDeep(FORUMS_PAGE_FILTERS);
    this.filterService.mergeFiltersArrays(pageFilters, this.filterService.filters);
  }

  initDefaultFiltersState() {
    const defaultFilters = _.cloneDeep(FORUMS_DEFAULT_FILTERS);
    this.filterService.defaultFilters = defaultFilters;
  }

  initForumTypeOptionsAndFilters() {
    FORUM_TYPE_OPTIONS.find(
      (o) => o.filterField === ForumRelatedFieldKey.Administrator,
    ).filterValue = this.currentUser.id.toString();
    FORUM_TYPE_OPTIONS.find(
      (o) => o.filterField === ForumRelatedFieldKey.User,
    ).filterValue = this.currentUser.id.toString();
    this.forumTypeOptions = this.currentUser.isStaff ? FORUM_TYPE_OPTIONS_STAFF : FORUM_TYPE_OPTIONS_MEMBER;

    this.forumTypeOptions = this.filterService.selectOptionsByFilters(
      this.forumTypeOptions,
      this.filterService.filters,
      ForumRelatedFieldKey.ForumType,
      null,
    );
    this.filterService.setFilterFromSelectOption(this.forumTypeOptions.find((o) => o.isSelected));
  }

  setCreateEditPageTitle() {
    this.createEditPageTitle = this.isCurrentModeCreate ? 'Создать форум' : 'Редактировать форум';
  }

  openForumsFilterDialog() {
    this.dialog.open(FilterDialogComponent);
  }

  updateTableConfig() {
    let rows = this.tableService.transformArrayToTableRows(this.forums, ForumRelatedFieldKey.MenuOptionsInList);
    rows = this._addClassesToForumStateCells(rows);

    this.tableConfig = {
      columns: this.tableCols?.length ? this.tableCols : this.defaultTableCols,
      rows,
      rowsSettingsEnabled: true,
    };
  }

  private _addClassesToForumStateCells(rows: ITableRow[]): ITableRow[] {
    return rows.map((row) => {
      const targetCellIdx = row.data.findIndex((item) => item.key === ForumRelatedFieldKey.State);
      if (targetCellIdx !== -1) {
        row.data[targetCellIdx].customClass =
          FORUM_STATE_COLOR_KEY_TO_VIEW_NAME[row.data[targetCellIdx].value as ForumStateKey];
      }
      return row;
    });
  }

  get defaultTableCols(): ITableColumn[] {
    if (this.currentUser?.isStaff) {
      return this.forumAndMeetingConfig.isCurrentTypeCommon
        ? COMMON_FORUMS_TABLE_STAFF_DEFAULT_COLUMNS
        : INDUSTRY_FORUMS_TABLE_STAFF_DEFAULT_COLUMNS;
    }
    return this.forumAndMeetingConfig.isCurrentTypeCommon
      ? COMMON_FORUMS_TABLE_MEMBER_DEFAULT_COLUMNS
      : INDUSTRY_FORUMS_TABLE_MEMBER_DEFAULT_COLUMNS;
  }

  openTableColumnsSettingsDialog(): MatDialogRef<SelectDialogComponent, any> {
    return this.dialog.open(SelectDialogComponent, {
      data: {
        options: this._forumsTableColumnsSettingsOptions,
        selectedOptionsValues: this.tableConfig.columns.map((col) => col.headerKey),
        selectMode: SelectMode.MultiSelect,
      },
    });
  }

  private get _forumsTableColumnsSettingsOptions(): ISelectOption[] {
    if (this.currentUser?.isStaff) {
      return this.forumAndMeetingConfig.isCurrentTypeCommon
        ? COMMON_FORUMS_TABLE_STAFF_COLUMNS_OPTIONS
        : INDUSTRY_FORUMS_TABLE_STAFF_COLUMNS_OPTIONS;
    }
    return this.forumAndMeetingConfig.isCurrentTypeCommon
      ? COMMON_FORUMS_TABLE_MEMBER_COLUMNS_OPTIONS
      : INDUSTRY_FORUMS_TABLE_MEMBER_COLUMNS_OPTIONS;
  }

  setTableColsByKeys(keys: string[]) {
    this.tableCols = FORUMS_TABLE_COLUMNS.filter((col) => keys.includes(col.headerKey));
  }

  transformForumDtoToForumView(dto: IForumDto): IForum {
    let view: Partial<IForum> = {};

    view.forumPageRoute = this.getForumPageRoute(dto);
    view.editPageRoute = this.getForumEditPageRouteById(dto);

    view.created = this.dateService.transfromStringToMoment(dto.created);
    view.finished_at = this.dateService.transfromStringToMoment(dto.finished_at);
    view.last_launch_started_at = this.dateService.transfromStringToMoment(dto.last_launch_started_at);
    view.started_at = this.dateService.transfromStringToMoment(dto.started_at);
    view.updated = this.dateService.transfromStringToMoment(dto.updated);

    view.administrator = this.authService.transformUserShortDtoToUserShortView(dto.administrator);
    view.creator = this.authService.transformUserShortDtoToUserShortView(dto.creator);
    view.curator = this.authService.transformUserShortDtoToUserShortView(dto.curator);
    view.moderator = this.authService.transformUserShortDtoToUserShortView(dto.moderator);

    view.menuOptionsInList = this.getForumMenuOptionsForListPage(dto);
    view.menuOptionsOnProfilePage = this.getForumMenuOptionsForProfilePage(dto);

    view = Object.assign(dto, view);
    return view as IForum;
  }

  getForumMenuOptionsForListPage(forum: IForumDto | IForum): ISelectOption[] {
    let options: ISelectOption[] = [];

    if (
      (forum.state === +ForumStateKey.Draft && this.authService.currentUser.id === forum.administrator?.id) ||
      this.authService.currentUser.permissions.delete_forum
    ) {
      options = DRAFT_FORUM_IN_LIST_MENU_OPTIONS_ADMIN;
    } else if (this.authService.currentUser.isStaff) {
      options = NOT_DRAFT_FORUM_IN_LIST_MENU_OPTIONS_STAFF;
    } else {
      options = FORUM_IN_LIST_MENU_OPTIONS_MEMBER;
    }

    return options;
  }

  getForumMenuOptionsForProfilePage(forum: IForumDto | IForum): ISelectOption[] {
    let options: ISelectOption[] = [];

    if (this.authService.currentUser.isStaff) {
      if (forum.state === +ForumStateKey.Draft) {
        options = DRAFT_FORUM_MENU_OPTIONS;
      } else if (forum.state === +ForumStateKey.Cancelled) {
        options = CANCELLED_FORUM_MENU_OPTIONS;
      } else if (forum.state === +ForumStateKey.Published) {
        options = PUBLISHED_FORUM_MENU_OPTIONS;
      }
    }

    return options;
  }

  getForumPageRoute(forum: IForumDto | IForum): string {
    let { pathToForumsBootstrap } = FORUM_AND_MEETING_CONFIG_COMMON;
    if (forum.type === +ForumOrMeetingType.Industry) {
      pathToForumsBootstrap = FORUM_AND_MEETING_CONFIG_INDUSTRY.pathToForumsBootstrap;
    }

    return `${pathToForumsBootstrap}/${forum.id}`;
  }

  getForumEditPageRouteById(forum: IForumDto | IForum): string {
    return `${this.getForumPageRoute(forum)}/edit`;
  }

  setModuleConfig(config: IForumAndMeetingModulesConfig) {
    FORUM_AND_MEETING_CONFIG.config = config;
  }

  setModalFiltersOptions() {
    if (this.currentUser?.isStaff) {
      this.forumsModalFiltersOptions = this.forumAndMeetingConfig.isCurrentTypeCommon
        ? COMMON_FORUMS_MODAL_FILTERS_OPTIONS_STAFF
        : INDUSTRY_FORUMS_MODAL_FILTERS_OPTIONS_STAFF;
    } else {
      this.forumsModalFiltersOptions = this.forumAndMeetingConfig.isCurrentTypeCommon
        ? COMMON_FORUMS_MODAL_FILTERS_OPTIONS_MEMBER
        : INDUSTRY_FORUMS_MODAL_FILTERS_OPTIONS_MEMBER;
    }
  }

  get isCurrentForumDraft(): boolean {
    return this.currentForum?.state === +ForumStateKey.Draft;
  }

  get isCurrentForumPublished(): boolean {
    return this.currentForum?.state === +ForumStateKey.Published;
  }

  get currentUser(): IUser {
    return this.authService.currentUser;
  }

  get isCurrentModeCreate(): boolean {
    return this.currentCreateEditMode === PageMode.Create;
  }

  get isCurrentModeEdit(): boolean {
    return this.currentCreateEditMode === PageMode.Edit;
  }

  get defaultLimit() {
    return FORUMS_LIST_DEFAULT_LIMIT;
  }
}
