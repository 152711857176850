import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BeforeInstallPromptEvent } from '@shared/declarations/before-install-prompt-event.interface';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  private readonly isPwaDownloadButtonVisibleState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isPwaDownloadButtonVisible$: Observable<boolean> =
    this.isPwaDownloadButtonVisibleState$.asObservable();

  private readonly deferredPrompt$: BehaviorSubject<BeforeInstallPromptEvent | null> =
    new BehaviorSubject<BeforeInstallPromptEvent>(null);

  constructor() {}

  public setIsPwaDownloadButtonVisibleState$(isVisible: boolean) {
    this.isPwaDownloadButtonVisibleState$.next(isVisible);
  }

  public initialize(): void {
    window.addEventListener('beforeinstallprompt', (event: Event) => {
      event.preventDefault();
      this.deferredPrompt$.next(event as BeforeInstallPromptEvent);
      this.setIsPwaDownloadButtonVisibleState$(true);
    });
  }

  public downloadPwa(): void {
    this.deferredPrompt$
      .pipe(
        take(1),
        filter((event: BeforeInstallPromptEvent) => !!event),
      )
      .subscribe((deferredPrompt: BeforeInstallPromptEvent) =>
        deferredPrompt.prompt().then(() => this._processingUserInput(deferredPrompt)),
      );
  }

  private _processingUserInput(deferredPrompt: BeforeInstallPromptEvent): void {
    if (deferredPrompt) {
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('App installed');
          this.setIsPwaDownloadButtonVisibleState$(false);
        }
        console.log('App installation declined');
      });
    }
  }
}
