import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
// import * as cloneDeep from "lodash/cloneDeep";
import { DomSanitizer } from '@angular/platform-browser';
import { MeetingService } from '@core/services/business/booking/meeting.service';
import * as moment from 'moment';

@Component({
  selector: 'kp-year-calendar',
  templateUrl: './year-calendar.component.html',
  styleUrls: ['./year-calendar.component.scss'],
})
export class YearCalendarComponent implements OnInit {
  @HostBinding('style')
  get style() {
    return this.sanitizer.bypassSecurityTrustStyle(`--themecolor: ${this.themecolor};`);
  }
  @Input()
  themecolor: any = 'black';
  // themecolor: any = '#ff0000';
  @Input()
  events: any[] = [];

  @Input()
  viewDate: Date = new Date();

  @Output()
  eventClicked = new EventEmitter<any>();

  @Output()
  actionClicked = new EventEmitter<any>();

  loader: any = false;
  days: any = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
  dayindex: any;
  daydetails: any = {};
  year: any = new Date().getFullYear();
  calendar: any = [];

  meetingDates: string[] = [];

  constructor(public sanitizer: DomSanitizer, private meetingService: MeetingService) {}

  ngOnInit() {
    this.initCalendar(this.viewDate);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initCalendar(this.viewDate);

    this.getMeetingsForYearCalendar(changes.goToNextYear?.firstChange, changes.viewDate);
  }

  getMeetingsForYearCalendar(isFirstChange: boolean, viewDate: SimpleChange) {
    if (isFirstChange) {
      return this.meetingService
        .getMeetingsForYearCalendar({ start_gte: '2022-01-01', start_lte: '2023-01-01' })
        .subscribe((res) => {
          this.meetingDates = (res as any).meeting_dates.map((el: any) => {
            return moment(el, 'YYYY-MM-DD').format('YYYY-MM-DD');
          });
        });
    } else {
      let currentYear = moment(viewDate.currentValue).format('YYYY');
      let perviousYear = moment(viewDate.previousValue).format('YYYY');

      let startGte;
      let startLte;

      if (currentYear > perviousYear) {
        startGte = +currentYear;
        startLte = +currentYear + 1;
      } else if (currentYear < perviousYear) {
        startGte = +currentYear;
        startLte = +perviousYear;
      } else if (currentYear === perviousYear) {
        startGte = 2022;
        startLte = 2023;
      }

      return this.meetingService
        .getMeetingsForYearCalendar({ start_gte: `${startGte}-01-01`, start_lte: `${startLte}-01-01` })
        .subscribe((res) => {
          this.meetingDates = (res as any).meeting_dates.map((el: any) => {
            return moment(el, 'YYYY-MM-DD').format('YYYY-MM-DD');
          });
        });
    }
  }

  initCalendar(date: any) {
    this.year = date.getFullYear();
    this.calendar = [];

    for (let index = 0; index < 12; index++) {
      this.calendar.push({
        date: new Date(this.year, index + 1, 0),
        days: this.generateCalendar(index + 1, this.year),
      });
    }

    let self = this;
  }

  generateCalendar(month: any, year: any): any[][] {
    let monthList = [];
    let nbweeks = this.getNbWeeks(month, year);
    let dayone = new Date(year, month - 1, 1).getDay();
    let nbdaysMonth = new Date(year, month, 0).getDate();
    let lastdayindex = new Date(year, month - 1, nbdaysMonth).getDay();
    let lastday = 7;
    let day = 1;
    let today = new Date().toDateString();

    for (let indexweek = 0; indexweek < nbweeks; indexweek++) {
      monthList[indexweek] = [];
      if (nbweeks == indexweek + 1) {
        lastday = lastdayindex + 1;
      }
      if (indexweek > 0) {
        dayone = 0;
      }
      for (let indexday = dayone; indexday < lastday; indexday++) {
        let d1 = new Date(year, month - 1, day).toDateString();
        let istoday = d1 == today;
        let colorsEvents = this.getnbevents(day, month - 1);

        let dayDate = `${year + '-' + month + '-' + day}`;

        monthList[indexweek][indexday] = {
          day: day,
          istoday: istoday,
          colors: colorsEvents.color,
          events: [],
          nb: colorsEvents.nb,
          dayDate: moment(dayDate as string).format('YYYY-MM-DD'),
        };

        day++;
      }
    }

    return monthList;
  }

  getNbWeeks(month: any, year: any) {
    let dayone = new Date(year, month - 1, 1).getDay();
    let nbdaysMonth = new Date(year, month, 0).getDate();
    let lastday = new Date(year, month - 1, nbdaysMonth).getDay();
    return (nbdaysMonth + dayone + (6 - lastday)) / 7;
  }

  getTodayEvents(day: any, month: any) {
    this.daydetails = {};

    if (this.events.length > 0) {
      this.loader = true;
      // this.daydetails = clone(day);
      let d1 = new Date(this.year, month, day.day).toDateString();

      for (let index = 0; index < this.events.length; index++) {
        const element = this.events[index];
        let d2 = element.start.toDateString();
        if (d2 == d1) {
          this.daydetails.events.push(element);
        }
        if (index == this.events.length - 1) {
          let self = this;
          setTimeout(() => {
            self.loader = false;
          }, 1000);
        }
      }
    }
  }

  getnbevents(day: any, month: any) {
    let nb = 0;
    let colors = [];
    if (this.events.length > 0) {
      let d1 = new Date(this.year, month, day).toDateString();
      for (let index = 0; index < this.events.length; index++) {
        const element = this.events[index];
        let d2 = element.start.toDateString();
        if (d2 == d1) {
          nb++;
          colors.push(element.color.secondary);
        }
      }
      return { nb: nb, color: colors.toString() };
    } else {
      return { color: '', nb: 0 };
    }
  }

  eventClickedFn(event: any) {
    this.eventClicked.emit(event);
  }

  refresh(date: any) {
    this.initCalendar(date);
  }

  actionClickedFn(action: any, event?: any) {
    this.actionClicked.emit({ action: action, event: event });
  }
}
