export const CHAT_REACTIONS_PLURAL: string[] = ['Реакция', 'Реакции', 'Реакций'];
export const CHAT_PARTICIPANTS_PLURAL: string[] = ['Участник', 'Участника', 'Участников'];
export const MESSAGE_PLURAL: string[] = ['Сообщение', 'Сообщения', 'Сообщений'];
export const AGE_FROM_TO_PLURAL: string[] = ['Года', 'Лет', 'Лет']; // Родительный падеж - для фильтров ОТ-ДО
export const AGE_PLURAL: string[] = ['Год', 'Года', 'Лет'];
export const VOTES_PLURAL: string[] = ['Голос', 'Голоса', 'Голосов'];

export const SECONDS_PLURAL: string[] = ['Секунда', 'Секунды', 'Секунд'];
export const MINUTES_PLURAL: string[] = ['Минута', 'Минуты', 'Минут'];
export const HOURS_PLURAL: string[] = ['Час', 'Часа', 'Часов'];
export const DAYS_PLURAL: string[] = ['День', 'Дня', 'Дней'];
export const MONTHS_PLURAL: string[] = ['Месяц', 'Месяца', 'Месяцев'];
