import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IForumDto } from '@shared/models/forums/dto/forum-dto.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ForumHttpService {
  private readonly _apiPath = '/forums/forum/';

  constructor(private http: HttpService) {}

  getForums(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IForumDto>> {
    return this.http.get<ArrayPayload<IForumDto>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getForumById(id: number): Observable<IForumDto> {
    return this.http.get<IForumDto>(`${this._apiPath}${id}/`);
  }

  createForum(forum: Partial<IForumDto>): Observable<IForumDto> {
    return this.http.post<IForumDto>(this._apiPath, forum);
  }

  updateForumById(id: number, forum: Partial<IForumDto> | FormData): Observable<IForumDto> {
    return this.http.put<IForumDto>(`${this._apiPath}${id}/`, forum);
  }

  patchForumById(id: number, forum: Partial<IForumDto> | FormData): Observable<IForumDto> {
    return this.http.patch<IForumDto>(`${this._apiPath}${id}/`, forum);
  }

  deleteForumById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }
}
