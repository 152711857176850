import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural',
  pure: true,
})
export class PluralPipe implements PipeTransform {
  constructor() {}

  /**
   * count - количество элементов
   * one - лейбл при количестве Х1
   * few - лейбл при количестве Х2-4
   * many - лейбл при количестве Х4+ или 0
   * keepCount - возвращать лейбл вместе с количеством
   */

  transform(count: number, [one, few, many]: string[], keepCount: boolean = false): string {
    if (!count) {
      return this.prepareLabel(count, many, keepCount);
    }

    const oneCondition: boolean = count % 10 === 1 && count % 100 !== 11;
    const fewCondition: boolean = count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20);

    return this.prepareLabel(count, oneCondition ? one : fewCondition ? few : many, keepCount);
  }

  private prepareLabel(count: number, label: string, keepCount: boolean): string {
    return keepCount ? `${count} ${label}` : `${label}`;
  }
}
