import { Injectable } from '@angular/core';
import { ForumReasonRefusalHttpService } from '@core/services/api/forums/forum-reason-refusal.service';
import { MeetingReasonRefusalHttpService } from '@core/services/api/meetings/meeting-reason-refusal.service';
import { IdName } from '@shared/models/id-name.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeetingReasonRefusalService {
  reasons: IdName[] = [];

  constructor(private meetingReasonRefusalHttpService: MeetingReasonRefusalHttpService) {}

  getAllAndAddToTargetList(reasonsOfRefusal?: IdName[], params?: KeyValue<string>): Observable<ArrayPayload<IdName>> {
    return this.meetingReasonRefusalHttpService.getAll(params).pipe(
      map((response) => {
        if (!reasonsOfRefusal) {
          reasonsOfRefusal = [];
        }

        reasonsOfRefusal.push(...response.results);

        const result: ArrayPayload<IdName> = {
          results: reasonsOfRefusal,
          count: response.count,
        };

        return result;
      }),
    );
  }

  getById(id: number): Observable<IdName> {
    return this.meetingReasonRefusalHttpService.getById(id);
  }
}
