import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IForumMemberDto } from '@shared/models/forums/dto/forum-member-dto.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ForumMembersHttpService {
  private readonly _apiPath = '/forums/forum-member/';

  constructor(private http: HttpService) {}

  getForumMembers(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IForumMemberDto>> {
    return this.http.get<ArrayPayload<IForumMemberDto>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getForumMemberById(id: number): Observable<IForumMemberDto> {
    return this.http.get<IForumMemberDto>(`${this._apiPath}${id}/`);
  }

  createForumMember(forumMember: Partial<IForumMemberDto>): Observable<IForumMemberDto> {
    return this.http.post<IForumMemberDto>(this._apiPath, forumMember);
  }

  updateForumMemberById(id: number, forumMember: Partial<IForumMemberDto>): Observable<IForumMemberDto> {
    return this.http.put<IForumMemberDto>(`${this._apiPath}${id}/`, forumMember);
  }

  patchForumMemberById(id: number, forumMember: Partial<IForumMemberDto> | FormData): Observable<IForumMemberDto> {
    return this.http.patch<IForumMemberDto>(`${this._apiPath}${id}/`, forumMember);
  }

  deleteForumMemberById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }
}
