import { IEnvironment } from '@shared/models/common/environment.model';

export const environment: IEnvironment = {
  sentryEnv: '',
  sentryDSN: '',
  production: true,
  apiUrl: '',
  socketUrl: '',
  apiVersionPath: '',
  firebaseConfig: {
    apiKey: 'AIzaSyDkpPCXf4Jhk-FwcgQkbx6Sz_PgsX-zduY',
    authDomain: 'clubfirst-95f6a.firebaseapp.com',
    databaseURL: 'https://clubfirst-95f6a-default-rtdb.firebaseio.com',
    projectId: 'clubfirst-95f6a',
    storageBucket: 'clubfirst-95f6a.appspot.com',
    messagingSenderId: '267500149806',
    appId: '1:267500149806:web:492daf09ea300e741713bf',
    measurementId: 'G-K3LSCXT74B',
  },
};
