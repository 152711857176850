import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface NgLetContext<T> {
  ngLet: T;
  $implicit: T;
}

@Directive({
  selector: '[kpNgLet]'
})
export class NgLetDirective<T> {

  private context: NgLetContext<T | null> = { ngLet: null, $implicit: null };
  private hasView = false;

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<NgLetContext<T>>) { }

  @Input()
  set kpNgLet(value: T) {
    this.context.$implicit = this.context.ngLet = value;
    if (!this.hasView) {
      this.hasView = true;
      this.viewContainer.createEmbeddedView(this.templateRef, this.context);
    }
  }

  public static ngLetUseIfTypeGuard: void;
  static ngTemplateGuard_ngLet: 'binding';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static ngTemplateContextGuard<T>(dir: NgLetDirective<T>, ctx: any): ctx is NgLetContext<T> {
    return true;
  }
}