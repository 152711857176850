import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[kpOnlyNumbersAndSeparator]'
})
export class OnlyNumbersAndSeparatorDirective {

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let allowedKeys = [
      'Backspace', 'Tab', 'End', 'Home', '/', 'ArrowLeft', 'ArrowRight', 'Delete'
    ];
    if (allowedKeys.includes(event.key) || this.isNumeric(event.key)) {
      return;
    } else {
      event.preventDefault();
    }
  }

  private isNumeric(value: string): boolean {
    return /^\d+$/g.test(value);
  }
}
