import { SEX_OPTIONS } from '@modules/profile/constants/selects-options.const';
import { USER_RELATED_FIELD_KEY_TO_VIEW_NAME } from '@shared/constants/mappers.const';
import { FILTER_RULE_OPTIONS } from '@shared/constants/selects-options.const';
import { FilterValueInputType } from '@shared/enums/filter/filter-value-input-type.enum';
import { UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { SelectOptionActionType } from '@shared/enums/select/select-option-action-type.enum';
import { OptionSourceKey } from '@shared/enums/select/select-source.enum';
import { ISelectOption } from '@shared/models/select.model';
import { MemberStatusInClubKey } from '../enums/keys.enum';
import { MemberMenuOptionViewName } from '../enums/view-names.enum';
import {
  MEMBERS_TABLE_COLUMNS,
  MEMBERS_TABLE_MEMBER_COLUMNS_KEYS,
  MEMBERS_TABLE_STAFF_COLUMNS_KEYS,
} from './common.const';
import { MEMBER_STATUS_IN_CLUB_KEY_TO_VIEW_NAME } from './mappers.const';

export const MEMBER_MENU_OPTIONS: ISelectOption[] = [
  {
    viewName: MemberMenuOptionViewName.Activate,
    iconKey: 'toggleRight',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: MemberMenuOptionViewName.Deactivate,
    iconKey: 'toggleRight',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: MemberMenuOptionViewName.Edit,
    iconKey: 'editPencil',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: MemberMenuOptionViewName.AddToEvent,
    iconKey: 'userPlus',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: MemberMenuOptionViewName.SendMessage,
    iconKey: 'mail',
    actionType: SelectOptionActionType.Action,
  },
];

// TODO: переделать определение набора селекта исходя из пермишенов, а не роли юзера
export const MEMBER_MENU_OPTIONS_FOR_MEMBER: ISelectOption[] = MEMBER_MENU_OPTIONS.filter(
  (option) => option.viewName === MemberMenuOptionViewName.SendMessage,
);

export const INACTIVE_MEMBER_MENU_OPTIONS_FOR_STAFF: ISelectOption[] = MEMBER_MENU_OPTIONS.filter(
  (option) => option.viewName !== MemberMenuOptionViewName.Deactivate,
);

export const ACTIVE_MEMBER_MENU_OPTIONS_FOR_STAFF: ISelectOption[] = MEMBER_MENU_OPTIONS.filter(
  (option) =>
    option.viewName !== MemberMenuOptionViewName.Activate && option.viewName !== MemberMenuOptionViewName.Deactivate,
);

export const MEMBERS_ROLE_OPTIONS_MOCK: ISelectOption[] = [
  {
    key: '1',
    viewName: 'Администраторы',
  },
  {
    key: '2',
    viewName: 'Участники клуба',
  },
  {
    key: '3',
    viewName: 'Кураторы',
  },
  {
    key: '4',
    viewName: 'Сотрудники клуба',
  },
];

export const MEMBER_STATUS_IN_CLUB_OPTIONS: ISelectOption[] = [
  {
    key: MemberStatusInClubKey.Expert,
    viewName: MEMBER_STATUS_IN_CLUB_KEY_TO_VIEW_NAME[MemberStatusInClubKey.Expert],
  },
  {
    key: MemberStatusInClubKey.Ambassador,
    viewName: MEMBER_STATUS_IN_CLUB_KEY_TO_VIEW_NAME[MemberStatusInClubKey.Ambassador],
  },
  {
    key: MemberStatusInClubKey.Moderator,
    viewName: MEMBER_STATUS_IN_CLUB_KEY_TO_VIEW_NAME[MemberStatusInClubKey.Moderator],
  },
];

export const MEMBERS_TABLE_STAFF_COLUMNS_OPTIONS: ISelectOption[] = MEMBERS_TABLE_COLUMNS.filter((col) =>
  MEMBERS_TABLE_STAFF_COLUMNS_KEYS.includes(col.headerKey as UserRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const MEMBERS_TABLE_MEMBER_COLUMNS_OPTIONS: ISelectOption[] = MEMBERS_TABLE_COLUMNS.filter((col) =>
  MEMBERS_TABLE_MEMBER_COLUMNS_KEYS.includes(col.headerKey as UserRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

const MEMBERS_MODAL_FILTERS_OPTIONS: ISelectOption[] = [
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.FirstName],
    filterField: UserRelatedFieldKey.FirstName,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.LastName],
    filterField: UserRelatedFieldKey.LastName,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.MiddleName],
    filterField: UserRelatedFieldKey.MiddleName,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Industries],
    filterField: UserRelatedFieldKey.Industries,
    filterValueInputType: FilterValueInputType.Select,
    filterFieldIsNotForApi: true,
    filterAdditionalFields: [UserRelatedFieldKey.PrimaryIndustries, UserRelatedFieldKey.AdditionalIndustries],
    filterAdditionalFieldsIsNotForUrl: true,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.Industries,
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.CanBeUseful],
    filterField: UserRelatedFieldKey.CanBeUseful,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Requests],
    filterField: UserRelatedFieldKey.Requests,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Email],
    filterField: UserRelatedFieldKey.Email,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Interests],
    filterField: UserRelatedFieldKey.Interests,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.BusinessExperience],
    filterField: UserRelatedFieldKey.BusinessExperience,
    filterFieldIsNotForApi: true,
    filterAdditionalFields: [
      UserRelatedFieldKey.BusinessExperienceCompany,
      UserRelatedFieldKey.BusinessExperienceCity,
      UserRelatedFieldKey.BusinessExperiencePosition,
    ],
    filterAdditionalFieldsIsNotForUrl: true,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Education],
    filterField: UserRelatedFieldKey.Education,
    filterFieldIsNotForApi: true,
    filterAdditionalFields: [UserRelatedFieldKey.EducationInstitution, UserRelatedFieldKey.EducationFaculty],
    filterAdditionalFieldsIsNotForUrl: true,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.StatusInClub],
    filterField: UserRelatedFieldKey.StatusInClub,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptions: MEMBER_STATUS_IN_CLUB_OPTIONS,
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Company],
    filterField: UserRelatedFieldKey.Company,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },

  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Sex],
    filterField: UserRelatedFieldKey.Sex,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptions: SEX_OPTIONS,
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.DateOfBirthday],
    filterField: UserRelatedFieldKey.DateOfBirthday,
    filterValueInputType: FilterValueInputType.Date,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.BusinessTurnover],
    filterField: UserRelatedFieldKey.BusinessTurnover,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.BusinessesTurnovers,
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.MeetingCounter],
    filterField: UserRelatedFieldKey.MeetingCounter,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.StartMembershipDate],
    filterField: UserRelatedFieldKey.StartMembershipDate,
    filterValueInputType: FilterValueInputType.Date,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.EndMembershipDate],
    filterField: UserRelatedFieldKey.EndMembershipDate,
    filterValueInputType: FilterValueInputType.Date,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  {
    viewName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.YearsOfMembership],
    filterField: UserRelatedFieldKey.YearsOfMembership,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
].map((option: ISelectOption) => {
  option.filterIsNotUnique = true;
  option.filterIsDialogParent = true;
  return option;
});

const FILTERS_KEYS_STAFF: string[] = [
  UserRelatedFieldKey.FirstName,
  UserRelatedFieldKey.LastName,
  UserRelatedFieldKey.MiddleName,
  UserRelatedFieldKey.Industries,
  UserRelatedFieldKey.CanBeUseful,
  UserRelatedFieldKey.Requests,
  UserRelatedFieldKey.Email,
  UserRelatedFieldKey.Interests,
  UserRelatedFieldKey.BusinessExperience,
  UserRelatedFieldKey.Education,
  UserRelatedFieldKey.StatusInClub,
  UserRelatedFieldKey.Company,
  UserRelatedFieldKey.Sex,
  UserRelatedFieldKey.DateOfBirthday,
  UserRelatedFieldKey.BusinessTurnover,
  UserRelatedFieldKey.MeetingCounter,
  UserRelatedFieldKey.StartMembershipDate,
  UserRelatedFieldKey.EndMembershipDate,
  UserRelatedFieldKey.YearsOfMembership,
];
export const MEMBERS_MODAL_FILTERS_OPTIONS_STAFF: ISelectOption[] = MEMBERS_MODAL_FILTERS_OPTIONS.filter((option) =>
  FILTERS_KEYS_STAFF.includes(option.filterField),
);

const FILTERS_KEYS_MEMBER: string[] = [
  UserRelatedFieldKey.FirstName,
  UserRelatedFieldKey.LastName,
  UserRelatedFieldKey.MiddleName,
  UserRelatedFieldKey.Industries,
  UserRelatedFieldKey.CanBeUseful,
  UserRelatedFieldKey.Requests,
  UserRelatedFieldKey.Email,
  UserRelatedFieldKey.Interests,
  UserRelatedFieldKey.BusinessExperience,
  UserRelatedFieldKey.Education,
  UserRelatedFieldKey.StatusInClub,
  UserRelatedFieldKey.Company,
  UserRelatedFieldKey.YearsOfMembership,
];
export const MEMBERS_MODAL_FILTERS_OPTIONS_MEMBER: ISelectOption[] = MEMBERS_MODAL_FILTERS_OPTIONS.filter((option) =>
  FILTERS_KEYS_MEMBER.includes(option.filterField),
);
