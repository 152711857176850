import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BaseClass } from '@shared/components/base/base.class';
import * as moment from 'moment';
import { EMAIL_REGEX } from './regexes';

export class CustomValidators extends BaseClass {
  static emailPattern(control: AbstractControl): ValidationErrors | null {
    return EMAIL_REGEX.test(control.value) ? null : { email: true };
  }

  static passwordMatch(control: AbstractControl): { invalid: boolean } {
    if (control.get('new_password1').value !== control.get('new_password2').value) {
      return { invalid: true };
    }
  }

  static dateCorrect(format: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return moment(control.value, format, true).isValid() ? null : { incorrectDate: true };
    };
  }

  static minDate(targetDate: moment.Moment, format: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (moment(control.value, format, true).isValid()) {
        const isDateLessThanTargetDate = moment(control.value, format, true).startOf('day').isBefore(targetDate);
        return isDateLessThanTargetDate ? { dateLessThanMinimum: { minDate: targetDate.format(format) } } : null;
      }
      return { incorrectDate: true };
    };
  }

  static maxDate(targetDate: moment.Moment, format: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (moment(control.value, format, true).isValid()) {
        const isDateGreaterThanTargetDate = moment(control.value, format, true).startOf('day').isAfter(targetDate);
        return isDateGreaterThanTargetDate ? { dateGreaterThanMaximum: { maxDate: targetDate.format(format) } } : null;
      }
      return { incorrectDate: true };
    };
  }

  static onlymultipleOf15InTime(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const time = moment(control.value).format('mm');

      return time !== '00' && time !== '15' && time !== '30' && time !== '45' ? { onlymultipleOf15InTime: true } : null;
    }

    return null;
  }

  static passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    // Проверка на спец символы
    const specSymb = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);

    /** Проверка на содержание цифр */
    const hasNumber = /[0-9]/.test(value);

    /** Проверка на минимальную длину пароля */
    const isLengthValid = value ? value.length >= 8 : false;

    /** Общая проверка */
    const passwordValid = hasNumber && isLengthValid && specSymb;

    if (!passwordValid) {
      return { invalidPassword: 'Пароль не прошел валидацию' };
    }
    return null;
  }

  static maxCountInIdArr(control: AbstractControl): ValidationErrors | null {
    const count = control.value;

    return count.length > 10 ? {maxCountInTagsArr : true} : null;
  }

  static maxPollLength(control: AbstractControl): ValidationErrors | null {
    const count = control.value;

    return count.length > 100 ? {maxPollLength : true} : null;
  }

  static compareEndAndStartDatetime(startDatetime: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate: Date = new Date(startDatetime.value)
      const endDate: Date = new Date(control.value);

      return startDate < endDate ? null : { compareEndAndStartDatetime: true };
    };
  }

  // Валидатор времени
  static timeValidator(control: AbstractControl): ValidationErrors | null {
      const value = control.value;

      if (value) {
        // Проверяем, что значение соответствует нужному формату
        // const isValidFormat = /^\d{4}$/.test(value);
        const isValidFormat = /^(\d{4}|\d{2}:\d{2})$/.test(value);

        if (isValidFormat) {
          let hours: number;
          let minutes: number;

          if(value.includes(':')) {
            hours = parseInt(value.substring(0, 2), 10);
            minutes = parseInt(value.substring(3, 5), 10);
          } else {
            hours = parseInt(value.substring(0, 2), 10);
            minutes = parseInt(value.substring(2, 4), 10);
          }

          const isHoursValid = hours >= 0 && hours <= 23;
          const isMinutesValid = minutes >= 0 && minutes <= 59;

          if (isHoursValid && isMinutesValid) {
            return null;
          }
        }
      }
      return {'invalidTime': {value: control.value}};
  }
}
