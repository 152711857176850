import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MembersUserService } from '@core/services/business/members/members-user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IN_ANIMATION } from '@shared/animations/in-out.animation';
import { UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { IUsersDialogConfig } from '@shared/models/user/members-dialog-config.model';
import { IUserWithProfile } from '@shared/models/user/view/user.model';
import * as _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const LIMIT = '10';

@UntilDestroy()
@Component({
  selector: 'kp-members-list-dialog',
  templateUrl: './members-list-dialog.component.html',
  styleUrls: ['./members-list-dialog.component.scss'],
  animations: [IN_ANIMATION],
})
export class MembersListDialogComponent implements OnInit {
  searchControl = new FormControl();
  isMembersLoading = false;
  isAllMembersLoaded = false;
  membersListEmpty = false;
  firstLoadingFinished = false;

  config: IUsersDialogConfig;
  members: IUserWithProfile[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: KeyValue<IUsersDialogConfig>,
    private dialogRef: MatDialogRef<MembersListDialogComponent>,
    private membersUserService: MembersUserService,
  ) {
    this.config = data.config;
  }

  ngOnInit() {
    if (this.config) {
      this._initMembers();
      this._searchControlChangesHandler();
    } else {
      this.closeDialog();
    }
  }

  private _searchControlChangesHandler() {
    this.searchControl.valueChanges.pipe(untilDestroyed(this), debounceTime(300)).subscribe((value: string) => {
      if (value === undefined || value === null) {
        return;
      }
      this.isAllMembersLoaded = false;
      this.getMembersByListRequestFromConfig(true);
    });
  }

  private _initMembers() {
    if (this.config.listItemsRequest) {
      this.getMembersByListRequestFromConfig(false, true);
    } else {
      this.config.searchEnabled = false;
      this._loadMembersByIds();
    }
  }

  getMembersByListRequestFromConfig(resetMembers = false, isFirstLoading = false) {
    if (this.isMembersLoading || this.isAllMembersLoaded || !this.config.listItemsRequest) {
      return;
    }

    let filters = this._getOffsetAndLimit(resetMembers);

    if (this.searchControl.value?.trim()) {
      const searchObj = {
        [UserRelatedFieldKey.FullName]: this.searchControl.value.trim(),
      };
      this.searchControl.setValue(searchObj[UserRelatedFieldKey.FullName], { emitEvent: false });

      filters = { ...filters, ...searchObj };
    }

    this.isMembersLoading = true;

    this.config
      .listItemsRequest(filters)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        this._membersListResponseActions(response, resetMembers, isFirstLoading);
      });
  }

  private _loadMembersByIds() {
    const membersReqs: Observable<IUserWithProfile>[] = [];

    this.config.membersIds.forEach((id) => {
      membersReqs.push(this.membersUserService.getUserById(id));
    });

    forkJoin(membersReqs)
      .pipe(untilDestroyed(this))
      .subscribe((members) => {
        this.members = members;
        this.firstLoadingFinished = true;
      });
  }

  private _membersListResponseActions(response: any, resetMembers = false, isFirstLoading = false) {
    if (!_.isArray(response)) {
      response = (response as ArrayPayload<unknown>).results;
    }

    if (!(response as []).length && !resetMembers) {
      this.isAllMembersLoaded = true;
      this.membersListEmpty = !this.members.length;
    } else {
      const members: IUserWithProfile[] = this.config.memberProfileKeyInParentObject
        ? response.map((member: any) => member[this.config.memberProfileKeyInParentObject])
        : response;

      if (resetMembers) {
        this.members = members;
        this.isAllMembersLoaded = !members.length;
      } else {
        this.members.push(...members);
      }
    }
    this.isMembersLoading = false;

    if (isFirstLoading) {
      this.firstLoadingFinished = true;
    }
  }

  private _getOffsetAndLimit(resetOffset?: boolean) {
    const filters: { limit: string; offset: string } = {
      limit: LIMIT,
      offset: this.members && !resetOffset ? this.members.length.toString() : '0',
    };
    return filters;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
