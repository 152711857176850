import { PostTypeKey, PostStatusKey } from '../enums/keys.enum';

export const POST_TYPE_KEY_TO_VIEW_NAME: Partial<Record<PostTypeKey, string>> = {
  [PostTypeKey.Events]: 'Мероприятия',
  [PostTypeKey.Forums]: 'Форумы',
  [PostTypeKey.KnowledgeManagment]: 'Управление знаниями',
  [PostTypeKey.Projects]: 'Проекты',
  [PostTypeKey.Travels]: 'Путешествия',
  [PostTypeKey.Communities]: 'Сообщества',
  [PostTypeKey.CommonInfo]: 'Общая информация',
  [PostTypeKey.Members]: 'Участники',
};

export const POST_STATUS_KEY_TO_VIEW_NAME: Record<PostStatusKey, string> = {
  [PostStatusKey.Applied]: 'Принят',
  [PostStatusKey.OnModeration]: 'На модерации',
  [PostStatusKey.Cancelled]: 'Отклонен',
};
