import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormValidationService } from '@core/services/business/utils/form-validation.service';
import { MembersUserService } from '@core/services/business/members/members-user.service';
import { MEMBERS_ROLE_OPTIONS_MOCK } from '@modules/members/constants/selects-options.const';
import { BaseFormComponent } from '@shared/components/base-form/base-form.component';
import { IconTextDialogComponent } from '@shared/dialogs/icon-text-dialog/icon-text-dialog.component';
import { CustomValidators } from '@shared/utils/custom-validators';
import { UtilsService } from '@core/services/business/utils/utils.service';
import { IUserWithProfileDto } from '@shared/models/user/dto/user-dto.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserTypeKey } from '@shared/enums/keys.enum';
import { FORM_OF_PARTICIPATION_OPTIONS } from '@modules/profile/constants/selects-options.const';

@UntilDestroy()
@Component({
  selector: 'kp-member-add-dialog',
  templateUrl: './member-add-dialog.component.html',
  styleUrls: ['./member-add-dialog.component.scss'],
})
export class MemberAddDialogComponent extends BaseFormComponent implements OnInit {
  form: FormGroup;
  formOfParticipationOptions = FORM_OF_PARTICIPATION_OPTIONS;
  membersRoleOptionsMock = MEMBERS_ROLE_OPTIONS_MOCK;

  constructor(
    protected formValidationService: FormValidationService,
    private dialogRef: MatDialogRef<MemberAddDialogComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private membersUserService: MembersUserService,
    private utilsService: UtilsService,
  ) {
    super(formValidationService);
  }

  ngOnInit() {
    this._initForm();
  }

  private _initForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, CustomValidators.emailPattern]],
      username: ['', [Validators.required, Validators.maxLength(150)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      first_name: ['', [Validators.required, Validators.maxLength(150)]],
      last_name: ['', [Validators.required, Validators.maxLength(150)]],
      middle_name: ['', [Validators.maxLength(150)]],
      form_of_participation: ['', Validators.required],
      groups: ['', Validators.required],
      admin: ['', Validators.required],
      curator: ['', Validators.required],
      user_type: UserTypeKey.Member,
    });
  }

  validateFormAndCreateUser() {
    this.validateAllFormFields(this.form);
    if (this.form.valid && this.form.enabled) {
      this.form.disable();
      this._createUserFromForm();
    }
  }

  private _createUserFromForm() {
    const userDto = this.utilsService.removeEmptyElementsFromObjectDeep<IUserWithProfileDto>(this.form.value);

    this.membersUserService
      .createUserAndAddToUsers(userDto, this.membersUserService.users$$)
      .pipe(untilDestroyed(this))
      .subscribe(
        (createdMember) => {
          this.closeDialog();
          this._openSuccessResultDialog(createdMember.firstAndLastName);
        },
        () => this.form.enable(),
      );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  private _openSuccessResultDialog(memberName: string) {
    this.dialog.open(IconTextDialogComponent, {
      data: { text: `Участник ${memberName} успешно добавлен` },
    });
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get username(): AbstractControl {
    return this.form.get('username');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get first_name(): AbstractControl {
    return this.form.get('first_name');
  }

  get last_name(): AbstractControl {
    return this.form.get('last_name');
  }

  get middle_name(): AbstractControl {
    return this.form.get('middle_name');
  }

  get form_of_participation(): AbstractControl {
    return this.form.get('form_of_participation');
  }

  get groups(): AbstractControl {
    return this.form.get('groups');
  }

  get admin(): AbstractControl {
    return this.form.get('admin');
  }

  get curator(): AbstractControl {
    return this.form.get('curator');
  }
}
