import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@core/services/business/utils/utils.service';

/**
 * Обнаруживает ссылки в переданной строке и оборачивает в тег <a>.
 */
@Pipe({
  name: 'autolinker',
})
export class AutolinkerPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}

  transform(target: string) {
    return this.utilsService.transformLinksInStringToHtml(target);
  }
}
