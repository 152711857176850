<div *ngIf="image; else fileBlock">
  <div class="file">
    <img [src]="imageDto.isNewFile ? imageDto.src : imageView.image" class="file__image-preview" />

    <div class="file-progress">
      <div class="file-progress__custom-upload">
        {{ imageDto.isNewFile ? imageDto.image.name : imageView.filename }}
        <span>{{ (imageDto.isNewFile ? imageDto.image.size : imageView.file_size) | transformWordFileSize }} </span>
      </div>

      <progress [value]="imageDto.isNewFile ? fileLoadProgress : fileLoadTotal" [max]="fileLoadTotal" class="progress-bar"></progress>
    </div>

    <kp-svg key="xCircle" size="20px" class="file__delete-icon" (click)="deleteFileFromPost()"></kp-svg>
  </div>
</div>

<ng-template #fileBlock>
  <div class="file">
    <kp-svg key="fileText" size="20px" class="file__text-icon"></kp-svg>

    <div class="file-progress">
      <div class="file-progress__custom-upload">
        {{ fileDto.isNewFile ? fileDto.file.name : fileView.filename }}
        <span>{{ (fileDto.isNewFile ? fileDto.file.size : fileView.file_size) | transformWordFileSize }}</span>
      </div>

      <progress [value]="fileDto.isNewFile ? fileLoadProgress : fileLoadTotal" [max]="fileLoadTotal" class="progress-bar"></progress>
    </div>

    <kp-svg key="xCircle" size="20px" class="file__delete-icon" (click)="deleteFileFromPost()"></kp-svg>
  </div>
</ng-template>
