import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IForumLink } from '@shared/models/forums/view/forum.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ForumLinkHttpService {
  private readonly _apiPath = '/forums/forum-link/';

  constructor(private http: HttpService) {}

  getAll(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IForumLink>> {
    return this.http.get<ArrayPayload<IForumLink>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getById(id: number): Observable<IForumLink> {
    return this.http.get<IForumLink>(`${this._apiPath}${id}/`);
  }

  create(dto: Partial<IForumLink>): Observable<IForumLink> {
    return this.http.post<IForumLink>(this._apiPath, dto);
  }

  updateById(id: number, dto: Partial<IForumLink>): Observable<IForumLink> {
    return this.http.put<IForumLink>(`${this._apiPath}${id}/`, dto);
  }

  patchById(id: number, dto: Partial<IForumLink>): Observable<IForumLink> {
    return this.http.patch<IForumLink>(`${this._apiPath}${id}/`, dto);
  }

  deleteById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }
}
