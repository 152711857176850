<div class="button-container" [@inAnimation] *ngIf="filterDialogService.activeFilters?.length; else filterIconTemplate">
  <button type="button" class="button" (click)="openFilterDialog()">
    <kp-svg key="filter" size="16px"></kp-svg>
    {{ filterDialogService.activeFilters.length | transformWordByCount: 'параметр' }}
  </button>

  <kp-svg class="button-container__reset-icon" key="xCircle" size="20px" (click)="resetFilters()" [kpTooltip]="'Сбросить фильтры'"></kp-svg>
</div>

<ng-template #filterIconTemplate>
  <kp-svg [@inAnimation] class="independent-filter-icon" key="filter" size="20px" (click)="openFilterDialog()"></kp-svg>
</ng-template>
