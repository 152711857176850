import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NewslinePostCommentHttpService } from '@core/services/api/newsline/newsline-post-comment-http.service';
import { IFile, IFileDto } from '@shared/models/file.model';
import { INewslinePostCommentDto } from '@shared/models/newsline/dto/newsline-post-comment-dto.model';
import { INewslinePostCommentImageDto } from '@shared/models/newsline/dto/newsline-post-comment-image-dto.model';
import { INewslinePostCommentImage } from '@shared/models/newsline/view/newsline-post-comment-image.model';
import { INewslinePostComment } from '@shared/models/newsline/view/newsline-post-comment.model';
import { INewslinePost } from '@shared/models/newsline/view/newsline-post.model';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { DateService } from '../utils/date.service';
import { NewslinePostCommentFileService } from './newsline-post-comment-file.service';
import { NewslinePostCommentImageService } from './newsline-post-comment-image.service';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostCommentService {
  constructor(
    private newslinePostCommentHttpService: NewslinePostCommentHttpService,
    private dateService: DateService,
    private authService: AuthService,
    private newslinePostCommentImageService: NewslinePostCommentImageService,
    private newslinePostCommentFileService: NewslinePostCommentFileService,
  ) {}

  getCommentsByPostIdAndAddToPost(postId: number, targetPost?: INewslinePost): Observable<INewslinePostComment[]> {
    if (targetPost) {
      targetPost.isLoadingComments = true;
    }

    return this.newslinePostCommentHttpService.getComments({ post: postId.toString() }).pipe(
      map((response) => {
        const commentsDtos = response.results;
        const comments: INewslinePostComment[] = [];
        commentsDtos.forEach((commentDto) => {
          comments.push(this._transformPostCommentDtoToPostCommentView(commentDto));
        });

        if (targetPost) {
          if (targetPost.comments?.length) {
            targetPost.comments.push(...comments);
          } else {
            targetPost.comments = comments;
          }
          targetPost.isLoadingComments = false;
        }

        return comments;
      }),
    );
  }

  getCommentsWithAttachmentsByPostIdAndAddToPost(postId: number, targetPost?: INewslinePost): Observable<INewslinePostComment[]> {
    return this.getCommentsByPostIdAndAddToPost(postId, targetPost).pipe(
      mergeMap((comments) => {
        const attachmentsRequests: Array<Observable<INewslinePostCommentImage[]> | Observable<IFile[]>> = [];
        const targetComments = targetPost?.comments ? targetPost.comments : comments;

        targetComments.forEach((comment) => {
          attachmentsRequests.push(
            this.newslinePostCommentImageService.getImagesByCommentIdAndAddToComment(comment.id, comment),
            this.newslinePostCommentFileService.getFilesByCommentIdAndAddToComment(comment.id, comment),
          );
        });

        if (attachmentsRequests.length) {
          return forkJoin(attachmentsRequests).pipe(map(() => targetComments));
        }
        return of(targetComments);
      }),
    );
  }

  getCommentById(id: number): Observable<INewslinePostComment> {
    return this.newslinePostCommentHttpService.getCommentById(id).pipe(
      map((commentDto) => {
        return this._transformPostCommentDtoToPostCommentView(commentDto);
      }),
    );
  }

  createCommentAndAddToPost(
    commentToCreate: Partial<INewslinePostCommentDto>,
    targetPost?: INewslinePost,
    formToReset?: FormGroup,
  ): Observable<INewslinePostComment> {
    return this.newslinePostCommentHttpService.createComment(commentToCreate).pipe(
      map((createdCommentDto) => {
        const createdComment = this._transformPostCommentDtoToPostCommentView(createdCommentDto);

        if (targetPost) {
          if (!targetPost.comments?.length) {
            targetPost.comments = [];
          }
          targetPost.comments.push(createdComment);
        }
        formToReset?.reset();

        return createdComment;
      }),
    );
  }

  createCommentWithAttachmentsAndAddToPost(
    comment: Partial<INewslinePostCommentDto>,
    targetPost?: INewslinePost,
    formToReset?: FormGroup,
    images?: INewslinePostCommentImageDto[],
    files?: IFileDto[],
  ): Observable<INewslinePostComment> {
    return this.createCommentAndAddToPost(comment, targetPost, formToReset).pipe(
      mergeMap((createdComment) => {
        const targetComment = targetPost ? targetPost.comments.find((c) => c.id === createdComment.id) : createdComment;
        const attachmentsRequests = this._getCreateRequestsForCommentAttachments(targetComment, images, files);
        return forkJoin(attachmentsRequests).pipe(
          map(() => {
            return targetComment;
          }),
        );
      }),
    );
  }

  updateCommentByIdAndReplaceInPost(
    commentId: number,
    commentDto: Partial<INewslinePostCommentDto>,
    targetPost?: INewslinePost,
    formToReset?: FormGroup,
  ): Observable<INewslinePostComment> {
    return this.newslinePostCommentHttpService.updateCommentById(commentId, commentDto).pipe(
      map((updatedCommentDto) => {
        const updatedComment = this._transformPostCommentDtoToPostCommentView(updatedCommentDto);

        if (targetPost?.comments?.length) {
          const targetCommentIdx = targetPost.comments.findIndex((c) => c.id === updatedComment.id);
          if (targetCommentIdx !== -1) {
            targetPost.comments[targetCommentIdx] = updatedComment;
          }
        }
        formToReset?.reset();

        return updatedComment;
      }),
    );
  }

  deleteCommentByIdAndRemoveFromPost(commentId: number, targetPost?: INewslinePost): Observable<void> {
    const commentToDeleteIdx = targetPost.comments.findIndex((c) => c.id === commentId);
    if (commentToDeleteIdx !== -1) {
      targetPost.comments[commentToDeleteIdx].isDeleting = true;
    }

    return this.newslinePostCommentHttpService.deleteCommentById(commentId).pipe(
      tap(
        () => {
          if (targetPost?.comments?.length) {
            targetPost.comments = targetPost.comments.filter((comment) => comment.id !== commentId);
          }
        },
        () => {
          if (commentToDeleteIdx !== -1) {
            targetPost.comments[commentToDeleteIdx].isDeleting = true;
          }
        },
      ),
    );
  }

  likeCommentByIdAndUpdateCommentState(commentId: number, targetComment?: INewslinePostComment): Observable<void> {
    if (targetComment) {
      targetComment.isLikeSwitchLoading = true;
    }

    return this.newslinePostCommentHttpService.likeCommentById(commentId).pipe(
      tap(() => {
        if (targetComment) {
          targetComment.liked_users.push(this.authService.currentUser?.id);
          targetComment.likes_count += 1;
          targetComment.isLikedByCurrentUser = true;
          targetComment.isLikeSwitchLoading = false;
        }
      }),
    );
  }

  unlikeCommentByIdAndUpdateCommentState(commentId: number, targetComment?: INewslinePostComment): Observable<void> {
    if (targetComment) {
      targetComment.isLikeSwitchLoading = true;
    }

    return this.newslinePostCommentHttpService.unlikeCommentById(commentId).pipe(
      tap(() => {
        if (targetComment) {
          targetComment.liked_users = targetComment.liked_users.filter((userId) => userId !== this.authService.currentUser?.id);
          targetComment.likes_count -= 1;
          targetComment.isLikedByCurrentUser = false;
          targetComment.isLikeSwitchLoading = false;
        }
      }),
    );
  }

  private _getCreateRequestsForCommentAttachments(
    targetComment: INewslinePostComment,
    images?: INewslinePostCommentImageDto[],
    files?: IFileDto[],
  ) {
    let imagesRequests: Observable<INewslinePostCommentImage>[] = [];
    if (images?.length) {
      imagesRequests = images?.map((imageDto) =>
        this.newslinePostCommentImageService.createImageAndAddToComment(
          {
            image: imageDto.image,
            comment: targetComment.id,
          },
          targetComment,
        ),
      );
    }

    let filesRequests: Observable<IFile>[] = [];
    if (files?.length) {
      filesRequests = files?.map((fileDto) =>
        this.newslinePostCommentFileService.createFileAndAddToComment(
          {
            file: fileDto.file,
            comment: targetComment.id,
          },
          targetComment,
        ),
      );
    }

    return [...imagesRequests, ...filesRequests];
  }

  private _transformPostCommentDtoToPostCommentView(commentDto: INewslinePostCommentDto): INewslinePostComment {
    let commentView: Partial<INewslinePostComment> = {};

    commentView.created = this.dateService.transfromStringToMoment(commentDto.created);
    commentView.author = this.authService.transformUserShortDtoToUserShortView(commentDto.author);
    commentView.isLikedByCurrentUser = commentDto.liked_users.some((userId) => userId === this.authService.currentUser?.id);

    commentView = Object.assign(commentDto, commentView);
    return commentView as INewslinePostComment;
  }
}
