export enum MeetingsTypeKey {
  AllMeetings = '1',
  MyMeetings = '2',
}

export enum MeetingsTypeOfPaidKey {
  Free = '0',
  Paid = '1',
}

export enum MeetingsFormatKey {
  Offline = '0',
  Online = '1',
}

export enum MeetingTypeKey {
  Setup = '0',
  Common = '1',
}

export enum MeetingStateKey {
  Draft = '0',
  Published = '1',
  Finished = '2',
  Cancelled = '3',
  InProgress = '4',
}

export enum MeetingMemberStateKey {
  MustVisit = '0',
  ConfirmedParticipation = '1',
  ReminderSent = '2',
  Visited = '3',
  Refusal = '4',
  PaymentPending = '5',
  Missed = '6',
  MeetingCanceled = '7',
  NotInForum = '8',
}

export enum MeetingMemberPaymentStateKey {
  Paid = '0',
  NotPaid = '1',
  Free = '2',
}

export enum MeetingRelatedFieldKey {
  /**
   * Data fields that exist in the backend
   */
  Avatar = 'avatar',
  CheckInComment = 'check_in_comment',
  Created = 'created',
  Creator = 'creator',
  Cost = 'cost',
  Description = 'description',
  Editors = 'editors',
  FinishedAt = 'finished_at',
  Format = 'format',
  Forum = 'forum',
  ForumBranch = 'forum_branch',
  ForumBranchNumber = 'forum_branch_number',
  ForumType = 'forum_type',
  Id = 'id',
  Ignore = 'ignore',
  MembersCount = 'members_count',
  Moderator = 'moderator',
  Name = 'name',
  Number = 'number',
  RefusedMembersCount = 'refused_members_count',
  PaymentType = 'payment_type',
  StartedAt = 'started_at',
  State = 'state',
  Location = 'location',
  Updated = 'updated',
  VisitedMembersCount = 'visited_members_count',

  /**
   * Data fields that exist only in the web interface
   */
  EditPageRoute = 'editPageRoute',
  Files = 'files',
  IncludedInForum = 'includedInForum',
  IsDeleting = 'isDeleting',
  IsEditing = 'isEditing',
  IsLoading = 'isLoading',
  IsSelected = 'isSelected',
  IsStateCancelled = 'isStateCancelled',
  IsStateDraft = 'isStateDraft',
  IsStateFinished = 'isStateFinished',
  IsStateInProgress = 'isStateInProgress',
  IsStatePublished = 'isStatePublished',
  MeetingPageRoute = 'meetingPageRoute',
  Members = 'members',
  MenuOptionsInList = 'menuOptionsInList',
  MenuOptionsOnProfilePage = 'menuOptionsOnProfilePage',
  Links = 'links',

  /**
   * Fields are used only for filters, exist in the backend
   */
  Address = 'location',
  ForumLocation = 'forum_location',
  Limit = 'limit',
  MeetingFormat = 'meeting_format',
  Offset = 'offset',
  Ordering = 'ordering',
  Type = 'type',
  User = 'user',

  /**
   * Fields are used only for filters, exist only in the web interface
   */
  NameFromDialog = 'nameFromDialog',
  ModeratorFromDialog = 'moderatorFromDialog',
  MeetingType = 'meeting_type',
  UserFromDialog = 'userFromDialog',
}

export enum MeetingMemberRelatedFieldKey {
  /**
   * Data fields that exist in the backend
   */
  CheckInComment = 'check_in_comment',
  Id = 'id',
  Meeting = 'meeting',
  PaymentState = 'payment_state',
  Permissions = 'permissions',
  ReasonOfRefusal = 'reason_of_refusal',
  ReasonOfRefusalComment = 'reason_of_refusal_comment',
  State = 'state',
  User = 'user',

  /**
   * Data fields that exist only in the web interface
   */
  IsDeleting = 'isDeleting',
  IsEditing = 'isEditing',
  IsLoading = 'isLoading',
  MenuOptions = 'menuOptions',
  Profile = 'profile',
  UserAdminProfile = 'userAdminProfile',
}
