<div class="container" *ngIf="firstLoadingFinished">
  <div class="title" *ngIf="config.title && !membersListEmpty">{{ config.title }}</div>

  <div class="form-control" *ngIf="config.searchEnabled && !membersListEmpty">
    <div class="form-control__input">
      <input placeholder="Поиск..." type="text" [formControl]="searchControl" />
      <kp-svg
        [key]="searchControl.value?.trim() ? 'xCircle' : 'search'"
        size="16px"
        [cursor]="searchControl.value?.trim() ? 'pointer' : 'default'"
        (click)="searchControl.value?.trim() ? searchControl.setValue('') : null"
      ></kp-svg>
    </div>
  </div>

  <button *ngIf="config.showCloseIcon">X</button>

  <div
    class="member-list"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50"
    (scrolled)="getMembersByListRequestFromConfig()"
    [scrollWindow]="false"
    [alwaysCallback]="true"
    *ngIf="members?.length; else noDataTemplate"
    [@inAnimation]
  >
    <a class="member-list-item" *ngFor="let member of members" [routerLink]="[member.profilePageRoute]" (click)="closeDialog()">
      <img class="circle-img" [src]="member.avatar" />
      <div>{{ member.firstAndLastName }}</div>
    </a>
  </div>

  <ng-template #noDataTemplate>
    <kp-no-data
      *ngIf="isAllMembersLoaded && !members.length"
      [@inAnimation]
      fontSize="14px"
      paddingY="20px"
      iconTextGap="12px"
      [text]="config.noDataText || 'Список участников пуст'"
    ></kp-no-data>
  </ng-template>
</div>
