import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValue } from '@shared/models/key-value.model';
import { NewslinePostService } from '@core/services/business/newsline/newsline-post.service';
import { QueryParams } from '@shared/models/query-params.model';

@Component({
  selector: 'kp-post-likes-dialog',
  templateUrl: './post-likes-dialog.component.html',
  styleUrls: ['./post-likes-dialog.component.scss']
})
export class PostLikesDialogComponent implements OnInit {
  public postPreviewLikesArr: any[] = [];
  public likesCount: number;
  public postId: number;

  private params: QueryParams = {
    limit: 20,
    offset: 0,
  };

  constructor(@Inject(MAT_DIALOG_DATA) data: KeyValue<any>, private newslinePostService: NewslinePostService) {
    this.postId = data.postId;
  }

  ngOnInit(): void {
    this._getPostPreviewLikes();
  }

  public onScroll(): void {
    const likedUsersCount: number = this.likesCount;

    if (
      likedUsersCount > this.params.offset &&
      likedUsersCount > (this.params.offset as number) + (this.params.limit as number)
    ) {
      this.params.offset = (this.params.offset as number) + 20;
      this._getPostPreviewLikes();
    }
  }

  private _getPostPreviewLikes(): void {
    this.newslinePostService.getPostLikes(this.postId, this.params).subscribe((res): void => {
      this.likesCount = res.count;
      this.postPreviewLikesArr = [...this.postPreviewLikesArr, ...res.results];
    })
  }
}
