import { Component, Input } from '@angular/core';
import { IUserWithProfile } from '@shared/models/user/view/user.model';

@Component({
  selector: 'kp-members-list-preview',
  templateUrl: './members-list-preview.component.html',
  styleUrls: ['./members-list-preview.component.scss'],
})
export class MembersListPreviewComponent {
  @Input() membersPath: string;
  @Input() members: IUserWithProfile[];
  @Input() membersCount: number;
}
