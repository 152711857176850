import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kpAutosizeTextarea]',
})
export class KpAutozieTextareaDirective {
  @Input() maxHeightPx: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('ngModelChange', ['$event'])
  onNgModelChange() {
    this._setHeightToTextarea();
  }

  private _setHeightToTextarea() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', `0`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${this.elementRef.nativeElement.scrollHeight}px`);
  }
}
