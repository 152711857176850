import { LocationStrategy } from '@angular/common';
import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRefService } from '@core/services/business/utils/window.service';

@Directive({
  selector: '[kpInnerHtmlLink]',
})
export class InnerHtmlLinkDirective {
  constructor(
    private router: Router,
    private windowRefService: WindowRefService,
    private LocationStrategy: LocationStrategy,
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: PointerEvent): void {
    let href = (event.target as HTMLElement).getAttribute('href');
    const origin = this.windowRefService.nativeWindow.location.origin + this.LocationStrategy.getBaseHref();
    if (!href || !href.startsWith(origin)) {
      return;
    }

    href = href.replace(origin, '');
    event.preventDefault();
    this.router.navigate([href]);
  }
}
