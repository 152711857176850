import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { KeyValue } from '@shared/models/key-value.model';
import { Locale } from '@shared/declarations/locale.type';
import { EMOJI_RU_LOCALE } from '@shared/locale/emoji-ru-locale';

@Component({
  selector: 'kp-emoji-box',
  templateUrl: './emoji-box.component.html',
  styleUrls: ['./emoji-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiBoxComponent {
  @Output() public emojiClickEvent: EventEmitter<EmojiData> = new EventEmitter<EmojiData>();
  @ViewChild('emojiContainerTemplate', { static: true }) public emojiContainerEl: ElementRef;

  public readonly emojiLocale: Locale = EMOJI_RU_LOCALE;

  public get emojiPopupStyle(): KeyValue<string> {
    return { position: 'absolute', bottom: '20px', right: '20px' };
  }

  public emojiClick(emoji: EmojiData): void {
    this.emojiClickEvent.emit(emoji);
  }
}
