import { Injectable } from '@angular/core';
import { objectKeys } from 'codelyzer/util/objectKeys';
import { Observable, ReplaySubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';

interface CacheItem<T> {
  dataFlow: ReplaySubject<T>;
  validTill: number;
}

type Cache = Record<string, CacheItem<unknown>>;

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private _cache: Cache = {};

  constructor(private router: Router) {
  }

  public cache<T>(path: string[], liveTime = 5000): (source$: Observable<T>) => Observable<T> {
    return (source$) => {
      return new Observable<T>((observer) => {
        let completed = false;
        const _cache = this.getCache<T>(path);

        if (_cache) {
          _cache.dataFlow.pipe(takeWhile(() => !completed)).subscribe((data) => observer.next(data));
        } else {
          const dataFlow = new ReplaySubject(1);
          this.setCache(path, dataFlow, liveTime);

          source$.pipe(takeWhile(() => !completed)).subscribe((data) => {
            this.setCache(path, dataFlow, liveTime);
            dataFlow.next(data);
            observer.next(data);
          },
            (error): void => {
              this.router.navigate(['not-found-page'])

              observer.error(error);
              this.dropCache(path);
            });
        }

        return function unsubscribe() {
          completed = true;
        };
      });
    };
  }

  public dropCache(path: string[]): void {
    const partPath = path.join('|');
    const keys = objectKeys(this._cache);

    for (let i = 0; i < keys.length; i++) {
      if (keys[i].startsWith(partPath)) {
        delete this._cache[keys[i]];
      }
    }
  }

  private getCache<T>(path: string[]): CacheItem<T> | undefined {
    const _cache = this._cache[path.join('|')];
    if (_cache?.validTill < new Date().getTime()) {
      delete this._cache[path.join('|')];
      return undefined;
    }

    return this._cache[path.join('|')] as CacheItem<T>;
  }

  private setCache(path: string[], dataFlow: ReplaySubject<unknown>, liveTime: number) {
    this._cache[path.join('|')] = {
      dataFlow,
      validTill: this.tillTime(liveTime),
    };
  }

  private tillTime(leaveTime: number) {
    return new Date().getTime() + leaveTime;
  }
}
