import { Injectable, Inject, ElementRef, RendererFactory2, Renderer2, SecurityContext } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IBlockOverflowState } from '@shared/models/block-overflow-state.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  renderer: Renderer2;
  clickOnDocument$ = new Subject<MouseEvent>();
  scrollOnDocument$ = new Subject<Event>();

  constructor(@Inject(DOCUMENT) public document: Document, rendererFactory: RendererFactory2, private sanitizer: DomSanitizer) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  sanitizeHtml(html: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.bypassSecurityTrustHtml(html));
  }

  bypassSecurityTrustHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getElementById(id: string) {
    return this.document.getElementById(id);
  }

  getElementsByClassName(className: string) {
    return this.document.getElementsByClassName(className);
  }

  getElementsByTagName(tagName: string) {
    return this.document.getElementsByTagName(tagName);
  }

  get body(): HTMLBodyElement {
    return this.document.getElementsByTagName('body')[0];
  }

  isClickInsideTargetElement(clickEvent: MouseEvent, targetEl: Element): boolean {
    return this.isElementHasTarget(clickEvent.target, targetEl);
  }

  isElementHasTarget(target: Element | EventTarget, intendedParent: Element): boolean {
    return intendedParent.contains(target as Element);
  }

  isElementHaveScrollbar(elementIdOrElementRef: string | ElementRef): boolean {
    const el = typeof elementIdOrElementRef === 'string' ? this.getElementById(elementIdOrElementRef) : elementIdOrElementRef.nativeElement;

    if (el) {
      return el.offsetWidth < el.scrollWidth;
    }
    return false;
  }

  isElementScrolledToBottom(elementIdOrElementRef: string | ElementRef, reachBottomBoundPx = 0): boolean {
    const el = typeof elementIdOrElementRef === 'string' ? this.getElementById(elementIdOrElementRef) : elementIdOrElementRef.nativeElement;

    if (el) {
      return el.scrollTop + el.clientHeight >= el.scrollHeight - reachBottomBoundPx;
    }
    return false;
  }

  removeClassesFromElementsAndChildren(els: Element[], classes: string[]) {
    els.forEach((el) => {
      classes.forEach((customClass) => {
        Array.from(el.getElementsByClassName(customClass)).forEach((targetEl) => this.renderer.removeClass(targetEl, customClass));
      });
    });
  }

  enableYScroll(el: Element) {
    this.renderer.setStyle(el, 'overflow-y', 'scroll');
  }

  disableYScroll(el: Element) {
    this.renderer.setStyle(el, 'overflow-y', 'hidden');
  }

  getBlockOverflowState(el: Element): Partial<IBlockOverflowState> {
    const blockOverflowState: Partial<IBlockOverflowState> = {};
    if (el) {
      blockOverflowState.height = el.clientHeight;
      blockOverflowState.width = el.clientWidth;

      const bounding = el.getBoundingClientRect();
      blockOverflowState.boundingLeft = bounding.left;
      blockOverflowState.boundingTop = bounding.top;
      blockOverflowState.boundingRight = bounding.right;
      blockOverflowState.boundingBottom = bounding.bottom;

      blockOverflowState.leftCrawledOut = bounding.left < 0 ? Math.abs(bounding.left) : 0;

      blockOverflowState.topCrawledOut = bounding.top < 0 ? Math.abs(bounding.top) : 0;

      const bottomCrawledOut = bounding.bottom - this.document.documentElement.clientHeight;
      blockOverflowState.bottomCrawledOut = bottomCrawledOut > 0 ? bottomCrawledOut : 0;

      const rightCrawledOut = bounding.right - this.document.documentElement.clientWidth;
      blockOverflowState.rightCrawledOut = rightCrawledOut > 0 ? rightCrawledOut : 0;

      blockOverflowState.isNotFitInContainer =
        blockOverflowState.leftCrawledOut > 0 ||
        blockOverflowState.topCrawledOut > 0 ||
        blockOverflowState.bottomCrawledOut > 0 ||
        blockOverflowState.rightCrawledOut > 0;
    } else {
      blockOverflowState.isNotFitInContainer = false;
    }
    return blockOverflowState;
  }

  isElementFitInContainer(el: Element): boolean {
    return !this.getBlockOverflowState(el).isNotFitInContainer;
  }
}
