import { Component } from '@angular/core';

export interface PeriodicElement {
  name?: string;
  position?: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 'ИНН:', name: '772573150' },
  { position: 'КПП:', name: '772501001' },
  { position: 'ОГРН:', name: '1117746638360' },
  { position: 'Адрес:', name: '115162, г. Москва, ул. Шухова, д. 14, комната 26' },
  { position: 'E-mail:', name: 'info@cmsteam.ru' },
  { position: 'Руководитель:', name: ' Управляющий - ИП Карпов Максим Сергеевич' },
];

@Component({
  selector: 'kp-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent {
  displayedColumns: string[] = ['position', 'name'];
  dataSource = ELEMENT_DATA;
}
