import { Injectable } from '@angular/core';
import { NewslinePostFileHttpService } from '@core/services/api/newsline/newsline-post-file-http.service';
import { IFile, IFileDto } from '@shared/models/file.model';
import { INewslinePost } from '@shared/models/newsline/view/newsline-post.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostFileService {
  constructor(private newslinePostFileHttpService: NewslinePostFileHttpService) {}

  getFilesByPostIdAndAddToPost(postId: number, post?: INewslinePost): Observable<IFile[]> {
    if (post) {
      post.isLoadingFiles = true;
    }

    return this.newslinePostFileHttpService.getFiles({ post: postId.toString() }).pipe(
      map((response) => {
        if (post) {
          post.files = response.results;
          post.isLoadingFiles = false;
        }

        return response.results;
      }),
    );
  }

  getFileById(id: number): Observable<IFile> {
    return this.newslinePostFileHttpService.getFileById(id);
  }

  createFileAndAddToPost(fileToCreate: IFileDto, post?: INewslinePost): Observable<IFile> {
    return this.newslinePostFileHttpService.createFile(fileToCreate).pipe(
      tap((createdFile) => {
        if (post) {
          if (!post.files?.length) {
            post.files = [];
          }
          post.files.push(createdFile);
        }
      }),
    );
  }

  updateFileByIdAndReplaceInPost(fileId: number, fileDto: IFileDto, post?: INewslinePost): Observable<IFile> {
    return this.newslinePostFileHttpService.updateFileById(fileId, fileDto).pipe(
      tap((updatedFile) => {
        if (post?.files) {
          const targetFileIdx = post.files.findIndex((file) => file.id === updatedFile.id);
          if (targetFileIdx !== -1) {
            post.files[targetFileIdx] = updatedFile;
          }
        }
      }),
    );
  }

  deleteFileByIdAndRemoveFromPost(fileId: number, post?: INewslinePost): Observable<void> {
    const fileToDeleteIdx = post?.files?.findIndex((p) => p.id === fileId);
    if (fileToDeleteIdx !== -1) {
      post.files[fileToDeleteIdx].isDeleting = true;
    }

    return this.newslinePostFileHttpService.deleteFileById(fileId).pipe(
      tap(
        () => {
          if (post.files) {
            post.files = post.files?.filter((file) => file.id !== fileId);
          }
        },
        () => {
          if (fileToDeleteIdx !== -1) {
            post.files[fileToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }
}
