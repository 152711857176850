import { Injectable } from '@angular/core';
import { ForumReasonForLeavingHttpService } from '@core/services/api/forums/forum-reason-for-leaving.service';
import { IdName } from '@shared/models/id-name.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ForumReasonForLeavingService {
  reasons: IdName[] = [];

  constructor(private forumReasonForLeavingHttpService: ForumReasonForLeavingHttpService) {}

  getAllAndAddToTargetList(targetList?: IdName[], params?: KeyValue<string>): Observable<ArrayPayload<IdName>> {
    return this.forumReasonForLeavingHttpService.getAll(params).pipe(
      map((response) => {
        if (!targetList) {
          targetList = [];
        }

        targetList.push(...response.results);

        const result: ArrayPayload<IdName> = {
          results: targetList,
          count: response.count,
        };

        return result;
      }),
    );
  }

  getById(id: number): Observable<IdName> {
    return this.forumReasonForLeavingHttpService.getById(id);
  }
}
