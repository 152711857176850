<a class="default-container default-container--column members-preview-container" [routerLink]="[membersPath]">
  <div class="default-container__header">
    <div>Участники</div>
    <div>{{ membersCount }}</div>
  </div>

  <div class="members-list">
    <ng-container *ngIf="members.length; else noDataTemplate">
      <div class="default-container__item default-container__item--no-paddings" *ngFor="let member of members">
        <img class="circle-img" [src]="member.avatar" />
        <div class="members-list__text">{{ member.firstAndLastName }}</div>
      </div>
    </ng-container>
  </div>
</a>

<ng-template #noDataTemplate> Нет участников </ng-template>
