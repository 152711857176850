import { Component, Input } from '@angular/core';

@Component({
  selector: 'kp-description-view',
  templateUrl: './description-view.component.html',
  styleUrls: ['./description-view.component.scss'],
})
export class DescriptionViewComponent {
  @Input() description: string;

  @Input() isMeeting = false;
}
