import { Injectable } from '@angular/core';
import { NewslinePostVoteHttpService } from '@core/services/api/newsline/newsline-post-vote-http.service';
import { INewslinePostVote } from '@shared/models/newsline/view/newsline-post-vote.model';
import { INewslinePost } from '@shared/models/newsline/view/newsline-post.model';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { NewslinePostVoteAnswerService } from './newsline-post-vote-answer.service';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostVoteService {
  constructor(
    private newslinePostVoteHttpService: NewslinePostVoteHttpService,
    private newslinePostVoteAnswerService: NewslinePostVoteAnswerService,
  ) {}

  public getNewslinePostPoll(chatPollId: number): Observable<any> {
    return this.newslinePostVoteHttpService.getNewslinePostPoll(chatPollId);
  }

  getVoteWithAnswersAndAddToPost(postId: number, targetPost?: INewslinePost): Observable<INewslinePostVote> {
    return this.getVoteByPostIdAndAddToPost(postId, targetPost).pipe(
      mergeMap((vote) => {
        if (vote) {
          return this.newslinePostVoteAnswerService.getAnswersByVoteIdAndAddToVote(vote.id, vote).pipe(map(() => vote));
        }
        return of(vote);
      }),
    );
  }

  getVoteByPostIdAndAddToPost(postId: number, targetPost?: INewslinePost): Observable<INewslinePostVote> {
    if (targetPost) {
      targetPost.isLoadingVote = true;
    }

    return this.newslinePostVoteHttpService.getVotes({ post: postId.toString() }).pipe(
      map((response) => {
        if (targetPost) {
          [targetPost.vote] = response.results;
          targetPost.isLoadingVote = false;
        }

        return response.results[0];
      }),
    );
  }

  getForumFirstMeetingVote(forumId: number): Observable<INewslinePostVote> {
    return this.newslinePostVoteHttpService.getForumFirstMeetingVote(forumId).pipe(
      mergeMap((vote) => {
        if (vote) {
          return this.newslinePostVoteAnswerService
            .getAnswersByVoteIdAndAddToVote(vote.id, vote as INewslinePostVote)
            .pipe(map(() => vote as INewslinePostVote));
        }
        return of(vote as INewslinePostVote);
      }),
    );
  }

  getVoteById(id: number): Observable<INewslinePostVote> {
    return this.newslinePostVoteHttpService.getVoteById(id);
  }

  createVoteAndAddToPost(
    voteToCreate: Partial<INewslinePostVote>,
    targetPost?: INewslinePost,
  ): Observable<INewslinePostVote> {
    return this.newslinePostVoteHttpService.createVote(voteToCreate).pipe(
      tap((createdVote) => {
        if (targetPost) {
          targetPost.vote = createdVote;
        }
      }),
    );
  }

  updateVoteByIdAndReplaceInPost(
    voteId: number,
    voteDto: Partial<INewslinePostVote>,
    targetPost?: INewslinePost,
  ): Observable<INewslinePostVote> {
    return this.newslinePostVoteHttpService.updateVoteById(voteId, voteDto).pipe(
      tap((updatedVote) => {
        if (targetPost) {
          targetPost.vote = Object.assign(targetPost.vote, updatedVote);
        }
      }),
    );
  }

  deleteAnswerByIdAndRemoveFromVote(voteId: number, targetPost?: INewslinePost): Observable<void> {
    if (targetPost?.vote) {
      targetPost.vote.isDeleting = true;
    }

    return this.newslinePostVoteHttpService.deleteVoteById(voteId).pipe(
      tap(
        () => {
          if (targetPost) {
            targetPost.vote = null;
          }
        },
        () => {
          if (targetPost?.vote) {
            targetPost.isDeleting = false;
          }
        },
      ),
    );
  }
}
