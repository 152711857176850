<div class="default-container default-container--column">
  <div class="default-container__header">
    <div>Файлы</div>
    <div class="count">0</div>
  </div>

  <div class="content">
    <kp-stuff-item [file]="file" [link]="link"></kp-stuff-item>
  </div>
</div>
