import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable()
export class ErrorHandlerService extends Sentry.SentryErrorHandler {
  constructor() {
    super({ showDialog: false });
  }

  public handleError(error: any) {
    super.handleError(error);

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      Sentry.captureMessage("Loading chunk capture: " + error.message);

      window.location.reload();
    }
  }
}
