import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthService } from '@core/services/business/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthCanLoadGuard implements CanLoad {
  constructor(private router: Router, private authService: AuthService) {}

  canLoad() {
    if (this.authService.isAuthenticated) {
      return true;
    }
    this.router.navigate(['/login']).then(() => false);
  }
}
