import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PRIMARY_COLOR } from '@shared/constants/constants.const';
import { ReplaySubject } from 'rxjs';

const FONT_SIZE_RATIO: number = 2.6;

@Component({
  selector: 'kp-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnChanges {
  @Input() public avatarUrl: string;
  @Input() public username: string;
  @Input() public backgroundColor: string = PRIMARY_COLOR; // primary;
  @Input() public fontColor: string = 'white';
  @Input() public avatarSize: number = 32;
  @Input() public fontSize: number = this._getFontSize();

  public readonly userInitials$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public readonly avatarUrl$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public readonly avatarLoaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public minimumDiameter = 10;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.avatarUrl?.currentValue) {
      this.avatarLoaded$.next(false);
    }

    if (changes.username?.currentValue) {
      const cleanUsername: string = this.username.trim();

      this.userInitials$.next(
        cleanUsername
          ? cleanUsername
              .split(' ')
              .map((word: string) => (word[0] ? word[0].toUpperCase() : ''))
              .slice(0, 2)
              .join('')
          : '',
      );
    } else {
      this.userInitials$.next('...');
    }

    this.avatarUrl$.next(this.avatarUrl);

    this.fontSize = this._getFontSize();
  }

  public onImageError(): void {
    this.avatarUrl$.next('');
  }

  private _getFontSize(): number {
    return Math.ceil(this.avatarSize / FONT_SIZE_RATIO);
  }
}
