<form class="container" [formGroup]="filterDialogService.form">
  <div class="title">Создать фильтр</div>

  <div formArrayName="filters" class="filters" *ngIf="filterDialogService.filters.controls?.length">
    <div class="filter-item" *ngFor="let filter of filterDialogService.filters.controls; let filterIdx = index"
         [formGroupName]="filterIdx">
      <!-- Выбор фильтра -->
      <div class="form-control" [ngClass]="{ 'form-control--invalid': isControlInvalid(filter.get('name')) }">
        <div class="form-control__input">
          <kp-select
            [control]="filter.get('name')"
            [options]="filterDialogService.filtersOptions"
            [selectedOptionValue]="filter.get('name').value"
            [optionsUpdatorField]="selectUpdatorField.FilterField"
            [position]="elementPositionKey.BottomLeft"
            [placeholder]="'Критерий'"
            (selectedOptionChangeEvent)="filterDialogService.filterFieldSelected(filter, $event)"
          ></kp-select>
        </div>

        <div class="form-control__error form-control__error--in-flow" *ngIf="isControlInvalid(filter.get('name'))">
          {{ getValidatorErrorMessage(filter.get('name')) }}
        </div>
      </div>

      <!-- Выбор условия для фильтра -->
      <div
        class="form-control"
        [ngClass]="{
          'form-control--invalid': isControlInvalid(filter.get('rule'))
        }"
      >
        <div class="form-control__input">
          <kp-select
            [class.disabled]="!filter.get('name').value"
            [control]="filter.get('rule')"
            [options]="getOptionByFilterGroup(filter)?.filterAvailableRulesOptions"
            [selectedOptionValue]="filter.get('rule').value"
            [optionsUpdatorField]="selectUpdatorField.Key"
            [position]="elementPositionKey.BottomLeft"
            [placeholder]="'Условие'"
            (selectedValueChangeEvent)="filter.get('rule').setValue($event)"
          ></kp-select>
        </div>

        <div class="form-control__error form-control__error--in-flow" *ngIf="isControlInvalid(filter.get('rule'))">
          {{ getValidatorErrorMessage(filter.get('rule')) }}
        </div>
      </div>

      <!-- Ввод или выбор значения фильтра -->
      <div
        class="form-control"
        [ngClass]="{
          'form-control--invalid': isControlInvalid(filter.get('value'))
        }"
      >
        <div class="form-control__input form-control__input--text-select-style">
          <!-- Значение выбирается из селекта -->
          <kp-select
            *ngIf="filterDialogService.isFilterGroupValueSelect(filter); else dateInputTemplate"
            [class.disabled]="!filter.get('name').value || !filter.get('rule').value"
            [control]="filter.get('value')"
            [mode]="getOptionByFilterGroup(filter)?.filterValueSelectMode || selectMode.SingleSelect"
            [options]="getOptionByFilterGroup(filter)?.filterValueOptions"
            [optionsSource]="optionsSources[getOptionByFilterGroup(filter)?.filterValueOptionsSourceKey]"
            [selectedOptionValue]="filter.get('value').value"
            [selectedOptionsValues]="filter.get('value').value"
            [optionsUpdatorField]="selectUpdatorField.Key"
            placeholder="Значение"
            [position]="elementPositionKey.BottomLeft"
            (selectedValueChangeEvent)="filter.get('value').setValue($event)"
            (selectedValuesChangeEvent)="filter.get('value').setValue($event)"
          ></kp-select>

          <!-- Значение выбирается из дэйт пикера -->
          <ng-template #dateInputTemplate>
            <kp-date-picker
              *ngIf="filterDialogService.isFilterGroupValueDate(filter); else textOrNumberInputTemplate"
              [class.disabled]="!filter.get('name').value || !filter.get('rule').value"
              [dateFromUpdator]="filter.get('value').value"
              [control]="filter.get('value')"
              [outputFormat]="getOptionByFilterGroup(filter)?.filterValueDateFormat"
              placeholder="Значение"
              [mode]="
                getOptionByFilterGroup(filter)?.filterValueInputType === filterValueInputType.DateWithTime
                  ? datePickerMode.SingleWithTime
                  : datePickerMode.Single
              "
            ></kp-date-picker>
          </ng-template>

          <!-- Значение вводится в инпут -->
          <ng-template #textOrNumberInputTemplate>
            <input
              [class.disabled]="!filter.get('name').value || !filter.get('rule').value"
              type="text"
              placeholder="Значение"
              formControlName="value"
              [mask]="filterDialogService.isFilterGroupValueNumber(filter) ? '0*' : null"
            />
          </ng-template>
        </div>

        <div class="form-control__error form-control__error--in-flow" *ngIf="isControlInvalid(filter.get('value'))">
          {{ getValidatorErrorMessage(filter.get('value')) }}
        </div>
      </div>

      <kp-svg class="filter-item__delete-icon" key="xCircle" size="20px"
              (click)="filterDialogService.filters.removeAt(filterIdx)"></kp-svg>
    </div>
  </div>

  <button type="button" class="button button--simple" (click)="filterDialogService.addEmptyFilterGroup()">+ Добавить
    условие
  </button>

  <div class="buttons-container buttons-container--full-width">
    <button
      [@inOutAnimation]
      type="button"
      class="button button--white"
      (click)="filterDialogService.filters.clear()"
      *ngIf="filterDialogService.filters.length"
    >
      Сбросить
    </button>

    <div class="buttons-container buttons-container--ml-auto">
      <button type="button" class="button button--white" (click)="filterDialogService.closeDialog()">Отмена</button>
      <button type="button" class="button button" (click)="filterDialogService.submit()">Показать результат</button>
    </div>
  </div>
</form>
