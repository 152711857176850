export enum ForumInListMenuOptionViewName {
  Open = 'Открыть',
  Copy = 'Скопировать',
  Delete = 'Удалить',
}

export enum ForumSettingsMenuOptionViewName {
  Start = 'Опубликовать',
  End = 'Завершить',
  Delete = 'Удалить',
  Cancel = 'Отменить',
}
