import { ForumRelatedFieldKey } from '@modules/forums/enums/keys.enum';
import { DATE_FULL_WITH_TIME } from '@shared/constants/common.const';
import { USER_RELATED_FIELD_KEY_TO_VIEW_NAME } from '@shared/constants/mappers.const';
import { UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { IMeetingMemberTileViewConfig } from '@shared/models/meetings/view/meeting-member.model';
import { ITableColumn } from '@shared/models/table/table-column.model';
import { MeetingMemberRelatedFieldKey, MeetingRelatedFieldKey, MeetingsTypeKey } from '../enums/keys.enum';
import {
  MEETINGS_FORMAT_KEY_TO_VIEW_NAME,
  MEETINGS_TYPE_OF_PAID_KEY_TO_VIEW_NAME,
  MEETING_MEMBERS_RELATED_FIELD_KEY_TO_VIEW_NAME,
  MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME,
  MEETING_STATE_KEY_TO_VIEW_NAME,
} from './mappers.const';

export const MEETING_MEMBERS_FILTERS_TILES_IGNORE_URL: UserRelatedFieldKey[] = [UserRelatedFieldKey.Limit, UserRelatedFieldKey.Offset];

export const MEETINGS_FILTERS_IGNORE_URL: MeetingRelatedFieldKey[] = [MeetingRelatedFieldKey.Limit];

export const MEETINGS_LIST_DEFAULT_OFFSET = '0';
export const MEETINGS_LIST_DEFAULT_LIMIT = '8';

export const MEETING_MEMBERS_LIST_DEFAULT_OFFSET = '0';
export const MEETING_MEMBERS_LIST_DEFAULT_LIMIT = '20';

export const MEETINGS_PAGE_DEFAULT_FILTERS: IFilter[] = [
  {
    field: MeetingRelatedFieldKey.Offset,
    value: MEETINGS_LIST_DEFAULT_OFFSET,
  },
  {
    field: MeetingRelatedFieldKey.Limit,
    value: MEETINGS_LIST_DEFAULT_LIMIT,
  },
];

export const MEETING_MEMBERS_PAGE_FILTERS_TILES: IFilter[] = [
  {
    field: UserRelatedFieldKey.Offset,
    value: MEETING_MEMBERS_LIST_DEFAULT_OFFSET,
  },
  {
    field: UserRelatedFieldKey.Limit,
    value: MEETING_MEMBERS_LIST_DEFAULT_LIMIT,
  },
];

export const MEETING_MEMBERS_PAGE_FILTERS_TABLE: IFilter[] = [
  {
    field: UserRelatedFieldKey.Offset,
    value: MEETINGS_LIST_DEFAULT_OFFSET,
  },
  {
    field: UserRelatedFieldKey.Limit,
    value: MEETINGS_LIST_DEFAULT_LIMIT,
  },
];

export const MEETING_MEMBERS_DEFAULT_FILTERS_TILES: IFilter[] = [...MEETING_MEMBERS_PAGE_FILTERS_TILES];
export const MEETING_MEMBERS_DEFAULT_FILTERS_TABLE: IFilter[] = [...MEETING_MEMBERS_PAGE_FILTERS_TABLE];

export const MEETINGS_DEFAULT_FILTERS: IFilter[] = [
  ...MEETINGS_PAGE_DEFAULT_FILTERS,
  { groupField: MeetingRelatedFieldKey.MeetingType, groupValue: MeetingsTypeKey.AllMeetings },
];

export const MEETING_MEMBERS_TABLE_COLUMNS: ITableColumn[] = [
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Avatar],
    headerKey: UserRelatedFieldKey.Avatar,
    dataKey: MeetingMemberRelatedFieldKey.Profile,
    subDataKey: UserRelatedFieldKey.Avatar,
    dataIsImage: true,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },

  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.FirstName],
    headerKey: UserRelatedFieldKey.FirstName,
    dataKey: MeetingMemberRelatedFieldKey.Profile,
    subDataKey: UserRelatedFieldKey.FirstAndLastName,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: UserRelatedFieldKey.ProfilePageRoute,
    isRequired: true,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-size': '14px',
      'font-weight': 'normal',
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Industries],
    headerKey: UserRelatedFieldKey.Industries,
    dataKey: MeetingMemberRelatedFieldKey.Profile,
    subDataKey: UserRelatedFieldKey.AllIndustriesInString,
    isRequired: true,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-size': '14px',
      'font-weight': 'normal',
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Company],
    headerKey: UserRelatedFieldKey.Company,
    dataKey: MeetingMemberRelatedFieldKey.Profile,
    subDataKey: UserRelatedFieldKey.Company,
    isRequired: true,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-size': '14px',
      'font-weight': 'normal',
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Admin],
    headerKey: UserRelatedFieldKey.Admin,
    dataKey: MeetingMemberRelatedFieldKey.UserAdminProfile,
    subDataKey: UserRelatedFieldKey.FirstAndLastName,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: UserRelatedFieldKey.ProfilePageRoute,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-size': '14px',
      'font-weight': 'normal',
      width: '170px',
    },
  },
  {
    headerName: MEETING_MEMBERS_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingMemberRelatedFieldKey.State],
    headerKey: MeetingMemberRelatedFieldKey.State,
    dataKey: MeetingMemberRelatedFieldKey.State,
    dataItemIsSelectOption: true,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-size': '14px',
      'font-weight': 'normal',
      width: '170px',
    },
  },
  {
    headerName: MEETING_MEMBERS_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingMemberRelatedFieldKey.PaymentState],
    headerKey: MeetingMemberRelatedFieldKey.PaymentState,
    dataKey: MeetingMemberRelatedFieldKey.PaymentState,
    dataItemIsSelectOption: true,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-size': '14px',
      'font-weight': 'normal',
      width: '170px',
    },
  },
];

export const MEETING_MEMBERS_TABLE_DEFAULT_COLUMNS_KEYS: (UserRelatedFieldKey | MeetingMemberRelatedFieldKey)[] = [
  UserRelatedFieldKey.Admin,
  UserRelatedFieldKey.Avatar,
  UserRelatedFieldKey.Company,
  UserRelatedFieldKey.FirstName,
  UserRelatedFieldKey.Industries,
  MeetingMemberRelatedFieldKey.PaymentState,
  MeetingMemberRelatedFieldKey.State,
];

export const MEETING_MEMBERS_TABLE_DEFAULT_MEMBER_COLUMNS_KEYS: (UserRelatedFieldKey | MeetingMemberRelatedFieldKey)[] = [
  UserRelatedFieldKey.Avatar,
  UserRelatedFieldKey.Company,
  UserRelatedFieldKey.FirstName,
  UserRelatedFieldKey.Industries,
];

export const MEETING_MEMBERS_TABLE_STAFF_COLUMNS_KEYS: (UserRelatedFieldKey | MeetingMemberRelatedFieldKey)[] = [
  UserRelatedFieldKey.Admin,
  UserRelatedFieldKey.Avatar,
  UserRelatedFieldKey.Company,
  UserRelatedFieldKey.FirstName,
  UserRelatedFieldKey.Industries,
  MeetingMemberRelatedFieldKey.PaymentState,
  MeetingMemberRelatedFieldKey.State,
];

export const MEETING_MEMBERS_TABLE_MEMBER_COLUMNS_KEYS: UserRelatedFieldKey[] = [
  UserRelatedFieldKey.Avatar,
  UserRelatedFieldKey.Company,
  UserRelatedFieldKey.FirstName,
  UserRelatedFieldKey.Industries,
];

export const MEETINGS_TABLE_COLUMNS: ITableColumn[] = [
  // Аватар
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Avatar],
    headerKey: MeetingRelatedFieldKey.Avatar,
    dataKey: MeetingRelatedFieldKey.Avatar,
    dataIsImage: true,
    sortEnabled: true,
    styles: {
      width: '80px',
    },
    itemsStyles: {
      width: '80px',
    },
  },
  // Название встречи
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Name],
    headerKey: MeetingRelatedFieldKey.Name,
    dataKey: MeetingRelatedFieldKey.Name,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: MeetingRelatedFieldKey.MeetingPageRoute,
    isRequired: true,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-size': '14px',
      'font-weight': 'normal',
      width: '170px',
    },
  },
  // Номер встречи
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Number],
    headerKey: MeetingRelatedFieldKey.Number,
    dataKey: MeetingRelatedFieldKey.Number,
    isRequired: true,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  // Номер форума
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.ForumBranchNumber],
    headerKey: MeetingRelatedFieldKey.ForumBranchNumber,
    dataKey: MeetingRelatedFieldKey.Forum,
    subDataKey: ForumRelatedFieldKey.UniqueBranchNumber,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: ForumRelatedFieldKey.ForumPageRoute,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  // Имя форума
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Forum],
    headerKey: MeetingRelatedFieldKey.Forum,
    dataKey: MeetingRelatedFieldKey.Forum,
    subDataKey: ForumRelatedFieldKey.Name,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: ForumRelatedFieldKey.ForumPageRoute,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  // Состояние
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.State],
    headerKey: MeetingRelatedFieldKey.State,
    dataKey: MeetingRelatedFieldKey.State,
    dataKeyOfEnum: true,
    dataEnum: MEETING_STATE_KEY_TO_VIEW_NAME,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-weight': '400',
      width: '170px',
    },
  },
  // Дата проведения
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.StartedAt],
    headerKey: MeetingRelatedFieldKey.StartedAt,
    dataKey: MeetingRelatedFieldKey.StartedAt,
    dataItemIsDate: true,
    dateFormat: DATE_FULL_WITH_TIME,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-weight': '400',
      width: '170px',
    },
  },
  // Адрес встречи
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Address],
    headerKey: MeetingRelatedFieldKey.Address,
    dataKey: MeetingRelatedFieldKey.Address,
    subDataKey: MeetingRelatedFieldKey.Name,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  // Завершение
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.FinishedAt],
    headerKey: MeetingRelatedFieldKey.FinishedAt,
    dataKey: MeetingRelatedFieldKey.FinishedAt,
    sortEnabled: true,
    dataItemIsDate: true,
    dateFormat: DATE_FULL_WITH_TIME,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-weight': '400',
      width: '170px',
    },
  },
  // Создал
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Creator],
    headerKey: MeetingRelatedFieldKey.Creator,
    dataKey: MeetingRelatedFieldKey.Creator,
    subDataKey: UserRelatedFieldKey.FirstAndLastName,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-weight': '400',
      width: '170px',
    },
  },
  // Дата создания
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Created],
    headerKey: MeetingRelatedFieldKey.Created,
    dataKey: MeetingRelatedFieldKey.Created,
    sortEnabled: true,
    dataItemIsDate: true,
    dateFormat: DATE_FULL_WITH_TIME,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-weight': '400',
      width: '170px',
    },
  },
  // Дата изменения
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Updated],
    headerKey: MeetingRelatedFieldKey.Updated,
    dataKey: MeetingRelatedFieldKey.Updated,
    sortEnabled: true,
    dataItemIsDate: true,
    dateFormat: DATE_FULL_WITH_TIME,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-weight': '400',
      width: '170px',
    },
  },
  // Администратор
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Moderator],
    headerKey: MeetingRelatedFieldKey.Moderator,
    dataKey: MeetingRelatedFieldKey.Moderator,
    subDataKey: UserRelatedFieldKey.FirstAndLastName,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: UserRelatedFieldKey.ProfilePageRoute,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-weight': '400',
      width: '170px',
    },
  },
  // Тип оплаты
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.PaymentType],
    headerKey: MeetingRelatedFieldKey.PaymentType,
    dataKey: MeetingRelatedFieldKey.PaymentType,
    dataKeyOfEnum: true,
    dataEnum: MEETINGS_TYPE_OF_PAID_KEY_TO_VIEW_NAME,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      'font-weight': '400',
      width: '100px',
    },
  },
  // Стоимость
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Cost],
    headerKey: MeetingRelatedFieldKey.Cost,
    dataKey: MeetingRelatedFieldKey.Cost,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      'font-weight': '400',
      width: '100px',
    },
  },
  // Число участников
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.User],
    headerKey: MeetingRelatedFieldKey.MembersCount,
    dataKey: MeetingRelatedFieldKey.MembersCount,
    isCellsClickEmitData: true,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsCustomClass: 'table-row__td-content-item--action-text',
  },
  // Формат(онлайн, офлайн)
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Format],
    headerKey: MeetingRelatedFieldKey.Format,
    dataKey: MeetingRelatedFieldKey.Format,
    sortEnabled: true,
    dataKeyOfEnum: true,
    dataEnum: MEETINGS_FORMAT_KEY_TO_VIEW_NAME,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  // Локация (филиал)
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.ForumBranch],
    headerKey: MeetingRelatedFieldKey.ForumBranch,
    dataKey: MeetingRelatedFieldKey.ForumBranch,
    subDataKey: MeetingRelatedFieldKey.Name,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  // Посетило
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.VisitedMembersCount],
    headerKey: MeetingRelatedFieldKey.VisitedMembersCount,
    dataKey: MeetingRelatedFieldKey.VisitedMembersCount,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  // Отказало
  {
    headerName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.RefusedMembersCount],
    headerKey: MeetingRelatedFieldKey.RefusedMembersCount,
    dataKey: MeetingRelatedFieldKey.RefusedMembersCount,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
];

/**
 * Список ключей дефолтных колонок ОБЫЧНЫХ встреч для роли "СОТРУДНИК"
 */
const COMMON_MEETINGS_TABLE_STAFF_DEFAULT_COLUMNS_KEYS: MeetingRelatedFieldKey[] = [
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.Name,
  MeetingRelatedFieldKey.Number,
  MeetingRelatedFieldKey.State,
  MeetingRelatedFieldKey.ForumBranchNumber,
  MeetingRelatedFieldKey.MembersCount,
  MeetingRelatedFieldKey.Moderator,
  MeetingRelatedFieldKey.StartedAt,
];

/**
 * Список дефолтных колонок ОБЫЧНЫХ форумов для роли "СОТРУДНИК"
 */
export const COMMON_MEETINGS_TABLE_STAFF_DEFAULT_COLUMNS = MEETINGS_TABLE_COLUMNS.filter((col) =>
  COMMON_MEETINGS_TABLE_STAFF_DEFAULT_COLUMNS_KEYS.includes(col.headerKey as MeetingRelatedFieldKey),
);

/**
 * Список ключей дефолтных колонок ОБЫЧНЫХ встреч для роли "УЧАСТНИК"
 */
const COMMON_MEETINGS_TABLE_MEMBER_DEFAULT_COLUMNS_KEYS: MeetingRelatedFieldKey[] = [
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.Name,
  MeetingRelatedFieldKey.Number,
  MeetingRelatedFieldKey.ForumBranchNumber,
  MeetingRelatedFieldKey.MembersCount,
  MeetingRelatedFieldKey.Moderator,
  MeetingRelatedFieldKey.StartedAt,
];

/**
 * Список дефолтных колонок ОБЫЧНЫХ форумов для роли "УЧАСТНИК"
 */
export const COMMON_MEETINGS_TABLE_MEMBER_DEFAULT_COLUMNS = MEETINGS_TABLE_COLUMNS.filter((col) =>
  COMMON_MEETINGS_TABLE_MEMBER_DEFAULT_COLUMNS_KEYS.includes(col.headerKey as MeetingRelatedFieldKey),
);

/**
 * Список ключей дефолтных колонок ОТРАСЛЕВЫХ встреч для роли "СОТРУДНИК"
 */
const INDUSTRY_MEETINGS_TABLE_STAFF_DEFAULT_COLUMNS_KEYS: MeetingRelatedFieldKey[] = [
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.Name,
  MeetingRelatedFieldKey.Number,
  MeetingRelatedFieldKey.State,
  MeetingRelatedFieldKey.Forum,
  MeetingRelatedFieldKey.MembersCount,
  MeetingRelatedFieldKey.Moderator,
  MeetingRelatedFieldKey.StartedAt,
];

/**
 * Список дефолтных колонок ОТРАСЛЕВЫХ форумов для роли "СОТРУДНИК"
 */
export const INDUSTRY_MEETINGS_TABLE_STAFF_DEFAULT_COLUMNS = MEETINGS_TABLE_COLUMNS.filter((col) =>
  INDUSTRY_MEETINGS_TABLE_STAFF_DEFAULT_COLUMNS_KEYS.includes(col.headerKey as MeetingRelatedFieldKey),
);

/**
 * Список ключей дефолтных колонок ОТРАСЛЕВЫХ встреч для роли "УЧАСТНИК"
 */
const INDUSTRY_MEETINGS_TABLE_MEMBER_DEFAULT_COLUMNS_KEYS: MeetingRelatedFieldKey[] = [
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.Name,
  MeetingRelatedFieldKey.Number,
  MeetingRelatedFieldKey.Forum,
  MeetingRelatedFieldKey.MembersCount,
  MeetingRelatedFieldKey.Moderator,
  MeetingRelatedFieldKey.StartedAt,
];

/**
 * Список дефолтных колонок ОТРАСЛЕВЫХ форумов для роли "УЧАСТНИК"
 */
export const INDUSTRY_MEETINGS_TABLE_MEMBER_DEFAULT_COLUMNS = MEETINGS_TABLE_COLUMNS.filter((col) =>
  INDUSTRY_MEETINGS_TABLE_MEMBER_DEFAULT_COLUMNS_KEYS.includes(col.headerKey as MeetingRelatedFieldKey),
);

/**
 * Список ключей колонок доступных роли "СОТРУДНИК" для ОБЫЧНЫХ встреч
 */
export const COMMON_MEETINGS_TABLE_STAFF_COLUMNS_KEYS: MeetingRelatedFieldKey[] = [
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.Avatar,
  MeetingRelatedFieldKey.Created,
  MeetingRelatedFieldKey.Creator,
  MeetingRelatedFieldKey.Cost,
  MeetingRelatedFieldKey.FinishedAt,
  MeetingRelatedFieldKey.Format,
  MeetingRelatedFieldKey.ForumBranch,
  MeetingRelatedFieldKey.ForumBranchNumber,
  MeetingRelatedFieldKey.MembersCount,
  MeetingRelatedFieldKey.Moderator,
  MeetingRelatedFieldKey.Name,
  MeetingRelatedFieldKey.Number,
  MeetingRelatedFieldKey.PaymentType,
  MeetingRelatedFieldKey.RefusedMembersCount,
  MeetingRelatedFieldKey.StartedAt,
  MeetingRelatedFieldKey.State,
  MeetingRelatedFieldKey.Updated,
  MeetingRelatedFieldKey.VisitedMembersCount,
];

/**
 * Список ключей колонок доступных роли "УЧАСТНИК" для ОБЫЧНЫХ встреч
 */
export const COMMON_MEETINGS_TABLE_MEMBER_COLUMNS_KEYS: MeetingRelatedFieldKey[] = [
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.Avatar,
  MeetingRelatedFieldKey.Cost,
  MeetingRelatedFieldKey.FinishedAt,
  MeetingRelatedFieldKey.Format,
  MeetingRelatedFieldKey.ForumBranch,
  MeetingRelatedFieldKey.ForumBranchNumber,
  MeetingRelatedFieldKey.MembersCount,
  MeetingRelatedFieldKey.Moderator,
  MeetingRelatedFieldKey.Name,
  MeetingRelatedFieldKey.Number,
  MeetingRelatedFieldKey.PaymentType,
  MeetingRelatedFieldKey.StartedAt,
  MeetingRelatedFieldKey.State,
];

/**
 * Список ключей колонок доступных роли "СОТРУДНИК" для ОТРАСЛЕВЫХ встреч
 */
export const INDUSTRY_MEETINGS_TABLE_STAFF_COLUMNS_KEYS: MeetingRelatedFieldKey[] = [
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.Avatar,
  MeetingRelatedFieldKey.Created,
  MeetingRelatedFieldKey.Creator,
  MeetingRelatedFieldKey.Cost,
  MeetingRelatedFieldKey.FinishedAt,
  MeetingRelatedFieldKey.Format,
  MeetingRelatedFieldKey.Forum,
  MeetingRelatedFieldKey.ForumBranch,
  MeetingRelatedFieldKey.MembersCount,
  MeetingRelatedFieldKey.Moderator,
  MeetingRelatedFieldKey.Name,
  MeetingRelatedFieldKey.Number,
  MeetingRelatedFieldKey.PaymentType,
  MeetingRelatedFieldKey.RefusedMembersCount,
  MeetingRelatedFieldKey.StartedAt,
  MeetingRelatedFieldKey.State,
  MeetingRelatedFieldKey.Updated,
  MeetingRelatedFieldKey.VisitedMembersCount,
];

/**
 * Список ключей колонок доступных роли "УЧАСТНИК" для ОТРАСЛЕВЫХ встреч
 */
export const INDUSTRY_MEETINGS_TABLE_MEMBER_COLUMNS_KEYS: MeetingRelatedFieldKey[] = [
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.Avatar,
  MeetingRelatedFieldKey.Cost,
  MeetingRelatedFieldKey.FinishedAt,
  MeetingRelatedFieldKey.Format,
  MeetingRelatedFieldKey.Forum,
  MeetingRelatedFieldKey.ForumBranch,
  MeetingRelatedFieldKey.MembersCount,
  MeetingRelatedFieldKey.Moderator,
  MeetingRelatedFieldKey.Name,
  MeetingRelatedFieldKey.Number,
  MeetingRelatedFieldKey.PaymentType,
  MeetingRelatedFieldKey.StartedAt,
  MeetingRelatedFieldKey.State,
];

export const MEETINGS_TABLE_FORUM_EDIT_MEETINGS_LIST_PAGE_COLUMNS_KEYS: MeetingRelatedFieldKey[] = [
  MeetingRelatedFieldKey.Name,
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.StartedAt,
  MeetingRelatedFieldKey.MembersCount,
];

export const MEETING_MEMBER_TILE_VIEW_CONFIG: IMeetingMemberTileViewConfig = {
  full_name: true,
  company: true,
};
