export enum PersonalInfoMonthsViewName {
  January = 'Январь',
  February = 'Февраль',
  March = 'Март',
  April = 'Апрель',
  May = 'Май',
  June = 'Июнь',
  July = 'Июль',
  August = 'Август',
  September = 'Сентябрь',
  October = 'Октябрь',
  November = 'Ноябрь',
  December = 'Декабрь',
}

export enum RoutingType {
  Personal = 'personal',
  Business = 'business',
  CanBeUseful = 'can_be_useful',
  Education = 'education',
}

export enum UserProfileMenuOptionViewName {
  Shared = 'Поделиться профилем в сообщении',
  CopyLink = 'Скопировать ссылку на профиль',
  StoryArchive = 'Архив историй',
  BlockedUsers = 'Заблокированные пользователи',
  BlockUser = 'Заблокировать пользователя',
  ExportPrivateCalendar = 'Выгрузить личный календарь',
}
