// Constants for transformWordByCount function
import { QueryParams } from '@shared/models/query-params.model';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { map, startWith, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

const SECOND_FORM_NUMBERS = ['2', '3', '4'];
const EXCEPTION_TO_RULE_NUMBERS = ['11', '12', '13', '14'];
const wordKits: string[][] = [
  ['значение', 'значения', 'значений'],
  ['тип', 'типа', 'типов'],
  ['участник', 'участника', 'участников'],
  ['страна', 'страны', 'стран'],
  ['час', 'часа', 'часов'],
  ['комментарий', 'комментария', 'комментариев'],
  ['ребёнок', 'детей', 'детей'],
  ['человек', 'человека', 'человек'],
  ['оценил', 'оценили', 'оценили'],
  ['проголосовал', 'проголосовали', 'проголосовали'],
  ['параметр', 'параметра', 'параметров'],
];

/**
 * Трансформирует слово в соответствии с количеством.
 *
 * Примечание: для корректной работы метода необходимо записать
 * три формы необходимого слова в константу `wordKits` в `@shared/utils/utils`.
 *
 * Например:
 *
 * `{{ 1 | transformWordByCount: 'комментарий' }}` // 1 комментарий
 *
 * `{{ 3 | transformWordByCount: 'комментарий' }}` // 3 комментария
 *
 * `{{ 6 | transformWordByCount: 'комментарий' }}` // 6 комментариев
 */
export function transformWordByCount(count: number | string, word: string, returnOnlyWord = false): string {
  const wordForms = wordKits.find((x: string[]) => x.includes(word.toLowerCase()));
  count = count?.toString();
  let resultWordIndex = 0;

  if (wordForms && count) {
    if (+count === 0) {
      resultWordIndex = 2;
    } else if (+count > 0 && +count < 1) {
      resultWordIndex = 1;
    } else if (count.slice(-1) === '1' && count.slice(-2) !== '11') {
      resultWordIndex = 0;
    } else if (SECOND_FORM_NUMBERS.includes(count.slice(-1)) && !EXCEPTION_TO_RULE_NUMBERS.includes(count.slice(-2))) {
      resultWordIndex = 1;
    } else {
      resultWordIndex = 2;
    }

    const resultWord = wordForms[resultWordIndex];
    return returnOnlyWord ? resultWord : `${count} ${resultWord}`;
  }

  return count;
}

export function prepareQuery(params: QueryParams): HttpParams {
  let newParams: HttpParams = new HttpParams();
  _.forOwn(params, (value: string | number | boolean | Array<any>, key: string) => {
    if (value === null || value === '' || value === undefined) {
      delete params[key];
    } else {
      newParams = newParams.append(key, value.toString());
    }
  });

  return newParams;
}

export function getObservableSnapshot<T>(source$: Observable<T>): T {
  let result: T;

  source$.pipe(take(1)).subscribe((_result) => (result = _result));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return result;
}

export function filterEntityByFilterField<T>(entities: T[], rawSearchQuery: string, filterField: string): T[] {
  const searchQuery: string = rawSearchQuery.trim().toLowerCase();

  if (!searchQuery) {
    return entities;
  }

  return entities.filter((entity: T) =>
    (entity[filterField as keyof T] as unknown as string).toLowerCase().includes(searchQuery),
  );
}

export function searchObservable<T>(
  items$: Observable<Array<T>>,
  searchControl: FormControl,
  searchField: string = 'name',
): Observable<T[]> {
  return combineLatest([searchControl.valueChanges.pipe(startWith('')), items$]).pipe(
    map(([searchQuery, items]: [string, Array<T>]) => filterEntityByFilterField(items, searchQuery, searchField)),
  );
}

export function getPercentage(targetCount: number, totalCount: number, asString: boolean = true): unknown {
  const percentage: number = Math.round((targetCount * 100) / totalCount);
  return asString ? `${percentage}%` : percentage;
}
