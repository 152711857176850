import { Component, Input } from '@angular/core';

@Component({
  selector: 'kp-stuff-item',
  templateUrl: './stuff-item.html',
  styleUrls: ['./stuff-item.scss'],
})
export class StuffItemComponent {
  @Input() file: any;
  @Input() link: any;

  downloadFile() {}
}
