<div class="file-container" *ngIf="file">
  <div class="file" (click)="downloadFile()">
    <kp-svg key="fileText" size="20px" class="file-text" cursor="default"></kp-svg>

    <div class="file-custom-upload">
      {{ file.filename }}
      <span>{{ file.file_size | transformWordFileSize }} </span>
    </div>

    <kp-svg key="download" size="16px"></kp-svg>
  </div>
</div>

<div class="file-container" *ngIf="link">
  <a class="file" [routerLink]="link.link_rout">
    <kp-svg key="linkIcon" size="20px" class="file-text" cursor="default"></kp-svg>

    <div class="file-custom-upload">
      {{ link.linkname }}
    </div>

    <kp-svg key="arrowUpRightSlim" size="16px"></kp-svg>
  </a>
</div>
