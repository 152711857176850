import { Injectable } from '@angular/core';
import { NavigationEnd, Event, ActivatedRoute, Params, Router } from '@angular/router';
import { DATE_WITH_TIME_BACKEND } from '@shared/constants/common.const';
import { CommonFilterKey, DatePeriodKey } from '@shared/enums/keys.enum';
import { FilterRuleSeparator, FilterSeparator } from '@shared/enums/filter/filter-separator.enum';
import { FilterRule } from '@shared/enums/filter/filter-rule.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { ISelectOption } from '@shared/models/select.model';
import * as _ from 'lodash';
import { DateService } from './date.service';
import { Subject } from 'rxjs';
import { FilterType } from '@shared/enums/filter/filters-type.enum';
import { IFilterService } from '@shared/models/services/filter-service.model';

@Injectable({
  providedIn: 'root',
})
export class FilterService implements IFilterService {
  private _navigationActionsEnabled = true;
  defaultFilters: IFilter[] = [];
  filters: IFilter[];

  filtersChangedFromUrl$ = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dateService: DateService) {}

  onNavigation(event: Event): void {
    if (event instanceof NavigationEnd && this._navigationActionsEnabled) {
      this.setUrlParametersToFilterFromRouter();
    }
  }

  setUrlParametersToFilterFromRouter(): void {
    const filtersFromUrl = this.mapUrlParametersToFilters(this.activatedRoute.snapshot.queryParams);
    this.filters = this.mergeFiltersArrays(filtersFromUrl, _.cloneDeep(this.defaultFilters), false);
    this.setFiltersToUrlParameters(null, true);
    this.filtersChangedFromUrl$.next();
  }

  mapUrlParametersToFilters(urlParameters: Params): IFilter[] {
    const resultFilters: IFilter[] = [];

    const queryString = urlParameters[FilterSeparator.FiltersQueryIndicator] as string;
    if (!queryString) {
      return [];
    }

    urlParameters = queryString.split(FilterSeparator.DifferentFiltersSeparatorUrl).reduce((acc, namevalue) => {
      const item = { [namevalue.split('=')[0]]: namevalue.split('=')[1] };
      return { ...acc, ...item };
    }, {});

    const unqiueFilters: IFilter[] = Object.entries(urlParameters).map(([fieldWithRule, value]) => {
      const [field, rule] = fieldWithRule.split(FilterRuleSeparator.Url).slice(0, 2) as [string, FilterRule];

      return {
        field,
        rule: rule || FilterRule.Equal,
        value,
      };
    });

    unqiueFilters.forEach((filter) => {
      const isArray = filter.value.includes(FilterSeparator.FilterValuesStartUrl || FilterSeparator.FilterValuesEndUrl);
      const values: Array<string | string[]> = isArray
        ? filter.value
            .split(FilterSeparator.SameFiltersSeparatorUrl)
            .map((x) => x.split(FilterSeparator.FilterValuesStartUrl)[1])
            .map((x) => x.split(FilterSeparator.FilterValuesEndUrl)[0])
            .map((x) => x.split(FilterSeparator.ArrayItemsSeparatorUrl))
        : filter.value.split(FilterSeparator.SameFiltersSeparatorUrl);

      const isFilterNotUnique = values.length > 1;

      const filtersWithSameKeys: IFilter[] = values.map((filterValue: string | string[]) => ({
        field: filter.field,
        rule: filter.rule,
        value: !isArray ? (filterValue as string) : null,
        values: isArray ? (filterValue as string[]) : null,
        isNotUnique: isFilterNotUnique,
        isArray,
      }));
      resultFilters.push(...filtersWithSameKeys);
    });

    return resultFilters;
  }

  mapFiltersToApiOrUrlStringState(filters: IFilter[], ruleSeparator: FilterRuleSeparator): string {
    if (this.isTargetMapStateApi(ruleSeparator)) {
      return this.getFiltersStringForApi(filters, ruleSeparator);
    }

    return this.getFiltersStringForUrl(filters, ruleSeparator);
  }

  getFiltersStringForUrl(filters: IFilter[], ruleSeparator: FilterRuleSeparator): string {
    const resultString = filters.reduce((queryString, filter) => {
      const filterName = this.getFilterName(filter, ruleSeparator);
      const isAlreadyInResultString = queryString.includes(`${filterName}=`);

      if (filter.isNotForUrl || isAlreadyInResultString) {
        return queryString;
      }

      let connector = '';
      if (queryString) {
        connector = FilterSeparator.DifferentFiltersSeparatorUrl;
      }

      const isGroupFilter = !!filter.groupField;
      const values = filters
        .filter(
          (f) => this.getFilterName(f, ruleSeparator) === filterName && f.isDialogParent === filter.isDialogParent,
        )
        .map((f) => {
          if (isGroupFilter) {
            return f.groupValue;
          }
          return f.isArray ? f.values : f.value;
        });

      const joinedValues = values.reduce((acc, value) => {
        let result = '';
        let nestedConnector = '';
        if (acc) {
          nestedConnector = FilterSeparator.SameFiltersSeparatorUrl;
        }

        if (filter.isArray) {
          result = `${FilterSeparator.FilterValuesStartUrl}${(value as string[])?.join(
            FilterSeparator.ArrayItemsSeparatorUrl,
          )}${FilterSeparator.FilterValuesEndUrl}`;
        } else {
          result = value as string;
        }

        return `${acc}${nestedConnector}${result}`;
      }, '');

      return `${queryString}${connector}${filterName}=${joinedValues}`;
    }, '');

    return resultString;
  }

  getFiltersStringForApi(filters: IFilter[], ruleSeparator: FilterRuleSeparator): string {
    const resultString = filters.reduce((queryString, filter) => {
      if (filter.isNotForApi) {
        return queryString;
      }

      const filterName = this.getFilterName(filter, ruleSeparator);
      let connector = '';
      if (queryString) {
        connector = FilterSeparator.DifferentFiltersSeparatorApi;
      }

      let result = '';
      if (filter.isArray) {
        result = filter.values?.reduce((acc, value) => {
          const nestedConnector = acc ? FilterSeparator.DifferentFiltersSeparatorApi : '';
          return `${acc}${nestedConnector}${filterName}=${value}`;
        }, '');
      } else if (filter.value) {
        result = `${filterName}=${filter.value}`;
      } else {
        return queryString;
      }

      return `${queryString}${connector}${result}`;
    }, '');

    return resultString;
  }

  getFilterName(filter: IFilter, ruleSeparator: FilterRuleSeparator): string {
    let { field } = filter;

    if (!this.isTargetMapStateApi(ruleSeparator) && filter.groupField) {
      field = filter.groupField;
    }

    return filter.rule && filter.rule !== FilterRule.Equal ? `${field}${ruleSeparator}${filter.rule}` : field;
  }

  isTargetMapStateApi(ruleSeparator: FilterRuleSeparator): boolean {
    return (
      ruleSeparator === FilterRuleSeparator.ApiDoubleUnderscore ||
      ruleSeparator === FilterRuleSeparator.ApiSingleUnderscore
    );
  }

  setFiltersToUrlParameters(ignoreFilterFields?: string[], skipLocationChange = false): void {
    const resultFilters = this.filters.filter(
      (filter) =>
        !ignoreFilterFields?.some((filterField) => {
          const targetField = filter.groupField || filter.field;
          return filterField === targetField && !filter.isNotForUrl;
        }),

    );

    const resultFiltersMappedForUrl = this.mapFiltersToApiOrUrlStringState(resultFilters, FilterRuleSeparator.Url);

    const queryParams = {
      [FilterSeparator.FiltersQueryIndicator]: resultFiltersMappedForUrl,
    };

    this._navigationActionsEnabled = false;
    this.router
      .navigate([], {
        relativeTo: this.activatedRoute,
        //queryParams: resultFiltersMappedForUrl ? queryParams : null,
        queryParams: !skipLocationChange && resultFiltersMappedForUrl ? queryParams : null, //to-do как было выше
        skipLocationChange,
      })
      .finally(() => {
        this._navigationActionsEnabled = true;
      });
  }

  setFilterFromSelectOption(option: ISelectOption): void {
    if (!option) {
      return;
    }

    let newFilter: IFilter = {
      field: option.filterField,
      rule: option.filterRule,
      value: !option.isSelected || option.filterIsArray ? null : option.filterValue,
      values: option.isSelected && option.filterIsArray ? option.filterValues : null,
      isNotUnique: !!option.filterIsNotUnique,
      isNotForApi: !!option.filterFieldIsNotForApi,
      isNotForUrl: !!option.filterFieldIsNotForUrl,
      groupField: option.filterGroupField,
      groupValue: option.isSelected ? option.filterGroupValue : null,
      isGroupNotUnique: !!option.filterIsGroupNotUnique,
    };
    this.setFilterInCurrentFilters(newFilter);

    option.filterChildrenFilters?.forEach((filter: IFilter) => {
      newFilter = {
        field: filter.field,
        rule: filter.rule,
        value: filter.value,
        isNotForUrl: !!option.filterChildrenFiltersIsNotForUrl,
        isNotForApi: !!option.filterChildrenFiltersIsNotForApi,
        isNotUnique: !!option.filterChildrenFiltersIsNotUnique,
      };
      this.setFilterInCurrentFilters(newFilter);
    });
  }

  setFiltersInCurrentFilters(newFilters: IFilter | IFilter[]): void {
    if (_.isArray(newFilters)) {
      newFilters.forEach((filter) => this.setFilterInCurrentFilters(filter));
    } else {
      this.setFilterInCurrentFilters(newFilters);
    }
  }

  setFilterInCurrentFilters(newFilter: IFilter): void {
    let targetFilterIdx: number;
    const isNotUnique = newFilter.groupField ? !!newFilter.isGroupNotUnique : !!newFilter.isNotUnique;

    if (newFilter.groupField) {
      targetFilterIdx = this.filters.findIndex(
        (f) =>
          f.groupField === newFilter.groupField &&
          this.isSameRules(f.rule, newFilter.rule) &&
          !!f.isDialogParent === !!newFilter.isDialogParent,
      );
    } else {
      targetFilterIdx = this.filters.findIndex(
        (f) =>
          f.field === newFilter.field &&
          this.isSameRules(f.rule, newFilter.rule) &&
          !!f.isDialogParent === !!newFilter.isDialogParent,
      );
    }

    if (targetFilterIdx === -1 || isNotUnique) {
      this.filters = this.mergeFiltersArrays([newFilter], this.filters, !isNotUnique);
    } else {
      this.filters[targetFilterIdx] = newFilter;
    }
  }

  isSameRules(first: FilterRule, second: FilterRule): boolean {
    if (first && second) {
      return first === second;
    }
    if (!first && second) {
      return second === FilterRule.Equal;
    }
    if (first && !second) {
      return first === FilterRule.Equal;
    }
    return !!first === !!second;
  }

  selectOptionsByFilters(
    options: ISelectOption[],
    filters: IFilter[],
    filterGroupField: string,
    filterField: string,
  ): ISelectOption[] {
    let targetFilter: IFilter;
    if (filterGroupField) {
      targetFilter = filters.find(
        (filter) => filter.groupField === filterGroupField || filter.field === filterGroupField,
      );

      if (targetFilter && targetFilter?.field === filterGroupField) {
        const targetOption = options.find((o) => o.filterGroupField === filterGroupField);

        targetFilter.groupField = targetFilter.field;
        targetFilter.groupValue = targetFilter.value;
        targetFilter.field = targetOption.filterField;
        targetFilter.value = targetOption.filterValue;
      }
    } else {
      targetFilter = filters.find((filter) => filter.field === filterField);
    }

    options.forEach((option) => {
      if (option.filterGroupValue) {
        option.isSelected = targetFilter?.groupValue === option.filterGroupValue;
      } else if (option.filterIsArray) {
        option.isSelected = _.isEqual(targetFilter?.values, option.filterValues);
      } else {
        option.isSelected = targetFilter?.value === option.filterValue;
      }
    });
    return _.cloneDeep(options);
  }

  adjustFiltersStateByOptions(options: ISelectOption[]): void {
    const newFilters: IFilter[] = [];

    this.filters.forEach((filter) => {
      const targetOption = options.find((option) => option.filterField === filter.field);
      if (targetOption) {
        filter.isNotForApi = !!targetOption.filterFieldIsNotForApi;
        filter.isNotForUrl = !!targetOption.filterFieldIsNotForUrl;
        filter.isDialogParent = !!targetOption.filterIsDialogParent;
        filter.isNotUnique = !!targetOption.filterIsNotUnique;

        if (targetOption.filterAdditionalFields) {
          newFilters.push(
            ...targetOption.filterAdditionalFields.map(
              (field) =>
                ({
                  field,
                  rule: filter.rule,
                  value: filter.value,
                  values: filter.values,
                  isArray: !!targetOption.filterIsArray,
                  isNotForUrl: !!targetOption.filterAdditionalFieldsIsNotForUrl,
                  isDialogParent: !!targetOption.filterIsDialogParent,
                  isNotUnique: !!targetOption.filterIsNotUnique,
                } as IFilter),
            ),
          );
        }
      }
    });

    this.filters.push(...newFilters);
  }

  getFilterValueByFilterField(filters: IFilter[], filterField: string): string | string[] {
    const targetFilter = this.getFilterByFilterField(filters, filterField);
    return targetFilter?.isArray ? targetFilter?.values : targetFilter?.value;
  }

  getFilterByFilterField(filters: IFilter[], filterField: string): IFilter {
    const targetFilter = filters.find((filter) => filter.field === filterField);
    return targetFilter;
  }

  setFilterValue(filters: IFilter[], targetFilterField: string, value: string): void {
    const targetFilter = this.getFilterByFilterField(filters, targetFilterField);
    if (targetFilter) {
      targetFilter.value = value;
    }
  }

  isOptionsContainFilter(options: ISelectOption[], filter: IFilter): boolean {
    return options.some((option) => {
      if (filter.groupField) {
        return option.filterGroupField === filter.groupField && option.filterIsDialogParent === filter.isDialogParent;
      }
      return (
        (option.filterField === filter.field || option.filterAdditionalFields?.includes(filter.field)) &&
        option.filterIsDialogParent === filter.isDialogParent
      );
    });
  }

  getOptionByFilterField(options: ISelectOption[], filterField: string): ISelectOption {
    return options.find((option) => option.filterField === filterField);
  }

  mergeFiltersArrays(newFilters: IFilter[], targetFilters: IFilter[], mergeUniqueFilters = true): IFilter[] {
    if (!newFilters?.length) {
      return targetFilters;
    }

    _.cloneDeep(newFilters).forEach((newFilter) => {
      const targetFilterIdx = targetFilters.findIndex(
        (targetFilter) =>
          (newFilter.field === targetFilter.groupField || targetFilter.field === newFilter.field) &&
          this.isSameRules(targetFilter.rule, newFilter.rule) &&
          !!newFilter.isDialogParent === !!targetFilter.isDialogParent,
      );

      const isUniqueFilter =
        mergeUniqueFilters || !newFilter.isNotUnique || !targetFilters[targetFilterIdx].isNotUnique;

      if (targetFilterIdx !== -1 && isUniqueFilter) {
        if (newFilter.groupValue === targetFilters[targetFilterIdx].field) {
          targetFilters[targetFilterIdx].groupField = newFilter.field;
          targetFilters[targetFilterIdx].groupValue = newFilter.value;
          targetFilters[targetFilterIdx].field = null;
          targetFilters[targetFilterIdx].value = null;
        } else {
          targetFilters[targetFilterIdx].value = newFilter.value;
          targetFilters[targetFilterIdx].values = newFilter.values;
        }
      } else {
        targetFilters.push(newFilter);
      }
    });

    targetFilters = this.clearFiltersFromNullableValues(targetFilters);
    return targetFilters;
  }

  filterCurrentFiltersByFilterFields(filterFieldsToDelete: string[]): void {
    this.filters = this.filters.filter(
      (f) =>
        !filterFieldsToDelete.some((filterField) => {
          if (f.groupField) {
            return f.groupField === filterField;
          }
          return f.field === filterField;
        }),
    );
  }

  clearFiltersFromNullableValues(filters: IFilter[]): IFilter[] {
    return filters.filter((filter) => {
      if (filter.groupField) {
        return !!filter.groupValue;
      }
      return !!filter.value || !!filter.values?.length;
    });
  }

  isFiltersArraysDifferent(first: IFilter[], second: IFilter[]): boolean {
    return !_.isEqual(first, second);
  }

  getPeriodFiltersByDatePeriodKey(fieldName: string, period: DatePeriodKey): IFilter[] {
    const filters: IFilter[] = [];

    const dateRange = this.dateService.getDateRangeByDatePeriodKey(period);

    filters.push(
      {
        field: fieldName,
        rule: FilterRule.After,
        value: dateRange[0]?.format(DATE_WITH_TIME_BACKEND),
      },
      {
        field: fieldName,
        rule: FilterRule.Before,
        value: dateRange[1]?.format(DATE_WITH_TIME_BACKEND),
      },
    );

    return filters;
  }

  setFilterFromTemplate(
    filterType: FilterType,
    option?: ISelectOption,
    filters?: IFilter | IFilter[],
    itemsCountTotal?: number,
    itemsCountCurrent?: number,
    limit?: string,
  ): { needLoadItems: boolean } {
    const filtersSnapshot = _.cloneDeep(this.filters);

    switch (filterType) {
      case FilterType.DataFilters: {
        if (option) {
          this.setFilterFromSelectOption(option);
        } else {
          this.setFiltersInCurrentFilters(filters);
        }
        break;
      }
      case FilterType.OffsetFromInfiniteScroll: {
        this.setOffsetFromInfiniteScroll(itemsCountTotal, itemsCountCurrent, limit);
        break;
      }
      case FilterType.OffsetFromPagination: {
        this.setOffsetFromPagination((filters as IFilter).value);
        break;
      }
    }

    this.filters = this.clearFiltersFromNullableValues(this.filters);
    return { needLoadItems: this.isFiltersArraysDifferent(this.filters, filtersSnapshot) };
  }

  setOffsetFromInfiniteScroll(itemsCountTotal: number, itemsCountCurrent: number, limit: string): void {
    if (itemsCountTotal > itemsCountCurrent) {
      const newOffset = this.getNextOffset(itemsCountTotal, itemsCountCurrent, this.offset, limit);
      this.setOffsetFromPagination(newOffset);
    }
  }

  setOffsetFromPagination(offset: string): void {
    const targetFilterIdx = this.filters.findIndex((f) => f.field === CommonFilterKey.Offset);
    if (targetFilterIdx !== -1) {
      this.filters[targetFilterIdx].value = offset;
    }
  }

  getPrevOffset(offset: string, limit: string): string {
    if (+offset >= +limit) {
      return (+offset - +limit).toString();
    }
    return '0';
  }

  getNextOffset(itemsCountTotal: number, itemsCountCurrent: number, offset: string, limit: string): string {
    if (itemsCountTotal > itemsCountCurrent) {

      return (+offset + +limit).toString();
    }
  }

  get offset(): string {
    return this.filters.find((f) => f.field === CommonFilterKey.Offset)?.value;
  }

  get limit(): string {
    return this.filters.find((f) => f.field === CommonFilterKey.Limit)?.value;
  }
}
