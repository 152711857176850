import { Pipe, PipeTransform } from '@angular/core';
import { transformWordByCount } from '@shared/utils/utils';

/**
 * Трансформирует слово в соответствии с количеством.
 *
 * Примечание: для корректной работы директивы необходимо записать
 * три формы необходимого слова в константу `wordKits` в `@shared/utils/utils`.
 *
 * Например:
 *
 * `{{ 1 | transformWordByCount: 'комментарий' }}` // 1 комментарий
 *
 * `{{ 3 | transformWordByCount: 'комментарий' }}` // 3 комментария
 *
 * `{{ 6 | transformWordByCount: 'комментарий' }}` // 6 комментариев
 */
@Pipe({
  name: 'transformWordByCount',
})
export class TransformWordByCountPipe implements PipeTransform {
  transform(count: string | number, word: string, returnOnlyWord = false): string {
    return transformWordByCount(count?.toString(), word, returnOnlyWord);
  }
}
