import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { NotifyToastComponent } from './notify-toast.component';
import { NotifyToastModel } from './notify-toast.model';

const DEFAULT_DURATION = 3000;
@Injectable({
  providedIn: 'root',
})
export class NotifyToastService {
  private toastRef: MatSnackBarRef<NotifyToastComponent> | null = null;

  constructor(private matSnackBar: MatSnackBar) {}

  public showNotify({
    type = 'simple',
    verticalPosition = 'top',
    horizontalPosition = 'end',
    duration = DEFAULT_DURATION,
    ...restData
  }: NotifyToastModel): void {
    this.toastRef = this.matSnackBar.openFromComponent(NotifyToastComponent, {
      panelClass: 'notify-toast-panel',
      verticalPosition,
      horizontalPosition,
      duration,
      data: {
        ...restData,
        type,
      },
    });

    this.toastRef
      .afterDismissed()
      .pipe(take(1))
      .subscribe(() => this.toastRef = null);
  }

  public dismiss() {
    if (!this.toastRef) {
      return;
    }

    this.toastRef.dismiss();
  }
}
