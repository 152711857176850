import { Pipe, PipeTransform } from '@angular/core';
import { LINK_REGEX } from '@shared/constants/link-regex.const';

@Pipe({
  name: 'hostName',
  pure: true,
})
export class HostNamePipe implements PipeTransform {
  constructor() {}

  transform(url: string) {
    return url?.match(LINK_REGEX) ? new URL(url).hostname : url;
  }
}
