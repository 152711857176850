import { SelectOptionActionType } from '@shared/enums/select/select-option-action-type.enum';
import { ISelectOption } from '@shared/models/select.model';
import { FormOfParticipationKey, SexKey } from '../enums/keys.enum';
import { PersonalInfoMonthsViewName, UserProfileMenuOptionViewName } from '../enums/view-names.enum';
import {
  FORM_OF_PARTICIPATION_KEY_TO_VIEW_NAME,
  ProfileAvatarOptionViewName,
  SEX_KEY_TO_VIEW_NAME,
} from './keys-to-view-names.const';
import { PRIMARY_COLOR } from '@shared/constants/constants.const';

export const SEX_OPTIONS: ISelectOption[] = [
  {
    key: SexKey.Male,
    viewName: SEX_KEY_TO_VIEW_NAME[SexKey.Male],
  },
  {
    key: SexKey.Female,
    viewName: SEX_KEY_TO_VIEW_NAME[SexKey.Female],
  },
];

export const FORM_OF_PARTICIPATION_OPTIONS: ISelectOption[] = [
  {
    key: FormOfParticipationKey.Offline,
    viewName: FORM_OF_PARTICIPATION_KEY_TO_VIEW_NAME[FormOfParticipationKey.Offline],
  },
  {
    key: FormOfParticipationKey.Online,
    viewName: FORM_OF_PARTICIPATION_KEY_TO_VIEW_NAME[FormOfParticipationKey.Online],
  },
];

export const PERSONAL_INFO_MONTHS_OPTIONS: ISelectOption[] = [
  {
    viewName: PersonalInfoMonthsViewName.January,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.February,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.March,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.April,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.May,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.June,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.July,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.August,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.September,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.October,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.November,
    actionType: SelectOptionActionType.Selection,
  },
  {
    viewName: PersonalInfoMonthsViewName.December,
    actionType: SelectOptionActionType.Selection,
  },
];

export const MARITAL_STATUS: { id: number; value: string }[] = [
  { id: 0, value: 'В браке' },
  { id: 1, value: 'Холост/Не замужем' },
];

export const USER_PROFILE_MENU_OPTIONS: ISelectOption[] = [
  {
    viewName: UserProfileMenuOptionViewName.Shared,
    iconKey: 'sharedIcon',
    iconColor: PRIMARY_COLOR,
    textColor: '#171619',
    textSize: '14px',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: UserProfileMenuOptionViewName.CopyLink,
    iconKey: 'getLinkIcon',
    iconColor: PRIMARY_COLOR,
    textColor: '#171619',
    textSize: '14px',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: UserProfileMenuOptionViewName.StoryArchive,
    iconKey: 'archiveStoriesIcon',
    iconColor: PRIMARY_COLOR,
    textColor: '#171619',
    textSize: '14px',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: UserProfileMenuOptionViewName.BlockedUsers,
    iconKey: 'blockedIcon',
    iconColor: PRIMARY_COLOR,
    textColor: '#171619',
    textSize: '14px',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: UserProfileMenuOptionViewName.BlockUser,
    iconKey: 'blockedIcon',
    iconColor: PRIMARY_COLOR,
    textColor: '#171619',
    textSize: '14px',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: UserProfileMenuOptionViewName.ExportPrivateCalendar,
    iconKey: 'calendarIcon',
    iconColor: PRIMARY_COLOR,
    textColor: '#171619',
    textSize: '14px',
    actionType: SelectOptionActionType.Action,
  },
];

// Меню личного профиля участника
export const PERSONAL_USER_PROFILE_MENU_OPTIONS = USER_PROFILE_MENU_OPTIONS.filter(
  (option) => option.viewName !== UserProfileMenuOptionViewName.BlockUser && option.viewName !== UserProfileMenuOptionViewName.ExportPrivateCalendar,
);

// Меню профиля другого пользователя
export const OTHER_USER_PROFILE_MENU_OPTIONS = USER_PROFILE_MENU_OPTIONS.filter(
  (option) => option.viewName !== UserProfileMenuOptionViewName.BlockedUsers && option.viewName !== UserProfileMenuOptionViewName.ExportPrivateCalendar,
);

// Меню профиля другого пользователя для админа
export const OTHER_USER_PROFILE_MENU_OPTIONS_FOR_ADMIN = USER_PROFILE_MENU_OPTIONS.filter(
  (option) => option.viewName !== UserProfileMenuOptionViewName.BlockedUsers,
);

export const PROFILE_AVATAR_MENU_OPTIONS: ISelectOption[] = [
  {
    viewName: ProfileAvatarOptionViewName.Open,
    iconKey: 'openPhotoMenu',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: ProfileAvatarOptionViewName.Change,
    iconKey: 'pencilMenuIcon',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: ProfileAvatarOptionViewName.Delete,
    iconKey: 'deleteBucketMenu',
    iconColor: 'transparent',
    actionType: SelectOptionActionType.Action,
  },
];
