<div
  class="like-preview-container"
  (mouseover)="isPreviewVisible = true"
  (mouseleave)="isPreviewVisible = false"
>
  <div class="like-preview-container__popup" *ngFor="let item of postPreviewLikesArr" >
    <a class="like-preview-container__popup--display-contents" [routerLink]="['/profile/' + item.id]">
      <kp-user-avatar [avatarSize]="30" [avatarUrl]="item.avatar" [username]="item.first_name + ' ' + item.last_name"></kp-user-avatar>

      <div>{{item.first_name + ' ' + item.last_name}}</div>
    </a>

  </div>

  <button class="kp-text-button-s padding-none" *ngIf="likesCount > 4" (click)="openDialog()">{{ 'Ещё ' + previewTotalLikes }}</button>
</div>
