import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotifyToastModel } from './notify-toast.model';

@Component({
  selector: 'kp-notify-toast',
  templateUrl: './notify-toast.component.html',
  styleUrls: ['./notify-toast.component.scss'],
})
export class NotifyToastComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public readonly data: NotifyToastModel,
    public snackRef: MatSnackBarRef<NotifyToastComponent>,
  ) {}
}
