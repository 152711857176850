import { FormOfParticipationKey, MaritalStatusKey, SexKey } from '../enums/keys.enum';

export const SEX_KEY_TO_VIEW_NAME: { [key in SexKey]: string } = {
  [SexKey.Female]: 'Женский',
  [SexKey.Male]: 'Мужской',
};

export const SEX_KEY_TO_VIEW_NAME_SHORT: { [key in SexKey]: string } = {
  [SexKey.Female]: 'Ж',
  [SexKey.Male]: 'М',
};

export const FORM_OF_PARTICIPATION_KEY_TO_VIEW_NAME: { [key in FormOfParticipationKey]: string } = {
  [FormOfParticipationKey.Offline]: 'Офлайн',
  [FormOfParticipationKey.Online]: 'Онлайн',
};

export enum ProfileAvatarOptionViewName {
  Open = 'Открыть фото',
  Change = 'Изменить фото',
  Delete = 'Удалить фото',
}
