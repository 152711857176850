import { Injectable } from '@angular/core';
import { MembersBusinessTurnoverHttpService } from '@core/services/api/members/members-business-turnover-http.service';
import { IdName } from '@shared/models/id-name.model';
import { KeyValue } from '@shared/models/key-value.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MembersBusinessTurnoverService {
  businessesTurnovers: IdName[] = [];

  constructor(private membersBusinessTurnoverHttpService: MembersBusinessTurnoverHttpService) {}

  getBusinessesTurnoversToBusinessesTurnovers(
    targetBusinessesTurnovers?: IdName[],
    params?: KeyValue<string>,
    paramsString?: string,
  ): Observable<IdName[]> {
    return this.membersBusinessTurnoverHttpService.getBusinessesTurnovers(params, paramsString).pipe(
      map((response) => {
        if (!targetBusinessesTurnovers) {
          targetBusinessesTurnovers = [];
        }
        targetBusinessesTurnovers.push(...response.results);

        return targetBusinessesTurnovers;
      }),
    );
  }

  getById(id: number): Observable<IdName> {
    return this.membersBusinessTurnoverHttpService.getBusinessTurnoverById(id);
  }
}
