import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomValidators } from '@shared/utils/custom-validators';
import * as moment from 'moment';

@Component({
  selector: 'kp-date-time-control',
  templateUrl: './date-time-control.component.html',
  styleUrls: ['./date-time-control.component.scss'],
})

@UntilDestroy()
export class DateTimeControlComponent implements ControlValueAccessor, OnInit {
  @Input() public dateLabel: string;
  @Input() public timeLabel: string;
  @Input() public minutesStep: number = 1;

  public dateTime = new FormGroup({date: new FormControl(null, [Validators.required]), time: new FormControl('', [Validators.required, CustomValidators.timeValidator])});
  public onChange: (value: string) => void;
  public onTouched: () => void;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (Boolean(this.ngControl)) {
      this.ngControl.valueAccessor = this;
    }

    this.dateTime.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if(this.dateTime.invalid) {
        this.ngControl?.control?.setErrors({invalidError: 'Invalid date'});
      } else {
        this.ngControl?.control?.setErrors(null);
      }

      const dateTime = moment(value.date?.format?.('YYYY-MM-DD') + value.time, 'YYYY-MM-DDHHmm');

      if (!dateTime.isValid() || value.time === '') {
        return
      }

      this.dateTime.get('time').setValue(value.time, {emitEvent: false});

      this.onChange(dateTime.format('YYYY-MM-DDTHH:mm:ss'));
    });
  }

  ngOnInit(): void {
    this.ngControl.statusChanges.subscribe(() => {
      if (this.ngControl.touched) {
        this.dateTime.get('date').markAsTouched();
        this.dateTime.get('time').markAsTouched();
      }
    })
  }

  writeValue(value: string): void {
    if (value) {
      const dateTime = moment(value);
      const date = dateTime.clone().startOf('day');
      const time = moment(value).format('HHmm');

      this.dateTime.get('date').patchValue(date, {emitEvent: false});
      if (value.split('T').length > 1 && time.slice(-2) !== '59') {
        this.dateTime.get('time').patchValue(time, {emitEvent: false});
      }
    }
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public onBlur(): void {
    // Логика округления до ближайших минут кратных 15
    const hours = this.dateTime.get('time').value.slice(0, 2);
    const minutes = +this.dateTime.get('time').value.slice(-2);

    if (this.dateTime.get('time').value.length === 4 && minutes % this.minutesStep !== 0 || minutes >= 60) {
      let roundMinutes = '' + Math.round(minutes / this.minutesStep) * this.minutesStep;

      if (roundMinutes === '0' || +roundMinutes >= 60) {
        roundMinutes = '00';
      }

      this.dateTime.get('time').setValue(hours + roundMinutes);
    }

    this.onTouched();
  }

}
