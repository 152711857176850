import { PostLikesDialogComponent } from '@modules/newsline/dialog/post-likes-dialog/post-likes-dialog.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'kp-likes',
  templateUrl: './likes.component.html',
  styleUrls: ['./likes.component.scss'],
})
export class LikesComponent implements OnInit {
  @Input() postPreviewLikesArr: any[];
  @Input() likesCount: number;
  @Input() postId: number;

  public isPreviewVisible: boolean = false;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  public get previewTotalLikes(): number {
    return this.likesCount - 4;
  }

  public openDialog(): void {
    this.dialog.open(PostLikesDialogComponent, {
      data: { postId: this.postId },
    });
  }
}
