import { Injectable } from '@angular/core';
import { MeetingFileHttpService } from '@core/services/api/meetings/meeting-file-http.service';
import { IFile, IFileDto } from '@shared/models/file.model';
import { KeyValue } from '@shared/models/key-value.model';
import { IMeeting } from '@shared/models/meetings/view/meeting.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeetingFileService {
  constructor(private MeetingFileHttpService: MeetingFileHttpService) {}

  getAllByTargetIdAndAddToTarget(
    targetId: number,
    target?: IMeeting,
    params?: KeyValue<string>,
    paramsString?: string,
  ): Observable<ArrayPayload<IFile>> {
    const filters = { meeting: targetId.toString(), ...params };
    const filtersInString = `meeting=${targetId}`;

    params = params ? { ...params, ...filters } : null;
    paramsString = paramsString ? `${paramsString}&${filtersInString}` : filtersInString;

    return this.MeetingFileHttpService.getAll(params, paramsString).pipe(
      tap((response) => {
        target.files = response.results;
      }),
    );
  }

  getById(id: number): Observable<IFile> {
    return this.MeetingFileHttpService.getById(id);
  }

  createAndAddToTarget(fileToCreate: IFileDto, target?: IMeeting): Observable<IFile> {
    return this.MeetingFileHttpService.create(fileToCreate).pipe(
      tap((createdFile) => {
        if (target) {
          if (!target.files?.length) {
            target.files = [];
          }
          target.files.push(createdFile);
        }
      }),
    );
  }

  updateByIdAndReplaceInTarget(id: number, dto: IFileDto, target?: IMeeting): Observable<IFile> {
    return this.MeetingFileHttpService.updateById(id, dto).pipe(
      tap((updatedFile) => {
        if (target?.files) {
          const targetFileIdx = target.files.findIndex((file) => file.id === updatedFile.id);
          if (targetFileIdx !== -1) {
            target.files[targetFileIdx] = updatedFile;
          }
        }
      }),
    );
  }

  deleteByIdAndRemoveFromTarget(id: number, target?: IMeeting): Observable<void> {
    const fileToDeleteIdx = target?.files?.findIndex((p) => p.id === id);
    if (fileToDeleteIdx !== -1) {
      target.files[fileToDeleteIdx].isDeleting = true;
    }

    return this.MeetingFileHttpService.deleteById(id).pipe(
      tap(
        () => {
          if (target.files) {
            target.files = target.files?.filter((file) => file.id !== id);
          }
        },
        () => {
          if (fileToDeleteIdx !== -1) {
            target.files[fileToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }
}
