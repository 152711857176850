import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IChatMessageDto } from '@shared/models/messages/dto/chat-message-dto.model';
import { ChatMessageHttpService } from '@core/services/api/messages/chat-message-http.service';
import { ArrayPayload } from '@shared/models/payload.model';
import { ShortMessageWithReaction } from '@shared/models/messages/view/chat-message.model';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatReactionsRequestService {
  constructor(private chatMessageHttpService: ChatMessageHttpService) {}

  // Запросы на реакции сообщения в чатах
  public postChatMessageReaction(chatId: number, chatMessageId: number, reaction: string): Observable<IChatMessageDto> {
    return this.chatMessageHttpService.postChatMessageReaction(chatId, chatMessageId, { reaction });
  }

  public deleteChatMessageReaction(chatId: number, chatMessageId: number): Observable<IChatMessageDto> {
    return this.chatMessageHttpService.deleteChatMessageReaction(chatId, chatMessageId);
  }

  // Пометить реакцию прочитанной
  public markReactionAsRead(chatId: number, chatMessageIds: number[]): Observable<IChatMessageDto> {
    return this.chatMessageHttpService.markReactionAsRead(chatId, chatMessageIds);
  }

  // Получить список всех непрочитанных реакций
  public getChatMessagesUnseenReactions(chatId: number): Observable<number[]> {
    return this.chatMessageHttpService.getChatMessagesUnseenReactions(chatId).pipe(
      take(1),
      map((unseenReactions: ArrayPayload<ShortMessageWithReaction>) =>
        unseenReactions.results.map((reaction: ShortMessageWithReaction) => reaction.id).reverse(),
      ),
    );
  }

  // Получить список всех непросмотренных упоминаний
  public getChatMessagesUnseenMentions(chatId: number): Observable<number[]> {
    return this.chatMessageHttpService.getChatMessagesUnseenMentions(chatId).pipe(
      take(1),
      map((unseenMentions: ArrayPayload<ShortMessageWithReaction>) =>
        unseenMentions.results.map((mention: ShortMessageWithReaction) => mention.id),
      ),
    );
  }
}
