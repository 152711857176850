<div
  class="kp-popup-header"
  fxLayout="row"
  fxLayoutAlign="space-between start"
  fxLayoutGap="24px"
>
  <div class="kp-popup-title">
    <ng-content></ng-content>
  </div>

  <kp-svg
    key="xIcon"
    color="#000000"
    width="24px"
    height="24px"
    mat-dialog-close=""
  ></kp-svg>
</div>
