import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChatMessageService } from '@core/services/business/messages/chat-message.service';
import { ChatService } from '@core/services/business/messages/chat.service';
import { ChatRelatedFieldKey } from '@modules/chats/enums/keys.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IN_ANIMATION } from '@shared/animations/in-out.animation';
import { BaseClass } from '@shared/components/base/base.class';
import { KeyValue } from '@shared/models/key-value.model';
import { IChat } from '@shared/models/messages/view/chat.model';
import { IUsersDialogConfig } from '@shared/models/user/members-dialog-config.model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ArrayPayload } from '@shared/models/payload.model';
import { QueryParams } from '@shared/models/query-params.model';
import { IChatMessage } from '@shared/models/messages/view/chat-message.model';
import { MatSnackBar } from '@angular/material/snack-bar';

const CHATS_REQUEST_LIMIT: number = 50;
const SELECTED_CHATS_LIMIT: number = 10;
const CHATS_SEARCH_DEBOUNCE_MS: number = 300;
const DEFAULT_CHAT_NAME: string = 'Название беседы';

@UntilDestroy()
@Component({
  selector: 'kp-chat-list-dialog',
  templateUrl: './forward-messages-target-chat-selection-dialog.component.html',
  styleUrls: ['./forward-messages-target-chat-selection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [IN_ANIMATION],
})
export class ForwardMessagesTargetChatSelectionDialogComponent extends BaseClass implements OnInit {
  public readonly currentChat$: Observable<IChat> = this.chatService.currentChat$;

  public readonly isChatsListLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // частые чаты видны только при отсутствии поискового запроса
  public readonly isOfterChatsVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly selectedChatsIds$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  public readonly selectedChatsCount$: Observable<number> = this.selectedChatsIds$.pipe(
    map((selectedChats: number[]) => selectedChats.length),
  );
  public readonly selectedChatsLimit: number = SELECTED_CHATS_LIMIT;

  public readonly searchControl: FormControl = new FormControl('');

  public readonly defaultChatName: string = DEFAULT_CHAT_NAME;

  public readonly isForwardInProcess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly paginationOptions: QueryParams = {
    limit: CHATS_REQUEST_LIMIT as number,
    offset: 0 as number,
  };

  public readonly oftenContactChats$: BehaviorSubject<IChat[]> = new BehaviorSubject<IChat[]>([]);
  public readonly chats$: BehaviorSubject<IChat[]> = new BehaviorSubject<IChat[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: KeyValue<IUsersDialogConfig>,
    private dialogRef: MatDialogRef<ForwardMessagesTargetChatSelectionDialogComponent>,
    private chatService: ChatService,
    private chatMessageService: ChatMessageService,
    private snackbar: MatSnackBar,
    private router: Router,
  ) {
    super();
  }

  public ngOnInit(): void {
    this._getOftenContactUsers();
    this._searchControlChangesHandler();
  }

  // ограничение выбора чатов
  public isSelectedChatsLimitAchieved(chatId: number): Observable<boolean> {
    return this.selectedChatsIds$.pipe(
      map(
        (selectedChatsIds: number[]) =>
          !this._isChatIdExistInChatIdList(chatId, selectedChatsIds) && selectedChatsIds.length >= SELECTED_CHATS_LIMIT,
      ),
    );
  }

  // Отмечает выбранные чаты и добавляет их в массив chatsForForwardMessages.
  public toggleChatSelection(chatId: number): void {
    combineLatest([this.selectedChatsIds$, this.isSelectedChatsLimitAchieved(chatId)])
      .pipe(take(1))
      .subscribe(([selectedChatsSelection, isSelectedChatsLimitAchieved]: [number[], boolean]) => {
        if (isSelectedChatsLimitAchieved) {
          this.snackbar.open('Нельзя выбрать больше 10 чатов для пересылки сообщений', 'Закрыть', {
            duration: 1500,
            panelClass: 'snack-bar-container-custom',
          });
        } else {
          this.selectedChatsIds$.next(this._toggleChatSelection(chatId, selectedChatsSelection));
        }
      });
  }

  // Отправляет массив пересылаемых сообщений в каждый выбранный чат
  public forwardMessagesToSelectedChats(): void {
    combineLatest([this.currentChat$, this.selectedChatsIds$, this.chatMessageService.selectedMessages$])
      .pipe(
        take(1),
        switchMap(([currentChat, selectedChatsIds, selectedMessages]: [IChat, number[], IChatMessage[]]) => {
          const messageIds: number[] = selectedMessages.map((message: IChatMessage) => message.id);
          this.isForwardInProcess$.next(true);

          return this.chatService.postForwardMessagesInChat(messageIds, currentChat.id, selectedChatsIds);
        }),
        withLatestFrom(this.selectedChatsIds$),
      )
      .subscribe(([_, selectedChatsIds]: [void, number[]]) => {
        // Переходит в первый выбранный чат, куда пересылаются сообщения
        this.router.navigate([`messages/${selectedChatsIds[0]}`]);
        this.isForwardInProcess$.next(false);
        this.dialogRef.close();
      });
  }

  public getNewChatsPortion(): void {
    (this.paginationOptions.offset as number) += CHATS_REQUEST_LIMIT;
    this._getChats();
  }

  public isChatSelected(chatId: number): Observable<boolean> {
    return this.selectedChatsIds$.pipe(
      take(1),
      map((selectedChatsIds: number[]) => this._isChatIdExistInChatIdList(chatId, selectedChatsIds)),
    );
  }

  private _getChats(): void {
    this.isChatsListLoading$
      .pipe(
        take(1),
        filter((isChatsListLoading: boolean) => !isChatsListLoading),
      )
      .subscribe(() => this._getChatsForForwardMessages());
  }

  private _searchControlChangesHandler(): void {
    this.searchControl.valueChanges.pipe(untilDestroyed(this), debounceTime(CHATS_SEARCH_DEBOUNCE_MS)).subscribe(() => {
      this.paginationOptions.offset = 0;
      this._getChats();
    });
  }

  // Получает список чатов для пересылки сообщений
  private _getChatsForForwardMessages(): void {
    this.isChatsListLoading$.next(true);

    const searchQuery: string = this.searchControl.value.trim();

    const filters: QueryParams = {
      ...this.paginationOptions,
      [ChatRelatedFieldKey.Name]: searchQuery,
    };

    const getChatsRequest: Observable<ArrayPayload<IChat>> = this.chatService.getChats(filters);

    combineLatest([this.chats$, getChatsRequest])
      .pipe(take(1))
      .subscribe(([existChats, response]: [IChat[], ArrayPayload<IChat>]) => {
        this.chats$.next(this.paginationOptions.offset ? [...existChats, ...response.results] : response.results);
        this.isOfterChatsVisible$.next(!!searchQuery);

        this.isChatsListLoading$.next(false);
      });
  }

  // Получает список недавних чатов
  private _getOftenContactUsers(): void {
    this.isChatsListLoading$.next(true);

    this.chatService.getChats({ recent: 'true' }).subscribe((response: ArrayPayload<IChat>) => {
      this.oftenContactChats$.next(response.results);
      this.isChatsListLoading$.next(false);

      this._getChatsForForwardMessages();
    });
  }

  private _toggleChatSelection(chatId: number, selectedChatsIds: number[]): number[] {
    return this._isChatIdExistInChatIdList(chatId, selectedChatsIds)
      ? selectedChatsIds.filter((selectedChatId: number) => selectedChatId !== chatId)
      : [...selectedChatsIds, chatId];
  }

  private _isChatIdExistInChatIdList(chatId: number, chatIdList: number[]): boolean {
    return chatIdList.includes(chatId);
  }
}
