import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { TooltipService } from '@core/services/business/utils/tooltip.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseClass } from '@shared/components/base/base.class';
import { fromEvent, interval } from 'rxjs';
import { SubSink } from 'subsink';

@UntilDestroy()
@Directive({
  selector: '[kpTooltip]',
})
export class TooltipDirective extends BaseClass implements OnChanges, OnDestroy {
  @Input('kpTooltip') tooltipText: string;
  @Input() tooltipCloseDebounceMs = 300;
  @Input() tooltipCloseOnHostClick = true;
  @Input() tooltipCloseOnClick = true;
  @Input() tooltipContainerListeners = false;
  @Output() tooltipClick = new EventEmitter<void>();

  isActive = false;

  tooltipContainer: Element;
  tooltipId: string;
  tooltipStartCloseAnimationDebounceMs = 100;
  mouseEnterListenerSub = new SubSink();
  mouseLeaveListenerSub = new SubSink();
  clickListenerSub = new SubSink();

  constructor(private elementRef: ElementRef, private tooltipService: TooltipService) {
    super();
    this.tooltipId = this.tooltipService.generateTooltipId();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tooltipText?.currentValue && this.isActive && !this.isShowed) {
      this._addTooltip();
    }
  }

  ngOnDestroy() {
    this.tooltipService.removeTooltipsByIds(this.tooltipService.tooltipIds);
  }

  @HostListener('mouseenter')
  private _onMouseEnter() {
    this.isActive = true;
    this.subs.unsubscribe();

    if (this.tooltipText) {
      if (!this.isShowed) {
        this._addTooltip();
      } else {
        this.tooltipService.removeTooltipsByIds(this.tooltipService.tooltipIds, this.tooltipId);
        this.tooltipService.animateTooltipContainer(
          this.tooltipContainer,
          this.tooltipService.addAnimationFromCurrentState,
          this.tooltipCloseDebounceMs,
        );
      }
    }
  }

  @HostListener('mouseleave')
  private _onMouseLeave() {
    if (this.isActive && this.isShowed) {
      this._removeCurrentTooltipWithDebounce();
    }
    this.isActive = false;
  }

  @HostListener('click')
  private _onClick() {
    if (this.tooltipCloseOnHostClick) {
      this.isActive = false;
      this._removeCurrentTooltip();
    }
  }

  private _addTooltip() {
    this.tooltipService.removeTooltipsByIds(this.tooltipService.tooltipIds, this.tooltipId);

    this.tooltipContainer = this.tooltipService.addTooltipToBody(
      this.elementRef,
      this.tooltipText,
      this.tooltipId,
      this.tooltipCloseDebounceMs,
      this.tooltipCloseOnClick,
    );
    this._startTooltipListeners();
  }

  private _removeCurrentTooltipWithDebounce() {
    this.subs.unsubscribe();

    if (this.tooltipContainerListeners) {
      this.subs.sink = interval(this.tooltipStartCloseAnimationDebounceMs)
        .pipe(untilDestroyed(this))
        .subscribe(() =>
          this.tooltipService.animateTooltipContainer(
            this.tooltipContainer,
            this.tooltipService.removeAnimationFromCurrentState,
            this.tooltipCloseDebounceMs - this.tooltipStartCloseAnimationDebounceMs,
          ),
        );
    } else {
      this.tooltipService.animateTooltipContainer(
        this.tooltipContainer,
        this.tooltipService.removeAnimationFromCurrentState,
        this.tooltipCloseDebounceMs,
      );
    }

    this.subs.sink = interval(this.tooltipCloseDebounceMs)
      .pipe(untilDestroyed(this))
      .subscribe(() => this._removeCurrentTooltip());
  }

  private _removeCurrentTooltip() {
    this.subs.unsubscribe();

    this.tooltipService.removeTooltipById(this.tooltipId);
  }

  private _startTooltipListeners() {
    if (this.tooltipContainerListeners) {
      this._tooltipMouseEnterListener(this.tooltipContainer);
      this._tooltipMouseLeaveListener(this.tooltipContainer);
      this._tooltipClickListener(this.tooltipContainer);
    }
  }

  private _tooltipMouseEnterListener(tooltipContainer: Element) {
    this.mouseEnterListenerSub.unsubscribe();

    this.mouseEnterListenerSub.sink = fromEvent(tooltipContainer, 'mouseenter')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._onMouseEnter();
      });
  }

  private _tooltipMouseLeaveListener(tooltipContainer: Element) {
    this.mouseLeaveListenerSub.unsubscribe();

    this.mouseLeaveListenerSub.sink = fromEvent(tooltipContainer, 'mouseleave')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._onMouseLeave();
      });
  }

  private _tooltipClickListener(tooltipContainer: Element) {
    this.clickListenerSub.unsubscribe();

    this.clickListenerSub.sink = fromEvent(tooltipContainer, 'click')
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        this.tooltipService.clickEventEmitter(event as MouseEvent, this.tooltipClick);

        if (this.tooltipCloseOnClick) {
          this._removeCurrentTooltip();
        }
      });
  }

  get isShowed(): boolean {
    return this.tooltipService.tooltipIds.includes(this.tooltipId);
  }
}
