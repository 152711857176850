import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Удаляет пробелы из вводимого значения.
 */
@Directive({
  selector: '[kpNoWhiteSpaces]',
})
export class KpNoWhiteSpacesDirective {
  @Input('kpNoWhiteSpaces') isFormControl = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('ngModelChange', ['$event'])
  onKeyDown() {
    this._deleteWhiteSpaces();
  }

  private _deleteWhiteSpaces() {
    const result = this.elementRef.nativeElement.value.toString().replace(/\s+/g, '');

    if (result !== this.elementRef.nativeElement.value) {
      this.elementRef.nativeElement.value = result;

      if (this.isFormControl) {
        this.elementRef.nativeElement.dispatchEvent(new Event('input'));
      }
    }
  }
}
