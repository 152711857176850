import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FilterDialogService } from '@core/services/business/utils/filter-dialog.service';
import { FormValidationService } from '@core/services/business/utils/form-validation.service';
import { IN_OUT_ANIMATION } from '@shared/animations/in-out.animation';
import { BaseFormComponent } from '@shared/components/base-form/base-form.component';
import { ISelectOption } from '@shared/models/select.model';

@Component({
  selector: 'kp-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  animations: [IN_OUT_ANIMATION],
})
export class FilterDialogComponent extends BaseFormComponent {
  constructor(
    public filterDialogService: FilterDialogService,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    protected formValidationService: FormValidationService,
  ) {
    super(formValidationService);
    this.filterDialogService.dialogRef = this.dialogRef;
    this.filterDialogService.initForm();
  }

  getOptionByFilterGroup(filter: AbstractControl): ISelectOption {
    return this.filterDialogService.getOptionByFilterField(filter.get('name').value);
  }
}
