import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prepareBackgroundImage',
})
export class PrepareBackgroundImagePipe implements PipeTransform {
  constructor() {}

  transform(imageLink: string): string {
    if (imageLink && typeof imageLink === 'string') {
      return `url(${imageLink})`;
    }
  }
}
