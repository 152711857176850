import { Injectable } from '@angular/core';
import { NewslinePostImageHttpService } from '@core/services/api/newsline/newsline-post-image-http.service';
import { INewslinePostImageDto } from '@shared/models/newsline/dto/newsline-post-image-dto.model';
import { INewslinePostImage } from '@shared/models/newsline/view/newsline-post-image.model';
import { INewslinePost } from '@shared/models/newsline/view/newsline-post.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostImageService {
  constructor(private newslinePostImageHttpService: NewslinePostImageHttpService) {}

  getImagesByPostIdAndAddToPost(postId: number, post?: INewslinePost): Observable<INewslinePostImage[]> {
    if (post) {
      post.isLoadingImages = true;
    }

    return this.newslinePostImageHttpService.getImages({ post: postId.toString() }).pipe(
      map((response) => {
        if (post) {
          post.images = response.results;
          post.isLoadingImages = false;
        }

        return response.results;
      }),
    );
  }

  getImageById(id: number): Observable<INewslinePostImage> {
    return this.newslinePostImageHttpService.getImageById(id);
  }

  createImageAndAddToPost(imageToCreate: INewslinePostImageDto, post?: INewslinePost): Observable<INewslinePostImage> {
    return this.newslinePostImageHttpService.createImage(imageToCreate).pipe(
      tap((createdImage) => {
        if (post) {
          if (!post.images?.length) {
            post.images = [];
          }
          post.images.push(createdImage);
        }
      }),
    );
  }

  updateImageByIdAndReplaceInPost(imageId: number, imageDto: INewslinePostImageDto, post?: INewslinePost): Observable<INewslinePostImage> {
    return this.newslinePostImageHttpService.updateImageById(imageId, imageDto).pipe(
      tap((updatedImage) => {
        if (post?.images) {
          const targetImageIdx = post.images.findIndex((image) => image.id === updatedImage.id);
          if (targetImageIdx !== -1) {
            post.images[targetImageIdx] = updatedImage;
          }
        }
      }),
    );
  }

  deleteImageByIdAndRemoveFromPost(imageId: number, post?: INewslinePost): Observable<void> {
    const imageToDeleteIdx = post?.images?.findIndex((p) => p.id === imageId);
    if (imageToDeleteIdx !== -1) {
      post.images[imageToDeleteIdx].isDeleting = true;
    }

    return this.newslinePostImageHttpService.deleteImageById(imageId).pipe(
      tap(
        () => {
          if (post?.images) {
            post.images = post.images.filter((image) => image.id !== imageId);
          }
        },
        () => {
          if (imageToDeleteIdx !== -1) {
            post.images[imageToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }
}
