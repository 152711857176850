import { Injectable } from '@angular/core';
import { IMeeting, IMeetingLink } from '@shared/models/meetings/view/meeting.model';
import { MeetingHttpService } from '@core/services/api/meetings/meeting-http.service';
import { KeyValue } from '@shared/models/key-value.model';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { DateService } from '../utils/date.service';
import { IMeetingDto } from '@shared/models/meetings/dto/meeting-dto.model';
import { ISelectOption } from '@shared/models/select.model';
import { MeetingRelatedFieldKey, MeetingStateKey } from '@modules/meetings/enums/keys.enum';
import { FilterType } from '@shared/enums/filter/filters-type.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { FilterService } from '../utils/filter.service';
import {
  COMMON_MEETINGS_TABLE_MEMBER_DEFAULT_COLUMNS,
  COMMON_MEETINGS_TABLE_STAFF_DEFAULT_COLUMNS,
  INDUSTRY_MEETINGS_TABLE_MEMBER_DEFAULT_COLUMNS,
  INDUSTRY_MEETINGS_TABLE_STAFF_DEFAULT_COLUMNS,
  MEETINGS_DEFAULT_FILTERS,
  MEETINGS_FILTERS_IGNORE_URL,
  MEETINGS_LIST_DEFAULT_LIMIT,
  MEETINGS_PAGE_DEFAULT_FILTERS,
  MEETINGS_TABLE_COLUMNS,
} from '@modules/meetings/constants/common.const';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FilterDialogComponent } from '@shared/dialogs/filter-dialog/filter-dialog.component';
import { IUser } from '@shared/models/user/view/user.model';
import { AuthService } from '../auth/auth.service';
import { ITableConfig } from '@shared/models/table/table-config.model';
import * as _ from 'lodash';
import { SelectDialogComponent } from '@shared/dialogs/select-dialog/select-dialog.component';
import {
  CANCELLED_MEETING_MENU_OPTIONS,
  COMMON_MEETINGS_TABLE_MEMBER_COLUMNS_OPTIONS,
  COMMON_MEETINGS_TABLE_STAFF_COLUMNS_OPTIONS,
  DRAFT_FORUM_IN_LIST_MENU_OPTIONS_ADMIN,
  DRAFT_MEETING_MENU_OPTIONS,
  FORM_OF_PAID_OPTIONS,
  FORUM_IN_LIST_MENU_OPTIONS_MEMBER,
  INDUSTRY_MEETINGS_TABLE_MEMBER_COLUMNS_OPTIONS,
  INDUSTRY_MEETINGS_TABLE_STAFF_COLUMNS_OPTIONS,
  MEETINGS_MODAL_FILTERS_OPTIONS_MEMBER,
  MEETINGS_MODAL_FILTERS_OPTIONS_STAFF,
  MEETINGS_TYPE_OPTIONS,
  NOT_DRAFT_FORUM_IN_LIST_MENU_OPTIONS_STAFF,
  PUBLISHED_MEETING_MENU_OPTIONS,
} from '@modules/meetings/constants/select-options.const';
import { SelectMode } from '@shared/enums/select/select-mode.enum';
import { ITableColumn } from '@shared/models/table/table-column.model';
import { TableService } from '../utils/table.service';
import { ArrayPayload } from '@shared/models/payload.model';
import { FORM_OF_PARTICIPATION_OPTIONS } from '@modules/profile/constants/selects-options.const';
import { PageMode } from '@shared/enums/keys.enum';
import { ForumService } from '../forums/forum.service';
import { IForum } from '@shared/models/forums/view/forum.model';
import { IForumMember } from '@shared/models/forums/view/forum-member.model';
import { BaseClass } from '@shared/components/base/base.class';
import {
  FORUM_AND_MEETING_CONFIG,
  FORUM_AND_MEETING_CONFIG_COMMON,
  FORUM_AND_MEETING_CONFIG_INDUSTRY,
} from '@modules/forums/constants/common.const';
import { IForumAndMeetingModulesConfig } from '@shared/models/forums/view/forum-meeting-config.model';
import { ITableRow } from '@shared/models/table/table-row.model';
import { MEETING_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME } from '@modules/meetings/constants/mappers.const';
import { IFile } from '@shared/models/file.model';
import { MeetingFileService } from './meeting-file.service';
import { MeetingLinkService } from './meeting-link.service';
import { ForumOrMeetingType } from '@modules/forums/enums/keys.enum';
import { IMeetingMember } from '@shared/models/meetings/view/meeting-member.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class MeetingService extends BaseClass {
  meetings: IMeeting[] = [];
  meetingsCount: number;
  currentMeeting: IMeeting;
  isListMeetingsLoading = false;

  currentUserMeetingMemberProfile: IMeetingMember;

  tableConfig: ITableConfig;
  tableCols: ITableColumn[];

  currentCreateEditMode: PageMode;
  createEditPageTitle: string;

  formOfParticipationOptions = FORM_OF_PARTICIPATION_OPTIONS;
  formOfPaidOptions = FORM_OF_PAID_OPTIONS;
  meetingsModalFiltersOptions: ISelectOption[];
  meetingTypeOptions: ISelectOption[];

  draftForNewMeeting: IMeeting;

  constructor(
    private forumService: ForumService,
    private authService: AuthService,
    private meetingHttpService: MeetingHttpService,
    private dialog: MatDialog,
    private dateService: DateService,
    private filterService: FilterService,
    private tableService: TableService,
    private meetingFileService: MeetingFileService,
    private meetingLinkService: MeetingLinkService,
  ) {
    super();
    this.meetingsModalFiltersOptions = this.currentUser?.isStaff
      ? MEETINGS_MODAL_FILTERS_OPTIONS_STAFF
      : MEETINGS_MODAL_FILTERS_OPTIONS_MEMBER;
  }

  getMeetingsCountByForumId(forumId: number, type = this.forumAndMeetingConfig.forumOrMeetingType): Observable<number> {
    const params = {
      [MeetingRelatedFieldKey.ForumType]: type,
      [MeetingRelatedFieldKey.Forum]: forumId.toString(),
      [MeetingRelatedFieldKey.Limit]: '1',
    };

    return this.meetingHttpService.getMeetings(params).pipe(
      map((response) => {
        return response.count;
      }),
    );
  }

  getMeetingsAndAddToTargetMeetings(
    targetMeetings?: IMeeting[],
    params?: KeyValue<string>,
    paramsString?: string,
    setMeetingsCount = true,
    type = this.forumAndMeetingConfig?.forumOrMeetingType,
  ): Observable<ArrayPayload<IMeeting>> {
    if (type !== undefined && type !== null) {
      const filter = { [MeetingRelatedFieldKey.ForumType]: type };
      const filterInString = `${MeetingRelatedFieldKey.ForumType}=${type}`;

      params = params ? { ...params, ...filter } : null;
      paramsString = paramsString ? `${paramsString}&${filterInString}` : filterInString;
    }

    return this.meetingHttpService.getMeetings(params, paramsString).pipe(
      mergeMap((response) => {
        if (setMeetingsCount) {
          this.meetingsCount = response.count;
        }

        if (!targetMeetings) {
          targetMeetings = [];
        }

        response.results.forEach((postDto) => {
          targetMeetings.push(this._transformMeetingDtoToMeetingView(postDto));
        });

        const result: ArrayPayload<IMeeting> = {
          results: targetMeetings,
          count: response.count,
        };

        const forumProfilesReqs = response.results.map((meetingDto) =>
          this.forumService.getForumById(meetingDto.forum).pipe(
            tap((forum) => {
              const targetMeetingIdx = result.results.findIndex((meetingView) => meetingView.id === meetingDto.id);
              if (targetMeetingIdx !== -1) {
                result.results[targetMeetingIdx].forum = forum;
              }
            }),
          ),
        );

        if (forumProfilesReqs?.length) {
          return forkJoin(forumProfilesReqs).pipe(map(() => result));
        }
        return of(result);
      }),
    );
  }

  getForumRequestAndFillTargetMembers(forumId: number, targetMembers?: IForumMember[]): Observable<IForum> {
    return this.forumService.getForumById(forumId).pipe(
      tap((memberForum) => {
        targetMembers?.forEach((member) => {
          member.forumProfile = memberForum;
        });
      }),
    );
  }

  getMeetingsByForumIdAndAddToTargetMeetings(
    forumId: number,
    targetMeetings?: IMeeting[],
    params?: KeyValue<string>,
    paramsString?: string,
    setMeetingsCount = true,
  ): Observable<ArrayPayload<IMeeting>> {
    const filters = { [MeetingRelatedFieldKey.Forum]: forumId.toString(), ...params };
    const filtersInString = `${MeetingRelatedFieldKey.Forum}=${forumId}`;

    params = params ? { ...params, ...filters } : null;
    paramsString = paramsString ? `${paramsString}&${filtersInString}` : filtersInString;

    return this.getMeetingsAndAddToTargetMeetings(targetMeetings, filters, filtersInString, setMeetingsCount);
  }

  getMeetingById(id: number): Observable<IMeeting> {
    return this.meetingHttpService.getMeetingById(id).pipe(
      mergeMap((dto) => {
        return this.forumService.getForumById(dto.forum).pipe(
          map((forum) => {
            const view = this._transformMeetingDtoToMeetingView(dto);
            view.forum = forum;
            return view;
          }),
        );
      }),
    );
  }

  getMeetingByIdWithAdditionalInfo(id: number): Observable<IMeeting> {
    return this.getMeetingById(id).pipe(
      mergeMap((dto) => {
        if (dto) {
          const reqs: Array<Observable<ArrayPayload<IFile | IMeetingLink>>> = [];
          reqs.push(this.meetingFileService.getAllByTargetIdAndAddToTarget(dto.id, dto));
          reqs.push(this.meetingLinkService.getAllByTargetIdAndAddToTarget(dto.id, dto));

          return forkJoin(reqs).pipe(map(() => dto));
        }

        return of(dto);
      }),
    );
  }

  createMeeting(meeting: Partial<IMeetingDto>): Observable<IMeeting> {
    meeting.started_at = moment(meeting.started_at).utc().format();

    return this.meetingHttpService.createMeeting(meeting).pipe(
      map((dto) => {
        return this._transformMeetingDtoToMeetingView(dto);
      }),
    );
  }

  patchMeetingById(id: number, meeting: Partial<IMeetingDto> | FormData): Observable<IMeeting> {
    return this.meetingHttpService.patchMeetingById(id, meeting).pipe(
      map((dto) => {
        return this._transformMeetingDtoToMeetingView(dto);
      }),
    );
  }

  deleteMeetingByIdAndRemoveFromTargetMeetings(postId: number, targetMeetings?: IMeeting[]): Observable<void> {
    const itemToDeleteIdx = targetMeetings?.findIndex((p) => p.id === postId);
    if (targetMeetings && itemToDeleteIdx !== -1) {
      targetMeetings[itemToDeleteIdx].isDeleting = true;
    }

    return this.meetingHttpService.deleteMeetingById(postId).pipe(
      tap(
        () => {
          if (targetMeetings && itemToDeleteIdx !== -1) {
            targetMeetings.splice(itemToDeleteIdx, 1);
          }
        },
        () => {
          if (targetMeetings && itemToDeleteIdx !== -1) {
            targetMeetings[itemToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }

  publishMeeting(id: number, targetMeeting?: IMeeting): Observable<IMeeting> {
    return this.patchMeetingById(id, { [MeetingRelatedFieldKey.State]: +MeetingStateKey.Published }).pipe(
      tap((meeting) => {
        if (targetMeeting) {
          targetMeeting.state = meeting.state;
          targetMeeting.menuOptionsInList = this.getMeetingMenuOptionsForListPage(targetMeeting);
          targetMeeting.menuOptionsOnProfilePage = this.getMeetingMenuOptionsForProfilePage(targetMeeting);
        }
      }),
    );
  }

  finishMeeting(id: number, targetMeeting?: IMeeting): Observable<IMeeting> {
    return this.patchMeetingById(id, { [MeetingRelatedFieldKey.State]: +MeetingStateKey.Finished }).pipe(
      tap((meeting) => {
        if (targetMeeting) {
          targetMeeting.state = meeting.state;
          targetMeeting.menuOptionsInList = this.getMeetingMenuOptionsForListPage(targetMeeting);
          targetMeeting.menuOptionsOnProfilePage = this.getMeetingMenuOptionsForProfilePage(targetMeeting);
          targetMeeting.finished_at = meeting.finished_at;
        }
      }),
    );
  }

  cancelMeeting(id: number, targetMeeting?: IMeeting): Observable<IMeeting> {
    return this.patchMeetingById(id, { [MeetingRelatedFieldKey.State]: +MeetingStateKey.Cancelled }).pipe(
      tap((meeting) => {
        if (targetMeeting) {
          targetMeeting.state = meeting.state;
          targetMeeting.menuOptionsInList = this.getMeetingMenuOptionsForListPage(targetMeeting);
          targetMeeting.menuOptionsOnProfilePage = this.getMeetingMenuOptionsForProfilePage(targetMeeting);
        }
      }),
    );
  }

  getFirstMeetingByForumId(forumId: number): Observable<IMeeting> {
    const params = {
      [MeetingRelatedFieldKey.Ordering]: MeetingRelatedFieldKey.StartedAt,
      [MeetingRelatedFieldKey.Limit]: '1',
    };

    return this.getMeetingsByForumIdAndAddToTargetMeetings(forumId, null, params, null, false).pipe(
      map((response) => response.results[0]),
    );
  }

  isForumFirstMeetingAlreadyEnded(forumId: number): Observable<boolean> {
    return this.getFirstMeetingByForumId(forumId).pipe(
      map((meeting) => {
        if (meeting) {
          return meeting.state === +MeetingStateKey.Finished;
        }
        return false;
      }),
    );
  }

  private _transformMeetingDtoToMeetingView(dto: IMeetingDto): IMeeting {
    let view: Partial<IMeeting> = {};

    view.meetingPageRoute = this.getMeetingPageRoute(dto);
    view.editPageRoute = this.getMeetingEditPageRouteById(dto);

    view.started_at = this.dateService.transfromStringToMoment(dto.started_at);
    view.finished_at = this.dateService.transfromStringToMoment(dto.finished_at);
    view.created = this.dateService.transfromStringToMoment(dto.created);
    view.updated = this.dateService.transfromStringToMoment(dto.updated);

    view.moderator = this.authService.transformUserShortDtoToUserShortView(dto.moderator);
    view.creator = this.authService.transformUserShortDtoToUserShortView(dto.creator);

    view.menuOptionsInList = this.getMeetingMenuOptionsForListPage(dto);
    view.menuOptionsOnProfilePage = this.getMeetingMenuOptionsForProfilePage(dto);

    view.isStateCancelled = dto.state === +MeetingStateKey.Cancelled;
    view.isStateDraft = dto.state === +MeetingStateKey.Draft;
    view.isStateFinished = dto.state === +MeetingStateKey.Finished;
    view.isStateInProgress = dto.state === +MeetingStateKey.InProgress;
    view.isStatePublished = dto.state === +MeetingStateKey.Published;

    view = Object.assign(dto, view);
    return view as IMeeting;
  }

  getMeetingMenuOptionsForListPage(meeting: IMeetingDto | IMeeting): ISelectOption[] {
    let options: ISelectOption[] = [];

    if (
      (meeting.state === +MeetingStateKey.Draft && this.authService.currentUser.id === meeting.creator?.id) ||
      this.authService.currentUser.permissions.delete_forum
    ) {
      options = DRAFT_FORUM_IN_LIST_MENU_OPTIONS_ADMIN;
    } else if (this.authService.currentUser.isStaff) {
      options = NOT_DRAFT_FORUM_IN_LIST_MENU_OPTIONS_STAFF;
    } else {
      options = FORUM_IN_LIST_MENU_OPTIONS_MEMBER;
    }

    return options;
  }

  getMeetingMenuOptionsForProfilePage(meeting: IMeetingDto | IMeeting): ISelectOption[] {
    let options: ISelectOption[] = [];

    if (this.authService.currentUser.isStaff) {
      if (meeting.state === +MeetingStateKey.Draft) {
        options = DRAFT_MEETING_MENU_OPTIONS;
      } else if (meeting.state === +MeetingStateKey.Cancelled) {
        options = CANCELLED_MEETING_MENU_OPTIONS;
      } else if (meeting.state === +MeetingStateKey.Published) {
        options = PUBLISHED_MEETING_MENU_OPTIONS;
      }
    }

    return options;
  }

  getMeetingPageRoute(meeting: IMeetingDto | IMeeting): string {
    let { pathToMeetingsBootstrap } = FORUM_AND_MEETING_CONFIG_COMMON;
    if (meeting.forum_type === +ForumOrMeetingType.Industry) {
      pathToMeetingsBootstrap = FORUM_AND_MEETING_CONFIG_INDUSTRY.pathToMeetingsBootstrap;
    }

    return `${pathToMeetingsBootstrap}/${meeting.id}`;
  }

  getMeetingEditPageRouteById(forum: IMeetingDto | IMeeting): string {
    return `${this.getMeetingPageRoute(forum)}/edit`;
  }

  initMeetingTypeOptionsAndFilters() {
    MEETINGS_TYPE_OPTIONS.find(
      (o) => o.filterField === MeetingRelatedFieldKey.User,
    ).filterValue = this.currentUser.id.toString();
    this.meetingTypeOptions = MEETINGS_TYPE_OPTIONS;

    this.meetingTypeOptions = this.filterService.selectOptionsByFilters(
      this.meetingTypeOptions,
      this.filterService.filters,
      MeetingRelatedFieldKey.MeetingType,
      null,
    );
    this.filterService.setFilterFromSelectOption(this.meetingTypeOptions.find((o) => o.isSelected));
  }

  setCreateEditPageTitle() {
    this.createEditPageTitle = this.isCurrentModeCreate ? 'Создать встречу' : 'Редактировать встречу';
  }

  openForumsFilterDialog() {
    this.dialog.open(FilterDialogComponent);
  }

  setFilterFromTemplate(
    filterType: FilterType,
    option?: ISelectOption,
    filters?: IFilter | IFilter[],
    itemsCountTotal?: number,
    itemsCountCurrent?: number,
    offsetStep?: string,
    tableConfig?: ITableConfig,
  ): { needLoadItems: boolean } {
    const { needLoadItems } = this.filterService.setFilterFromTemplate(
      filterType,
      option,
      filters,
      itemsCountTotal,
      itemsCountCurrent,
      offsetStep,
    );

    if (needLoadItems) {
      switch (filterType) {
        case FilterType.DataFilters: {
          this.initPageFilters();
          this.initDefaultFiltersState();
          this.updateTableConfig(tableConfig);
        }
      }
      this.filterService.setFiltersToUrlParameters(MEETINGS_FILTERS_IGNORE_URL);
    }
    return { needLoadItems };
  }

  initPageFilters() {
    const pageFilters = _.cloneDeep(MEETINGS_PAGE_DEFAULT_FILTERS);
    this.filterService.mergeFiltersArrays(pageFilters, this.filterService.filters);
  }

  initDefaultFiltersState() {
    const defaultFilters = _.cloneDeep(MEETINGS_DEFAULT_FILTERS);
    this.filterService.defaultFilters = defaultFilters;
  }

  openTableColumnsSettingsDialog(): MatDialogRef<SelectDialogComponent, any> {
    return this.dialog.open(SelectDialogComponent, {
      data: {
        options: this._meetingsTableColumnsSettingsOptions,
        selectedOptionsValues: this.tableConfig.columns.map((col) => col.headerKey),
        selectMode: SelectMode.MultiSelect,
      },
    });
  }

  private get _meetingsTableColumnsSettingsOptions(): ISelectOption[] {
    if (this.currentUser?.isStaff) {
      return this.forumAndMeetingConfig.isCurrentTypeCommon
        ? COMMON_MEETINGS_TABLE_STAFF_COLUMNS_OPTIONS
        : INDUSTRY_MEETINGS_TABLE_STAFF_COLUMNS_OPTIONS;
    }
    return this.forumAndMeetingConfig.isCurrentTypeCommon
      ? COMMON_MEETINGS_TABLE_MEMBER_COLUMNS_OPTIONS
      : INDUSTRY_MEETINGS_TABLE_MEMBER_COLUMNS_OPTIONS;
  }

  setTableColsByKeys(keys: string[]) {
    this.tableCols = MEETINGS_TABLE_COLUMNS.filter((col) => keys.includes(col.headerKey));
  }

  updateTableConfig(config: ITableConfig) {
    config.rows = this._addClassesToMeetingStateCells(config.rows);

    this.tableConfig = _.cloneDeep({ ...config });
  }

  private _addClassesToMeetingStateCells(rows: ITableRow[]): ITableRow[] {
    return rows.map((row) => {
      const targetCellIdx = row.data.findIndex((item) => item.key === MeetingRelatedFieldKey.State);
      if (targetCellIdx !== -1) {
        row.data[targetCellIdx].customClass =
          MEETING_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME[row.data[targetCellIdx].value as MeetingStateKey];
      }
      return row;
    });
  }

  setModuleConfig(config: IForumAndMeetingModulesConfig) {
    FORUM_AND_MEETING_CONFIG.config = config;
  }

  get meetingsListPageTableConfig(): ITableConfig {
    return {
      columns: this.tableCols?.length ? this.tableCols : this.defaultTableCols,
      rows: this.tableService.transformArrayToTableRows(this.meetings, MeetingRelatedFieldKey.MenuOptionsInList),
      rowsSettingsEnabled: true,
    };
  }

  get forumEditMeetingsListPageTableConfig(): ITableConfig {
    return {
      columns: this.tableCols?.length ? this.tableCols : this.defaultTableCols,
      rows: this.tableService.transformArrayToTableRows(this.meetings),
      rowsSelectEnabled: true,
    };
  }

  get currentUser(): IUser {
    return this.authService.currentUser;
  }

  get defaultTableCols(): ITableColumn[] {
    if (this.currentUser?.isStaff) {
      return this.forumAndMeetingConfig.isCurrentTypeCommon
        ? COMMON_MEETINGS_TABLE_STAFF_DEFAULT_COLUMNS
        : INDUSTRY_MEETINGS_TABLE_STAFF_DEFAULT_COLUMNS;
    }
    return this.forumAndMeetingConfig.isCurrentTypeCommon
      ? COMMON_MEETINGS_TABLE_MEMBER_DEFAULT_COLUMNS
      : INDUSTRY_MEETINGS_TABLE_MEMBER_DEFAULT_COLUMNS;
  }

  get isCurrentMeetingDraft(): boolean {
    return this.currentMeeting?.state === +MeetingStateKey.Draft;
  }

  get isCurrentMeetingPublished(): boolean {
    return this.currentMeeting?.state === +MeetingStateKey.Published;
  }

  get isCurrentModeCreate(): boolean {
    return this.currentCreateEditMode === PageMode.Create;
  }

  get isCurrentModeEdit(): boolean {
    return this.currentCreateEditMode === PageMode.Edit;
  }

  get defaultLimit() {
    return MEETINGS_LIST_DEFAULT_LIMIT;
  }
}
