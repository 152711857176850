import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ForumMembersHttpService } from '@core/services/api/forums/forum-members-http.service';
import {
  FORUM_MEMBERS_LIST_DEFAULT_LIMIT,
  FORUM_MEMBERS_PAGE_FILTERS_TILES,
  FORUM_MEMBERS_DEFAULT_FILTERS_TILES,
} from '@modules/forums/constants/common.const';
import { ForumRelatedFieldKey } from '@modules/forums/enums/keys.enum';
import { MEETING_MEMBERS_FILTERS_TILES_IGNORE_URL } from '@modules/meetings/constants/common.const';
import { MembersListDialogComponent } from '@shared/dialogs/members-list-dialog/members-list-dialog.component';
import { FilterType } from '@shared/enums/filter/filters-type.enum';
import { UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { ViewContent } from '@shared/enums/view-content.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { IForumMemberDto } from '@shared/models/forums/dto/forum-member-dto.model';
import { IForumMember } from '@shared/models/forums/view/forum-member.model';
import { IForum } from '@shared/models/forums/view/forum.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ISelectOption } from '@shared/models/select.model';
import { ITableCellClickEvent } from '@shared/models/table/table-config.model';
import { IUsersDialogConfig } from '@shared/models/user/members-dialog-config.model';
import { IUserWithProfile } from '@shared/models/user/view/user.model';
import * as _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { MembersUserService } from '../members/members-user.service';
import { DateService } from '../utils/date.service';
import { FilterService } from '../utils/filter.service';
import { ForumService } from './forum.service';

@Injectable({
  providedIn: 'root',
})
export class ForumMembersService {
  forumMembers: IForumMember[] = [];
  forumMembersCount: number;
  isListForumsMembersLoading = false;
  currentViewContent = ViewContent.Tile;

  constructor(
    private forumMembersHttpService: ForumMembersHttpService,
    private dateService: DateService,
    private membersUserService: MembersUserService,
    private forumService: ForumService,
    private filterService: FilterService,
    private dialog: MatDialog,
  ) {}

  getMembersCount(forumId: number): Observable<number> {
    return this.forumMembersHttpService.getForumMembers({ forum: forumId.toString(), limit: '1' }).pipe(
      map((response) => {
        return response.count;
      }),
    );
  }

  getMembersAndAddToTargetMembers(
    forumId: number,
    targetForumMembers?: IForumMember[],
    params?: KeyValue<string>,
    paramsString?: string,
    setForumsMembersCount = true,
  ): Observable<IForumMember[]> {
    const filters = { forum: forumId.toString(), state: '0', ...params };
    const filtersInString = `forum=${forumId}`;

    params = params ? { ...params, ...filters } : null;
    paramsString = paramsString ? `${paramsString}&${filtersInString}` : filtersInString;

    return this.forumMembersHttpService.getForumMembers(params, paramsString).pipe(
      map((response) => {
        if (setForumsMembersCount) {
          this.forumMembersCount = response.count;
        }

        if (!targetForumMembers) {
          targetForumMembers = [];
        }

        response.results.forEach((postDto) => {
          targetForumMembers.push(this._transformForumMembersDtoToForumMembersView(postDto));
        });

        return targetForumMembers;
      }),
    );
  }

  getMembersWithMembersAndForumProfilesAndAddToTargetMembers(
    forumId: number,
    targetForumMembers?: IForumMember[],
    params?: KeyValue<string>,
    paramsString?: string,
    setForumsMembersCount = true,
  ): Observable<IForumMember[]> {
    return this.getMembersAndAddToTargetMembers(
      forumId,
      targetForumMembers,
      params,
      paramsString,
      setForumsMembersCount,
    ).pipe(
      mergeMap((members) => {
        const requests: Array<Observable<IForum> | Observable<IUserWithProfile>> = [];

        if (this.forumService.currentForum?.id === forumId) {
          members.map((member) => {
            member.forumProfile = this.forumService.currentForum;
          });
        } else {
          requests.push(this._getForumRequestAndFillTargetMembers(forumId, members));
        }

        requests.push(...this._getProfilesRequestsAndFillTargetMembers(members));

        if (requests.length) {
          return forkJoin(requests).pipe(map(() => members));
        }
        return of(members);
      }),
    );
  }

  getMembersByUserId(userId: number): Observable<IForumMember[]> {
    return this.forumMembersHttpService.getForumMembers({ [ForumRelatedFieldKey.User]: userId.toString() }).pipe(
      map((response) => {
        return response.results.map((postDto) => this._transformForumMembersDtoToForumMembersView(postDto));
      }),
    );
  }

  getForumMemberById(id: number): Observable<IForumMember> {
    return this.forumMembersHttpService.getForumMemberById(id).pipe(
      map((dto) => {
        return this._transformForumMembersDtoToForumMembersView(dto);
      }),
    );
  }

  createForumMember(forumMember: Partial<IForumMemberDto>): Observable<IForumMember> {
    return this.forumMembersHttpService.createForumMember(forumMember).pipe(
      map((dto) => {
        return this._transformForumMembersDtoToForumMembersView(dto);
      }),
    );
  }

  patchForumMemberById(id: number, forumMember: Partial<IForumMemberDto> | FormData): Observable<IForumMember> {
    return this.forumMembersHttpService.patchForumMemberById(id, forumMember).pipe(
      map((dto) => {
        return this._transformForumMembersDtoToForumMembersView(dto);
      }),
    );
  }

  deleteForumMemberByIdAndRemoveFromTargetForumsMembers(
    id: number,
    targetForumsMembers?: IForumMember[],
  ): Observable<void> {
    const itemToDeleteIdx = targetForumsMembers?.findIndex((p) => p.id === id);
    if (targetForumsMembers && itemToDeleteIdx !== -1) {
      targetForumsMembers[itemToDeleteIdx].isDeleting = true;
    }

    return this.forumMembersHttpService.deleteForumMemberById(id).pipe(
      tap(
        () => {
          if (targetForumsMembers && itemToDeleteIdx !== -1) {
            targetForumsMembers.splice(itemToDeleteIdx, 1);
          }
        },
        () => {
          if (targetForumsMembers && itemToDeleteIdx !== -1) {
            targetForumsMembers[itemToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }

  private _getProfilesRequestsAndFillTargetMembers(members: IForumMember[]): Observable<IUserWithProfile>[] {
    return members.map((member) =>
      this.membersUserService.getUserById(member.user).pipe(
        tap((memberProfile) => {
          member.profile = memberProfile;
        }),
      ),
    );
  }

  private _getForumRequestAndFillTargetMembers(forumId: number, targetMembers?: IForumMember[]): Observable<IForum> {
    return this.forumService.getForumById(forumId).pipe(
      tap((memberForum) => {
        targetMembers?.forEach((member) => {
          member.forumProfile = memberForum;
        });
      }),
    );
  }

  private _transformForumMembersDtoToForumMembersView(dto: IForumMemberDto): IForumMember {
    let view: Partial<IForumMember> = {};

    view.start_participation = this.dateService.transfromStringToMoment(dto.start_participation);
    view.end_participation = this.dateService.transfromStringToMoment(dto.end_participation);

    view = Object.assign(dto, view);
    return view as IForumMember;
  }

  initMembersAndFilters(members: IForumMember[]) {
    this.initMembersAndDefaultFiltersState(members);
    this.initCurrentFilters();
  }

  initMembersAndDefaultFiltersState(members: IForumMember[]) {
    members.splice(0, members.length);
    this.filterService.defaultFilters = _.cloneDeep(FORUM_MEMBERS_DEFAULT_FILTERS_TILES);
  }

  initCurrentFilters() {
    this.filterService.filters = _.cloneDeep(this.filterService.defaultFilters);
  }

  initPageFilters() {
    const pageFilters = _.cloneDeep(FORUM_MEMBERS_PAGE_FILTERS_TILES);
    this.filterService.mergeFiltersArrays(pageFilters, this.filterService.filters);
  }

  setFilterFromTemplate(
    filterType: FilterType,
    option?: ISelectOption,
    filters?: IFilter | IFilter[],
    itemsCountTotal?: number,
    itemsCountCurrent?: number,
    offsetStep?: string,
  ): { needLoadItems: boolean } {
    const { needLoadItems } = this.filterService.setFilterFromTemplate(
      filterType,
      option,
      filters,
      itemsCountTotal,
      itemsCountCurrent,
      offsetStep,
    );

    if (needLoadItems) {
      switch (filterType) {
        case FilterType.DataFilters: {
          this.initPageFilters();
          this.initMembersAndDefaultFiltersState(this.forumMembers);
        }
      }
      this.filterService.setFiltersToUrlParameters(this.ignoreFilterUrlForCurrentView);
    }
    return { needLoadItems };
  }

  forumListTableCellClickHandler(event: ITableCellClickEvent) {
    if (event.headerKey === ForumRelatedFieldKey.MembersCount) {
      this.openMembersListDialog((event.data as IForum).id);
    }
  }

  openMembersListDialog(forumId: number) {
    const config: IUsersDialogConfig = {
      listItemsRequest: (filters: KeyValue<string>) =>
        this.getMembersWithMembersAndForumProfilesAndAddToTargetMembers(forumId, null, filters),
      memberProfileKeyInParentObject: 'profile',
      title: 'Участники форума',
      searchEnabled: true,
    };

    this.dialog.open(MembersListDialogComponent, {
      data: { config },
    });
  }

  get defaultLimit() {
    return FORUM_MEMBERS_LIST_DEFAULT_LIMIT;
  }

  get isCurrentViewTile(): boolean {
    return this.currentViewContent === ViewContent.Tile;
  }

  get ignoreFilterUrlForCurrentView(): UserRelatedFieldKey[] {
    return MEETING_MEMBERS_FILTERS_TILES_IGNORE_URL;
  }
}
