import { ElementRef, EventEmitter, Injectable, Renderer2 } from '@angular/core';
import { ElementPostionKey } from '@shared/enums/keys.enum';
import { DomService } from './dom.service';
import { ElementPositionService } from './element-position.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  readonly addAnimationFromInitState: Keyframe[] = [{ opacity: 0 }, { opacity: 1 }];
  readonly addAnimationFromCurrentState: Keyframe[] = [{ opacity: 1 }];
  readonly removeAnimationFromCurrentState: Keyframe[] = [{ opacity: 0 }];

  tooltipIds: string[] = [];

  constructor(private domService: DomService, private utilsService: UtilsService, private elementPositionService: ElementPositionService) {}

  removeTooltipsByIds(ids: string[], ignoreId?: string) {
    ids.forEach((id) => {
      if (ignoreId !== id) {
        this.removeTooltipById(id);
        this.tooltipIds = this.tooltipIds.filter((tooltipId) => tooltipId !== id);
      }
    });
  }

  removeTooltipById(id: string) {
    const tooltip = this.domService.getElementById(id);
    if (tooltip) {
      this.renderer.removeChild(this.domService.document, tooltip);
      this.tooltipIds = this.tooltipIds.filter((tooltipId) => tooltipId !== id);
    }
  }

  addTooltipToBody(
    targetElForPosition: ElementRef,
    tooltipText: string,
    containerId: string,
    animationDuration: number,
    clickable = true,
  ): Element {
    const container = this._getTooltipContainer(containerId, clickable);
    this._setContentToTooltipContainer(container, tooltipText);

    this.animateTooltipContainer(container, this.addAnimationFromInitState, animationDuration);
    this.renderer.appendChild(this.domService.body, container);
    this.elementPositionService.setElementPositionRelativeToParent(
      container,
      ElementPostionKey.BottomCenter,
      targetElForPosition.nativeElement,
    );

    return container;
  }

  private _getTooltipContainer(containerId: string, clickable = true): Element {
    const container: Element = this.renderer.createElement('div');

    this.tooltipIds.push(containerId);
    this.renderer.setProperty(container, 'id', containerId);
    this.renderer.setAttribute(container, 'class', 'tooltip-container');
    if (!clickable) {
      this.renderer.setAttribute(container, 'class', 'tooltip-container tooltip-container--cursor-default');
    }

    return container;
  }

  private _setContentToTooltipContainer(tooltipContainer: Element, tooltipText: string) {
    this.renderer.setProperty(tooltipContainer, 'innerHTML', this._getTooltipSanitizedHtml(tooltipText));
  }

  private _getTooltipSanitizedHtml(tooltipText: string): string {
    const tooltipHtml = `
      <div class="tooltip-container__content tooltip-container__content--arrow-top">
        ${tooltipText}
      </div>
    `;

    return this.domService.sanitizeHtml(tooltipHtml);
  }

  animateTooltipContainer(tooltipContainer: Element, keyframes: Keyframe[], duration: number) {
    tooltipContainer.animate(keyframes, {
      duration: duration + 1,
      delay: 0,
      easing: 'linear',
    });
  }

  clickEventEmitter(event: MouseEvent, clickEventEmitter: EventEmitter<void>) {
    event.stopPropagation();
    event.preventDefault();
    clickEventEmitter.emit();
  }

  generateTooltipId(): string {
    return this.utilsService.getUniqueId();
  }

  get renderer(): Renderer2 {
    return this.domService.renderer;
  }
}
