<div class="page-title" *ngIf="title; else withNoTitle">
  <!-- Иконка навигации к родительской странице -->
  <kp-svg
    *ngIf="navigationEnabled"
    [ngClass]="{ 'row-absolute-position': isRowAbsolutePosition }"
    key="arrowUpRightSlim"
    size="25px"
    rotateAngle="-135deg"
    (click)="navigateToPreviousPage()"
  ></kp-svg>

  <!-- Отображаемый текст -->
  <a *ngIf="link; else withNoLink" [routerLink]="link">
    <div class="title title--lg" [ngClass]="{ 'underline-hover': link }" [ngStyle]="{'font-size.px': fontSizeTitle}">{{ title }}</div>
  </a>

  <ng-template #withNoLink>
    <div class="title title--lg" [ngStyle]="{'font-size.px': fontSizeTitle}">{{ title }}</div>
  </ng-template>

  <!-- Количество итемов -->
  <p
    *ngIf="itemsCount || (showZero && itemsCount === 0)"
    [kpTooltip]="itemsCountTooltip"
    [tooltipCloseOnHostClick]="false"
    [ngStyle]="itemsStyle"
    [@inAnimation]
  >
    {{ itemsCount | number }}
  </p>
</div>

<ng-template #withNoTitle>
  <div class="page-title cursor-pointer" (click)="navigateToPreviousPage()">
    <kp-svg *ngIf="navigationEnabled" key="arrow" size="14px" rotateAngle="90deg" color="#5B2F87"></kp-svg>

    <div class="arrow-description" *ngIf="arrowDescription">
      {{ arrowDescription }}
    </div>
  </div>
</ng-template>
