import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyValue } from '@shared/models/key-value.model';
import { INewslinePostVoteAnswer } from '@shared/models/newsline/view/newsline-post-vote-answer.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostVoteAnswerHttpService {
  private readonly _apiPath = '/newsline/poll-answer/';

  constructor(private http: HttpService) {}

  getAnswers(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<INewslinePostVoteAnswer>> {
    return this.http.get<ArrayPayload<INewslinePostVoteAnswer>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getAnswerById(id: number): Observable<INewslinePostVoteAnswer> {
    return this.http.get<INewslinePostVoteAnswer>(`${this._apiPath}${id}/`);
  }

  createAnswer(answer: Partial<INewslinePostVoteAnswer>): Observable<INewslinePostVoteAnswer> {
    return this.http.post<INewslinePostVoteAnswer>(this._apiPath, answer);
  }

  updateAnswerById(id: number, answer: Partial<INewslinePostVoteAnswer>): Observable<INewslinePostVoteAnswer> {
    return this.http.patch<INewslinePostVoteAnswer>(`${this._apiPath}${id}/`, answer);
  }

  deleteAnswerById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }

  voteByAnswerId(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/vote/`, null);
  }
}
