import { FilterRule } from '@shared/enums/filter/filter-rule.enum';
import { ISelectOption } from '@shared/models/select.model';
import { FILTER_RULE_TO_VIEW_NAME } from './mappers.const';

// export const USER_MENU_OPTIONS: ISelectOption[] = [
//   {
//     viewName: UserMenuOptionViewName.Profile,
//     iconKey: 'userInRectangle',
//     iconColor: '#5B2F87',
//     textColor: '#171619',
//     textSize: '14px',
//     actionType: SelectOptionActionType.Action,
//   },
//   {
//     viewName: UserMenuOptionViewName.Settings,
//     iconKey: 'settings',
//     iconColor: '#5B2F87',
//     textColor: '#171619',
//     textSize: '14px',
//     actionType: SelectOptionActionType.Action,
//   },
//   {
//     viewName: UserMenuOptionViewName.ChangeAvatar,
//     iconKey: 'userInRectangle',
//     iconColor: '#5B2F87',
//     textColor: '#171619',
//     textSize: '14px',
//     actionType: SelectOptionActionType.Action,
//   },
//   {
//     viewName: UserMenuOptionViewName.Logout,
//     iconKey: 'logout',
//     iconColor: '#FF3B30',
//     textColor: '#FF3B30',
//     textSize: '14px',
//     iconRotateAngle: '180deg',
//     actionType: SelectOptionActionType.Action,
//   },
// ];

export const FILTER_RULE_OPTIONS: {
  equal: ISelectOption;
  gte: ISelectOption;
  lte: ISelectOption;
  after: ISelectOption;
  before: ISelectOption;
} = {
  equal: {
    viewName: FILTER_RULE_TO_VIEW_NAME[FilterRule.Equal],
    key: FilterRule.Equal,
  },
  gte: {
    viewName: FILTER_RULE_TO_VIEW_NAME[FilterRule.GTE],
    key: FilterRule.GTE,
  },
  lte: {
    viewName: FILTER_RULE_TO_VIEW_NAME[FilterRule.LTE],
    key: FilterRule.LTE,
  },
  after: {
    viewName: FILTER_RULE_TO_VIEW_NAME[FilterRule.After],
    key: FilterRule.After,
  },
  before: {
    viewName: FILTER_RULE_TO_VIEW_NAME[FilterRule.Before],
    key: FilterRule.Before,
  },
};
