<div class="menu__options-container">
  <ng-content select="[menuHeader]"></ng-content>

  <div
    *ngFor="let option of options; let first = first; let last = last"
    class="menu__option"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    [class.bottom-separator]="separateFirstElement && first"
    [class.top-separator]="separateLastElement && last"
    (click)="optionClicked.emit(option)"
  >
    <kp-svg
      *ngIf="option.iconKey"
      [key]="option.iconKey"
      [rotateAngle]="option.iconRotateAngle"
      [color]="option.color || defaultIconColor"
      height="18px"
      width="18px"
    ></kp-svg>

    <div
      fxFlex
      class="menu__option-text"
      [style.color]="option.color || defaultTextColor"
    >
      {{ option.viewName }}
    </div>
  </div>

  <ng-content select="[menuFooter]"></ng-content>
</div>
