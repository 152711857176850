import { Pipe, PipeTransform } from '@angular/core';
import {
  IChatParticipantsShortInfo,
  IChatParticipantUserData,
} from '@shared/models/messages/dto/chat-participant-dto.model';

@Pipe({
  name: 'fullUserById',
})
export class FullUserByIdPipe implements PipeTransform {
  constructor() {}

  transform(userId: number | number[], usersMapById: IChatParticipantsShortInfo): Partial<IChatParticipantUserData>[] {
    if (typeof userId === 'number') {
      return [usersMapById[userId]];
    } else if (Array.isArray(userId)) {
      return userId.map((id: number) => usersMapById[id]);
    }
    return;
  }
}
