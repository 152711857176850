import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyValue } from '@shared/models/key-value.model';
import { INewslinePostCommentDto } from '@shared/models/newsline/dto/newsline-post-comment-dto.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostCommentHttpService {
  private readonly _apiPath = '/newsline/comment/';

  constructor(private http: HttpService) {}

  getComments(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<INewslinePostCommentDto>> {
    return this.http.get<ArrayPayload<INewslinePostCommentDto>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getCommentById(id: number): Observable<INewslinePostCommentDto> {
    return this.http.get<INewslinePostCommentDto>(`${this._apiPath}${id}/`);
  }

  createComment(comment: Partial<INewslinePostCommentDto>): Observable<INewslinePostCommentDto> {
    return this.http.post<INewslinePostCommentDto>(this._apiPath, comment);
  }

  updateCommentById(id: number, comment: Partial<INewslinePostCommentDto>): Observable<INewslinePostCommentDto> {
    return this.http.patch<INewslinePostCommentDto>(`${this._apiPath}${id}/`, comment);
  }

  deleteCommentById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }

  likeCommentById(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/like/`, null);
  }

  unlikeCommentById(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/unlike/`, null);
  }
}
