import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformWordFileSize',
})
export class TransformWordFileSizePipe implements PipeTransform {
  transform(fileSizeInBytes: number) {
    let i = -1;
    const byteUnits = [' Kb', ' Mb', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes /= 1024;
      i += 1;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }
}
