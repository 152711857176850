<div class="forward-popup-container" fxLayout="column">
  <kp-popup-header>
    Переслать
    <span class="forward-popup-selected-count">
      {{ selectedChatsCount$ | async }}/{{ selectedChatsLimit }}
    </span>
  </kp-popup-header>

  <div
    class="forward-popup-content"
    fxLayout="column"
    fxLayoutGap="16px"
    fxFlex
  >
    <ng-container [ngTemplateOutlet]="searchBar"></ng-container>

    <div
      *ngIf="(chats$ | async).length; else loadingOrEmpty"
      fxLayout="column"
      fxLayoutGap="16px"
      fxFlex="calc(100% - 42px - 52px - 32px)"
      class="forward-popup-chat-list"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      [@inAnimation]=""
      (scrolled)="getNewChatsPortion()"
    >
      <div
        *ngIf="!(isOfterChatsVisible$ | async)"
        fxLayout="column"
      >
        <div class="forward-popup-chat-list-title">
          Частые чаты
        </div>

        <ng-container
          *ngFor="let chat of oftenContactChats$ | async"
          [ngTemplateOutlet]="chatItem"
          [ngTemplateOutletContext]="{chat: chat}"
        ></ng-container>
      </div>

      <div fxLayout="column">
        <div
          *ngIf="!(isOfterChatsVisible$ | async)"
          class="forward-popup-chat-list-title"
        >
          Недавние
        </div>

        <ng-container
          *ngFor="let chat of chats$ | async"
          [ngTemplateOutlet]="chatItem"
          [ngTemplateOutletContext]="{chat: chat}"
        ></ng-container>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="12px">
      <button
        class="kp-stroked-button-m"
        fxFlex="120px"
        mat-dialog-close=""
        [disabled]="isForwardInProcess$ | async"
      >
        Отмена
      </button>

      <button
        class="kp-primary-button-m"
        (click)="forwardMessagesToSelectedChats()"
        [disabled]="!(selectedChatsCount$ | async) || (isForwardInProcess$ | async)"
        fxFlex
      >
        {{ (isForwardInProcess$ | async) ? 'Пересылаем сообщения...' : 'Переслать' }}
      </button>
    </div>
  </div>
</div>

<ng-template #loadingOrEmpty>
  <div
    *ngIf="searchControl.value; else loader"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex
  >
    <kp-no-data></kp-no-data>
  </div>

  <ng-template #loader>
    <kp-loader></kp-loader>
  </ng-template>
</ng-template>

<ng-template #searchBar>
  <div class="kp-form-control-l">
    <kp-svg
      color="#6A728A"
      size="18px"
      [key]="searchControl.value?.trim() ? 'xCircle' : 'search'"
      [cursor]="searchControl.value?.trim() ? 'pointer' : 'default'"
      (click)="searchControl.value?.trim() ? searchControl.setValue('') : null"
    ></kp-svg>

    <input
      placeholder="Введите название чата или фамилию..."
      type="text"
      [formControl]="searchControl"/>
  </div>
</ng-template>

<ng-template #chatItem let-chat="chat">
  <div
    *ngIf="chat"
    class="forward-popup-chat-list-item"
    [class.forward-popup-chat-list-item-disabled]="isSelectedChatsLimitAchieved(chat.id) | async"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    (click)="toggleChatSelection(chat.id)"
  >
    <mat-checkbox
      class="kp-checkbox-without-ripple"
      color="primary"
      [checked]="isChatSelected(chat.id) | async"
      [disabled]="isSelectedChatsLimitAchieved(chat.id) | async"
      (change)="toggleChatSelection(chat.id)"
      (click)="$event.stopPropagation()"
    ></mat-checkbox>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="12px" fxFlex="calc(100% - 8px - 20px"
    >
      <kp-user-avatar [avatarSize]="36" [avatarUrl]="chat.avatar" [username]="chat.name"></kp-user-avatar>

      <div
        fxFlex="calc(100% -12px - 36px"
        class="forward-popup-chat-list-item-name overflow-ellipsis"
      >
        {{ chat.name || defaultChatName }}
      </div>
    </div>
  </div>
</ng-template>
