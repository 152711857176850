import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
  selector: '[kpConfirmAction]',
})
export class ConfirmActionDirective {
  /**
   * Текст в диалоговом окне подтверждения
   */
  @Input('kpConfirmAction') conrifmText = '';

  /**
   * Включено ли подтверждение клика. В выключенном состоянии выполняет действия без подтверждения
   */
  @Input() confirmationDisabled = false;

  /**
   * Путь для навигации при подтверждении клика
   */
  @Input() confirmedNavigation: string;

  /**
   * Путь для навигации (относительно текущего роута) при подтверждении клика
   */
  @Input() confirmedRelativeNavigation: string;

  /**
   * Событие подтверждения клика
   */
  @Output() confirmedClick = new EventEmitter();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  @HostListener('click', ['$event'])
  private _onMouseClick(event: MouseEvent) {
    if (this.confirmationDisabled) {
      this._actionsConfirmed();
    } else {
      // eslint-disable-next-line no-restricted-globals
      // eslint-disable-next-line no-alert
      // eslint-disable-next-line no-restricted-globals
      const confirmAction = confirm(this.conrifmText);
      if (confirmAction) {
        this._actionsConfirmed();
      } else {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
    }
  }

  private _actionsConfirmed() {
    this.confirmedClick.emit();

    if (this.confirmedNavigation) {
      this.router.navigate([this.confirmedNavigation]);
    } else if (this.confirmedRelativeNavigation) {
      this.router.navigate([this.confirmedRelativeNavigation], { relativeTo: this.activatedRoute });
    }
  }
}
