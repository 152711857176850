import { Injectable } from '@angular/core';
import { KeyValue } from '@shared/models/key-value.model';
import { IChatDto } from '@shared/models/messages/dto/chat-dto.model';
import { IChatParticipant, IChatParticipantShortDto } from '@shared/models/messages/dto/chat-participant-dto.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { QueryParams } from '@shared/models/query-params.model';
import { prepareQuery } from '@shared/utils/utils';
import { IChatInviteLink } from '@shared/models/messages/view/chat.model';

@Injectable({
  providedIn: 'root',
})
export class ChatHttpService {
  private readonly _apiPath = '/chat/chat/';

  constructor(private http: HttpService) {}

  getList(params?: QueryParams): Observable<ArrayPayload<IChatDto>> {
    return this.http.get<ArrayPayload<IChatDto>>(this._apiPath, { params: prepareQuery(params) });
  }

  getById(id: number): Observable<IChatDto> {
    return this.http.get<IChatDto>(`${this._apiPath}${id}/`);
  }

  create(chat: Partial<IChatDto>): Observable<IChatDto> {
    return this.http.post<IChatDto>(this._apiPath, chat);
  }

  updateById(id: number, chat: Partial<IChatDto> | FormData): Observable<IChatDto> {
    return this.http.put<IChatDto>(`${this._apiPath}${id}/`, chat);
  }

  patchById(id: number, chat: Partial<IChatDto> | FormData, params?: any): Observable<IChatDto> {
    return this.http.patch<IChatDto>(`${this._apiPath}${id}/`, chat, params);
  }

  deleteById(id: number, params?: KeyValue<any>): Observable<void> {
    return this.http.post<void>(`${this._apiPath}delete-chats/`, params);
  }

  //Эндпоинт на чтение всех непрочитанных сообщений
  readAllMessagesInChatById(id: number): Observable<void> {
    let data = {
      chats: [id],
    };

    return this.http.post<void>(`${this._apiPath}read-chats/`, data);
  }

  markChatAsUnread(chatId: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}mark-chats-as-unread/`, prepareQuery({ chats: [chatId] }));
  }

  clearHistory(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/clear-history/`, null);
  }

  addParticipants(id: number, data: { participants: IChatParticipantShortDto[] }): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/add-participants/`, data);
  }

  // Удалить участника из чата
  deleteParticipant(id: number, data: { participants: IChatParticipantShortDto[] }): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/remove-participants/`, data);
  }

  //Переслать сообщения в чат
  postForwardMessagesInChat(messagesIds: number[], chatId: number, chatIds: number[]): Observable<void> {
    const fw_data = {
      recipients: chatIds,
      recipient_users: [] as any[],
      messages: messagesIds,
    };

    return this.http.post(`${this._apiPath}${chatId}/forward-messages/`, fw_data);
  }

  // Выход из чата
  deleteChats(chats: number[], for_all = false): Observable<void> {
    return this.http.post<void>(`${this._apiPath}delete-chats/`, {
      chats,
      for_all,
    });
  }

  // Отправка события, что пользователю доставлено сообщение
  messageDeliveredToRecipient(chatIds: number[]): Observable<void> {
    const data = { chats: chatIds };

    return this.http.post<void>(`${this._apiPath}confirm-message-delivery/`, data);
  }

  chatParticipantActivateAdmin(chatId: number, userId: number) {
    return this.http.post<void>(`${this._apiPath}${chatId}/activate-admin-status/`, { user: userId });
  }

  chatParticipantDeactivateAdmin(chatId: number, userId: number) {
    return this.http.post<void>(`${this._apiPath}${chatId}/deactivate-admin-status/`, { user: userId });
  }

  // Получение участников чата
  getChatParticipantsById(params?: QueryParams): Observable<ArrayPayload<IChatParticipant>> {
    return this.http.get<ArrayPayload<IChatParticipant>>('/chat/participant/', { params: prepareQuery(params) });
  }

  // Запрос на получение динамической ссылки
  getChatInviteDynamicLink(chatId: number): Observable<IChatInviteLink> {
    return this.http.get<IChatInviteLink>(`${this._apiPath}${chatId}/dynamic-link/`);
  }
}
