<div class="container">
  <div class="title">Рекомендовать участника</div>

  <!-- Name -->
  <div class="form-control" [ngClass]="{ 'form-control--invalid': isControlInvalid(null) }">
    <div class="form-control__input">
      <input type="text" placeholder="Имя" />
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(null)">{{ getValidatorErrorMessage(null) }}</div>
  </div>

  <!-- Surname -->
  <div class="form-control" [ngClass]="{ 'form-control--invalid': isControlInvalid(null) }">
    <div class="form-control__input">
      <input type="text" placeholder="Фамилия" />
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(null)">{{ getValidatorErrorMessage(null) }}</div>
  </div>

  <!-- Email -->
  <div class="form-control" [ngClass]="{ 'form-control--invalid': isControlInvalid(null) }">
    <div class="form-control__input">
      <input type="text" placeholder="Email" />
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(null)">{{ getValidatorErrorMessage(null) }}</div>
  </div>

  <!-- Phone number -->
  <div class="form-control" [ngClass]="{ 'form-control--invalid': isControlInvalid(null) }">
    <div class="form-control__input">
      <input type="text" placeholder="Телефон" />
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(null)">{{ getValidatorErrorMessage(null) }}</div>
  </div>

  <!-- Company -->
  <div class="form-control" [ngClass]="{ 'form-control--invalid': isControlInvalid(null) }">
    <div class="form-control__input">
      <input type="text" placeholder="Компания" />
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(null)">{{ getValidatorErrorMessage(null) }}</div>
  </div>

  <!-- Position -->
  <div class="form-control" [ngClass]="{ 'form-control--invalid': isControlInvalid(null) }">
    <div class="form-control__input">
      <input type="text" placeholder="Бизнес-роль" />
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(null)">{{ getValidatorErrorMessage(null) }}</div>
  </div>

  <!-- Recommendation -->
  <div class="form-control" [ngClass]="{ 'form-control--invalid': isControlInvalid(null) }">
    <div class="form-control__input form-control__textarea">
      <textarea type="text" placeholder="Почему рекомендуете?"></textarea>
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(null)">{{ getValidatorErrorMessage(null) }}</div>
  </div>

  <div class="buttons-container buttons-container--self-align-end">
    <button type="button" class="button button--white" (click)="closeDialog()">Отмена</button>
    <button type="button" class="button button" (click)="closeDialog(true)">Добавить</button>
  </div>
</div>
