<div class="emoji-box-trigger" (click)="toggleEmojiBoxVisibility()">
  <kp-svg class="arrow" key="arrow" size="10px"></kp-svg>
</div>

<div class="emoji-box-container">
  <emoji-mart
    [style.visibility]="(isVisible$ | async) ? 'visible' : 'hidden'"
    [darkMode]="false"
    [i18n]="emojiLocale"
    [enableFrequentEmojiSort]="true"
    [showPreview]="false"
    [enableSearch]="false"
    [isNative]="false"
    [emojiSize]="25"
    [perLine]="7"
    [skin]="1"
    [style]="emojiPopupStyle"
    (emojiClick)="emojiClick($event.emoji)"
    [set]="'apple'"
    [emojiTooltip]="false"
  ></emoji-mart>
</div>
