import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValue } from '@shared/models/key-value.model';

@Component({
  selector: 'kp-icon-text-dialog',
  templateUrl: './icon-text-dialog.component.html',
  styleUrls: ['./icon-text-dialog.component.scss'],
})
export class IconTextDialogComponent {
  iconKey = 'checkCircle';
  iconSize = '29px';
  text: string;
  // @Input() textButton?:string =
  textButton: string;
  icon = true;

  constructor(
    private dialogRef: MatDialogRef<IconTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: KeyValue<string>,
  ) {
    this.iconKey = data.iconKey ? data.iconKey : this.iconKey;
    this.iconSize = data.iconSize ? data.iconSize : this.iconSize;
    if (data.noIcon) {
      this.icon = false;
    }

    this.text = data.text;

    if (!data.textButton) {
      this.textButton = 'Закрыть';
    } else {
      this.textButton = data.textButton;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
