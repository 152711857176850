import { Injectable } from '@angular/core';
import { NewslinePostCommentImageHttpService } from '@core/services/api/newsline/newsline-post-comment-image-http.service';
import { INewslinePostCommentImageDto } from '@shared/models/newsline/dto/newsline-post-comment-image-dto.model';
import { INewslinePostCommentImage } from '@shared/models/newsline/view/newsline-post-comment-image.model';
import { INewslinePostComment } from '@shared/models/newsline/view/newsline-post-comment.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostCommentImageService {
  constructor(private newslinePostCommentImageHttpService: NewslinePostCommentImageHttpService) {}

  getImagesByCommentIdAndAddToComment(commentId: number, comment?: INewslinePostComment): Observable<INewslinePostCommentImage[]> {
    if (comment) {
      comment.isLoadingImages = true;
    }

    return this.newslinePostCommentImageHttpService.getImages({ comment: commentId.toString() }).pipe(
      map((response) => {
        if (comment) {
          comment.images = response.results;
          comment.isLoadingImages = false;
        }

        return response.results;
      }),
    );
  }

  getImageById(id: number): Observable<INewslinePostCommentImage> {
    return this.newslinePostCommentImageHttpService.getImageById(id);
  }

  createImageAndAddToComment(
    imageToCreate: INewslinePostCommentImageDto,
    comment?: INewslinePostComment,
  ): Observable<INewslinePostCommentImage> {
    return this.newslinePostCommentImageHttpService.createImage(imageToCreate).pipe(
      tap((createdImage) => {
        if (comment) {
          if (!comment.images?.length) {
            comment.images = [];
          }
          comment.images.push(createdImage);
        }
      }),
    );
  }

  updateImageByIdAndReplaceInComment(
    imageId: number,
    imageDto: INewslinePostCommentImageDto,
    comment?: INewslinePostComment,
  ): Observable<INewslinePostCommentImage> {
    return this.newslinePostCommentImageHttpService.updateImageById(imageId, imageDto).pipe(
      tap((updatedImage) => {
        if (comment?.images) {
          const targetImageIdx = comment.images.findIndex((image) => image.id === updatedImage.id);
          if (targetImageIdx !== -1) {
            comment.images[targetImageIdx] = updatedImage;
          }
        }
      }),
    );
  }

  deleteImageByIdAndRemoveFromComment(imageId: number, comment?: INewslinePostComment): Observable<void> {
    const imageToDeleteIdx = comment?.images?.findIndex((p) => p.id === imageId);
    if (imageToDeleteIdx !== -1) {
      comment.images[imageToDeleteIdx].isDeleting = true;
    }

    return this.newslinePostCommentImageHttpService.deleteImageById(imageId).pipe(
      tap(
        () => {
          if (comment?.images) {
            comment.images = comment.images.filter((image) => image.id !== imageId);
          }
        },
        () => {
          if (imageToDeleteIdx !== -1) {
            comment.images[imageToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }
}
