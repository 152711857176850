export enum TaskContentType {
  User = 'user',
  Forum = 'forum',
  Meeting = 'meeting',
  Post = 'post',
}

export enum TasksStateKey {
  New = '0',
  Done = '1',
  Cancelled = '2',
  Overdue = '3',
}

export enum TypeOfTaskKey {
  Users = '1',
  Post = '2',
  Forum = '3',
  Event = '4',
  Meeting = '5',
  Project = '6',
  Payment = '7',
}
