<div class="paginator user-select--disabled" *ngIf="pagesCount > 1">
  <div class="paginator__pages-numbers">
    <div
      class="paginator__nav-btn"
      [ngClass]="{ 'paginator__nav-btn--disabled': currentPageNumber === 1 }"
      (click)="changePageNumber(currentPageNumber - 1)"
    >
      <kp-svg key="arrow" height="10px" width="10px" cursor="inherit"></kp-svg>
      <!-- Пред. -->
    </div>

    <div
      *ngFor="let pageNumber of pageNavNumbers"
      (click)="changePageNumber(pageNumber)"
      class="paginator__page-number"
      [ngClass]="{
        'paginator__page-number--active': pageNumber === currentPageNumber
      }"
    >
      {{ pageNumber }}
    </div>

    <div
      class="paginator__nav-btn"
      [ngClass]="{ 'paginator__nav-btn--disabled': currentPageNumber === pagesCount }"
      style="margin-top: 6px"
      (click)="changePageNumber(currentPageNumber + 1)"
    >
      <!-- След. -->
      <kp-svg key="arrow" height="10px" width="10px" cursor="inherit"></kp-svg>
    </div>
  </div>
</div>
