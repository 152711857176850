import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyValue } from '@shared/models/key-value.model';
import { IMeetingDto } from '@shared/models/meetings/dto/meeting-dto.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class MeetingHttpService {
  private readonly _apiPath = '/meetings/meeting/';

  constructor(private http: HttpService) {}

  getMeetings(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IMeetingDto>> {
    return this.http.get<ArrayPayload<IMeetingDto>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getMeetingById(id: number): Observable<IMeetingDto> {
    return this.http.get<IMeetingDto>(`${this._apiPath}${id}/`);
  }

  createMeeting(meeting: Partial<IMeetingDto>): Observable<IMeetingDto> {
    return this.http.post<IMeetingDto>(this._apiPath, meeting);
  }

  updateMeetingById(id: number, meeting: Partial<IMeetingDto> | FormData): Observable<IMeetingDto> {
    return this.http.put<IMeetingDto>(`${this._apiPath}${id}/`, meeting);
  }

  patchMeetingById(id: number, meeting: Partial<IMeetingDto> | FormData): Observable<IMeetingDto> {
    return this.http.patch<IMeetingDto>(`${this._apiPath}${id}/`, meeting);
  }

  deleteMeetingById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }
}
