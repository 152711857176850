import { DatePeriodKey } from '@shared/enums/keys.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { INewslineConfig } from '@shared/models/newsline/view/newsline-config.model';
import { PostFilterField, PostTypeKey } from '../enums/keys.enum';

export const NEWSLINE_CONFIG: INewslineConfig = {
  scrollEventsTargetClassName: null,

  pagePaddingsEnabled: true,
  showNewslineTitle: true,
  showPostsPeriodFilter: true,
  showAfishaPostTypeFilter: true,
  showAdvertisingBanner: true,
  showUpcomingEvents: true,
  showNoDataIfNoPosts: true,
  setFiltersInUrl: true,
  isForumHaveFirstMeetVote: false,

  showAfishaPostTypeInput: true,
  showTargetObjectInput: true,

  postTypeKey: PostTypeKey.Afisha,
};

export const POST_FORUM_MEETING_FIRST_MEETING_ANY_DATE_VOTE_TEXT = 'Выберите удобную дату и время первой встречи';
export const POST_FORUM_MEETING_FIRST_MEETING_ANY_DATE_ANSWER_TEXT = 'Любая дата и время';

/**
 * В игнорируемые фильтры при добавлении в url добавлены `PostFilterField.Created` из-за различий
 * между фильтрами отправляемыми в запросе (created_after/created_before)
 * и фильтрами записываемыми в адресную строку (created_period_key), в которую не должны попасть фильтры (created__after/created__before)
 */
export const POSTS_FILTERS_IGNORE_URL: PostFilterField[] = [PostFilterField.Limit, PostFilterField.Offset, PostFilterField.Pinned];
export const POSTS_LIST_DEFAULT_OFFSET = '0';
export const POSTS_LIST_DEFAULT_LIMIT = '8';

export const POSTS_PAGE_FILTERS = [
  {
    field: PostFilterField.Offset,
    value: POSTS_LIST_DEFAULT_OFFSET,
  },
  {
    field: PostFilterField.Limit,
    value: POSTS_LIST_DEFAULT_LIMIT,
  },
];

export const POSTS_DEFAULT_FILTERS: IFilter[] = [
  ...POSTS_PAGE_FILTERS,
  {
    field: PostFilterField.CreatedPeriodKey,
    value: DatePeriodKey.AllTime,
  },
  {
    field: PostFilterField.Pinned,
    value: 'false',
  },
];
