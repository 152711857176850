import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IdName } from '@shared/models/id-name.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class MeetingAddressHttpService {
  private readonly _apiPath = '/meetings/meeting-address/';

  constructor(private http: HttpService) {}

  getАddresses(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IdName>> {
    return this.http.get<ArrayPayload<IdName>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getАddressById(id: number): Observable<IdName> {
    return this.http.get<IdName>(`${this._apiPath}${id}/`);
  }

  createАddress(address: Partial<IdName>): Observable<IdName> {
    return this.http.post<IdName>(this._apiPath, address);
  }

  updateАddresshById(id: number, address: Partial<IdName>): Observable<IdName> {
    return this.http.put<IdName>(`${this._apiPath}${id}/`, address);
  }

  patchАddressById(id: number, address: Partial<IdName>): Observable<IdName> {
    return this.http.patch<IdName>(`${this._apiPath}${id}/`, address);
  }

  deleteАddressById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }
}
