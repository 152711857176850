<div class="notify-toast">
  <div class="notify-toast__ico" *ngIf="data.icon">
    <kp-svg [key]="data.icon" class="xIcon" size="24px" color="#ffffff"></kp-svg>
  </div>
  <div class="notify-toast__content">
    <div *ngIf="data.title" class="notify-toast__title">{{ data.title }}</div>
    <div *ngIf="data.description" class="notify-toast__description">{{ data.description }}</div>
  </div>

  <button class="notify-toast__close" type="button" (click)="snackRef.dismiss()">
    <kp-svg key="xIcon" class="xIcon" size="20px" color="#ffffff"></kp-svg>
  </button>
</div>
