<div class="emoji-box-trigger" [kpSwitchElement]="emojiContainerEl">
  <kp-svg key="friendlyEmojiIcon" size="20px" color="white"></kp-svg>
</div>

<div style="z-index: 1000" #emojiContainerTemplate>
  <emoji-mart
    [darkMode]="false"
    [i18n]="emojiLocale"
    [enableFrequentEmojiSort]="true"
    [showPreview]="false"
    [enableSearch]="false"
    [isNative]="false"
    [emojiSize]="25"
    [skin]="1"
    [style]="emojiPopupStyle"
    (emojiClick)="emojiClick($event.emoji)"
    [set]="'apple'"
    [emojiTooltip]="true"
  ></emoji-mart>
</div>
