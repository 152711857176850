import { EventEmitter } from '@angular/core';
import { Component, Input, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { KeyValue } from '@shared/models/key-value.model';

@Component({
  selector: 'kp-radio-button',
  templateUrl: './radio-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent {
  @Input() column = false;
  @Input() data: Array<any>;
  @Input() keyToView: string;
  @Input() keyToSelect: string;
  @Input() disabled = false;
  @Input() selectedItem: any;
  @Input() control: AbstractControl;
  @Input() itemsGapLg: boolean;
  @Output() change = new EventEmitter<any>();

  set selectedValue(value) {
    if (this.control) {
      this.control.setValue(value, { emitEvent: false });
      this.control.markAsDirty();
    }
    this.selectedItem = value;

    this.change.emit(value);
  }

  get selectedValue(): any {
    if (this.control) {
      return this.control.value;
    }
    if (this.selectedItem) {
      return this.keyToSelect ? this.selectedItem[this.keyToSelect] : this.selectedItem;
    }
  }
}
