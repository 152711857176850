import { Component, Input, OnChanges, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { IFile, IFileDto } from '@shared/models/file.model';
import { INewslinePostCommentImageDto } from '@shared/models/newsline/dto/newsline-post-comment-image-dto.model';
import { INewslinePostImageDto } from '@shared/models/newsline/dto/newsline-post-image-dto.model';
import { INewslinePostCommentImage } from '@shared/models/newsline/view/newsline-post-comment-image.model';
import { INewslinePostImage } from '@shared/models/newsline/view/newsline-post-image.model';

@Component({
  selector: 'kp-file-inline-preview',
  templateUrl: './file-inline-preview.component.html',
  styleUrls: ['./file-inline-preview.component.scss'],
})
export class FileInlinePreviewComponent implements OnChanges {
  @Input() file: IFileDto | IFile;
  @Input() image: INewslinePostImageDto | INewslinePostCommentImageDto | INewslinePostImage | INewslinePostCommentImage;
  @Output() deleteFilePostEvent = new EventEmitter();

  fileLoadProgress = 0;
  fileLoadTotal = 100;

  ngOnChanges(changes: SimpleChanges) {
    this.image = changes.image?.currentValue;
    if (this.image && this.imageDto.isNewFile) {
      this._previewImage(this.imageDto.image);
    }

    this.file = changes.file?.currentValue;
    if (this.file && this.fileDto.isNewFile) {
      this._loadFile(this.fileDto.file);
    }
  }

  private _previewImage(imageFile: File) {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = (event) => {
      (this.image as INewslinePostImageDto | INewslinePostCommentImageDto).src = event.target.result;
    };

    reader.onprogress = (event) => {
      this.fileLoadProgress = event.loaded;
      this.fileLoadTotal = event.total;
    };
  }

  private _loadFile(file: File) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onprogress = (event) => {
      this.fileLoadProgress = event.loaded;
      this.fileLoadTotal = event.total;
    };
  }

  deleteFileFromPost() {
    this.deleteFilePostEvent.emit();
  }

  get imageDto(): INewslinePostImageDto | INewslinePostCommentImageDto {
    return this.image as INewslinePostImageDto | INewslinePostCommentImageDto;
  }

  get fileDto(): IFileDto {
    return this.file as IFileDto;
  }

  get imageView(): INewslinePostImage | INewslinePostCommentImage {
    return this.image as INewslinePostImage | INewslinePostCommentImage;
  }

  get fileView(): IFile {
    return this.file as IFile;
  }
}
