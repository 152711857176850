import { KeyValue } from '@shared/models/key-value.model';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

const CACHE_CONTROL_HEADERS: KeyValue<string> = {
  'Cache-Control': 'no-store, no-cache, must-revalidate',
  Pragma: 'no-cache',
  Expires: '0',
};

export class CacheControlInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: {
        ...CACHE_CONTROL_HEADERS,
      },
    });
    return next.handle(request);
  }
}
