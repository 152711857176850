import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MembersUserHttpService } from '@core/services/api/members/members-user-http.service';
import { UserRelatedFieldKey, UserTypeKey } from '@shared/enums/keys.enum';
import { IMemberGratsDto } from '@shared/interfaces/members/members-grats.interface';
import { IMemberHobbyDto } from '@shared/interfaces/members/members-hobby.interface';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { IUserWithProfileDto } from '@shared/models/user/dto/user-dto.model';
import { IUserWithProfile } from '@shared/models/user/view/user.model';
import { transformWordByCount } from '@shared/utils/utils';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class MembersUserService {
  users$$ = new BehaviorSubject<IUserWithProfile[]>([]);
  usersCount: number;
  currentMember: IUserWithProfile;
  isListUsersLoading = false;
  isUsersActivating = false;

  userGratitudes: IMemberGratsDto[] = [];

  constructor(
    private authService: AuthService,
    private membersUserHttpService: MembersUserHttpService,
    private utilsService: UtilsService,
    private snackBar: MatSnackBar,
  ) {}

  getUsersAndAddToUsers(
    targetUsers$$?: BehaviorSubject<IUserWithProfile[]>,
    params: KeyValue<string> = null,
    paramsString: string = '',
    updateCountsAndFlags = true,
    setDefaultAvatar: boolean = true,
  ): Observable<ArrayPayload<IUserWithProfile>> {
    if (updateCountsAndFlags) {
      this.isListUsersLoading = true;
    }

    return this.membersUserHttpService.getUsers(params, paramsString).pipe(
      map((response) => {
        if (updateCountsAndFlags) {
          this.usersCount = response.count;
        }

        const currentUsers = targetUsers$$?.value ? targetUsers$$.value : [];
        const usersDtos = response.results;
        const users: IUserWithProfile[] = [];
        usersDtos?.forEach((userDto) => {
          users.push(this.authService.transformUserWithProfileDtoToUserWithProfileView(userDto, setDefaultAvatar));
        });

        if (targetUsers$$) {
          targetUsers$$.next([...currentUsers, ...users]);
        }

        if (updateCountsAndFlags) {
          this.isListUsersLoading = false;
        }

        return { results: users, count: response.count };
      }),
    );
  }

  getUsers(params?: KeyValue<string>): Observable<ArrayPayload<IUserWithProfileDto>> {
    return this.membersUserHttpService.getUsers(params);
  }

  getStaffUsers(
    targetUsers$$?: BehaviorSubject<IUserWithProfile[]>,
    params?: KeyValue<string>,
    paramsString?: string,
  ): Observable<ArrayPayload<IUserWithProfile>> {
    const staffFilter = { [UserRelatedFieldKey.UserType]: UserTypeKey.Staff };
    const staffFilterInString = `${UserRelatedFieldKey.UserType}=${UserTypeKey.Staff}`;

    params = params ? { ...params, ...staffFilter } : null;
    paramsString = paramsString ? `${paramsString}&${staffFilterInString}` : staffFilterInString;

    return this.getUsersAndAddToUsers(targetUsers$$, params, paramsString);
  }

  getUserById(id: number): Observable<IUserWithProfile> {
    return this.membersUserHttpService.getUserById(id).pipe(
      map((userDto) => {
        return this.authService.transformUserWithProfileDtoToUserWithProfileView(userDto, false);
      }),
    );
  }

  createUserAndAddToUsers(
    userToCreate: Partial<IUserWithProfileDto>,
    targetUsers$$?: BehaviorSubject<IUserWithProfile[]>,
    formToReset?: FormGroup,
  ): Observable<IUserWithProfile> {
    return this.membersUserHttpService.createUser(userToCreate).pipe(
      map((userDto) => {
        const createdUser = this.authService.transformUserWithProfileDtoToUserWithProfileView(userDto);

        if (targetUsers$$) {
          targetUsers$$.next([createdUser, ...targetUsers$$.value]);
        }
        formToReset?.reset();

        return createdUser;
      }),
    );
  }

  patchUserByIdAndReplaceInUsers(
    userId: number,
    userDto: Partial<IUserWithProfileDto> | FormData,
    targetUsers$$?: BehaviorSubject<IUserWithProfile[]>,
    formToReset?: FormGroup,
  ): Observable<IUserWithProfile> {
    return this.membersUserHttpService.patchUserById(userId, userDto).pipe(
      map((updatedUserDto) => {
        const updatedUser = this.authService.transformUserWithProfileDtoToUserWithProfileView(updatedUserDto);

        if (targetUsers$$ && targetUsers$$.value?.length) {
          const targetUserIdx = targetUsers$$.value.findIndex((p) => p.id === updatedUser.id);
          if (targetUserIdx !== -1) {
            targetUsers$$.value[targetUserIdx] = Object.assign(targetUsers$$.value[targetUserIdx], updatedUser);
          }
        }
        formToReset?.reset();

        return updatedUser;
      }),
    );
  }

  patchUserById(id: number, user: Partial<IUserWithProfileDto> | FormData): Observable<IUserWithProfileDto> {
    return this.membersUserHttpService.patchUserById(id, user);
  }

  loadNewAvatar(
    userId: number,
    avatarFile: File,
    targetUsers$$?: BehaviorSubject<IUserWithProfile[]>,
    formToReset?: FormGroup,
  ): Observable<IUserWithProfile> {
    const userFD = this.utilsService.transformObjectToFormData({ avatar: avatarFile });
    return this.patchUserByIdAndReplaceInUsers(userId, userFD, targetUsers$$, formToReset);
  }

  switchUsersActiveState(users: IUserWithProfile[], targetState: boolean, notificate?: boolean): Observable<void[]> {
    // Пока нет функционала на деактивацию
    if (!targetState) {
      return of();
    }
    users = users.filter((user) => user.is_active !== targetState);

    const usersActivateRequests = users.map((user) => {
      if (targetState) {
        return this.membersUserHttpService.activateUserById(user.id);
      }
    });

    if (usersActivateRequests.length) {
      return forkJoin(usersActivateRequests).pipe(
        tap(() => {
          users.forEach((user) => {
            user.is_active = targetState;
            user.menuOptions = this.authService.getMemberMenuOptions(targetState);
          });

          if (notificate) {
            this._switchUsersActiveStateSuccessNotification(users, targetState);
          }
        }),
      );
    }
    return of();
  }

  deleteUserByIdAndRemoveFromUsers(
    userId: number,
    targetUsers$$?: BehaviorSubject<IUserWithProfile[]>,
  ): Observable<void> {
    const userToDeleteIdx = targetUsers$$.value.findIndex((p) => p.id === userId);
    if (userToDeleteIdx !== -1) {
      targetUsers$$.value[userToDeleteIdx].isDeleting = true;
    }

    return this.membersUserHttpService.deleteUserById(userId).pipe(
      tap(
        () => {
          if (targetUsers$$?.value?.length) {
            targetUsers$$.next(targetUsers$$.value.filter((u) => u.id !== userId));
          }
        },
        () => {
          if (userToDeleteIdx !== -1) {
            targetUsers$$.value[userToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }

  private _switchUsersActiveStateSuccessNotification(users: IUserWithProfile[], targetState: boolean) {
    let message: string;
    if (users.length === 1) {
      message = `Членство участника ${users[0].firstAndLastName} ${targetState ? 'активировано' : 'деактивировано'}`;
    } else {
      message = `${targetState ? 'Активировано' : 'Деактивировано'} членство ${transformWordByCount(
        users.length,
        'участник',
      )}`;
    }
    this.snackBar.open(message, null, { duration: 3000 });
  }

  getMembersHobbies(params?: KeyValue<string>): Observable<ArrayPayload<IMemberHobbyDto>> {
    return this.membersUserHttpService.getMembersHobbies(params);
  }

  // Получение динамической ссылки профиля участника
  getDynamicLinkForProfile(id: number) {
    return this.membersUserHttpService.createDynamicLinkForProfile(id);
  }

  getUserGrats(id: number, params?: KeyValue<string>, paramsString?: string) {
    return this.membersUserHttpService.getUserGrats(id, params);
  }

  postUserGrat(id: number, gratitude: { text: string }) {
    return this.membersUserHttpService.postUserGrat(id, gratitude);
  }

  public patchUserGrat(id: number, gratitude: { text: string }): Observable<ArrayPayload<{ text: string }>> {
    return this.membersUserHttpService.patchUserGrat(id, gratitude);
  }

  public deleteUserGrat(id: number): Observable<void> {
    return this.membersUserHttpService.deleteUserGrat(id);
  }

  public getUserPrivateCalendar(id: number): Observable<any> {
    return this.membersUserHttpService.getUserPrivateCalendar(id);
  }
}
