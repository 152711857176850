import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ChatMessageService } from '@core/services/business/messages/chat-message.service';
import { SelectService } from '@core/services/business/utils/select.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IN_OUT_ANIMATION } from '@shared/animations/in-out.animation';
import { ElementPostionKey } from '@shared/enums/keys.enum';
import { SelectBarViewMode } from '@shared/enums/select/select-bar-view-mode.enum';
import { SelectMode } from '@shared/enums/select/select-mode.enum';
import { SelectUpdatorField } from '@shared/enums/select/select-updator-field.enum';
import { SelectViewMode } from '@shared/enums/select/select-view-mode.enum';
import { KeyValue } from '@shared/models/key-value.model';
import { IChatMessage } from '@shared/models/messages/view/chat-message.model';
import { IOptionsSource, ISelectOption } from '@shared/models/select.model';
import { BaseClass } from '../base/base.class';
import { Subject } from 'rxjs';
import { ChatReactionsService } from '@core/services/business/messages/chat-reactions.service';

@UntilDestroy()
@Component({
  selector: 'kp-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [IN_OUT_ANIMATION],
})
export class SelectComponent extends BaseClass implements OnChanges {
  @ViewChild('optionsWrapperRef', { static: true }) optionsWrapperEl: ElementRef;
  @Input() externalTrigger$: Subject<void>;

  @Input() options: ISelectOption[] = [];
  @Input() selectedOption: ISelectOption;
  @Input() selectedOptionValue: string;
  @Input() selectedOptions: ISelectOption[];
  @Input() selectedOptionsValues: string[];
  @Input() optionsUpdatorField: SelectUpdatorField;
  @Input() control: AbstractControl;

  @Input() optionsSource: IOptionsSource;

  @Input() placeholder = 'Выбрать';
  @Input() mode = SelectMode.SingleSelect;
  @Input() viewMode = SelectViewMode.Default;
  @Input() canDeselectInSingleMode = false;
  @Input() position = ElementPostionKey.BottomRight;
  @Input() imageBeforeTextPropName: string;

  @Input() containerWidth: string;
  @Input() containerHeight: string;

  @Input() barHeight = '30px';
  @Input() barViewMode: SelectBarViewMode;

  @Input() barIconKey: string;
  @Input() barIconHeight: string;
  @Input() barIconWidth: string;
  @Input() barIconColor: string;

  @Input() isBarInNgContent = false;
  @Input() addArrowToNgContent = true;

  @Input() isNeedBackground: boolean;

  @Input() isSelectHasReactions: boolean;

  @Input() message: IChatMessage;

  @Output() optionDeselectedEvent = new EventEmitter<ISelectOption>();
  @Output() selectedOptionChangeEvent = new EventEmitter<ISelectOption>();
  @Output() selectedOptionsChangeEvent = new EventEmitter<ISelectOption[]>();

  @Output() valueDeselectedEvent = new EventEmitter<unknown>();
  @Output() selectedValueChangeEvent = new EventEmitter<unknown>();
  @Output() selectedValuesChangeEvent = new EventEmitter<unknown[]>();

  isSelectActive = false;
  optionMinHeightPx = 31;
  optionsContainerHeightPx: number;
  isOptionsLoading = false;
  isAllOptionsLoaded = false;

  constructor(
    public selectService: SelectService,
    public chatMessageService: ChatMessageService,
    private chatReactionsService: ChatReactionsService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectService.onChanges(this.context, changes);
  }

  controlChangesHandler() {
    this.subs.unsubscribe();

    this.subs.sink = this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.options || this.isOptionsLoading) {
        return;
      }

      this.selectService.updateOptionsState(this.context);
    });
  }

  loadOptionsAndSet(filters: KeyValue<string>) {
    this.optionsSource
      .listItemsRequest(filters)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        this.selectService.getOptionsFromSourceResponseActions(this.context, response);
      });
  }

  loadMissingOptionAndSet(id: string) {
    this.optionsSource
      .singleItemRequest(+id)
      .pipe(untilDestroyed(this))
      .subscribe((item: unknown) => {
        this.selectService.loadMissingOptionResponseActions(this.context, item);
      });
  }

  get context(): SelectComponent {
    return this;
  }
}
