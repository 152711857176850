<div class="select-dialog-container">
  <div *ngIf="title" class="title">{{ title }}</div>

  <kp-select
    [options]="options"
    [optionsSource]="optionsSource"
    [selectedOptionValue]="selectedOptionValue"
    [selectedOptionsValues]="selectedOptionsValues"
    [optionsUpdatorField]="selectUpdatorField.Key"
    [mode]="currentSelectMode"
    [viewMode]="selectViewMode.Inline"
    [barHeight]="'max-content'"
    [imageBeforeTextPropName]="imageBeforeTextPropName"
    (selectedOptionChangeEvent)="selectedOption = $event"
    (selectedOptionsChangeEvent)="selectedOptions = $event"
    (selectedValueChangeEvent)="selectedOptionValue = $event"
    (selectedValuesChangeEvent)="selectedOptionsValues = $event"
  ></kp-select>

  <div class="buttons-container buttons-container--self-align-end">
    <button type="button" class="button button--white" (click)="closeDialog()">Отмена</button>
    <button type="button" class="button" (click)="closeDialog(true)">{{ submitButtonText }}</button>
  </div>
</div>
