import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { KeyValue } from '@shared/models/key-value.model';
import { Observable } from 'rxjs';
import { ArrayPayload } from '@shared/models/payload.model';
import { HttpParameterCodec, HttpParams, HttpParamsOptions } from '@angular/common/http';
import {
  IBookingMeetingDto,
  IBookingMeetingReport,
  IBookingMeetingView,
} from '@shared/interfaces/booking/meeting-interface';

class FullUrlEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({
  providedIn: 'root',
})
export class MeetingHttpService {
  private readonly _apiPath = '/booking/meetings/';
  private readonly _apiPathYearCalendar = '/booking/meetings/list-of-meeting-dates/';
  private readonly _apiPathDownloadReport = '/booking/meetings/generate-report-on-meetings/';

  constructor(private http: HttpService) {}
  getMeetings(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IBookingMeetingDto>> {
    const additional_params: HttpParamsOptions = { encoder: new FullUrlEncoder() };
    return this.http.get<ArrayPayload<IBookingMeetingDto>>(this._apiPath, {
      params: params
        ? new HttpParams({ fromObject: params, ...additional_params })
        : new HttpParams({ fromString: paramsString, ...additional_params }),
    });
  }

  getMeetingById(id: number): Observable<IBookingMeetingView> {
    return this.http.get<IBookingMeetingView>(`${this._apiPath}${id}/`);
  }

  createMeeting(meeting: Partial<IBookingMeetingDto>): Observable<IBookingMeetingDto> {
    return this.http.post<IBookingMeetingDto>(this._apiPath, meeting);
  }

  patchMeetingById(id: number, meeting: Partial<IBookingMeetingDto> | FormData): Observable<IBookingMeetingDto> {
    return this.http.patch<IBookingMeetingDto>(`${this._apiPath}${id}/`, meeting);
  }

  deleteMeetingById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }

  // Используется для получения списка дат, в которых проводятся встречи (для годового календаря)
  getMeetingsForYearCalendar(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<any>> {
    return this.http.get<ArrayPayload<any>>(this._apiPathYearCalendar, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  // Отправка полей для выгрузки отчета по бронируемым встречам
  getBookingMeetReport(reportFields: IBookingMeetingReport): Observable<IBookingMeetingReport> {
    return this.http.post<IBookingMeetingReport>(this._apiPathDownloadReport, reportFields);
  }

  // Принудительная синхронизация пользователей во встрече с битрикс
  getMeetingMembersSyncWithBitrix(id: number): Observable<void> {
    return this.http.get<void>(`${this._apiPath}${id}/sync-members-from-bitrix/`);
  }
}
