<kp-user-avatar
  [avatarUrl]="avatarUrl"
  [avatarSize]="avatarSize"
  [username]="username"
  class="avatar"
></kp-user-avatar>

<div class="content-sidebar">
  <div class="content">
    <div class="card-title">
      <ng-content select="[card-title]"></ng-content>
    </div>

    <div class="description">
      <ng-content select="[card-description]"></ng-content>
    </div>
  </div>

  <div class="right-sidebar">
    <ng-content select="[right-sidebar]"></ng-content>
  </div>
</div>
