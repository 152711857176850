import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'visitDateTime',
})
export class VisitDateTimePipe implements PipeTransform {
  transform(value: string): unknown {
    if (Number.isNaN(Date.parse(value))) {
      throw Error('Invalid Date');
    }

    const date = new Date(value);
    const diffDays = new Date().getDate() - date.getDate();
    const diffMonths = new Date().getMonth() - date.getMonth();
    const diffYears = new Date().getFullYear() - date.getFullYear();

    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const time = `${date.getHours()}:${minutes}`;

    if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
      return `Сегодня ${time}`;
    } else if (diffYears === 0 && diffMonths === 0 && diffDays === 1) {
      return `Вчера ${time}`;
    }

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  }
}
