import { Injectable } from '@angular/core';
import { MeetingLinkHttpService } from '@core/services/api/meetings/meeting-link-http.service';
import { KeyValue } from '@shared/models/key-value.model';
import { IMeeting, IMeetingLink } from '@shared/models/meetings/view/meeting.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeetingLinkService {
  links: IMeetingLink[] = [];

  constructor(private MeetingLinkHttpService: MeetingLinkHttpService) {}

  getAllByTargetIdAndAddToTarget(
    targetId: number,
    target?: IMeeting,
    params?: KeyValue<string>,
    paramsString?: string,
  ): Observable<ArrayPayload<IMeetingLink>> {
    const filters = { meeting: targetId.toString(), ...params };
    const filtersInString = `meeting=${targetId}`;

    params = params ? { ...params, ...filters } : null;
    paramsString = paramsString ? `${paramsString}&${filtersInString}` : filtersInString;

    return this.MeetingLinkHttpService.getAll(params, paramsString).pipe(
      tap((response) => {
        target.links = response.results;
      }),
    );
  }

  getById(id: number): Observable<IMeetingLink> {
    return this.MeetingLinkHttpService.getById(id);
  }

  createAndAddToTarget(dto: IMeetingLink, target?: IMeeting): Observable<IMeetingLink> {
    return this.MeetingLinkHttpService.create(dto).pipe(
      tap((createdItem) => {
        if (target) {
          if (!target.links?.length) {
            target.links = [];
          }
          target.links.push(createdItem);
        }
      }),
    );
  }

  updateByIdAndReplaceInTarget(id: number, dto: IMeetingLink, target?: IMeeting): Observable<IMeetingLink> {
    return this.MeetingLinkHttpService.updateById(id, dto).pipe(
      tap((updatedItem) => {
        if (target?.links) {
          const targetItemIdx = target.links.findIndex((item) => item.id === updatedItem.id);
          if (targetItemIdx !== -1) {
            target.links[targetItemIdx] = updatedItem;
          }
        }
      }),
    );
  }

  deleteByIdAndRemoveFromTarget(id: number, target?: IMeeting): Observable<void> {
    const itemToDeleteIdx = target?.links?.findIndex((p) => p.id === id);
    if (itemToDeleteIdx !== -1) {
      target.links[itemToDeleteIdx].isDeleting = true;
    }

    return this.MeetingLinkHttpService.deleteById(id).pipe(
      tap(
        () => {
          if (target.links) {
            target.links = target.links?.filter((item) => item.id !== id);
          }
        },
        () => {
          if (itemToDeleteIdx !== -1) {
            target.links[itemToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }
}
