<div
  class="container"
  [ngStyle]="{
    'padding-top': paddingY,
    'padding-bottom': paddingY
  }"
>
  <kp-svg
    [key]="iconKey"
    [size]="iconSize"
    *ngIf="iconKey"
    cursor="default"
    [ngStyle]="{
      'margin-bottom': iconTextGap
    }"
    [color]="color || iconColor"
  >
  </kp-svg>

  <div
    [ngStyle]="{
      'font-size': fontSize,
      'font-weight': fontWeight,
      color: color || textColor
    }"
  >
    {{ text }}
  </div>
</div>
