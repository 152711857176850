import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from '@shared/components/base/base.class';
import { SelectMode } from '@shared/enums/select/select-mode.enum';
import { KeyValue } from '@shared/models/key-value.model';
import { IOptionsSource, ISelectDialogResult, ISelectOption } from '@shared/models/select.model';

@Component({
  selector: 'kp-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectDialogComponent extends BaseClass {
  title: string;
  options: ISelectOption[];
  optionsSource: IOptionsSource;
  selectedOption: ISelectOption;
  selectedOptions: ISelectOption[];
  selectedOptionValue: any;
  selectedOptionsValues: any[];
  currentSelectMode: SelectMode;
  submitButtonText: string;
  imageBeforeTextPropName: string;

  constructor(private dialogRef: MatDialogRef<SelectDialogComponent>, @Inject(MAT_DIALOG_DATA) data: KeyValue<any>) {
    super();
    this._initVarsFromData(data);
  }

  private _initVarsFromData(data: KeyValue<any>) {
    if (!data) {
      return;
    }

    this.title = data.title;
    this.options = data.options;
    this.optionsSource = data.optionsSource;
    this.selectedOption = data.selectedOption;
    this.selectedOptions = data.selectedOptions;
    this.selectedOptionValue = data.selectedOptionValue;
    this.selectedOptionsValues = data.selectedOptionsValues;
    this.currentSelectMode = data.selectMode;
    this.submitButtonText = data.submitButtonText || 'Сохранить';
    this.imageBeforeTextPropName = data.imageBeforeTextPropName;
  }

  closeDialog(withResult = false) {
    const result: ISelectDialogResult<any> = {
      selectedOption: this.selectedOption,
      selectedOptions: this.selectedOptions,
      selectedOptionValue: this.selectedOptionValue,
      selectedOptionsValues: this.selectedOptionsValues,
    };

    this.dialogRef.close(withResult ? result : null);
  }
}
