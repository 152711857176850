import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IChatMessageDto } from '@shared/models/messages/dto/chat-message-dto.model';
import { IChatMessage, IChatMessageReaction } from '@shared/models/messages/view/chat-message.model';
import { take } from 'rxjs/operators';
import { ChatReactionsRequestService } from '@core/services/business/messages/chat-reactions.request.service';
import { AuthService } from '@core/services/business/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ChatReactionsService {
  constructor(private requestService: ChatReactionsRequestService, private authService: AuthService) {}

  public getReactionsCountByMessage(message: IChatMessage): number {
    const { reactions }: IChatMessage = message;
    return reactions?.length
      ? reactions.reduce(
          (reactionsCount: number, reactionItem: IChatMessageReaction) => reactionsCount + reactionItem.count,
          0,
        )
      : 0;
  }

  public changeReaction(
    chatId: number,
    messageId: number,
    currentUserReaction: IChatMessageReaction,
    reaction: string,
  ): Observable<IChatMessageDto> {
    if (!currentUserReaction || currentUserReaction.reaction !== reaction) {
      return this._addMessageReaction(chatId, messageId, reaction);
    }

    return this._deleteMessageReaction(chatId, messageId);
  }

  private _addMessageReaction(chatId: number, messageId: number, reaction: string): Observable<IChatMessageDto> {
    return this.requestService.postChatMessageReaction(chatId, messageId, reaction).pipe(take(1));
  }

  private _deleteMessageReaction(chatId: number, messageId: number): Observable<IChatMessageDto> {
    return this.requestService.deleteChatMessageReaction(chatId, messageId).pipe(take(1));
  }
}
