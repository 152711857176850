export enum PostFilterField {
  Offset = 'offset',
  Limit = 'limit',
  Type = 'type',
  PublicationType = 'publication_type',
  Created = 'created',
  CreatedPeriodKey = 'created_period_key',
  Pinned = 'pinned',
}

export enum PostTypeKey {
  Events = '0',
  Forums = '1',
  KnowledgeManagment = '2',
  Projects = '3',
  Travels = '4',
  Communities = '5',
  CommonInfo = '6',
  Members = '7',
  Afisha = '8',
}

export enum PostStatusKey {
  Applied = '0',
  OnModeration = '1',
  Cancelled = '2',
}

export enum PostVoteType {
  Common = '0',
  FirstMeetingDate = '1',
}
