type sortDirection = 'asc' | 'desc';

export function orderByTwoFieldsFunction<T>(
  array: T[],
  mainField: string,
  additionalField: string,
  mainFieldDirection: sortDirection = 'asc',
  additionalFieldDirection: sortDirection = 'asc',
): T[] {
  return array.sort((a: T, b: T) => {
    if (a[mainField as keyof T] < b[mainField as keyof T]) return mainFieldDirection === 'asc' ? -1 : 1;
    if (a[mainField as keyof T] > b[mainField as keyof T]) return mainFieldDirection === 'asc' ? 1 : -1;

    // Если mainField равны, сравниваем по additionalField
    if (a[additionalField as keyof T] < b[additionalField as keyof T])
      return additionalFieldDirection === 'asc' ? -1 : 1;
    if (a[additionalField as keyof T] > b[additionalField as keyof T])
      return additionalFieldDirection === 'asc' ? 1 : -1;

    return 0;
  });
}
