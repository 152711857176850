import { FILTER_RULE_OPTIONS } from '@shared/constants/selects-options.const';
import { FilterValueInputType } from '@shared/enums/filter/filter-value-input-type.enum';
import { ConfimationMessage, UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { SelectOptionActionType } from '@shared/enums/select/select-option-action-type.enum';
import { OptionSourceKey } from '@shared/enums/select/select-source.enum';
import { ISelectOption } from '@shared/models/select.model';
import {
  MeetingMemberPaymentStateKey,
  MeetingMemberStateKey,
  MeetingRelatedFieldKey,
  MeetingsFormatKey,
  MeetingStateKey,
  MeetingsTypeKey,
  MeetingsTypeOfPaidKey,
  MeetingTypeKey,
} from '../enums/keys.enum';
import { MeetingInListMenuOptionViewName, MeetingSettingsMenuOptionViewName } from '../enums/view-names.enum';
import {
  COMMON_MEETINGS_TABLE_MEMBER_COLUMNS_KEYS,
  COMMON_MEETINGS_TABLE_STAFF_COLUMNS_KEYS,
  INDUSTRY_MEETINGS_TABLE_MEMBER_COLUMNS_KEYS,
  INDUSTRY_MEETINGS_TABLE_STAFF_COLUMNS_KEYS,
  MEETINGS_TABLE_COLUMNS,
  MEETING_MEMBERS_TABLE_COLUMNS,
  MEETING_MEMBERS_TABLE_MEMBER_COLUMNS_KEYS,
  MEETING_MEMBERS_TABLE_STAFF_COLUMNS_KEYS,
} from './common.const';
import {
  MEETINGS_FORMAT_KEY_TO_VIEW_NAME,
  MEETINGS_TYPE_KEY_TO_VIEW_NAME,
  MEETINGS_TYPE_OF_PAID_KEY_TO_VIEW_NAME,
  MEETINGS_TYPE_OF_TYPES_KEY_TO_VIEW_NAME,
  MEETING_MEMBER_PAYMENT_STATE_KEY_TO_VIEW_NAME,
  MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME,
  MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME,
  MEETING_STATE_KEY_TO_VIEW_NAME,
} from './mappers.const';

export const MEETING_TYPE_OPTIONS: ISelectOption[] = [
  {
    key: MeetingTypeKey.Setup,
    viewName: MEETINGS_TYPE_OF_TYPES_KEY_TO_VIEW_NAME[MeetingTypeKey.Setup],
  },
  {
    key: MeetingTypeKey.Common,
    viewName: MEETINGS_TYPE_OF_TYPES_KEY_TO_VIEW_NAME[MeetingTypeKey.Common],
  },
];

export const FORM_OF_PAID_OPTIONS: ISelectOption[] = [
  {
    key: MeetingsTypeOfPaidKey.Free,
    viewName: MEETINGS_TYPE_OF_PAID_KEY_TO_VIEW_NAME[MeetingsTypeOfPaidKey.Free],
  },
  {
    key: MeetingsTypeOfPaidKey.Paid,
    viewName: MEETINGS_TYPE_OF_PAID_KEY_TO_VIEW_NAME[MeetingsTypeOfPaidKey.Paid],
  },
];

export const MEETING_MEMBER_STATE_OPTIONS: ISelectOption[] = [
  {
    key: MeetingMemberStateKey.MustVisit,
    viewName: MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME[MeetingMemberStateKey.MustVisit],
  },
  {
    key: MeetingMemberStateKey.ConfirmedParticipation,
    viewName: MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME[MeetingMemberStateKey.ConfirmedParticipation],
  },
  {
    key: MeetingMemberStateKey.ReminderSent,
    viewName: MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME[MeetingMemberStateKey.ReminderSent],
  },
  {
    key: MeetingMemberStateKey.Visited,
    viewName: MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME[MeetingMemberStateKey.Visited],
  },
  {
    key: MeetingMemberStateKey.Refusal,
    viewName: MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME[MeetingMemberStateKey.Refusal],
  },
  {
    key: MeetingMemberStateKey.PaymentPending,
    viewName: MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME[MeetingMemberStateKey.PaymentPending],
  },
  {
    key: MeetingMemberStateKey.Missed,
    viewName: MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME[MeetingMemberStateKey.Missed],
  },
  {
    key: MeetingMemberStateKey.MeetingCanceled,
    viewName: MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME[MeetingMemberStateKey.MeetingCanceled],
  },
  {
    key: MeetingMemberStateKey.NotInForum,
    viewName: MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME[MeetingMemberStateKey.NotInForum],
  },
];

export const MEETING_MEMBER_PAYMENT_STATE_OPTIONS: ISelectOption[] = [
  {
    key: MeetingMemberPaymentStateKey.Paid,
    viewName: MEETING_MEMBER_PAYMENT_STATE_KEY_TO_VIEW_NAME[MeetingMemberPaymentStateKey.Paid],
  },
  {
    key: MeetingMemberPaymentStateKey.NotPaid,
    viewName: MEETING_MEMBER_PAYMENT_STATE_KEY_TO_VIEW_NAME[MeetingMemberPaymentStateKey.NotPaid],
  },
  {
    key: MeetingMemberPaymentStateKey.Free,
    viewName: MEETING_MEMBER_PAYMENT_STATE_KEY_TO_VIEW_NAME[MeetingMemberPaymentStateKey.Free],
  },
];

const MEETING_IN_LIST_MENU_OPTIONS: ISelectOption[] = [
  {
    viewName: MeetingInListMenuOptionViewName.Open,
    iconKey: 'arrowUpRightSlim',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: MeetingInListMenuOptionViewName.Copy,
    iconKey: 'copy',
    actionType: SelectOptionActionType.Action,
  },
];

export const DRAFT_FORUM_IN_LIST_MENU_OPTIONS_ADMIN = MEETING_IN_LIST_MENU_OPTIONS;
export const NOT_DRAFT_FORUM_IN_LIST_MENU_OPTIONS_STAFF = MEETING_IN_LIST_MENU_OPTIONS;
export const FORUM_IN_LIST_MENU_OPTIONS_MEMBER = MEETING_IN_LIST_MENU_OPTIONS.filter(
  (o) => o.viewName === MeetingInListMenuOptionViewName.Open,
);

export const DRAFT_MEETING_MENU_OPTIONS: ISelectOption[] = [
  {
    viewName: MeetingSettingsMenuOptionViewName.Start,
    iconKey: 'publishIcon',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.PublishMeeting,
  },
  {
    viewName: MeetingSettingsMenuOptionViewName.Cancel,
    iconKey: 'xCircle',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.CancelMeeting,
  },
];

export const CANCELLED_MEETING_MENU_OPTIONS: ISelectOption[] = [
  {
    viewName: MeetingSettingsMenuOptionViewName.Start,
    iconKey: 'publishIcon',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.PublishMeeting,
  },
];

export const PUBLISHED_MEETING_MENU_OPTIONS: ISelectOption[] = [
  {
    viewName: MeetingSettingsMenuOptionViewName.End,
    iconKey: 'checkSquareIcon',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.FinishMeeting,
  },
  {
    viewName: MeetingSettingsMenuOptionViewName.Cancel,
    iconKey: 'xCircle',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.CancelMeeting,
  },
];

export const MEETINGS_TYPE_OPTIONS: ISelectOption[] = [
  {
    viewName: MEETINGS_TYPE_KEY_TO_VIEW_NAME[MeetingsTypeKey.MyMeetings],
    filterField: MeetingRelatedFieldKey.User,
    filterGroupValue: MeetingsTypeKey.MyMeetings,
  },
  {
    viewName: MEETINGS_TYPE_KEY_TO_VIEW_NAME[MeetingsTypeKey.AllMeetings],
    filterGroupValue: MeetingsTypeKey.AllMeetings,
  },
].map((option: ISelectOption) => {
  option.filterIsNotUnique = true;
  option.filterGroupField = MeetingRelatedFieldKey.MeetingType;
  return option;
});

export const MEETING_MEMBERS_TABLE_STAFF_COLUMNS_OPTIONS: ISelectOption[] = MEETING_MEMBERS_TABLE_COLUMNS.filter((col) =>
  MEETING_MEMBERS_TABLE_STAFF_COLUMNS_KEYS.includes(col.headerKey as UserRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const MEETING_MEMBERS_TABLE_MEMBER_COLUMNS_OPTIONS: ISelectOption[] = MEETING_MEMBERS_TABLE_COLUMNS.filter((col) =>
  MEETING_MEMBERS_TABLE_MEMBER_COLUMNS_KEYS.includes(col.headerKey as UserRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const COMMON_MEETINGS_TABLE_STAFF_COLUMNS_OPTIONS: ISelectOption[] = MEETINGS_TABLE_COLUMNS.filter((col) =>
  COMMON_MEETINGS_TABLE_STAFF_COLUMNS_KEYS.includes(col.headerKey as MeetingRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const INDUSTRY_MEETINGS_TABLE_STAFF_COLUMNS_OPTIONS: ISelectOption[] = MEETINGS_TABLE_COLUMNS.filter((col) =>
  INDUSTRY_MEETINGS_TABLE_STAFF_COLUMNS_KEYS.includes(col.headerKey as MeetingRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const COMMON_MEETINGS_TABLE_MEMBER_COLUMNS_OPTIONS: ISelectOption[] = MEETINGS_TABLE_COLUMNS.filter((col) =>
  COMMON_MEETINGS_TABLE_MEMBER_COLUMNS_KEYS.includes(col.headerKey as MeetingRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const INDUSTRY_MEETINGS_TABLE_MEMBER_COLUMNS_OPTIONS: ISelectOption[] = MEETINGS_TABLE_COLUMNS.filter((col) =>
  INDUSTRY_MEETINGS_TABLE_MEMBER_COLUMNS_KEYS.includes(col.headerKey as MeetingRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const MEETING_STATE_OPTIONS: ISelectOption[] = [
  {
    viewName: MEETING_STATE_KEY_TO_VIEW_NAME[MeetingStateKey.Draft],
    key: MeetingStateKey.Draft,
  },
  {
    viewName: MEETING_STATE_KEY_TO_VIEW_NAME[MeetingStateKey.Published],
    key: MeetingStateKey.Published,
  },
  {
    viewName: MEETING_STATE_KEY_TO_VIEW_NAME[MeetingStateKey.Cancelled],
    key: MeetingStateKey.Cancelled,
  },
  {
    viewName: MEETING_STATE_KEY_TO_VIEW_NAME[MeetingStateKey.Finished],
    key: MeetingStateKey.Finished,
  },
  {
    viewName: MEETING_STATE_KEY_TO_VIEW_NAME[MeetingStateKey.InProgress],
    key: MeetingStateKey.InProgress,
  },
];

export const MEETING_TYPE_OF_PAID_OPTIONS: ISelectOption[] = [
  {
    viewName: MEETINGS_TYPE_OF_PAID_KEY_TO_VIEW_NAME[MeetingsTypeOfPaidKey.Free],
    key: MeetingsTypeOfPaidKey.Free,
  },
  {
    viewName: MEETINGS_TYPE_OF_PAID_KEY_TO_VIEW_NAME[MeetingsTypeOfPaidKey.Paid],
    key: MeetingsTypeOfPaidKey.Paid,
  },
];

export const MEETING_FORMAT_OPTIONS: ISelectOption[] = [
  {
    viewName: MEETINGS_FORMAT_KEY_TO_VIEW_NAME[MeetingsFormatKey.Offline],
    key: MeetingsFormatKey.Offline,
  },
  {
    viewName: MEETINGS_FORMAT_KEY_TO_VIEW_NAME[MeetingsFormatKey.Online],
    key: MeetingsFormatKey.Online,
  },
];

const MEETINGS_MODAL_FILTERS_OPTIONS: ISelectOption[] = [
  // Название
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.NameFromDialog],
    filterField: MeetingRelatedFieldKey.NameFromDialog,
    filterFieldIsNotForApi: true,
    filterAdditionalFields: [MeetingRelatedFieldKey.Name],
    filterAdditionalFieldsIsNotForUrl: true,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },
  // Номер встречи в форуме
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Number],
    filterField: MeetingRelatedFieldKey.Number,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  // Номер форума
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.ForumBranchNumber],
    filterField: MeetingRelatedFieldKey.ForumBranchNumber,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  // Адрес встречи (локация)
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Address],
    filterField: MeetingRelatedFieldKey.Address,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.MeetingAddress,
  },
  // Состояние
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.State],
    filterField: MeetingRelatedFieldKey.State,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptions: MEETING_STATE_OPTIONS,
  },
  // Модератор
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.ModeratorFromDialog],
    filterField: MeetingRelatedFieldKey.ModeratorFromDialog,
    filterFieldIsNotForApi: true,
    filterAdditionalFields: [MeetingRelatedFieldKey.Moderator],
    filterAdditionalFieldsIsNotForUrl: true,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.StaffMembers,
  },
  // Участники
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.User],
    filterField: MeetingRelatedFieldKey.UserFromDialog,
    filterFieldIsNotForApi: true,
    filterAdditionalFields: [MeetingRelatedFieldKey.User],
    filterAdditionalFieldsIsNotForUrl: true,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.Users,
  },
  // Число участников
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.MembersCount],
    filterField: MeetingRelatedFieldKey.MembersCount,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  // Начало
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.StartedAt],
    filterField: MeetingRelatedFieldKey.StartedAt,
    filterValueInputType: FilterValueInputType.DateWithTime,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  // Завершение
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.FinishedAt],
    filterField: MeetingRelatedFieldKey.FinishedAt,
    filterValueInputType: FilterValueInputType.DateWithTime,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  // Создал
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Creator],
    filterField: MeetingRelatedFieldKey.Creator,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.StaffMembers,
  },
  // Дата создания
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Created],
    filterField: MeetingRelatedFieldKey.Created,
    filterValueInputType: FilterValueInputType.DateWithTime,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  // Дата изменения
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Updated],
    filterField: MeetingRelatedFieldKey.Updated,
    filterValueInputType: FilterValueInputType.DateWithTime,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  // Тип оплаты
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.PaymentType],
    filterField: MeetingRelatedFieldKey.PaymentType,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptions: MEETING_TYPE_OF_PAID_OPTIONS,
  },
  // Стоимость
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.Cost],
    filterField: MeetingRelatedFieldKey.Cost,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  // Локация (филиал)
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.ForumBranch],
    filterField: MeetingRelatedFieldKey.ForumBranch,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.ForumLocations,
  },
  // Посетило участников
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.VisitedMembersCount],
    filterField: MeetingRelatedFieldKey.VisitedMembersCount,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  // Отказало
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.RefusedMembersCount],
    filterField: MeetingRelatedFieldKey.RefusedMembersCount,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },
  // Формат
  {
    viewName: MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME[MeetingRelatedFieldKey.MeetingFormat],
    filterField: MeetingRelatedFieldKey.MeetingFormat,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptions: MEETING_FORMAT_OPTIONS,
  },
].map((option: ISelectOption) => {
  option.filterIsNotUnique = true;
  option.filterIsDialogParent = true;
  return option;
});

const FILTERS_KEYS_STAFF: string[] = [
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.Cost,
  MeetingRelatedFieldKey.Created,
  MeetingRelatedFieldKey.Creator,
  MeetingRelatedFieldKey.FinishedAt,
  MeetingRelatedFieldKey.Format,
  MeetingRelatedFieldKey.Forum,
  MeetingRelatedFieldKey.ForumBranch,
  MeetingRelatedFieldKey.ForumBranchNumber,
  MeetingRelatedFieldKey.ForumLocation,
  MeetingRelatedFieldKey.MeetingFormat,
  MeetingRelatedFieldKey.MembersCount,
  MeetingRelatedFieldKey.Moderator,
  MeetingRelatedFieldKey.ModeratorFromDialog,
  MeetingRelatedFieldKey.NameFromDialog,
  MeetingRelatedFieldKey.Number,
  MeetingRelatedFieldKey.PaymentType,
  MeetingRelatedFieldKey.RefusedMembersCount,
  MeetingRelatedFieldKey.StartedAt,
  MeetingRelatedFieldKey.State,
  MeetingRelatedFieldKey.Updated,
  MeetingRelatedFieldKey.User,
  MeetingRelatedFieldKey.VisitedMembersCount,
];
export const MEETINGS_MODAL_FILTERS_OPTIONS_STAFF: ISelectOption[] = MEETINGS_MODAL_FILTERS_OPTIONS.filter((option) =>
  FILTERS_KEYS_STAFF.includes(option.filterField),
);

const FILTERS_KEYS_MEMBER: string[] = [
  MeetingRelatedFieldKey.Address,
  MeetingRelatedFieldKey.Cost,
  MeetingRelatedFieldKey.FinishedAt,
  MeetingRelatedFieldKey.Format,
  MeetingRelatedFieldKey.Forum,
  MeetingRelatedFieldKey.ForumBranch,
  MeetingRelatedFieldKey.ForumBranchNumber,
  MeetingRelatedFieldKey.ForumLocation,
  MeetingRelatedFieldKey.MeetingFormat,
  MeetingRelatedFieldKey.MembersCount,
  MeetingRelatedFieldKey.Moderator,
  MeetingRelatedFieldKey.ModeratorFromDialog,
  MeetingRelatedFieldKey.NameFromDialog,
  MeetingRelatedFieldKey.Number,
  MeetingRelatedFieldKey.PaymentType,
  MeetingRelatedFieldKey.StartedAt,
  MeetingRelatedFieldKey.State,
  MeetingRelatedFieldKey.User,
];
export const MEETINGS_MODAL_FILTERS_OPTIONS_MEMBER: ISelectOption[] = MEETINGS_MODAL_FILTERS_OPTIONS.filter((option) =>
  FILTERS_KEYS_MEMBER.includes(option.filterField),
);
