import { Injectable } from '@angular/core';
import { MembersIndustryHttpService } from '@core/services/api/members/members-industry-http.service';
import { IdName } from '@shared/models/id-name.model';
import { KeyValue } from '@shared/models/key-value.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MembersIndustryService {
  industries: IdName[] = [];

  constructor(private membersIndustryHttpService: MembersIndustryHttpService) {}

  getIndustriesAndAddToIndustries(
    targetIndustries?: IdName[],
    params?: KeyValue<string>,
    paramsString?: string,
  ): Observable<IdName[]> {
    return this.membersIndustryHttpService.getIndustries(params, paramsString).pipe(
      map((response) => {
        if (!targetIndustries) {
          targetIndustries = [];
        }
        targetIndustries.push(...response.results);

        return targetIndustries;
      }),
    );
  }

  getIndustries(params?: KeyValue<string>, paramsString?: string): Observable<any> {
    return this.membersIndustryHttpService.getIndustries(params, paramsString);
  }

  getRegions(params?: KeyValue<string>, paramsString?: string): Observable<any> {
    return this.membersIndustryHttpService.getRegions(params, paramsString);
  }

  getPositions(params?: KeyValue<string>, paramsString?: string): Observable<any> {
    return this.membersIndustryHttpService.getPositions(params, paramsString);
  }

  getById(id: number): Observable<IdName> {
    return this.membersIndustryHttpService.getIndustryById(id);
  }
}
