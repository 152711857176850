<form class="container" [formGroup]="form" (ngSubmit)="validateFormAndCreateUser()">
  <div class="title">Добавить участника</div>

  <!-- Email -->
  <div class="form-control form-control--required" [ngClass]="{ 'form-control--invalid': isControlInvalid(email) }">
    <div class="form-control__input">
      <input type="text" placeholder="Email" formControlName="email" [kpNoWhiteSpaces]="true"/>
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(email)">{{ getValidatorErrorMessage(email) }}</div>
  </div>

  <!-- Login -->
  <div class="form-control form-control--required" [ngClass]="{ 'form-control--invalid': isControlInvalid(username) }">
    <div class="form-control__input">
      <input type="text" placeholder="Логин" formControlName="username" [kpNoWhiteSpaces]="true"/>
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(username)">{{ getValidatorErrorMessage(username) }}</div>
  </div>

  <!-- Password -->
  <div class="form-control form-control--required" [ngClass]="{ 'form-control--invalid': isControlInvalid(password) }">
    <div class="form-control__input">
      <input type="text" placeholder="Пароль" formControlName="password" [kpNoWhiteSpaces]="true"/>
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(password)">{{ getValidatorErrorMessage(password) }}</div>
  </div>

  <!-- Name -->
  <div class="form-control form-control--required"
       [ngClass]="{ 'form-control--invalid': isControlInvalid(first_name) }">
    <div class="form-control__input">
      <input type="text" placeholder="Имя" formControlName="first_name"/>
    </div>

    <div class="form-control__error"
         *ngIf="isControlInvalid(first_name)">{{ getValidatorErrorMessage(first_name) }}</div>
  </div>

  <!-- Surname -->
  <div class="form-control form-control--required" [ngClass]="{ 'form-control--invalid': isControlInvalid(last_name) }">
    <div class="form-control__input">
      <input type="text" placeholder="Фамилия" formControlName="last_name"/>
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(last_name)">{{ getValidatorErrorMessage(last_name) }}</div>
  </div>

  <!-- Patronymic -->
  <div class="form-control" [ngClass]="{ 'form-control--invalid': isControlInvalid(middle_name) }">
    <div class="form-control__input">
      <input type="text" placeholder="Отчество" formControlName="middle_name"/>
    </div>

    <div class="form-control__error"
         *ngIf="isControlInvalid(middle_name)">{{ getValidatorErrorMessage(middle_name) }}</div>
  </div>

  <!-- Form_of_participation -->
  <div class="form-control form-control--required"
       [ngClass]="{ 'form-control--invalid': isControlInvalid(form_of_participation) }">
    <div class="form-control__input">
      <kp-select
        [control]="form_of_participation"
        [options]="formOfParticipationOptions"
        [optionsUpdatorField]="selectUpdatorField.Key"
        placeholder="Форма участия"
        [position]="elementPositionKey.BottomLeft"
        (selectedValueChangeEvent)="form_of_participation.setValue($event)"
      ></kp-select>
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(form_of_participation)">
      {{ getValidatorErrorMessage(form_of_participation) }}
    </div>
  </div>

  <!-- Groups -->
  <div class="form-control" [ngClass]="{ 'form-control--invalid': isControlInvalid(groups) }">
    <div class="form-control__input">
      <kp-select
        [options]="membersRoleOptionsMock"
        placeholder="Группа"
        [position]="elementPositionKey.BottomLeft"
        (selectedOptionChangeEvent)="groups.setValue([$event.key])"
      ></kp-select>
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(groups)">{{ getValidatorErrorMessage(groups) }}</div>
  </div>

  <!-- Admin -->
  <div class="form-control form-control--required" [ngClass]="{ 'form-control--invalid': isControlInvalid(admin) }">
    <div class="form-control__input">
      <kp-select
        [control]="admin"
        [optionsSource]="optionsSources.staffMembers"
        [optionsUpdatorField]="selectUpdatorField.Key"
        placeholder="Администратор"
        [position]="elementPositionKey.BottomLeft"
        (selectedValueChangeEvent)="admin.setValue($event)"
      ></kp-select>
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(admin)">{{ getValidatorErrorMessage(admin) }}</div>
  </div>

  <!-- Curator -->
  <div class="form-control form-control--required" [ngClass]="{ 'form-control--invalid': isControlInvalid(curator) }">
    <div class="form-control__input">
      <kp-select
        [control]="curator"
        [optionsSource]="optionsSources.staffMembers"
        [optionsUpdatorField]="selectUpdatorField.Key"
        placeholder="Куратор"
        [position]="elementPositionKey.BottomLeft"
        (selectedValueChangeEvent)="curator.setValue($event)"
      ></kp-select>
    </div>

    <div class="form-control__error" *ngIf="isControlInvalid(curator)">{{ getValidatorErrorMessage(curator) }}</div>
  </div>

  <div class="buttons-container buttons-container--self-align-end">
    <button type="button" class="button button--white" (click)="closeDialog()">Отмена</button>
    <button type="submit" class="button button">Добавить</button>
  </div>
</form>
