import { EVENT_STATE_KEY_TO_VIEW_NAME } from '@modules/events/constants/mappers.const';
import { FORUM_AND_MEETING_CONFIG } from '@modules/forums/constants/common.const';
import {
  FORUM_MEMBER_STATE_NAME_KEY_TO_VIEW_NAME,
  FORUM_STATE_COLOR_KEY_TO_VIEW_NAME,
  FORUM_STATE_KEY_TO_VIEW_NAME,
  FORUM_STATE_NAME_KEY_COLOR_TO_VIEW_NAME,
} from '@modules/forums/constants/mappers.const';
import { ForumMemberStateKey, ForumOrMeetingType, ForumStateKey } from '@modules/forums/enums/keys.enum';
import {
  MEETING_MEMBER_PAYMENT_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME,
  MEETING_MEMBER_PAYMENT_STATE_KEY_TO_VIEW_NAME,
  MEETING_MEMBER_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME,
  MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME,
  MEETING_STATE_KEY_TO_PRIMARY_INFO_CLASS_NAME,
  MEETING_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME,
  MEETING_STATE_KEY_TO_VIEW_NAME,
  MEETINGS_FORMAT_KEY_TO_VIEW_NAME,
} from '@modules/meetings/constants/mappers.const';
import {
  MeetingMemberStateKey,
  MeetingStateKey,
  MeetingsTypeOfPaidKey,
  MeetingTypeKey,
} from '@modules/meetings/enums/keys.enum';
import { MembersTypeKey } from '@modules/members/enums/keys.enum';
import { NOTIFICATION_TYPE_KEY_TO_VIEW_NAME } from '@modules/notifications/constants/mappers.const';
import { NotificationTypeKey } from '@modules/notifications/enums/keys.enum';
import {
  FORM_OF_PARTICIPATION_KEY_TO_VIEW_NAME,
  SEX_KEY_TO_VIEW_NAME_SHORT,
} from '@modules/profile/constants/keys-to-view-names.const';
import { FormOfParticipationKey } from '@modules/profile/enums/keys.enum';
import { TaskContentType } from '@modules/tasks/enums/keys.enum';
import {
  DATE_DAY_NUMBER_MONTH,
  DATE_DAY_NUMBER_MONTH_WORD_TIME,
  DATE_FULL,
  DATE_FULL_BACKEND,
  DATE_FULL_MONTH_WORD,
  DATE_FULL_WITH_MONTH_WORD_AND_TIME,
  DATE_FULL_WITH_TIME,
  DATE_FULL_WITH_TIME_SHORT,
  DATE_SHORT,
  DATE_WITH_TIME_NO_MS_BACKEND,
  DAY_NUMBER_AND_MONTH_WORD,
  MASK_DAY_MONTH_YEAR,
  MASK_HOUR_MINUTE,
  OPTIONS_SOURCES,
  QUILL_DEFAULT_TOOLBAR_FORMATS,
  QUILL_MODULES_DEAFULT_SETTINGS,
  QUILL_MODULES_MEETING_SETTINGS,
} from '@shared/constants/common.const';
import { FilterValueInputType } from '@shared/enums/filter/filter-value-input-type.enum';
import { FilterType } from '@shared/enums/filter/filters-type.enum';
import { SortType } from '@shared/enums/filter/sort-type.enum';
import {
  ConfimationMessage as ConfirmationMessage,
  DatePickerMode,
  ElementPostionKey,
  NewslineScrollSelector,
  PageMode,
  UserRelatedFieldKey,
  UserTypeKey,
} from '@shared/enums/keys.enum';
import { SelectBarViewMode } from '@shared/enums/select/select-bar-view-mode.enum';
import { SelectRelatedFieldKey } from '@shared/enums/select/select-fields.enum';
import { SelectMode } from '@shared/enums/select/select-mode.enum';
import { OptionSourceKey } from '@shared/enums/select/select-source.enum';
import { SelectUpdatorField } from '@shared/enums/select/select-updator-field.enum';
import { SelectViewMode } from '@shared/enums/select/select-view-mode.enum';
import { ViewContent } from '@shared/enums/view-content.enum';
import { IForumAndMeetingModulesConfig } from '@shared/models/forums/view/forum-meeting-config.model';
import { KeyValue } from '@shared/models/key-value.model';
import { SubSink } from 'subsink';

export abstract class BaseClass {
  readonly subs = new SubSink();
  readonly dialogRefSubs = new SubSink();

  readonly sortType = SortType;
  readonly elementPositionKey = ElementPostionKey;
  readonly selectMode = SelectMode;
  readonly selectViewMode = SelectViewMode;
  readonly selectBarViewMode = SelectBarViewMode;
  readonly selectUpdatorField = SelectUpdatorField;
  readonly selectRelatedFieldKey = SelectRelatedFieldKey;
  readonly userTypeKey = UserTypeKey;
  readonly filterType = FilterType;
  readonly dataViewContent = ViewContent;
  readonly membersTypeKey = MembersTypeKey;
  readonly formOfParticipationKey = FormOfParticipationKey;
  readonly pageMode = PageMode;
  readonly userFieldKey = UserRelatedFieldKey;
  readonly filterValueInputType = FilterValueInputType;
  readonly datePickerMode = DatePickerMode;
  readonly optionSourceKey = OptionSourceKey;
  readonly meetingStateKey = MeetingStateKey;
  readonly meetingTypeKey = MeetingTypeKey;
  readonly newslineScrollSelector = NewslineScrollSelector;
  readonly forumOrMeetingType = ForumOrMeetingType;
  readonly taskContentType = TaskContentType;
  readonly confirmationMessage = ConfirmationMessage;
  readonly forumStateKey = ForumStateKey;
  readonly forumMemberStateKey = ForumMemberStateKey;
  readonly meetingMemberStateKey = MeetingMemberStateKey;
  readonly notificationTypeKey = NotificationTypeKey;
  readonly notificationTypesKeysList = Object.values(NotificationTypeKey);
  readonly meetingsTypeOfPaidKey = MeetingsTypeOfPaidKey;

  readonly sexKeyToViewNameShort = SEX_KEY_TO_VIEW_NAME_SHORT as KeyValue<string>;
  readonly formOfParticiptaionKeyToViewName = FORM_OF_PARTICIPATION_KEY_TO_VIEW_NAME as KeyValue<string>;
  readonly forumStateKeyToViewName = FORUM_STATE_KEY_TO_VIEW_NAME as KeyValue<string>;
  readonly forumStateNameKeyColorToViewName = FORUM_STATE_NAME_KEY_COLOR_TO_VIEW_NAME as KeyValue<string>;
  readonly forumStateColorKeyToViewName = FORUM_STATE_COLOR_KEY_TO_VIEW_NAME as KeyValue<string>;
  readonly forumMemberStateNameKeyToViewName = FORUM_MEMBER_STATE_NAME_KEY_TO_VIEW_NAME as KeyValue<string>;
  readonly meetingStateNameKeyToViewName = MEETING_STATE_KEY_TO_VIEW_NAME as KeyValue<string>;
  readonly meetingFormatKeyToViewName = MEETINGS_FORMAT_KEY_TO_VIEW_NAME as KeyValue<string>;
  readonly meetingMemberStateNameKeyToViewName = MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME as KeyValue<string>;
  readonly meetingMemberStateKeyToPrimaryTextClassName =
    MEETING_MEMBER_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME as KeyValue<string>;
  readonly meetingMemberPaymentStateKeyToPrimaryTextClassName =
    MEETING_MEMBER_PAYMENT_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME as KeyValue<string>;
  readonly meetingStateKeyToPrimaryTextClassName = MEETING_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME as KeyValue<string>;
  readonly meetingStateKeyToPrimaryInfoClassName = MEETING_STATE_KEY_TO_PRIMARY_INFO_CLASS_NAME as KeyValue<string>;
  readonly meetingMemberPaymentStateKeyToViewName = MEETING_MEMBER_PAYMENT_STATE_KEY_TO_VIEW_NAME as KeyValue<string>;
  readonly notificationTypeKeyToViewName = NOTIFICATION_TYPE_KEY_TO_VIEW_NAME as KeyValue<string>;

  readonly dateWithTimeNoMsBackend = DATE_WITH_TIME_NO_MS_BACKEND;
  readonly dateShort = DATE_SHORT;
  readonly dateFull = DATE_FULL;
  readonly dateFullMonthWord = DATE_FULL_MONTH_WORD;
  readonly dayNumberAndMonthWord = DAY_NUMBER_AND_MONTH_WORD;
  readonly dateDayNumberMonth = DATE_DAY_NUMBER_MONTH;
  readonly dateFullWithTime = DATE_FULL_WITH_TIME;
  readonly dateFullWithTimeShort = DATE_FULL_WITH_TIME_SHORT;
  readonly dateFullWithMonthWordAndTime = DATE_FULL_WITH_MONTH_WORD_AND_TIME;
  readonly dateFullBackend = DATE_FULL_BACKEND;
  readonly maskDayMonthYear = MASK_DAY_MONTH_YEAR;
  readonly maskHourMinute = MASK_HOUR_MINUTE;
  readonly dateDayNumberMonthWordTime = DATE_DAY_NUMBER_MONTH_WORD_TIME;

  readonly quillModulesDefaultSettings = QUILL_MODULES_DEAFULT_SETTINGS;
  readonly quillDefaultToolbarFormats = QUILL_DEFAULT_TOOLBAR_FORMATS;
  readonly quillModuleMeetingSeetings = QUILL_MODULES_MEETING_SETTINGS;

  readonly optionsSources = OPTIONS_SOURCES;

  readonly eventStateNameKeyToViewName = EVENT_STATE_KEY_TO_VIEW_NAME as KeyValue<string>;

  get forumAndMeetingConfig(): IForumAndMeetingModulesConfig {
    return FORUM_AND_MEETING_CONFIG.config;
  }
}
