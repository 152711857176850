import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '@core/services/business/utils/date.service';
import { DATE_DAY_NUMBER_MONTH } from '@shared/constants/common.const';
import { Moment } from 'moment';

@Pipe({
  name: 'onlineStatus',
})
export class OnlineStatusPipe implements PipeTransform {
  constructor(private dateService: DateService) {}

  transform(date: Moment, needRound: boolean = false, dateFormat = DATE_DAY_NUMBER_MONTH) {
    return needRound
      ? this.dateService.transformMomentToRoundOnlineStatusString(date)
      : this.dateService.transformMomentToOnlineStatusString(date, dateFormat);
  }
}
