import { Pipe, PipeTransform } from '@angular/core';
import { IUserBusinessExperienceDto } from '@shared/models/user/dto/user-business-experience-dto.model';

@Pipe({
  name: 'userPrimaryBusiness',
})
export class UserPrimaryBusinessPipe implements PipeTransform {
  constructor() {}

  // Возвращает название основного бизнеса участника
  transform(business: IUserBusinessExperienceDto[]) {
    if (!business.length) {
      return 'Компания не указана';
    }

    return business.filter((el) => el.is_primary === true)[0].company;
  }
}
