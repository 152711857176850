import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IFilter } from '@shared/models/filter/filter.model';

@UntilDestroy()
@Component({
  selector: 'kp-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnChanges, OnInit {
  @Input() itemsCount: number;
  @Input() limit: number;
  @Input() offset: number;
  @Output() offsetChangedEvent = new EventEmitter<string>();
  @Output() offsetFilterChangedEvent = new EventEmitter<IFilter>();
  @Output() changePageEvent = new EventEmitter<number>();
  @Output() offsetChange = new EventEmitter<number>();

  currentPageNumber = 1;
  currentPageSize: number;
  pagesForNavMaxCount = 3;
  pagesCount: number;
  pageNavNumbers: number[];

  ngOnChanges(changes: SimpleChanges) {
    this._updatePaginatorState();
  }

  ngOnInit() {
    this._updatePaginatorState();
  }

  changePageNumber(pageNumber: number) {
    if (pageNumber !== this.currentPageNumber && pageNumber !== -1 && pageNumber > 0 && pageNumber <= this.pagesCount) {
      this.changePageEvent.emit(pageNumber);
      this.currentPageNumber = pageNumber;
      this._emitChanges();
    }
  }

  private _emitChanges() {
    this.offset = (this.currentPageNumber - 1) * this.currentPageSize;
    this.offsetChange.emit(this.offset);

    this.offsetChangedEvent.emit(this.offset.toString());
    this.offsetFilterChangedEvent.emit({
      field: 'offset',
      value: this.offset.toString(),
    });

    this._updatePaginatorState();
  }

  private _updatePaginatorState() {
    this._checkAndSetPageSizeAndNumber();
    this._setPagesCount();
    this._setPageNavNumbers();
  }

  private _checkAndSetPageSizeAndNumber() {
    if (this.limit !== undefined && this.limit !== null) {
      this.currentPageSize = +this.limit;
    }

    if (this.offset !== undefined && this.offset !== null) {
      this.currentPageNumber = +this.offset / this.currentPageSize + 1;
    }
  }

  private _setPagesCount() {
    if (this.itemsCount && this.currentPageSize) {
      this.pagesCount = Math.ceil(this.itemsCount / this.currentPageSize);
    }
  }

  private _setPageNavNumbers() {
    if (this.pagesCount) {
      if (this.pagesCount > this.pagesForNavMaxCount) {
        if (this.currentPageNumber === 1) {
          const firstPages = [...Array(this.pagesForNavMaxCount + 1).keys()].slice(1);
          this.pageNavNumbers = firstPages;
        } else if (this.currentPageNumber === this.pagesCount) {
          const lastPages = [...Array(this.pagesCount + 1).keys()].slice(this.pagesCount - this.pagesForNavMaxCount + 1);
          this.pageNavNumbers = lastPages;
        } else {
          const pagesCountOnSidesFromCurrent = Math.floor(this.pagesForNavMaxCount / 2);
          const leftFromMiddlePages = [...Array(this.currentPageNumber).keys()].slice(
            this.currentPageNumber - pagesCountOnSidesFromCurrent,
          );
          const rightFromMiddlePages = [...Array(this.currentPageNumber + 1 + pagesCountOnSidesFromCurrent).keys()].slice(
            this.currentPageNumber + 1,
          );

          const middlePages = [...leftFromMiddlePages, this.currentPageNumber, ...rightFromMiddlePages];
          this.pageNavNumbers = middlePages;
        }
      } else {
        this.pageNavNumbers = [...Array(this.pagesCount + 1).keys()].slice(1);
      }
    }
  }
}
