import { ForumMemberStateKey, ForumRelatedFieldKey, ForumStateKey, ForumsTypeKey } from '../enums/keys.enum';

export const FORUMS_TYPE_KEY_TO_VIEW_NAME: { [key in ForumsTypeKey]: string } = {
  [ForumsTypeKey.MyForumsStaff]: 'Мои форумы',
  [ForumsTypeKey.MyForumsMember]: 'Мои форумы',
  [ForumsTypeKey.Finished]: 'Завершённые',
  [ForumsTypeKey.Published]: 'Запущенные',
  [ForumsTypeKey.AllForums]: 'Все форумы',
};

export const FORUM_STATE_KEY_TO_VIEW_NAME: { [key in ForumStateKey]: string } = {
  [ForumStateKey.Draft]: 'Черновик',
  [ForumStateKey.Published]: 'Запущен',
  [ForumStateKey.Finished]: 'Завершён',
  [ForumStateKey.Cancelled]: 'Отменён',
};

export const FORUM_STATE_COLOR_KEY_TO_VIEW_NAME: { [key in ForumStateKey]: string } = {
  [ForumStateKey.Draft]: 'primary-text primary-text--dark-gray',
  [ForumStateKey.Published]: 'primary-text primary-text--turquoise',
  [ForumStateKey.Finished]: 'primary-text primary-text--accent ',
  [ForumStateKey.Cancelled]: 'primary-text primary-text--pastel-pink',
};

export const FORUM_MEMBER_STATE_NAME_KEY_TO_VIEW_NAME: { [key in ForumMemberStateKey]: string } = {
  [ForumMemberStateKey.Consist]: 'Состоит',
  [ForumMemberStateKey.Refused]: 'Отказался',
  [ForumMemberStateKey.CameOut]: 'Вышел',
  [ForumMemberStateKey.WaitAnswer]: 'Ожидается ответ',
  [ForumMemberStateKey.SentRequest]: 'Отправил заявку',
  [ForumMemberStateKey.Invited]: 'Приглашен',
  [ForumMemberStateKey.Denied]: 'Отказано',
};

export const FORUM_STATE_NAME_KEY_COLOR_TO_VIEW_NAME: { [key in ForumMemberStateKey]: string } = {
  [ForumMemberStateKey.Consist]: 'primary-text primary-text--turquoise',
  [ForumMemberStateKey.Refused]: 'primary-text primary-text--pastel-pink',
  [ForumMemberStateKey.CameOut]: 'primary-text primary-text--wet-asphalt',
  [ForumMemberStateKey.WaitAnswer]: 'primary-text primary-text--yellow',
  [ForumMemberStateKey.SentRequest]: 'primary-text primary-text--gray',
  [ForumMemberStateKey.Invited]: 'primary-text primary-text--gray',
  [ForumMemberStateKey.Denied]: 'primary-text primary-text--pastel-pink',
};

export const FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME: Partial<Record<ForumRelatedFieldKey, string>> = {
  [ForumRelatedFieldKey.AdministratorFromDialog]: 'Администратор',
  [ForumRelatedFieldKey.Administrator]: 'Администратор',
  [ForumRelatedFieldKey.Avatar]: 'Аватар',
  [ForumRelatedFieldKey.Branch]: 'Филиал',
  [ForumRelatedFieldKey.Created]: 'Дата создания',
  [ForumRelatedFieldKey.Creator]: 'Создал',
  [ForumRelatedFieldKey.Curator]: 'Куратор',
  [ForumRelatedFieldKey.Editors]: 'Изменил',
  [ForumRelatedFieldKey.FinishedAt]: 'Завершение',
  [ForumRelatedFieldKey.FinishedMeetingsCount]: 'Прошло встреч',
  [ForumRelatedFieldKey.ForumYear]: 'Возраст форума',
  [ForumRelatedFieldKey.Industry]: 'Отрасль',
  [ForumRelatedFieldKey.LastLaunchStartedAt]: 'Последний запуск',
  [ForumRelatedFieldKey.MeetingsLeftCount]: 'Осталось встреч',
  [ForumRelatedFieldKey.MembersCount]: 'Число участников',
  [ForumRelatedFieldKey.Moderator]: 'Модератор',
  [ForumRelatedFieldKey.MonthsLeft]: 'Осталось месяцев',
  [ForumRelatedFieldKey.Name]: 'Название',
  [ForumRelatedFieldKey.NameFromDialog]: 'Название',
  [ForumRelatedFieldKey.Specific]: 'Специфика',
  [ForumRelatedFieldKey.StartedAt]: 'Запуск',
  [ForumRelatedFieldKey.State]: 'Состояние',
  [ForumRelatedFieldKey.StateFromDialog]: 'Состояние',
  [ForumRelatedFieldKey.UniqueBranchNumber]: 'Номер',
  [ForumRelatedFieldKey.Updated]: 'Дата изменения',
  [ForumRelatedFieldKey.User]: 'Участники',
};
