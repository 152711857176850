import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MEMBERS_DEFAULT_FILTERS_TABLE,
  MEMBERS_DEFAULT_FILTERS_TILES,
  MEMBERS_FILTERS_TABLE_IGNORE_URL,
  MEMBERS_FILTERS_TILES_IGNORE_URL,
  MEMBERS_LIST_DEFAULT_LIMIT_TABLE,
  MEMBERS_LIST_DEFAULT_LIMIT_TILES,
  MEMBERS_PAGE_FILTERS_TABLE,
  MEMBERS_PAGE_FILTERS_TILES,
  MEMBERS_TABLE_COLUMNS,
  MEMBERS_TABLE_DEFAULT_COLUMNS_KEYS,
} from '@modules/members/constants/common.const';
import {
  MEMBERS_MODAL_FILTERS_OPTIONS_MEMBER,
  MEMBERS_MODAL_FILTERS_OPTIONS_STAFF,
  MEMBERS_TABLE_MEMBER_COLUMNS_OPTIONS,
  MEMBERS_TABLE_STAFF_COLUMNS_OPTIONS,
} from '@modules/members/constants/selects-options.const';
import { MemberAddDialogComponent } from '@modules/members/dialogs/member-add-dialog/member-add-dialog.component';
import { MembersTypeKey } from '@modules/members/enums/keys.enum';
import { ProfileRecommendMemberDialogComponent } from '@modules/profile/dialogs/profile-recommend-member-dialog/profile-recommend-member-dialog.component';
import { BaseClass } from '@shared/components/base/base.class';
import { SelectDialogComponent } from '@shared/dialogs/select-dialog/select-dialog.component';
import { FilterType } from '@shared/enums/filter/filters-type.enum';
import { DatePeriodKey, UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { ViewContent } from '@shared/enums/view-content.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { ISelectOption } from '@shared/models/select.model';
import { ITableColumn } from '@shared/models/table/table-column.model';
import { ITableConfig } from '@shared/models/table/table-config.model';
import { IUserBusinessExperience } from '@shared/models/user/view/user-business-experience.model';
import { IUserEducation } from '@shared/models/user/view/user-education.model';
import { IUser, IUserWithProfile } from '@shared/models/user/view/user.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FilterService } from '../utils/filter.service';
import { TableService } from '../utils/table.service';
import { MembersUserService } from './members-user.service';

@Injectable({
  providedIn: 'root',
})
export class MembersUtilsService extends BaseClass {
  tableConfig: ITableConfig;
  tableCols: ITableColumn[];

  currentViewContent = ViewContent.Tile;
  membersModalFiltersOptions: ISelectOption[];

  constructor(
    private tableService: TableService,
    private membersUserService: MembersUserService,
    private dialog: MatDialog,
    private authService: AuthService,
    private filterService: FilterService,
  ) {
    super();
    this.membersModalFiltersOptions = this.currentUser.isStaff
      ? MEMBERS_MODAL_FILTERS_OPTIONS_STAFF
      : MEMBERS_MODAL_FILTERS_OPTIONS_MEMBER;
  }

  memberSelectedStateSwitched(member: IUserWithProfile) {
    const targetMemberIdx = this.members.findIndex((m) => m.id === member.id);
    if (targetMemberIdx !== -1) {
      this.members[targetMemberIdx].isSelected = !this.members[targetMemberIdx].isSelected;
      this.updateTableConfig();
    }
  }

  membersSelectedStateSwitchAll(nextStateForAllMembers: boolean) {
    this.members.forEach((m) => {
      m.isSelected = nextStateForAllMembers;
    });
    this.updateTableConfig();
  }

  initTableConfig(
    columns?: ITableColumn[],
    membersForRows?: IUserWithProfile[],
    rowsSelectEnabled?: boolean,
    canSelectAllRows?: boolean,
    rowsSettingsEnabled?: boolean,
  ) {
    this.tableConfig = {
      columns: columns || (this.tableCols?.length ? this.tableCols : this.defaultTableCols),
      rows: this.tableService.transformArrayToTableRows(
        membersForRows || this.members,
        UserRelatedFieldKey.MenuOptions,
      ),
      rowsSelectEnabled:
        rowsSelectEnabled === undefined || rowsSelectEnabled === null ? this.currentUser?.isStaff : rowsSelectEnabled,
      canSelectAllRows: canSelectAllRows === undefined || canSelectAllRows === null ? true : canSelectAllRows,
      rowsSettingsEnabled:
        rowsSettingsEnabled === undefined || rowsSettingsEnabled === null ? true : rowsSettingsEnabled,
      isAllRowsSelected: this.isAllMembersSelected,
    };
  }

  updateTableConfig() {
    this.tableConfig.columns = this.tableCols?.length ? this.tableCols : this.defaultTableCols;
    this.tableConfig.rows = this.tableService.transformArrayToTableRows(this.members, UserRelatedFieldKey.MenuOptions);
    this.tableConfig.isAllRowsSelected = this.isAllMembersSelected;
    this.tableConfig = _.cloneDeep(this.tableConfig);
  }

  get defaultTableCols(): ITableColumn[] {
    return MEMBERS_TABLE_COLUMNS.filter((col) =>
      MEMBERS_TABLE_DEFAULT_COLUMNS_KEYS.includes(col.headerKey as UserRelatedFieldKey),
    );
  }

  openTableColumnsSettingsDialog(): MatDialogRef<SelectDialogComponent, any> {
    return this.dialog.open(SelectDialogComponent, {
      data: {
        options: this.currentUser.isStaff ? MEMBERS_TABLE_STAFF_COLUMNS_OPTIONS : MEMBERS_TABLE_MEMBER_COLUMNS_OPTIONS,
        selectedOptionsValues: this.tableConfig.columns.map((col) => col.headerKey),
        selectMode: this.selectMode.MultiSelect,
      },
    });
  }

  setTableColsByKeys(keys: string[]) {
    this.tableCols = MEMBERS_TABLE_COLUMNS.filter((col) => keys.includes(col.headerKey));
  }

  openRecommendMemberDialog() {
    this.dialog.open(ProfileRecommendMemberDialogComponent);
  }

  openMemberAddDialog() {
    this.dialog.open(MemberAddDialogComponent);
  }

  setFilterFromTemplate(
    filterType: FilterType,
    option?: ISelectOption,
    filters?: IFilter | IFilter[],
    itemsCountTotal?: number,
    itemsCountCurrent?: number,
    offsetStep?: string,
    skipLocationChange?: boolean, //to-do добавил это поле
  ): { needLoadItems: boolean } {
    const { needLoadItems } = this.filterService.setFilterFromTemplate(
      filterType,
      option,
      filters,
      itemsCountTotal,
      itemsCountCurrent,
      offsetStep,
    );

    if (needLoadItems) {
      switch (filterType) {
        case FilterType.DataFilters: {
          this.initMembersAndDefaultFilters(this.membersUserService.users$$);
          this.updateTableConfig();
        }
      }
    }
    return { needLoadItems };
  }

  initPageFilters() {
    const pageFilters = _.cloneDeep(this.isCurrentViewTable ? MEMBERS_PAGE_FILTERS_TABLE : MEMBERS_PAGE_FILTERS_TILES);
    this.filterService.mergeFiltersArrays(pageFilters, this.filterService.filters);
  }

  initMembersAndDefaultFilters(members$$: BehaviorSubject<IUserWithProfile[]>) {
    this.initMembers(members$$);
    this.initDefaultFilters();
  }

  initMembersAndCount(members$$: BehaviorSubject<IUserWithProfile[]>) {
    this.membersUserService.usersCount = 0;
    this.initMembers(members$$);
  }

  initMembers(members$$: BehaviorSubject<IUserWithProfile[]>) {
    members$$.next([]);
  }

  initFilters() {
    this.initDefaultFilters();
    this.initCurrentFilters();
  }

  initDefaultFilters() {
    this.filterService.defaultFilters = _.cloneDeep(
      this.isCurrentViewTable ? MEMBERS_DEFAULT_FILTERS_TABLE : MEMBERS_DEFAULT_FILTERS_TILES,
    );
  }

  initCurrentFilters() {
    this.filterService.filters = _.cloneDeep(this.filterService.defaultFilters);
  }

  setMembersTypeFiltersByMembersTypeKey(membersTypeKey?: MembersTypeKey | string, forumId?: string) {
    if (!membersTypeKey) {
      membersTypeKey = this.filterService.getFilterValueByFilterField(
        this.filterService.filters,
        UserRelatedFieldKey.MembersType,
      ) as string;
    }

    if (membersTypeKey) {
      let membersTypeFilters: IFilter[] = [];
      this.filterService.filterCurrentFiltersByFilterFields([UserRelatedFieldKey.CreatedAt, UserRelatedFieldKey.Forum]);

      switch (membersTypeKey) {
        case MembersTypeKey.NewMembers: {
          membersTypeFilters = this.filterService.getPeriodFiltersByDatePeriodKey(
            UserRelatedFieldKey.CreatedAt,
            DatePeriodKey.LastTwoMonths,
          );
          membersTypeFilters.forEach((f) => {
            f.isNotForUrl = true;
          });
          break;
        }
        case MembersTypeKey.MyForumMembers: {
          membersTypeFilters = [
            {
              field: UserRelatedFieldKey.Forum,
              value: forumId,
              isNotForUrl: true,
            },
          ];
          break;
        }
        case MembersTypeKey.AllMembers: {
          membersTypeFilters = this.filterService.getPeriodFiltersByDatePeriodKey(
            UserRelatedFieldKey.CreatedAt,
            DatePeriodKey.AllTime,
          );
          break;
        }
      }

      this.filterService.filters = this.filterService.mergeFiltersArrays(
        membersTypeFilters,
        this.filterService.filters,
      );
    }
  }

  // Преобразовывает массив бизнесов к виду, в котором бэк принимает патч
  transformBusiness(dto: IUserWithProfile): any {
    let view: Partial<IUserBusinessExperience[]> = [];

    dto.business_experience.forEach((business: IUserBusinessExperience) => {
      business.business_region = business.business_region.map((el: any) => el.id);

      typeof business.position === 'object'
        ? (business.position = business.position.id)
        : (business.position = business.position);

      business.industries = business.industries.map((el: any) => el.id);

      if (business.start_experience) {
        business.start_experience = moment(business.start_experience).format('YYYY-MM-DD');
      }

      if (business.end_experience) {
        business.end_experience = moment(business.end_experience).format('YYYY-MM-DD');
      }

      if (business.until_now) {
        delete business.end_experience;
      }
    });

    return view as IUserBusinessExperience[];
  }

  // Преобразовывает массив бизнесов к виду, в котором бэк принимает патч
  transformEducation(dto: IUserWithProfile): any {
    let view: Partial<IUserEducation[]> = [];

    dto.education.forEach((education: IUserEducation) => {
      if (education.start_education) {
        education.start_education = moment(education.start_education).format('YYYY-MM-DD');
      }
      education.end_education = moment(education.end_education).format('YYYY-MM-DD');
    });

    return view as IUserEducation[];
  }

  get defaultLimit() {
    return this.isCurrentViewTable ? MEMBERS_LIST_DEFAULT_LIMIT_TABLE : MEMBERS_LIST_DEFAULT_LIMIT_TILES;
  }

  get ignoreFilterUrlForCurrentView(): UserRelatedFieldKey[] {
    return this.isCurrentViewTable ? MEMBERS_FILTERS_TABLE_IGNORE_URL : MEMBERS_FILTERS_TILES_IGNORE_URL;
  }

  get isSomeMembersSelected(): boolean {
    return this.members.some((m) => m.isSelected);
  }

  get isAllMembersSelected(): boolean {
    return this.members.length && !this.members.some((m) => !m.isSelected);
  }

  get selectedMembersActiveState(): boolean {
    return this.selectedMembers.every((m) => m.is_active);
  }

  get members(): IUserWithProfile[] {
    return this.membersUserService.users$$.value;
  }

  get selectedMembers(): IUserWithProfile[] {
    return this.members.filter((m) => m.isSelected);
  }

  get currentMember(): IUserWithProfile {
    return this.membersUserService.currentMember;
  }

  get usersCount(): number {
    return this.membersUserService.usersCount;
  }

  get currentUser(): IUser {
    return this.authService.currentUser;
  }

  set isUsersActivating(isUsersActivating: boolean) {
    this.membersUserService.isUsersActivating = isUsersActivating;
  }

  get isUsersActivating(): boolean {
    return this.membersUserService.isUsersActivating;
  }

  get isListUsersLoading(): boolean {
    return this.membersUserService.isListUsersLoading;
  }

  get isCurrentViewTable(): boolean {
    return this.currentViewContent === ViewContent.Table;
  }

  get isCurrentViewTile(): boolean {
    return this.currentViewContent === ViewContent.Tile;
  }

  get isCurrentViewChart(): boolean {
    return this.currentViewContent === ViewContent.Chart;
  }
}
