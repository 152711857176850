import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { KeyValue } from '@shared/models/key-value.model';
import { Observable } from 'rxjs';
import { ArrayPayload } from '@shared/models/payload.model';
import { HttpParams } from '@angular/common/http';
import { IBookingLocationDto } from '@shared/interfaces/booking/location-interface';

export const BOOKING_URL: string = '/booking/locations/';

@Injectable({
  providedIn: 'root',
})
export class BookingLocationHttpService {
  private readonly _apiPath = '/booking/locations/';

  constructor(private http: HttpService) {}
  getLocations(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IBookingLocationDto>> {
    return this.http.get<ArrayPayload<IBookingLocationDto>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getLocationById(id: number): Observable<IBookingLocationDto> {
    return this.http.get<IBookingLocationDto>(`${this._apiPath}${id}/`);
  }

  createLocation(location: Partial<IBookingLocationDto>): Observable<IBookingLocationDto> {
    return this.http.post<IBookingLocationDto>(this._apiPath, location);
  }

  patchLocationById(id: number, location: Partial<IBookingLocationDto> | FormData): Observable<IBookingLocationDto> {
    return this.http.patch<IBookingLocationDto>(`${this._apiPath}${id}/`, location);
  }

  deleteLocationById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }
}
