import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timezoneFormat',
})
export class TimezoneFormatPipe implements PipeTransform {
  constructor() {}

  transform(timezone: string, format: string = 'ZZ') {
    return moment().tz(timezone).format(format);
  }
}
