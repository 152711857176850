import { Component, Input } from '@angular/core';
import { FileService } from '@core/services/business/utils/file.service';
import { HttpService } from '@core/services/api/http/http.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IFile } from '@shared/models/file.model';
import { BaseClass } from '../base/base.class';

import { RetrieveMessageFile } from '@shared/models/messages/view/chat-message.model';

@UntilDestroy()
@Component({
  selector: 'kp-file-inline-view',
  templateUrl: './file-inline-view.component.html',
  styleUrls: ['./file-inline-view.component.scss'],
})
export class FileInlineViewComponent extends BaseClass {
  @Input() file: IFile | RetrieveMessageFile;

  @Input() showIcon = true;
  @Input() showDownloadText = true;
  @Input() fontWeight = '300';

  @Input() isFileInChat: boolean;

  constructor(private fileService: FileService, private httpInterlayerService: HttpService) {
    super();
  }

  downloadFile() {
    this.httpInterlayerService
      .get<Blob>(this.file.file, { responseType: 'blob' }, '', '')
      .pipe(untilDestroyed(this))
      .subscribe((responseBlob) => {
        this.fileService.prepareAndDownloadFile(responseBlob, this.file.filename);
      });
  }
}
