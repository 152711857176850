import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kp-select-dropdown-footer',
  templateUrl: './select-dropdown-footer.component.html',
  styleUrls: ['./select-dropdown-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDropdownFooterComponent {
  @Input() public applyButtonLabel: string = 'Применить';
  @Output() public apply: EventEmitter<void> = new EventEmitter<void>();

  @Input() public clearButtonLabel: string = 'Сбросить всё';
  @Output() public clear: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}
