import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ForumMembersService } from '@core/services/business/forums/forum-members.service';
import { ForumService } from '@core/services/business/forums/forum.service';
import { MembersUserService } from '@core/services/business/members/members-user.service';
import { MembersUtilsService } from '@core/services/business/members/members-utils.service';
import { FilterService } from '@core/services/business/utils/filter.service';
import { FormValidationService } from '@core/services/business/utils/form-validation.service';
import { ForumMemberStateKey } from '@modules/forums/enums/keys.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IN_ANIMATION, IN_OUT_ANIMATION } from '@shared/animations/in-out.animation';
import { BaseFormComponent } from '@shared/components/base-form/base-form.component';
import { FilterRuleSeparator } from '@shared/enums/filter/filter-separator.enum';
import { FilterType } from '@shared/enums/filter/filters-type.enum';
import { UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { ViewContent } from '@shared/enums/view-content.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { IForumMember } from '@shared/models/forums/view/forum-member.model';
import { IUserWithProfile } from '@shared/models/user/view/user.model';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'kp-members-list-edit',
  templateUrl: './members-list-edit.component.html',
  animations: [IN_ANIMATION, IN_OUT_ANIMATION],
})
export class MembersListEditComponent extends BaseFormComponent implements OnInit {
  searchControl = new FormControl();
  addedMembers: Array<IForumMember> = [];
  selectedToDelete: IUserWithProfile[] = [];
  selectedToCreate: IUserWithProfile[] = [];
  isSelectionsUpdating = false;

  targetEntitiesGetReq: (memberId: number) => Observable<any[]>;
  targetEntityCreateReq: (memberId: number) => Observable<any>;
  targetEntityDeleteReq: (memberId: number) => Observable<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    protected formValidationService: FormValidationService,
    private filterService: FilterService,
    public membersUtilsService: MembersUtilsService,
    private membersUserService: MembersUserService,
    private forumService: ForumService,
    private forumMembersService: ForumMembersService,
  ) {
    super(formValidationService);
    this._setComponentConfig();

    this.membersUtilsService.currentViewContent = ViewContent.Table;
    this.membersUtilsService.initTableConfig(null, null, null, false, false);
    this.membersUtilsService.initMembersAndCount(this.membersUserService.users$$);
    this.membersUtilsService.initFilters();
    this.filterService.setUrlParametersToFilterFromRouter();
  }

  ngOnInit() {
    this._initPageActions();
    this._filtersChangeFromUrlHandler();
    this._searchControlChangesHandler();
  }

  private _setComponentConfig() {
    if (this.activatedRoute.snapshot.data.isForumMode) {
      this.targetEntitiesGetReq = (memberId: number) =>
        this.forumMembersService.getMembersAndAddToTargetMembers(this.forumService.currentForum.id, null, { user: memberId.toString() });
      this.targetEntityCreateReq = (memberId: number) =>
        this.forumMembersService.createForumMember({
          forum: this.forumService.currentForum.id,
          user: memberId,
          state: +ForumMemberStateKey.Invited,
        });
    }
  }

  private _filtersChangeFromUrlHandler() {
    this.filterService.filtersChangedFromUrl$.pipe(untilDestroyed(this)).subscribe(() => {
      this._initPageActions();
    });
  }

  private _initPageActions() {
    this.searchControl.setValue(this.filterService.getFilterValueByFilterField(this.filterService.filters, UserRelatedFieldKey.FullName), {
      emitEvent: false,
    });

    this._getUsersAndAddToUsers();
  }

  setFiltersAndLoadItems(filterType: FilterType, filters?: IFilter | IFilter[]) {
    const { needLoadItems } = this.membersUtilsService.setFilterFromTemplate(
      filterType,
      null,
      filters,
      this.membersUtilsService.usersCount,
      this.membersUtilsService.members.length,
      this.membersUtilsService.defaultLimit,
    );

    if (needLoadItems) {
      this._getUsersAndAddToUsers();
    }
  }

  private _getUsersAndAddToUsers() {
    this.subs.unsubscribe();
    const newMembers$$ = new BehaviorSubject<IUserWithProfile[]>([]);
    this.membersUserService.isListUsersLoading = true;

    this.subs.sink = this.membersUserService
      .getUsersAndAddToUsers(
        newMembers$$,
        null,
        this.filterService.mapFiltersToApiOrUrlStringState(this.filterService.filters, FilterRuleSeparator.ApiDoubleUnderscore),
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cancelSelections(false);
        this.membersUserService.users$$.next(newMembers$$.value);
        this.membersUtilsService.updateTableConfig();

        this.membersUserService.isListUsersLoading = false;
        this._setMembersSelectedStateByAddedMembers();
      });
  }

  private _setMembersSelectedStateByAddedMembers() {
    this.subs.unsubscribe();
    this.addedMembers = [];

    this.membersUtilsService.members.forEach((member) => {
      this.subs.sink = this.targetEntitiesGetReq(member.id)
        .pipe(untilDestroyed(this))
        .subscribe((addedMembers) => {
          if (addedMembers.length) {
            member.isSelected = true;
            this.addedMembers.push(addedMembers[0]);
          } else {
            member.isSelected = false;
          }
          this.membersUtilsService.updateTableConfig();
        });
    });
  }

  private _searchControlChangesHandler() {
    this.searchControl.valueChanges.pipe(untilDestroyed(this), debounceTime(300)).subscribe((value) => {
      if (value === undefined || value === null) {
        return;
      }

      const searchFilter: IFilter = {
        field: UserRelatedFieldKey.FullName,
        value: value.trim(),
      };

      this.searchControl.setValue(searchFilter.value, { emitEvent: false });
      this.setFiltersAndLoadItems(FilterType.DataFilters, searchFilter);
    });
  }

  cancelSelections(updateConfig = true) {
    this.selectedToCreate.forEach((m) => {
      m.isSelected = false;
    });

    this.selectedToDelete.forEach((m) => {
      m.isSelected = true;
    });

    this.selectedToCreate = [];
    this.selectedToDelete = [];

    if (updateConfig) {
      this.membersUtilsService.updateTableConfig();
    }
  }

  saveSelections() {
    const reqs: Array<Observable<IForumMember> | Observable<void>> = [];

    const createReqs = this.selectedToCreate.map((m) => this.targetEntityCreateReq(m.id));
    const deleteReqs = this.selectedToDelete.map((m) => this.targetEntityDeleteReq(this.addedMembers.find((f) => f.user === m.id).user));

    if (createReqs.length) {
      reqs.push(...createReqs);
    }
    if (deleteReqs.length) {
      reqs.push(...deleteReqs);
    }

    if (reqs.length) {
      this.isSelectionsUpdating = true;
      forkJoin(reqs)
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.isSelectionsUpdating = false;
            this._setMembersSelectedStateByAddedMembers();
            this.cancelSelections(false);
          },
          () => {
            this.cancelSelections();
            this.isSelectionsUpdating = false;
          },
        );
    }
  }

  memberSelectedStateSwitched(member: IUserWithProfile) {
    const targetMember = this.membersUtilsService.members.find((m) => m.id === member.id);
    if (targetMember) {
      targetMember.isSelected = !targetMember.isSelected;

      if (targetMember.isSelected) {
        this.selectedToDelete = this.selectedToDelete.filter((m) => m.id !== targetMember.id);

        if (!this.addedMembers.find((m) => m.user === targetMember.id)) {
          this.selectedToCreate.push(targetMember);
        }
      } else {
        this.selectedToCreate = this.selectedToCreate.filter((m) => m.id !== targetMember.id);

        if (this.addedMembers.find((m) => m.user === targetMember.id)) {
          this.selectedToDelete.push(targetMember);
        }
      }

      this.membersUtilsService.updateTableConfig();
    }
  }

  get offset(): string {
    return this.filterService.offset;
  }

  get limit(): string {
    return this.filterService.limit;
  }
}
