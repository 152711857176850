import { Injectable } from '@angular/core';
import { NewslinePostVoteAnswerHttpService } from '@core/services/api/newsline/newsline-post-vote-answer-http.service';
import { INewslinePostVoteAnswer } from '@shared/models/newsline/view/newsline-post-vote-answer.model';
import { INewslinePostVote } from '@shared/models/newsline/view/newsline-post-vote.model';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostVoteAnswerService {
  constructor(private authService: AuthService, private newslinePostVoteAnswerHttpService: NewslinePostVoteAnswerHttpService) {}

  getAnswersByVoteIdAndAddToVote(voteId: number, targetVote?: INewslinePostVote): Observable<INewslinePostVoteAnswer[]> {
    if (targetVote) {
      targetVote.isLoadingAnswers = true;
    }

    return this.newslinePostVoteAnswerHttpService.getAnswers({ poll: voteId.toString() }).pipe(
      map((response) => {
        if (targetVote) {
          targetVote.answers = response.results;
          targetVote.isLoadingAnswers = false;
        }

        return response.results;
      }),
    );
  }

  getAnswerById(id: number): Observable<INewslinePostVoteAnswer> {
    return this.newslinePostVoteAnswerHttpService.getAnswerById(id);
  }

  createAnswerAndAddToVote(
    answerToCreate: Partial<INewslinePostVoteAnswer>,
    targetVote?: INewslinePostVote,
  ): Observable<INewslinePostVoteAnswer> {
    return this.newslinePostVoteAnswerHttpService.createAnswer(answerToCreate).pipe(
      tap((createdAnswer) => {
        if (targetVote) {
          if (!targetVote.answers?.length) {
            targetVote.answers = [];
          }
          targetVote.answers.push(createdAnswer);
        }
      }),
    );
  }

  updateAnswerByIdAndReplaceInVote(
    answerId: number,
    answerDto: Partial<INewslinePostVoteAnswer>,
    targetVote?: INewslinePostVote,
  ): Observable<INewslinePostVoteAnswer> {
    return this.newslinePostVoteAnswerHttpService.updateAnswerById(answerId, answerDto).pipe(
      tap((updatedAnswer) => {
        if (targetVote) {
          const targetAnswerIdx = targetVote.answers?.findIndex((answer) => answer.id === updatedAnswer.id);
          if (targetAnswerIdx !== -1) {
            targetVote.answers[targetAnswerIdx] = updatedAnswer;
          }
        }
      }),
    );
  }

  deleteAnswerByIdAndRemoveFromVote(answerId: number, targetVote?: INewslinePostVote): Observable<void> {
    const answerToDeleteIdx = targetVote?.answers?.findIndex((a) => a.id === answerId);
    if (answerToDeleteIdx !== -1 && targetVote) {
      targetVote.answers[answerToDeleteIdx].isDeleting = true;
    }

    return this.newslinePostVoteAnswerHttpService.deleteAnswerById(answerId).pipe(
      tap(
        () => {
          if (targetVote?.answers) {
            targetVote.answers = targetVote.answers.filter((a) => a.id !== answerId);
          }
        },
        () => {
          if (answerToDeleteIdx !== -1 && targetVote) {
            targetVote.answers[answerToDeleteIdx].isDeleting = true;
          }
        },
      ),
    );
  }

  voteByAnswerIdAndReplaceInVote(answerId: number, targetVote?: INewslinePostVote): Observable<void> {
    return this.newslinePostVoteAnswerHttpService.voteByAnswerId(answerId).pipe(
      mergeMap((response) => {
        if (targetVote) {
          let answersRequest: Observable<INewslinePostVoteAnswer[]>;

          const targetAnswerIdx = targetVote.answers?.findIndex((answer) => answer.id === answerId);
          if (targetAnswerIdx !== -1) {
            targetVote.is_voted = true;

            if (!targetVote.is_results_hidden) {
              targetVote.answers[targetAnswerIdx].is_voted = true;
              targetVote.answers[targetAnswerIdx].voted_users.push(this.authService.currentUser?.id);
              targetVote.answers[targetAnswerIdx].votes_count += 1;
            } else {
              answersRequest = this.getAnswersByVoteIdAndAddToVote(targetVote.id, targetVote);
            }
          }

          return answersRequest ? answersRequest.pipe(map(() => response)) : of(response);
        }
        return of(response);
      }),
    );
  }
}
