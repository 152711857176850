import { SelectOptionActionType } from '@shared/enums/select/select-option-action-type.enum';
import { ISelectOption } from '@shared/models/select.model';
import { ForumInListMenuOptionViewName, ForumSettingsMenuOptionViewName } from '../enums/view-names.enum';
import { ForumRelatedFieldKey, ForumStateKey, ForumsTypeKey } from '../enums/keys.enum';
import {
  COMMON_FORUMS_TABLE_MEMBER_COLUMNS_KEYS,
  COMMON_FORUMS_TABLE_STAFF_COLUMNS_KEYS,
  FORUMS_TABLE_COLUMNS,
  INDUSTRY_FORUMS_TABLE_MEMBER_COLUMNS_KEYS,
  INDUSTRY_FORUMS_TABLE_STAFF_COLUMNS_KEYS,
} from './common.const';
import { FORUMS_TYPE_KEY_TO_VIEW_NAME, FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME, FORUM_STATE_KEY_TO_VIEW_NAME } from './mappers.const';
import { FILTER_RULE_OPTIONS } from '@shared/constants/selects-options.const';
import { FilterValueInputType } from '@shared/enums/filter/filter-value-input-type.enum';
import { Validators } from '@angular/forms';
import { OptionSourceKey } from '@shared/enums/select/select-source.enum';
import { ConfimationMessage } from '@shared/enums/keys.enum';

export const FORUM_TYPE_OPTIONS: ISelectOption[] = [
  {
    viewName: FORUMS_TYPE_KEY_TO_VIEW_NAME[ForumsTypeKey.MyForumsStaff],
    filterField: ForumRelatedFieldKey.Administrator,
    filterGroupValue: ForumsTypeKey.MyForumsStaff,
  },
  {
    viewName: FORUMS_TYPE_KEY_TO_VIEW_NAME[ForumsTypeKey.MyForumsMember],
    filterField: ForumRelatedFieldKey.User,
    filterGroupValue: ForumsTypeKey.MyForumsMember,
  },
  {
    viewName: FORUMS_TYPE_KEY_TO_VIEW_NAME[ForumsTypeKey.Finished],
    filterField: ForumRelatedFieldKey.State,
    filterValue: ForumStateKey.Finished,
    filterGroupValue: ForumsTypeKey.Finished,
  },
  {
    viewName: FORUMS_TYPE_KEY_TO_VIEW_NAME[ForumsTypeKey.Published],
    filterField: ForumRelatedFieldKey.State,
    filterValue: ForumStateKey.Published,
    filterGroupValue: ForumsTypeKey.Published,
  },
  {
    viewName: FORUMS_TYPE_KEY_TO_VIEW_NAME[ForumsTypeKey.AllForums],
    filterGroupValue: ForumsTypeKey.AllForums,
  },
].map((option: ISelectOption) => {
  option.filterIsNotUnique = true;
  option.filterGroupField = ForumRelatedFieldKey.ForumType;
  return option;
});

export const FORUM_TYPE_OPTIONS_STAFF = FORUM_TYPE_OPTIONS.filter((o) => o.filterField !== ForumRelatedFieldKey.User);
export const FORUM_TYPE_OPTIONS_MEMBER = FORUM_TYPE_OPTIONS.filter(
  (o) => o.filterGroupValue === ForumsTypeKey.MyForumsMember || o.filterGroupValue === ForumsTypeKey.AllForums,
);

const FORUM_IN_LIST_MENU_OPTIONS: ISelectOption[] = [
  {
    viewName: ForumInListMenuOptionViewName.Open,
    iconKey: 'arrowUpRightSlim',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: ForumInListMenuOptionViewName.Copy,
    iconKey: 'copy',
    actionType: SelectOptionActionType.Action,
  },
];

export const DRAFT_FORUM_IN_LIST_MENU_OPTIONS_ADMIN = FORUM_IN_LIST_MENU_OPTIONS;
export const NOT_DRAFT_FORUM_IN_LIST_MENU_OPTIONS_STAFF = FORUM_IN_LIST_MENU_OPTIONS;
export const FORUM_IN_LIST_MENU_OPTIONS_MEMBER = FORUM_IN_LIST_MENU_OPTIONS.filter(
  (o) => o.viewName === ForumInListMenuOptionViewName.Open,
);

export const DRAFT_FORUM_MENU_OPTIONS = [
  {
    viewName: ForumSettingsMenuOptionViewName.Start,
    iconKey: 'publishIcon',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.PublishForum,
  },
  {
    viewName: ForumSettingsMenuOptionViewName.Cancel,
    iconKey: 'xCircle',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.CancelForum,
  },
];

export const CANCELLED_FORUM_MENU_OPTIONS = [
  {
    viewName: ForumSettingsMenuOptionViewName.Start,
    iconKey: 'publishIcon',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.PublishForum,
  },
];

export const PUBLISHED_FORUM_MENU_OPTIONS = [
  {
    viewName: ForumSettingsMenuOptionViewName.End,
    iconKey: 'checkSquareIcon',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.FinishForum,
  },
  {
    viewName: ForumSettingsMenuOptionViewName.Cancel,
    iconKey: 'xCircle',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.CancelForum,
  },
];

export const COMMON_FORUMS_TABLE_STAFF_COLUMNS_OPTIONS: ISelectOption[] = FORUMS_TABLE_COLUMNS.filter((col) =>
  COMMON_FORUMS_TABLE_STAFF_COLUMNS_KEYS.includes(col.headerKey as ForumRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const INDUSTRY_FORUMS_TABLE_STAFF_COLUMNS_OPTIONS: ISelectOption[] = FORUMS_TABLE_COLUMNS.filter((col) =>
  INDUSTRY_FORUMS_TABLE_STAFF_COLUMNS_KEYS.includes(col.headerKey as ForumRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const COMMON_FORUMS_TABLE_MEMBER_COLUMNS_OPTIONS: ISelectOption[] = FORUMS_TABLE_COLUMNS.filter((col) =>
  COMMON_FORUMS_TABLE_MEMBER_COLUMNS_KEYS.includes(col.headerKey as ForumRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const INDUSTRY_FORUMS_TABLE_MEMBER_COLUMNS_OPTIONS: ISelectOption[] = FORUMS_TABLE_COLUMNS.filter((col) =>
  INDUSTRY_FORUMS_TABLE_MEMBER_COLUMNS_KEYS.includes(col.headerKey as ForumRelatedFieldKey),
).map((col) => ({
  key: col.headerKey,
  viewName: col.headerName,
  isRequired: col.isRequired,
}));

export const FORUM_STATE_OPTIONS: ISelectOption[] = [
  {
    viewName: FORUM_STATE_KEY_TO_VIEW_NAME[ForumStateKey.Draft],
    key: ForumStateKey.Draft,
  },
  {
    viewName: FORUM_STATE_KEY_TO_VIEW_NAME[ForumStateKey.Published],
    key: ForumStateKey.Published,
  },
  {
    viewName: FORUM_STATE_KEY_TO_VIEW_NAME[ForumStateKey.Cancelled],
    key: ForumStateKey.Cancelled,
  },
  {
    viewName: FORUM_STATE_KEY_TO_VIEW_NAME[ForumStateKey.Finished],
    key: ForumStateKey.Finished,
  },
];

const FORUMS_MODAL_FILTERS_OPTIONS: ISelectOption[] = [
  // Номер
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.UniqueBranchNumber],
    filterField: ForumRelatedFieldKey.UniqueBranchNumber,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },

  // Локация (филиал)
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Branch],
    filterField: ForumRelatedFieldKey.Branch,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.ForumLocations,
  },

  // Название
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.NameFromDialog],
    filterField: ForumRelatedFieldKey.NameFromDialog,
    filterFieldIsNotForApi: true,
    filterAdditionalFields: [ForumRelatedFieldKey.Name],
    filterAdditionalFieldsIsNotForUrl: true,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },

  // Последний запуск
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.LastLaunchStartedAt],
    filterField: ForumRelatedFieldKey.LastLaunchStartedAt,
    filterValueInputType: FilterValueInputType.Date,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },

  // Возраст форума
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.ForumYear],
    filterField: ForumRelatedFieldKey.ForumYear,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
    filterValueValidators: [Validators.min(1), Validators.max(10)],
  },

  // Участники
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.User],
    filterField: ForumRelatedFieldKey.UserFromDialog,
    filterFieldIsNotForApi: true,
    filterAdditionalFields: [ForumRelatedFieldKey.User],
    filterAdditionalFieldsIsNotForUrl: true,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.Users,
  },

  // Состояние
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.StateFromDialog],
    filterField: ForumRelatedFieldKey.StateFromDialog,
    filterFieldIsNotForApi: true,
    filterAdditionalFields: [ForumRelatedFieldKey.State],
    filterAdditionalFieldsIsNotForUrl: true,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptions: FORUM_STATE_OPTIONS,
  },

  // Администратор
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.AdministratorFromDialog],
    filterField: ForumRelatedFieldKey.AdministratorFromDialog,
    filterFieldIsNotForApi: true,
    filterAdditionalFields: [ForumRelatedFieldKey.Administrator],
    filterAdditionalFieldsIsNotForUrl: true,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.StaffMembers,
  },

  // Осталось встреч
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.MeetingsLeftCount],
    filterField: ForumRelatedFieldKey.MeetingsLeftCount,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
    filterValueValidators: [Validators.min(1), Validators.max(10)],
  },

  // Осталось месяцев
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.MonthsLeft],
    filterField: ForumRelatedFieldKey.MonthsLeft,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
    filterValueValidators: [Validators.min(1), Validators.max(12)],
  },

  // Запуск
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.StartedAt],
    filterField: ForumRelatedFieldKey.StartedAt,
    filterValueInputType: FilterValueInputType.Date,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },

  // Число участников
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.MembersCount],
    filterField: ForumRelatedFieldKey.MembersCount,
    filterValueInputType: FilterValueInputType.Number,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },

  // Создал
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Creator],
    filterField: ForumRelatedFieldKey.Creator,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.StaffMembers,
  },

  // Изменил
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Editors],
    filterField: ForumRelatedFieldKey.Editors,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.StaffMembers,
  },

  // Дата создания
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Created],
    filterField: ForumRelatedFieldKey.Created,
    filterValueInputType: FilterValueInputType.Date,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },

  // Дата изменения
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Updated],
    filterField: ForumRelatedFieldKey.Updated,
    filterValueInputType: FilterValueInputType.Date,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },

  // Модератор
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Moderator],
    filterField: ForumRelatedFieldKey.Moderator,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
  },

  // Завершение
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.FinishedAt],
    filterField: ForumRelatedFieldKey.FinishedAt,
    filterValueInputType: FilterValueInputType.Date,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal, FILTER_RULE_OPTIONS.gte, FILTER_RULE_OPTIONS.lte],
  },

  // Куратор
  {
    viewName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Curator],
    filterField: ForumRelatedFieldKey.Curator,
    filterValueInputType: FilterValueInputType.Select,
    filterAvailableRulesOptions: [FILTER_RULE_OPTIONS.equal],
    filterValueOptionsSourceKey: OptionSourceKey.StaffMembers,
  },
].map((option: ISelectOption) => {
  option.filterIsNotUnique = true;
  option.filterIsDialogParent = true;
  return option;
});

/**
 * Ключи опций-фильтров для диалогового окна для списка ОБЫЧНЫХ форумов для роли "СОТРУДНИК"
 */
const COMMON_FORUMS_FILTERS_KEYS_STAFF: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.UniqueBranchNumber,
  ForumRelatedFieldKey.Branch,
  ForumRelatedFieldKey.NameFromDialog,
  ForumRelatedFieldKey.LastLaunchStartedAt,
  ForumRelatedFieldKey.ForumYear,
  ForumRelatedFieldKey.UserFromDialog,
  ForumRelatedFieldKey.StateFromDialog,
  ForumRelatedFieldKey.AdministratorFromDialog,
  ForumRelatedFieldKey.MeetingsLeftCount,
  ForumRelatedFieldKey.MonthsLeft,
  ForumRelatedFieldKey.StartedAt,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.Creator,
  ForumRelatedFieldKey.Editors,
  ForumRelatedFieldKey.Created,
  ForumRelatedFieldKey.Updated,
  ForumRelatedFieldKey.Moderator,
  ForumRelatedFieldKey.FinishedAt,
  ForumRelatedFieldKey.Curator,
];

/**
 * Список опций-фильтров для диалогового окна для списка ОБЫЧНЫХ форумов для роли "СОТРУДНИК"
 */
export const COMMON_FORUMS_MODAL_FILTERS_OPTIONS_STAFF: ISelectOption[] = FORUMS_MODAL_FILTERS_OPTIONS.filter((option) =>
  COMMON_FORUMS_FILTERS_KEYS_STAFF.includes(option.filterField as ForumRelatedFieldKey),
);

/**
 * Ключи опций-фильтров диалогового окна для списка ОТРАСЛЕВЫХ форумов для роли "СОТРУДНИК"
 */
const INDUSTRY_FORUMS_FILTERS_KEYS_STAFF: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.UniqueBranchNumber,
  ForumRelatedFieldKey.Branch,
  ForumRelatedFieldKey.NameFromDialog,
  ForumRelatedFieldKey.ForumYear,
  ForumRelatedFieldKey.UserFromDialog,
  ForumRelatedFieldKey.StateFromDialog,
  ForumRelatedFieldKey.AdministratorFromDialog,
  ForumRelatedFieldKey.StartedAt,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.Creator,
  ForumRelatedFieldKey.Editors,
  ForumRelatedFieldKey.Created,
  ForumRelatedFieldKey.Updated,
  ForumRelatedFieldKey.FinishedAt,
];

/**
 * Список опций для диалогового окна с фильтрами для списка ОТРАСЛЕВЫХ форумов для роли "СОТРУДНИК"
 */
export const INDUSTRY_FORUMS_MODAL_FILTERS_OPTIONS_STAFF: ISelectOption[] = FORUMS_MODAL_FILTERS_OPTIONS.filter((option) =>
  INDUSTRY_FORUMS_FILTERS_KEYS_STAFF.includes(option.filterField as ForumRelatedFieldKey),
);

/**
 * Ключи опций для диалогового окна с фильтрами для списка ОБЫЧНЫХ форумов для роли "УЧАСТНИК"
 */
const COMMON_FILTERS_KEYS_MEMBER: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.UniqueBranchNumber,
  ForumRelatedFieldKey.Branch,
  ForumRelatedFieldKey.NameFromDialog,
  ForumRelatedFieldKey.LastLaunchStartedAt,
  ForumRelatedFieldKey.ForumYear,
  ForumRelatedFieldKey.UserFromDialog,
  ForumRelatedFieldKey.AdministratorFromDialog,
  ForumRelatedFieldKey.MeetingsLeftCount,
  ForumRelatedFieldKey.StartedAt,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.Moderator,
  ForumRelatedFieldKey.FinishedAt,
  ForumRelatedFieldKey.Curator,
];

/**
 * Список опций для диалогового окна с фильтрами для списка ОБЫЧНЫХ форумов для роли "УЧАСТНИК"
 */
export const COMMON_FORUMS_MODAL_FILTERS_OPTIONS_MEMBER: ISelectOption[] = FORUMS_MODAL_FILTERS_OPTIONS.filter((option) =>
  COMMON_FILTERS_KEYS_MEMBER.includes(option.filterField as ForumRelatedFieldKey),
);

/**
 * Ключи опций диалогового окна с фильтрами для списка ОТРАСЛЕВЫХ форумов для роли "УЧАСТНИК"
 */
const INDUSTRY_FORUMS_FILTERS_KEYS_MEMBER: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.UniqueBranchNumber,
  ForumRelatedFieldKey.Branch,
  ForumRelatedFieldKey.NameFromDialog,
  ForumRelatedFieldKey.ForumYear,
  ForumRelatedFieldKey.UserFromDialog,
  ForumRelatedFieldKey.AdministratorFromDialog,
  ForumRelatedFieldKey.StartedAt,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.FinishedAt,
];

/**
 * Список опций для диалогового окна с фильтрами для списка ОТРАСЛЕВЫХ форумов для роли "УЧАСТНИК"
 */
export const INDUSTRY_FORUMS_MODAL_FILTERS_OPTIONS_MEMBER: ISelectOption[] = FORUMS_MODAL_FILTERS_OPTIONS.filter((option) =>
  INDUSTRY_FORUMS_FILTERS_KEYS_MEMBER.includes(option.filterField as ForumRelatedFieldKey),
);
