import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {DomService} from '@core/services/business/utils/dom.service';
import {ElementPositionService} from '@core/services/business/utils/element-position.service';
import {NavigationService} from '@core/services/business/utils/navigation.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ElementPostionKey} from '@shared/enums/keys.enum';
import {Subject, SubscriptionLike} from 'rxjs';

/**
 * Удаляет `switchElementRef` из DOM дерева при инициализации.
 *
 * Добавляет его рядом с хостом при клике по оному. Смотреть примеры в коде.
 */
@UntilDestroy()
@Directive({
  selector: '[kpSwitchElement]',
})
export class SwitchElementDirective implements OnInit, OnChanges, OnDestroy {
  /**
   * Целевой элемент для добавления/удаления.
   */
  @Input('kpSwitchElement') switchElementRef: ElementRef<Element>;

  /**
   * Удалять ли элемент при любом срколле на странице.
   */
  @Input() removeOnScroll = true;

  /**
   * Удалять ли элемент при навигации.
   */
  @Input() closeOnNavigation = true;

  /**
   * Состояние свитчера.
   */
  @Input('switcherDisabled') disabled = false;

  /**
   * Триггер для удаления элемента снаружи.
   */
  @Input() closeTrigger: boolean;

  /**
   * Позиция добавляемого элемента по умолчанию.
   */
  @Input() position = ElementPostionKey.BottomRight;
  @Input() externalTrigger$: Subject<void>;

  @Output() elementOpen = new EventEmitter<void>();
  @Output() elementOpened = new EventEmitter<void>();
  @Output() elementClose = new EventEmitter<void>();
  @Output() elementClosed = new EventEmitter<void>();

  navListenerSub: SubscriptionLike;
  clickListenerSub: SubscriptionLike;
  scrollListenerSub: SubscriptionLike;
  isActive = false;

  readonly addAnimationFromInitState: Keyframe[] = [{ opacity: 0 }, { opacity: 1 }];

  constructor(
    private elementRef: ElementRef<Element>,
    private domService: DomService,
    private navigationService: NavigationService,
    private elementPositionService: ElementPositionService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.disabled) {
      return;
    }

    if (changes.switchElementRef?.currentValue) {
      this._initActions();
    }

    if (changes.closeTrigger?.currentValue === false && this.isActive) {
      this._removeElement();
    }
  }

  public ngOnInit(): void {
    if (this.externalTrigger$) {
      this._handleExternalTrigger();
    }
  }

  ngOnDestroy() {
    if (this.disabled) {
      return;
    }

    this._removeElement();
  }

  private _handleExternalTrigger(): void {
    this.externalTrigger$.pipe(untilDestroyed(this)).subscribe(() => this._onHostClick());
  }

  private _initActions() {
    this.switchElementRef.nativeElement.remove();
    this.domService.renderer.setStyle(this.switchElementRef.nativeElement, 'position', 'fixed');
  }

  @HostListener('click')
  private _onHostClick() {
    if (this.disabled) {
      return;
    }

    this._switch();
  }

  private _switch() {
    if (this.isActive) {
      this._removeElement();
    } else {
      this._addElement();
    }
  }

  private _addElement() {
    this.elementOpen.emit();

    this._animateContainer(this.addAnimationFromInitState);

    this.domService.renderer.appendChild(this.domService.body, this.switchElementRef.nativeElement);
    this.elementPositionService.setElementPositionRelativeToParent(
      this.switchElementRef.nativeElement,
      this.position,
      this.elementRef.nativeElement,
    );

    this.isActive = true;
    this.elementOpened.emit();

    this._onOutsideClick();
    this._onNavigation();

    if (this.removeOnScroll) {
      this._onScroll();
    }
  }

  private _removeElement() {
    if (!this.domService.isElementHasTarget(this.switchElementRef.nativeElement, this.domService.body)) {
      return;
    }

    this.elementClose.emit();

    this.clickListenerSub?.unsubscribe();
    this.scrollListenerSub?.unsubscribe();
    this.navListenerSub?.unsubscribe();

    this.domService.renderer.removeChild(this.domService.body, this.switchElementRef.nativeElement);

    this.isActive = false;
    this.elementClosed.emit();
  }

  private _animateContainer(keyframes: Keyframe[]) {
    this.switchElementRef.nativeElement.animate(keyframes, {
      duration: 200,
      delay: 0,
      easing: 'linear',
    });
  }

  private _onOutsideClick() {
    this.clickListenerSub?.unsubscribe();
    this.clickListenerSub = this.domService.clickOnDocument$.pipe(untilDestroyed(this)).subscribe((clickEvent) => {
      const isClickInsideSwitchElement = this.domService.isClickInsideTargetElement(
        clickEvent,
        this.switchElementRef.nativeElement,
      );
      const isClickInsideHost = this.domService.isClickInsideTargetElement(clickEvent, this.elementRef.nativeElement);

      if (!isClickInsideSwitchElement && !isClickInsideHost) {
        this._removeElement();
      }
    });
  }

  private _onScroll() {
    this.scrollListenerSub?.unsubscribe();
    this.scrollListenerSub = this.domService.scrollOnDocument$.pipe(untilDestroyed(this)).subscribe((event) => {
      const isScrollInsideSwitchElement =
        event instanceof Event && this.domService.isElementHasTarget(event.target, this.switchElementRef.nativeElement);

      if (!isScrollInsideSwitchElement) {
        this._removeElement();
      }
    });
  }

  private _onNavigation() {
    this.navListenerSub?.unsubscribe();
    this.navListenerSub = this.navigationService.navigationEvent$.pipe(untilDestroyed(this)).subscribe(() => {
      this._removeElement();
    });
  }
}
