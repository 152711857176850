import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@core/services/business/utils/navigation.service';
import { IN_ANIMATION } from '@shared/animations/in-out.animation';

@Component({
  selector: 'kp-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  animations: [IN_ANIMATION],
})
export class PageTitleComponent {
  /**
   * Включена ли навигация к родительской странице
   */
  @Input() navigationEnabled = false;

  /**
   * Путь для навигации к родительской странице
   */
  @Input() parentPath: string;

  /**
   * Отображаемый текст
   */
  @Input() title: string;

  /**
   * Текст подсказки при наведении на число итемов
   */
  @Input() itemsCountTooltip: string;

  /**
   * Число итемов на странице
   */
  @Input() itemsCount: number;

  /**
   * Показывать число итемов, если их количество равно нулю
   */
  @Input() showZero: boolean;

  /**
   * Переход на страницу
   */
  @Input() link: string;

  /**
   * Подвинуть стрелку перехода назад для страницы бронирования
   */
  @Input() isRowAbsolutePosition: boolean;

  @Input() arrowDescription: string;

  
  /**
   * Размер заголовка в px 
   */
  @Input() fontSizeTitle: string;

   /**
   * Объект стилей для итемов 
   */
  @Input() itemsStyle: any = {color: 'red'};

  constructor(private navigationService: NavigationService, private activatedRoute: ActivatedRoute) {}

  navigateToPreviousPage() {
    this.navigationService.back(this.activatedRoute, this.parentPath);
  }
}
