export enum FilterRuleSeparator {
  Url = '___',
  ApiSingleUnderscore = '_',
  ApiDoubleUnderscore = '__',
}

export enum FilterSeparator {
  FiltersQueryIndicator = 'f',
  DifferentFiltersSeparatorUrl = '┆',
  DifferentFiltersSeparatorApi = '&',
  SameFiltersSeparatorUrl = '•',
  FilterValuesStartUrl = '<',
  FilterValuesEndUrl = '>',
  ArrayItemsSeparatorUrl = '¦',
  DescendingOrderingKey = '-',
}
