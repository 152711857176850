import { DatePeriodKey, UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { FilterRule } from '@shared/enums/filter/filter-rule.enum';

export const FILTER_RULE_TO_VIEW_NAME: Partial<Record<FilterRule, string>> = {
  [FilterRule.Equal]: 'Равно',
  [FilterRule.GTE]: 'Больше или равно',
  [FilterRule.LTE]: 'Меньше или равно',
  [FilterRule.After]: 'После',
  [FilterRule.Before]: 'До',
};

export const DATE_PERIOD_KEY_TO_VIEW_NAME: Partial<Record<DatePeriodKey, string>> = {
  [DatePeriodKey.Today]: 'Сегодня',
  [DatePeriodKey.LastWeek]: 'Последняя неделя',
  [DatePeriodKey.LastMonth]: 'Последний месяц',
  [DatePeriodKey.LastTwoMonths]: 'Последние два месяца',
  [DatePeriodKey.LastThreeMonths]: 'Последние три месяца',
  [DatePeriodKey.AllTime]: 'Все время',
  [DatePeriodKey.Custom]: '',
};

export const USER_RELATED_FIELD_KEY_TO_VIEW_NAME: Partial<Record<UserRelatedFieldKey, string>> = {
  [UserRelatedFieldKey.Admin]: 'Администратор',
  [UserRelatedFieldKey.Avatar]: 'Аватар',
  [UserRelatedFieldKey.BusinessExperience]: 'Бизнес-опыт',
  [UserRelatedFieldKey.BusinessTurnover]: 'Оборот бизнеса',
  [UserRelatedFieldKey.Company]: 'Компания',
  [UserRelatedFieldKey.DateOfBirthday]: 'День рождения',
  [UserRelatedFieldKey.Education]: 'Образование',
  [UserRelatedFieldKey.Email]: 'Email',
  [UserRelatedFieldKey.EndMembershipDate]: 'Дата окончания членства',
  [UserRelatedFieldKey.FirstName]: 'Имя',
  [UserRelatedFieldKey.FormOfParticipation]: 'Форма участия',
  [UserRelatedFieldKey.FullName]: 'Имя',
  [UserRelatedFieldKey.Industries]: 'Отрасли',
  [UserRelatedFieldKey.Interests]: 'Личные интересы',
  [UserRelatedFieldKey.MeetingCounter]: 'Счётчик встреч',
  [UserRelatedFieldKey.MiddleName]: 'Отчество',
  [UserRelatedFieldKey.CanBeUseful]: 'Ресурсы',
  [UserRelatedFieldKey.Requests]: 'Запросы',
  [UserRelatedFieldKey.Sex]: 'Пол',
  [UserRelatedFieldKey.StartMembershipDate]: 'Дата начала членства',
  [UserRelatedFieldKey.StatusInClub]: 'Статус в клубе',
  [UserRelatedFieldKey.LastName]: 'Фамилия',
  [UserRelatedFieldKey.YearsOfMembership]: 'Годы членства',
};
