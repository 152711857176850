import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterDialogService } from '@core/services/business/utils/filter-dialog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IN_ANIMATION } from '@shared/animations/in-out.animation';
import { FilterDialogComponent } from '@shared/dialogs/filter-dialog/filter-dialog.component';
import { IFilter } from '@shared/models/filter/filter.model';
import { ISelectOption } from '@shared/models/select.model';
import { BaseClass } from '../base/base.class';

@UntilDestroy()
@Component({
  selector: 'kp-filter-dialog-controller',
  templateUrl: './filter-dialog-controller.component.html',
  styleUrls: ['./filter-dialog-controller.component.scss'],
  animations: [IN_ANIMATION],
})
export class FilterDialogControllerComponent extends BaseClass implements OnChanges {
  @Input() filtersOptions: ISelectOption[];
  @Output() submittedFiltersEvent = new EventEmitter<IFilter[]>();

  constructor(private dialog: MatDialog, public filterDialogService: FilterDialogService) {
    super();
    this.filterDialogService.filtersOptions = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filtersOptions) {
      this.filterDialogService.filtersOptions = changes.filtersOptions.currentValue;
    }
  }

  openFilterDialog() {
    this.dialogRefSubs.unsubscribe();
    if (!this.filtersOptions) {
      return;
    }

    this.dialogRefSubs.sink = this.dialog
      .open(FilterDialogComponent)
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.submittedFiltersEvent.emit(data.filters);
        }
      });
  }

  resetFilters() {
    this.filterDialogService.resetFilters();
    this.submittedFiltersEvent.emit([]);
  }
}
