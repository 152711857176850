import { AbstractControl, ValidatorFn } from "@angular/forms";

export const customError = 'customError';

export function customErrors(validator: ValidatorFn, errorMessage: string): ValidatorFn {
  return (control: AbstractControl) => {
    const error = validator(control);

    if (!error) {
      return null
    }

    return { [customError]: errorMessage };
  }
}