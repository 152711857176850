import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  SecurityContext,
} from '@angular/core';
import icons from 'src/assets/svg/svg-icons';
import { KeyValue } from '@shared/models/key-value.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import svgIcons from '../../../../assets/svg/svg-icons';

@Component({
  selector: 'kp-svg',
  template: ` <div #svgContainer class="svg-container" [innerHTML]="svgHtml" [ngStyle]="{ transform: this.rotate }"></div>`,
  encapsulation: ViewEncapsulation.None,
})
export class SvgComponent implements OnChanges {
  @ViewChild('svgContainer', { static: true }) svgContainer: ElementRef;

  @Input() key: keyof typeof svgIcons | string;
  @Input() color: string;
  @Input() colorOnHover: string;
  @Input() height: number | string;
  @Input() width: number | string;
  @Input() size: number | string;
  @Input() cursor: string;
  @Input() rotateAngle: string;

  svgHtml: SafeHtml;

  constructor(private renderer: Renderer2, private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.key) {
      this._injectSvg();
    }

    if (changes.size) {
      this.height = changes.size.currentValue;
      this.width = changes.size.currentValue;
    } else {
      this.height = changes.height?.currentValue;
      this.width = changes.width?.currentValue;
    }

    this._setStylesForSvgContainer(changes.color?.currentValue, this.height, this.width, changes.cursor?.currentValue);
  }

  private _setStylesForSvgContainer(color: string, height: number | string, width: number | string, cursor: string) {
    if (cursor) {
      this.renderer.setStyle(this.svgContainer.nativeElement, 'cursor', cursor);
    }
    if (color) {
      this.renderer.setStyle(this.svgContainer.nativeElement, 'color', color);
    }
    if (height || height === 0) {
      this.renderer.setStyle(this.svgContainer.nativeElement, 'height', height);
    }
    if (width || width === 0) {
      this.renderer.setStyle(this.svgContainer.nativeElement, 'width', width);
    }
  }

  private _injectSvg() {
    this.svgHtml = this.sanitizer.bypassSecurityTrustHtml(
      this.sanitizer.sanitize(SecurityContext.NONE, (icons as KeyValue<string>)[this.key]) as string,
    );
  }

  @HostListener('mouseenter')
  onMouseOver() {
    if (this.colorOnHover) {
      this.renderer.setStyle(this.svgContainer.nativeElement, 'color', this.colorOnHover);
    }
  }

  @HostListener('mouseleave')
  onMouseOut() {
    if (this.colorOnHover) {
      this.renderer.setStyle(this.svgContainer.nativeElement, 'color', this.color ? this.color : 'inherit');
    }
  }

  get rotate(): string {
    return `rotate(${this.rotateAngle})`;
  }
}
