import { Component, Input } from '@angular/core';

@Component({
  selector: 'kp-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent {
  @Input() iconKey = 'sadFace';
  @Input() iconSize = '29px';
  @Input() text = 'Извините, ничего не найдено';
  @Input() paddingY = '40px';
  @Input() iconTextGap = '24px';
  @Input() fontSize = '18px';
  @Input() fontWeight = '700';
  @Input() color: string;
  @Input() textColor: string;
  @Input() iconColor: string;
}
