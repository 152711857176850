<div class="description-container" *ngIf="!isMeeting; else meetingLayout">
  <div
    class="default-container__header name-value-grid__value name-value-grid__value--normal description-container__header"
  >
    Описание
  </div>
  <quill-view class="description-container__content quill-view" [content]="description" format="html"></quill-view>
</div>

<ng-template #meetingLayout>
  <div class="description-container">
    <div
      class="default-container__header name-value-grid__value name-value-grid__value--normal description-container__header"
    >
      Описание
    </div>

    <quill-view class="description-container__content quill-view" [content]="description" format="html"></quill-view>
  </div>
</ng-template>
