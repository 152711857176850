import { DATE_PERIOD_KEY_TO_VIEW_NAME } from '@shared/constants/mappers.const';
import { ConfimationMessage, DatePeriodKey } from '@shared/enums/keys.enum';
import { SelectOptionActionType } from '@shared/enums/select/select-option-action-type.enum';
import { ISelectOption } from '@shared/models/select.model';
import { PostFilterField, PostTypeKey } from '../enums/keys.enum';
import { PostAttachmentMenuOptionViewName, PostMenuOptionViewName } from '../enums/view-names.enum';
import { POST_TYPE_KEY_TO_VIEW_NAME } from './mappers.const';

export const POST_ATTACHMENT_MENU_OPTIONS: ISelectOption[] = [
  {
    viewName: PostAttachmentMenuOptionViewName.Image,
    iconKey: 'image',
    actionType: SelectOptionActionType.FileInput,
    filesMymeType: 'image/*',
  },
  {
    viewName: PostAttachmentMenuOptionViewName.Document,
    iconKey: 'fileText',
    actionType: SelectOptionActionType.FileInput,
    filesMymeType: 'application/*',
  },
  {
    viewName: PostAttachmentMenuOptionViewName.Vote,
    iconKey: 'barChart',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: PostAttachmentMenuOptionViewName.Geo,
    iconKey: 'mapPin',
    actionType: SelectOptionActionType.Action,
  },
];

export const POST_COMMENT_ATTACHMENT_MENU_OPTIONS: ISelectOption[] = POST_ATTACHMENT_MENU_OPTIONS.filter(
  (option) => option.viewName !== PostAttachmentMenuOptionViewName.Vote,
);

const POST_MENU_OPTIONS: ISelectOption[] = [
  {
    viewName: PostMenuOptionViewName.Delete,
    iconKey: 'delete',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.DeletePost,
  },
  {
    viewName: PostMenuOptionViewName.Edit,
    iconKey: 'editPost',
    actionType: SelectOptionActionType.Action,
  },
  {
    viewName: PostMenuOptionViewName.Pin,
    iconKey: 'pin',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.PinPost,
  },
  {
    viewName: PostMenuOptionViewName.Unpin,
    iconKey: 'pin',
    actionType: SelectOptionActionType.Action,
    confirmActionText: ConfimationMessage.UnpinPost,
  },
];

export const DEFAULT_POST_MENU_OPTIONS = POST_MENU_OPTIONS.filter((option) => option.viewName !== PostMenuOptionViewName.Unpin);

export const PINNED_POST_MENU_OPTIONS = POST_MENU_OPTIONS.filter((option) => option.viewName !== PostMenuOptionViewName.Pin);

export const POST_PUBLICATION_PERIOD_OPTIONS: ISelectOption[] = [
  {
    viewName: DATE_PERIOD_KEY_TO_VIEW_NAME[DatePeriodKey.Today],
    filterValue: DatePeriodKey.Today,
  },
  {
    viewName: DATE_PERIOD_KEY_TO_VIEW_NAME[DatePeriodKey.LastWeek],
    filterValue: DatePeriodKey.LastWeek,
  },
  {
    viewName: DATE_PERIOD_KEY_TO_VIEW_NAME[DatePeriodKey.LastMonth],
    filterValue: DatePeriodKey.LastMonth,
  },
  {
    viewName: DATE_PERIOD_KEY_TO_VIEW_NAME[DatePeriodKey.AllTime],
    filterValue: DatePeriodKey.AllTime,
  },
].map((option: ISelectOption) => {
  option.filterField = PostFilterField.CreatedPeriodKey;
  option.filterFieldIsNotForApi = true;
  option.filterChildrenFiltersIsNotForUrl = true;
  return option;
});

export const POST_TYPE_OPTIONS: ISelectOption[] = [
  {
    filterValue: PostTypeKey.Members,
    key: PostTypeKey.Members,
    viewName: POST_TYPE_KEY_TO_VIEW_NAME[PostTypeKey.Members],
  },
  {
    filterValue: PostTypeKey.Events,
    key: PostTypeKey.Events,
    viewName: POST_TYPE_KEY_TO_VIEW_NAME[PostTypeKey.Events],
  },
  {
    filterValue: PostTypeKey.Forums,
    key: PostTypeKey.Forums,
    viewName: POST_TYPE_KEY_TO_VIEW_NAME[PostTypeKey.Forums],
  },
  {
    filterValue: PostTypeKey.KnowledgeManagment,
    key: PostTypeKey.KnowledgeManagment,
    viewName: POST_TYPE_KEY_TO_VIEW_NAME[PostTypeKey.KnowledgeManagment],
  },
  {
    filterValue: PostTypeKey.Projects,
    key: PostTypeKey.Projects,
    viewName: POST_TYPE_KEY_TO_VIEW_NAME[PostTypeKey.Projects],
  },
  {
    filterValue: PostTypeKey.Travels,
    key: PostTypeKey.Travels,
    viewName: POST_TYPE_KEY_TO_VIEW_NAME[PostTypeKey.Travels],
  },
  {
    filterValue: PostTypeKey.Communities,
    key: PostTypeKey.Communities,
    viewName: POST_TYPE_KEY_TO_VIEW_NAME[PostTypeKey.Communities],
  },
  {
    filterValue: PostTypeKey.CommonInfo,
    key: PostTypeKey.CommonInfo,
    viewName: POST_TYPE_KEY_TO_VIEW_NAME[PostTypeKey.CommonInfo],
  },
].map((option) => {
  (option as ISelectOption).filterField = PostFilterField.PublicationType;
  return option;
});

export const POST_TYPES_WITHOUT_OBJECT: PostTypeKey[] = [PostTypeKey.CommonInfo];

export const POST_PUPLICATION_OBJECT_OPTIONS_MOCK: ISelectOption[] = [
  {
    key: '1',
    viewName: 'Событие 1',
  },
  {
    key: '2',
    viewName: 'Событие 2',
  },
  {
    key: '3',
    viewName: 'Событие 3',
  },
  {
    key: '4',
    viewName: 'Событие 4',
  },
  {
    key: '5',
    viewName: 'Событие 5',
  },
];
