import { Pipe, PipeTransform } from '@angular/core';
import { DomService } from '@core/services/business/utils/dom.service';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private domService: DomService) {}

  transform(html: string) {
    return this.domService.bypassSecurityTrustHtml(html);
  }
}
