import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { KeyValue } from '@shared/models/key-value.model';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { EMOJI_RU_LOCALE } from '@shared/locale/emoji-ru-locale';
import { Locale } from '@shared/declarations/locale.type';

@Component({
  selector: 'kp-emoji-box-reactions',
  templateUrl: './emoji-box-reactions.component.html',
  styleUrls: ['./emoji-box-reactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiBoxReactionsComponent {
  @Output() emojiClickEvent: EventEmitter<string> = new EventEmitter<string>();

  public readonly isVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly emojiLocale: Locale = EMOJI_RU_LOCALE;

  public get emojiPopupStyle(): KeyValue<string> {
    return {
      position: 'absolute',
      bottom: '-130px',
      right: '20px',
    };
  }

  public emojiClick(emoji: EmojiData): void {
    this.emojiClickEvent.emit(emoji.native);
    this.isVisible$.next(!this.isVisible$.value);
  }

  public toggleEmojiBoxVisibility(): void {
    this.isVisible$.pipe(take(1)).subscribe((isVisible: boolean) => this.isVisible$.next(!isVisible));
  }
}
