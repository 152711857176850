import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseFormComponent } from '@shared/components/base-form/base-form.component';
import { FilterDialogComponent } from '@shared/dialogs/filter-dialog/filter-dialog.component';
import { SelectUpdatorField } from '@shared/enums/select/select-updator-field.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ISelectOption } from '@shared/models/select.model';
import { FilterService } from './filter.service';
import { FormValidationService } from './form-validation.service';

@Injectable({
  providedIn: 'root',
})
export class FilterDialogService extends BaseFormComponent {
  filtersOptions: ISelectOption[];
  form = new FormGroup({});
  dialogRef: MatDialogRef<FilterDialogComponent>;

  constructor(private fb: FormBuilder, protected formValidationService: FormValidationService, private filterService: FilterService) {
    super(formValidationService);
  }

  initForm() {
    this.form = this.fb.group({
      filters: this.fb.array([]),
    });

    this.filterService.filters?.forEach((filterInput) => {
      const targetOption = this.getOptionByFilterField(filterInput.field);
      if (targetOption) {
        const newFilterGroup = this.addEmptyFilterGroup();
        this.setFilterFormGroupValue(newFilterGroup, targetOption, filterInput);
      }
    });
  }

  addEmptyFilterGroup() {
    const newFilterGroup = this.getNewFilterFormGroup();
    this.filters.push(newFilterGroup);
    return newFilterGroup;
  }

  getNewFilterFormGroup(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      rule: ['', Validators.required],
      value: ['', Validators.required],
    });
  }

  setFilterFormGroupValue(targetFilterGroup: FormGroup, sourceOption?: ISelectOption, filter?: IFilter) {
    const targetRule = sourceOption?.filterAvailableRulesOptions?.find((option) => option.key === filter?.rule?.toString())?.key;

    targetFilterGroup.get('name').setValue(filter?.field);
    targetFilterGroup.get('rule').setValue(targetRule);
    targetFilterGroup.get('value').setValue(sourceOption?.filterIsArray ? filter?.values : filter?.value);
    this.setValidatorsToValueField(targetFilterGroup, filter);
  }

  filterFieldSelected(filterForm: AbstractControl | FormGroup, option: ISelectOption) {
    filterForm.get('name').setValue(option[SelectUpdatorField.FilterField]);
    filterForm.get('value').setValue(null);
    this.setValidatorsToValueField(filterForm as FormGroup, null, option);

    if (option.filterAvailableRulesOptions.length === 1) {
      filterForm.get('rule').setValue(option.filterAvailableRulesOptions[0].key);
    } else {
      filterForm.get('rule').setValue(null);
    }
  }

  setValidatorsToValueField(targetFilterGroup: FormGroup, filter?: IFilter, option?: ISelectOption) {
    let valueFieldValidators: ValidatorFn[];

    if (this.getOptionByFilterField(filter?.field)?.filterValueValidators) {
      valueFieldValidators = this.getOptionByFilterField(filter?.field)?.filterValueValidators;
    } else if (option?.filterValueValidators) {
      valueFieldValidators = option.filterValueValidators;
    } else {
      return;
    }

    valueFieldValidators.unshift(Validators.required);
    targetFilterGroup.get('value').setValidators(valueFieldValidators);
  }

  submit() {
    this.validateAllFormFields(this.form);
    if (this.form.valid) {
      this.filterService.filters = this.filterService.filters.filter((f) => !f.isDialogParent);
      const filters = this.transformFiltersFormArrayToFiltersObjects();
      this.closeDialog(filters);
    }
  }

  resetFilters() {
    this.filterService.filters.forEach((filter) => {
      if (this.filterService.isOptionsContainFilter(this.filtersOptions, filter)) {
        filter.value = null;
      }
    });
  }

  transformFiltersFormArrayToFiltersObjects(): IFilter[] {
    return (this.filters.value as []).reduce((acc, filter) => [...acc, ...this.transformFilterFormGroupToFilters(filter)], []);
  }

  transformFilterFormGroupToFilters(filterFormValue: KeyValue<any>): IFilter[] {
    const filters: IFilter[] = [];
    const targetOption = this.getOptionByFilterField(filterFormValue.name);

    filters.push({
      field: filterFormValue.name,
      rule: filterFormValue.rule,
      value: !targetOption.filterIsArray ? filterFormValue.value : null,
      values: targetOption.filterIsArray ? filterFormValue.value : null,
      isNotForUrl: !!targetOption.filterFieldIsNotForUrl,
      isNotForApi: !!targetOption.filterFieldIsNotForApi,
      isNotUnique: !!targetOption.filterIsNotUnique,
      isArray: !!targetOption.filterIsArray,
      isDialogParent: true,
    });

    targetOption.filterAdditionalFields?.forEach((additionalFieldName) => {
      filters.push({
        field: additionalFieldName,
        rule: filterFormValue.rule,
        value: !targetOption.filterIsArray ? filterFormValue.value : null,
        values: targetOption.filterIsArray ? filterFormValue.value : null,
        isNotForUrl: !!targetOption.filterAdditionalFieldsIsNotForUrl,
        isNotUnique: !!targetOption.filterIsNotUnique,
        isArray: !!targetOption.filterIsArray,
        isDialogParent: true,
      });
    });

    return filters;
  }

  closeDialog(filters?: IFilter[]) {
    const result = {
      filters,
    };

    this.dialogRef.close(filters ? result : null);
  }

  isFilterGroupValueText(filter: AbstractControl) {
    return (
      !this.getOptionByFilterField(filter.get('name').value)?.filterValueInputType ||
      this.getOptionByFilterField(filter.get('name').value)?.filterValueInputType === this.filterValueInputType.Text
    );
  }

  isFilterGroupValueNumber(filter: AbstractControl) {
    return this.getOptionByFilterField(filter.get('name').value)?.filterValueInputType === this.filterValueInputType.Number;
  }

  isFilterGroupValueDate(filter: AbstractControl) {
    const targetOption = this.getOptionByFilterField(filter.get('name').value);
    return (
      targetOption?.filterValueInputType === this.filterValueInputType.Date ||
      targetOption?.filterValueInputType === this.filterValueInputType.DateWithTime
    );
  }

  isFilterGroupValueSelect(filter: AbstractControl) {
    return this.getOptionByFilterField(filter.get('name').value)?.filterValueInputType === this.filterValueInputType.Select;
  }

  getOptionByFilterField(filterField: string) {
    return this.filterService.getOptionByFilterField(this.filtersOptions, filterField);
  }

  get activeFilters(): IFilter[] {
    return this.filterService.filters.filter((filter) =>
      this.filtersOptions?.some((option) => option.filterField === filter.field && filter.value !== null),
    );
  }

  get filters(): FormArray {
    return this.form.get('filters') as FormArray;
  }
}
