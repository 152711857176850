import { Injectable } from '@angular/core';
import { NewslinePostCommentFileHttpService } from '@core/services/api/newsline/newsline-post-comment-file-http.service';
import { IFile, IFileDto } from '@shared/models/file.model';
import { INewslinePostComment } from '@shared/models/newsline/view/newsline-post-comment.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostCommentFileService {
  constructor(private newslinePostCommentFileHttpService: NewslinePostCommentFileHttpService) {}

  getFilesByCommentIdAndAddToComment(commentId: number, comment?: INewslinePostComment): Observable<IFile[]> {
    if (comment) {
      comment.isLoadingFiles = true;
    }

    return this.newslinePostCommentFileHttpService.getFiles({ comment: commentId.toString() }).pipe(
      map((response) => {
        if (comment) {
          comment.files = response.results;
          comment.isLoadingFiles = false;
        }

        return response.results;
      }),
    );
  }

  getFileById(id: number): Observable<IFile> {
    return this.newslinePostCommentFileHttpService.getFileById(id);
  }

  createFileAndAddToComment(fileToCreate: IFileDto, comment?: INewslinePostComment): Observable<IFile> {
    return this.newslinePostCommentFileHttpService.createFile(fileToCreate).pipe(
      tap((createdFile) => {
        if (comment) {
          if (!comment.files?.length) {
            comment.files = [];
          }
          comment.files.push(createdFile);
        }
      }),
    );
  }

  updateFileByIdAndReplaceInComment(fileId: number, fileDto: IFileDto, comment?: INewslinePostComment): Observable<IFile> {
    return this.newslinePostCommentFileHttpService.updateFileById(fileId, fileDto).pipe(
      tap((updatedFile) => {
        if (comment?.files) {
          const targetFileIdx = comment.files.findIndex((file) => file.id === updatedFile.id);
          if (targetFileIdx !== -1) {
            comment.files[targetFileIdx] = updatedFile;
          }
        }
      }),
    );
  }

  deleteFileByIdAndRemoveFromComment(fileId: number, comment?: INewslinePostComment): Observable<void> {
    const fileToDeleteIdx = comment?.files?.findIndex((p) => p.id === fileId);
    if (fileToDeleteIdx !== -1) {
      comment.files[fileToDeleteIdx].isDeleting = true;
    }

    return this.newslinePostCommentFileHttpService.deleteFileById(fileId).pipe(
      tap(
        () => {
          if (comment.files) {
            comment.files = comment.files?.filter((file) => file.id !== fileId);
          }
        },
        () => {
          if (fileToDeleteIdx !== -1) {
            comment.files[fileToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }
}
