import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { FilterService } from './filter.service';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  readonly navigationEvent$ = new Subject<Event>();

  private readonly navSub = new SubSink();
  private readonly history: string[] = [];

  constructor(private router: Router, private filterService: FilterService) {
    this._navigationHandler();
  }

  ngOnDestroy() {
    this.navSub.unsubscribe();
  }

  private _navigationHandler() {
    this.navSub.sink = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const urlWithoutQueryParams = event.urlAfterRedirects.split('?')[0];

        if (this.history[this.history.length - 1] !== urlWithoutQueryParams) {
          this.history.push(urlWithoutQueryParams);
        }
      }

      this.filterService.onNavigation(event);
      this.navigationEvent$.next(event);
    });
  }

  back(activatedRoute: ActivatedRoute, previousPathRelative?: string, previousPathAbsolute?: string) {
    this.history.pop();
    const previousPathFromHistory = this.history[this.history.length - 1];

    if (previousPathAbsolute) {
      this.router.navigate([previousPathAbsolute]);
    } else if (previousPathRelative) {
      this.router.navigate([previousPathRelative], { relativeTo: activatedRoute });
    } else if (previousPathFromHistory) {
      this.router.navigate([previousPathFromHistory]);
    } else {
      this.router.navigate(['..'], { relativeTo: activatedRoute });
    }
  }
}
