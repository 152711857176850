import { FORM_OF_PARTICIPATION_KEY_TO_VIEW_NAME } from '@modules/profile/constants/keys-to-view-names.const';
import { DATE_FULL } from '@shared/constants/common.const';
import { USER_RELATED_FIELD_KEY_TO_VIEW_NAME } from '@shared/constants/mappers.const';
import { UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { ITableColumn } from '@shared/models/table/table-column.model';

export const MEMBERS_FILTERS_TILES_IGNORE_URL: UserRelatedFieldKey[] = [
  UserRelatedFieldKey.Limit,
  UserRelatedFieldKey.Offset,
];
export const MEMBERS_FILTERS_TABLE_IGNORE_URL: UserRelatedFieldKey[] = [UserRelatedFieldKey.Limit];

export const MEMBERS_LIST_DEFAULT_OFFSET = '0';
export const MEMBERS_LIST_DEFAULT_LIMIT_TILES = '40';
export const MEMBERS_LIST_DEFAULT_LIMIT_TABLE = '8';
export const MEMBERS_LIST_DEFAULT_ORDERING_TILE = '-date_joined_club';

export const MEMBERS_PAGE_FILTERS_TILES: IFilter[] = [
  {
    field: UserRelatedFieldKey.Offset,
    value: MEMBERS_LIST_DEFAULT_OFFSET,
  },
  {
    field: UserRelatedFieldKey.Limit,
    value: MEMBERS_LIST_DEFAULT_LIMIT_TILES,
  },
  {
    field: UserRelatedFieldKey.Ordering,
    value: MEMBERS_LIST_DEFAULT_ORDERING_TILE,
  },
  {
    field: UserRelatedFieldKey.IsBlocked,
    value: 'false',
  },
];

export const MEMBERS_PAGE_FILTERS_TABLE: IFilter[] = [
  {
    field: UserRelatedFieldKey.Offset,
    value: MEMBERS_LIST_DEFAULT_OFFSET,
  },
  {
    field: UserRelatedFieldKey.Limit,
    value: MEMBERS_LIST_DEFAULT_LIMIT_TABLE,
  },
];

const MEMBERS_DEFAULT_FILTERS: IFilter[] = [
  // {
  //   field: UserRelatedFieldKey.MembersType,
  //   value: MembersTypeKey.AllMembers,
  //   isNotForApi: true,
  // },
  // {
  //   field: UserRelatedFieldKey.UserType,
  //   value: UserTypeKey.Member,
  //   isNotForUrl: true,
  // },
];

export const MEMBERS_DEFAULT_FILTERS_TILES: IFilter[] = [...MEMBERS_DEFAULT_FILTERS, ...MEMBERS_PAGE_FILTERS_TILES];
export const MEMBERS_DEFAULT_FILTERS_TABLE: IFilter[] = [...MEMBERS_DEFAULT_FILTERS, ...MEMBERS_PAGE_FILTERS_TABLE];

export const MEMBERS_TABLE_COLUMNS: ITableColumn[] = [
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.FullName],
    headerKey: UserRelatedFieldKey.FullName,
    dataKey: UserRelatedFieldKey.FirstAndLastName,
    isRequired: true,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: UserRelatedFieldKey.ProfilePageRoute,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-size': '14px',
      'font-weight': 'normal',
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Industries],
    headerKey: UserRelatedFieldKey.Industries,
    dataKey: UserRelatedFieldKey.AllIndustriesInString,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.CanBeUseful],
    headerKey: UserRelatedFieldKey.CanBeUseful,
    dataKey: UserRelatedFieldKey.CanBeUseful,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Requests],
    headerKey: UserRelatedFieldKey.Requests,
    dataKey: UserRelatedFieldKey.Requests,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Email],
    headerKey: UserRelatedFieldKey.Email,
    dataKey: UserRelatedFieldKey.Email,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      'font-weight': '400',
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Interests],
    headerKey: UserRelatedFieldKey.Interests,
    dataKey: UserRelatedFieldKey.Interests,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.BusinessExperience],
    headerKey: UserRelatedFieldKey.BusinessExperience,
    dataKey: UserRelatedFieldKey.BusinessExperienceInArrayString,
    dataIsArray: true,
    dataArrayItemsConnector: ' ',
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Education],
    headerKey: UserRelatedFieldKey.Education,
    dataKey: UserRelatedFieldKey.EducationInArrayString,
    dataIsArray: true,
    dataArrayItemsConnector: ' ',
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.StatusInClub],
    headerKey: UserRelatedFieldKey.StatusInClub,
    dataKey: UserRelatedFieldKey.StatusInClubInArrayString,
    dataIsArray: true,
    dataArrayItemsConnector: ', ',
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.Company],
    headerKey: UserRelatedFieldKey.Company,
    dataKey: UserRelatedFieldKey.Company,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.DateOfBirthday],
    headerKey: UserRelatedFieldKey.DateOfBirthday,
    dataKey: UserRelatedFieldKey.DateOfBirthday,
    dataItemIsDate: true,
    dateFormat: DATE_FULL,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.BusinessTurnover],
    headerKey: UserRelatedFieldKey.BusinessTurnover,
    dataKey: UserRelatedFieldKey.BusinessTurnover,
    subDataKey: 'name',
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.FormOfParticipation],
    headerKey: UserRelatedFieldKey.FormOfParticipation,
    dataKey: UserRelatedFieldKey.FormOfParticipation,
    dataKeyOfEnum: true,
    dataEnum: FORM_OF_PARTICIPATION_KEY_TO_VIEW_NAME,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.MeetingCounter],
    headerKey: UserRelatedFieldKey.MeetingCounter,
    dataKey: UserRelatedFieldKey.MeetingCounter,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.StartMembershipDate],
    headerKey: UserRelatedFieldKey.StartMembershipDate,
    dataKey: UserRelatedFieldKey.StartMembershipDate,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.EndMembershipDate],
    headerKey: UserRelatedFieldKey.EndMembershipDate,
    dataKey: UserRelatedFieldKey.EndMembershipDate,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: USER_RELATED_FIELD_KEY_TO_VIEW_NAME[UserRelatedFieldKey.YearsOfMembership],
    headerKey: UserRelatedFieldKey.YearsOfMembership,
    dataKey: UserRelatedFieldKey.YearsOfMembership,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
];

export const MEMBERS_TABLE_DEFAULT_COLUMNS_KEYS: UserRelatedFieldKey[] = [
  UserRelatedFieldKey.FullName,
  UserRelatedFieldKey.Industries,
  UserRelatedFieldKey.CanBeUseful,
  UserRelatedFieldKey.Requests,
  UserRelatedFieldKey.Email,
];

export const MEMBERS_TABLE_STAFF_COLUMNS_KEYS: UserRelatedFieldKey[] = [
  UserRelatedFieldKey.FullName,
  UserRelatedFieldKey.Industries,
  UserRelatedFieldKey.CanBeUseful,
  UserRelatedFieldKey.Requests,
  UserRelatedFieldKey.Email,
  UserRelatedFieldKey.Interests,
  UserRelatedFieldKey.BusinessExperience,
  UserRelatedFieldKey.Education,
  UserRelatedFieldKey.StatusInClub,
  UserRelatedFieldKey.Company,
  UserRelatedFieldKey.DateOfBirthday,
  UserRelatedFieldKey.BusinessTurnover,
  UserRelatedFieldKey.FormOfParticipation,
  UserRelatedFieldKey.MeetingCounter,
  UserRelatedFieldKey.StartMembershipDate,
  UserRelatedFieldKey.EndMembershipDate,
  UserRelatedFieldKey.YearsOfMembership,
];

export const MEMBERS_TABLE_MEMBER_COLUMNS_KEYS: UserRelatedFieldKey[] = [
  UserRelatedFieldKey.FullName,
  UserRelatedFieldKey.Industries,
  UserRelatedFieldKey.CanBeUseful,
  UserRelatedFieldKey.Requests,
  UserRelatedFieldKey.Email,
  UserRelatedFieldKey.Interests,
  UserRelatedFieldKey.BusinessExperience,
  UserRelatedFieldKey.Education,
  UserRelatedFieldKey.StatusInClub,
  UserRelatedFieldKey.Company,
];
