import { Component, ElementRef, Input, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FormValidationService } from '@core/services/business/utils/form-validation.service';
import svgIcons from '../../../../assets/svg/svg-icons';

@Component({
  selector: 'kp-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements ControlValueAccessor {
  @Input()
  icon: keyof typeof svgIcons | null = null;
  @Input()
  iconColor = '#6A728A';
  @Input()
  inputPlaceholder: string | null = null;
  @Input()
  allowClearButton = false;
  @Input()
  inputHeight = '52px';
  @ViewChild('input')
  input: ElementRef;

  public model: string | null = '';

  constructor(@Optional() @Self() public ngControl: NgControl, private formValidationService: FormValidationService) {
    if (Boolean(this.ngControl)) {
      this.ngControl.valueAccessor = this;
    }
  }

  public onChange: (value: string) => void = () => null;
  public onTouched = (): null => null;

  public writeValue(value?: string | null): void {
    this.model = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onModelChange(value: string) {
    this.onTouched();
    this.model = value;
    this.onChange(value);
  }

  public getValidatorErrorMessage(field: NgControl) {
    return this.formValidationService.getValidatorErrorMessage(field.control);
  }

  public onClear() {
    this.onModelChange('');
  }

  public focus() {
    this.input.nativeElement.focus();
  }
}
