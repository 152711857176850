import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TableService } from '@core/services/business/utils/table.service';
import { KeyValue } from '@shared/models/key-value.model';
import { ITableColumn } from '@shared/models/table/table-column.model';
import { ITableCellClickEvent, ITableConfig } from '@shared/models/table/table-config.model';
import { ITableRow, ITableRowDataItem } from '@shared/models/table/table-row.model';
import * as _ from 'lodash';
import { ISelectOption } from '@shared/models/select.model';
import { BaseClass } from '../base/base.class';
import { IFilter } from '@shared/models/filter/filter.model';

@Component({
  selector: 'kp-table',
  templateUrl: './table.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent extends BaseClass implements OnChanges {
  @Input() config: ITableConfig;
  @Output() cellClickedEvent = new EventEmitter<ITableCellClickEvent>();
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() rowSelectedStateSwitchedEvent = new EventEmitter<any>();
  @Output() allRowsSelectedStateSwitchedEvent = new EventEmitter();
  @Output() rowMenuSelectedEvent = new EventEmitter<{ rowData: any; option: ISelectOption }>();
  @Output() colSettingsIconClickedEvent = new EventEmitter();
  @Output() orderBy = new EventEmitter<IFilter>();

  constructor(private tableService: TableService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config?.currentValue) {
      this.tableService.checkAndSetSortingStateByFilterService((changes.config.currentValue as ITableConfig).columns);
    }
  }

  optionSelected(col: ITableColumn, row: ITableRow, selectedOption: ISelectOption): void {
    this.cellClickedEvent.emit({
      data: this.tableService.transformTableRowToDataItem(row),
      headerKey: col.dataKey,
      selectedOption,
    });
  }

  cellClickedEventEmitter(col: ITableColumn, row: ITableRow): void {
    if (!col.isCellsClickEmitData) {
      return;
    }
    this.cellClickedEvent.emit({ data: this.tableService.transformTableRowToDataItem(row), headerKey: col.dataKey });
  }

  switchRowSelectState(row: ITableRow): void {
    this.rowSelectedStateSwitchedEvent.emit(this.tableService.transformTableRowToDataItem(row));
  }

  emitAllRowsSelectedStateSwitchedEvent() {
    this.allRowsSelectedStateSwitchedEvent.emit();
  }

  emitRowClickedEvent(row: ITableRow): void {
    if (this.config.rowsClickable) {
      this.rowClickedEvent.emit(this.tableService.transformTableRowToDataItem(row));
    }
  }

  rowMenuSelectedEventEmitter(row: ITableRow, option: ISelectOption): void {
    const rowData = this.tableService.transformTableRowToDataItem(row);
    this.rowMenuSelectedEvent.emit({ rowData, option });
  }

  sortByColumn(col: ITableColumn): void {
    this.tableService.sortByColumn(this.config.columns, col, this.orderBy);
  }

  getTrTdContentStyles(col: ITableColumn, row: ITableRow, item: ITableRowDataItem): KeyValue<string> {
    return this.tableService.getTrTdContentStyles(col, row, item);
  }

  getRouterLink(col: ITableColumn, row: ITableRow, keyOfParameterWithRouteInData: string): { value: string; isEmpty: boolean } {
    return this.tableService.getRouterLink(col, row, keyOfParameterWithRouteInData);
  }

  getRowDataItemCustomClass(col: ITableColumn, row: ITableRow): string {
    return this.tableService.getRowDataItemCustomClass(col, row);
  }

  getRowDataItemByKey(row: ITableRow, dataKey?: string): ITableRowDataItem {
    return this.tableService.getRowDataItemByKey(row, dataKey);
  }

  getRowDataValueItemByKey(col: ITableColumn, row: ITableRow, dataKey?: string, subDataKey?: string): { value: string; isEmpty: boolean } {
    return this.tableService.getRowDataValueItemByKey(col, row, dataKey, subDataKey);
  }

  isRowSelected(row: ITableRow): boolean {
    return this.tableService.isRowSelected(row);
  }

  get isSomeRowsSelected(): boolean {
    return this.tableService.isSomeRowsSelected(this.config.rows);
  }
}
