import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from '@core/services/business/utils/utils.service';
import { KeyValue } from '@shared/models/key-value.model';
import { INewslinePostCommentImageDto } from '@shared/models/newsline/dto/newsline-post-comment-image-dto.model';
import { INewslinePostCommentImage } from '@shared/models/newsline/view/newsline-post-comment-image.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostCommentImageHttpService {
  private readonly _apiPath = '/newsline/comment-photo/';

  constructor(private http: HttpService, private utilsService: UtilsService) {}

  getImages(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<INewslinePostCommentImage>> {
    return this.http.get<ArrayPayload<INewslinePostCommentImage>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getImageById(id: number): Observable<INewslinePostCommentImage> {
    return this.http.get<INewslinePostCommentImage>(`${this._apiPath}${id}/`);
  }

  createImage(image: INewslinePostCommentImageDto): Observable<INewslinePostCommentImage> {
    const imageFD = this.utilsService.transformObjectToFormData(image);
    return this.http.post<INewslinePostCommentImage>(this._apiPath, imageFD);
  }

  updateImageById(id: number, image: INewslinePostCommentImageDto): Observable<INewslinePostCommentImage> {
    const imageFD = this.utilsService.transformObjectToFormData(image);
    return this.http.patch<INewslinePostCommentImage>(`${this._apiPath}${id}/`, imageFD);
  }

  deleteImageById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }
}
