<div
  class="select"
  [class.select--active]="isSelectActive"
  [class.background-opacity]="isNeedBackground"
  [style.height]="selectService.getSelectBarHeight(context)"
  [kpSwitchElement]="optionsWrapperEl"
  [switcherDisabled]="selectService.isInlineViewMode(context)"
  [closeTrigger]="isSelectActive"
  [position]="position"
  [externalTrigger$]="externalTrigger$"
  (elementOpen)="selectService.activateSelect(context)"
  (elementClose)="selectService.deactivateSelect(context)"
>
  <!-- Bar -->
  <ng-container *ngIf="selectService.isDefaultViewMode(context)">
    <div *ngIf="isBarInNgContent; else nativeBar" class="select-bar-ng-content">
      <ng-content></ng-content>
      <kp-svg
        *ngIf="addArrowToNgContent"
        key="arrow"
        height="6px"
        width="10px"
      ></kp-svg>
    </div>

    <ng-template #nativeBar>
      <kp-svg
        *ngIf="selectService.isIconBarEnabled(context); else defaultBar"
        class="select-bar-icon"
        [key]="barIconKey"
        [height]="barIconHeight"
        [width]="barIconWidth"
        [color]="barIconColor"
      ></kp-svg>

      <ng-template #defaultBar>
        <div
          class="select-bar"
          [class.select-bar--light]="barViewMode === selectBarViewMode.Light"
        >
          <div class="select-bar__title">
            {{ selectService.getSelectBarData(context) }}
          </div>
          <kp-svg
            key="arrow"
            height="6px"
            width="10px"
          ></kp-svg>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>

  <!-- Options -->
  <div
    #optionsWrapperRef
    [style.width]="containerWidth"
    [style.height]="containerHeight"
    class="select-options-wrapper"
    [ngClass]="{
        'select-options-wrapper--static select-options-wrapper--full-width': selectService.isInlineViewMode(context)
      }"
  >
    <div
      class="select-options-container"
      [ngClass]="{
        'select-options-container--no-border select-options-container--no-shadow':
          selectService.isInlineViewMode(context),
        'select-options-container--y-margin': selectService.isDefaultViewMode(context)
      }"
      [ngStyle]="{
          height: optionsContainerHeightPx ? optionsContainerHeightPx + 'px' : null,
          'max-height': selectService.isInlineViewMode(context) ? 'max-content' : null
        }"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="selectService.getOptionsFromSource(context)"
      [scrollWindow]="false"
      [alwaysCallback]="true"
    >
      <div class="select-options-container__no-data" *ngIf="!options?.length; else optionsBlock">Нет данных</div>

      <ng-template #optionsBlock>
        <div
          *ngFor="let option of options"
          class="select-options-container__option"
          [ngClass]="{
              'select-options-container__option--active': selectService.isMenuModeEnabled(context),
              'select-options-container__option--selected':
                option.isSelected && selectService.isSingleSelectModeEnabled(context),
              'select-options-container__option--no-margin': selectService.isInlineViewMode(context),
              'select-options-container__option--just-text': option.isJustText,
              disabled: option.isRequired && option.isSelected && selectService.isMultiSelectModeEnabled(context)
            }"
          [kpTooltip]="option.tooltipText"
          [kpConfirmAction]="option.confirmActionText"
          [confirmationDisabled]="!option.confirmActionText"
          (confirmedClick)="option.isJustText ? null : selectService.selectOptionFromTemplate(context, option)"
        >
          <ng-container [ngSwitch]="mode">
            <ng-container *ngSwitchCase="selectMode.Menu">
              <kp-svg
                *ngIf="option.iconKey"
                [key]="option.iconKey"
                [rotateAngle]="option.iconRotateAngle"
                height="18px"
                width="18px"
                [color]="option.iconColor"
              ></kp-svg>
            </ng-container>

            <mat-checkbox
              *ngSwitchCase="selectMode.MultiSelect"
              class="checkbox"
              [checked]="option.isSelected"
              (click)="$event.preventDefault()"
            ></mat-checkbox>

            <kp-svg *ngSwitchCase="selectMode.SingleSelect" key="check" height="7px" width="9px"></kp-svg>
          </ng-container>

          <img *ngIf="imageBeforeTextPropName" [src]="option.data[imageBeforeTextPropName]" class="circle-img"/>

          <div
            class="select-options-container__option-text"
            [style.color]="option.textColor"
            [style.fontSize]="option.textSize"
          >
            {{ option.viewName }}
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-- File input trigger:start -->
<div class="file-input-trigger">
  <input
    *ngFor="let option of options"
    [id]="option.id"
    [multiple]="true"
    [accept]="option.filesMymeType ? option.filesMymeType : null"
    type="file"
    (change)="selectService.handleFilesInput(context, $event, option)"
  />
</div>
<!-- File input trigger:end -->
