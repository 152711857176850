<div class="contents">
  <div class="contents__content">
    <h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
    <p>
      Соглашение является договором присоединения и устанавливает правила использования программного обеспечения
      «ClubFirst» (Приложение).
    </p>
    <p>Правообладатель Приложения - ООО “КМС” (Компания).</p>
  </div>
  <div class="contents__content">
    <h1>Пользователь</h1>
    <p>Пользователь - дееспособное физическое лицо, которое одновременно:</p>
    <p>
      (1)&nbsp; является участником «ClubFirst» - набора организуемых Компанией мероприятий и сервисов для
      предпринимателей;
    </p>
    <p>(2)&nbsp; получило доступ к Приложению и использует его;</p>
    <p>(3)&nbsp; приняло условия Соглашения.</p>
    <br />
    <p>2.&nbsp; Пользователь получает доступ к Приложению одним из способов:</p>
    <p>(1) используя данные для авторизации, переданные Компанией;</p>
    <p>(2) путем авторизации через иные сервисы.</p>
    <br />
    <p>3.&nbsp;Пользователь использует Приложение путем:</p>
    <p>(1) просмотра материалов, размещенных в Приложении;</p>
    <p>(2) размещение материалов в Приложении;</p>
    <p>(3) редактирования своего профиля;</p>
    <p>(4) поиска и просмотра профилей других Пользователей;</p>
    <p>(5) взаимодействия с Пользователями;</p>
    <p>(6) иным образом осуществляя действия в Приложении.</p>
    <br />
    <p>
      4. Пользователь принимает условия Соглашения путем проставления специальной галочки в отведенном поле при первом
      запуске Приложения.
    </p>
    <br />

    <p>
      5. Любые действия, совершенные с использованием логина и пароля, считаются совершенными Пользователем. В случае
      несанкционированного доступа к логину и паролю Пользователя, или распространения логина и пароля Пользователь
      обязан незамедлительно сообщить об этом Компании.
    </p>
    <br />
    <p>
      6. После авторизации Пользователь получает право самостоятельно в личных целях создавать, использовать и
      определять содержание данных о себе. Пользователь принимает на себя обязательства заполнить достоверную информацию
      в своем профиле в полном объеме.
    </p>
    <br />
    <p>
      7. Пользователь несет ответственность за достоверность, актуальность, полноту и соответствие законодательству РФ,
      предоставленной информации и ее свободу от претензий третьих лиц.
    </p>
    <br />
    <p>8. Пользователю запрещается:</p>
    <p>
      (1) загружать, хранить, публиковать, распространять и предоставлять доступ или иным образом использовать любую
      информацию, которая:
    </p>
    <p>
      <li>содержит угрозы, призывы к насилию, в том числе скрытые, одобрение и поощрение насильственных действий;</li>
    </p>

    <p>
      <li>
        дискредитирует, оскорбляет, порочит честь и достоинство или деловую репутацию, или нарушает неприкосновенность
        частной жизни других Пользователей или третьих лиц;
      </li>
    </p>

    <p><li>нарушает права несовершеннолетних лиц;</li></p>
    <p>
      <li>
        является вульгарной или непристойной, содержит порнографические изображения и тексты или сцены сексуального
        характера с участием несовершеннолетних;
      </li>
    </p>

    <p><li>содержит сцены бесчеловечного обращения с животными;</li></p>
    <p><li>содержит описание средств и способов суицида, любое подстрекательство к его совершению;</li></p>
    <p>
      <li>
        пропагандирует и/или способствует разжиганию расовой, религиозной, этнической ненависти или вражды, по признакам
        отнесения к определенным полу, ориентации, а также по иным индивидуальным признакам и особенностям человека
        (включая вопросы его здоровья);
      </li>
    </p>

    <p><li>содержит экстремистские материалы;</li></p>
    <p>
      <li>
        пропагандирует преступную деятельность или содержит советы, инструкции или руководства по совершению преступных
        действий;
      </li>
    </p>

    <p>
      <li>
        содержит информацию ограниченного доступа: государственную и коммерческую тайну, информацию о частной жизни
        третьих лиц и т.п.;
      </li>
    </p>

    <p>
      <li>
        содержит рекламу или описывает привлекательность употребления наркотических веществ, информацию о
        распространении наркотиков, рецепты их изготовления и советы по употреблению;
      </li>
    </p>

    <p>
      <li>
        может привести к совершению противоправных действий путем введения Пользователей в заблуждение или
        злоупотребления их доверием;
      </li>
    </p>

    <p><li>нарушает права и интересы граждан и юридических лиц или требования законодательства РФ.</li></p>
    <br />
    <p>
      (2) незаконно загружать, публиковать, распространять или иным образом использовать интеллектуальную собственность
      Пользователей и третьих лиц;
    </p>
    <br />
    <p>
      (3) осуществлять массовые рассылки сообщений в адрес других Пользователей без их предварительного согласия и/или
      любые другие аналогичные нежелательные рассылки (спам);
    </p>
    <br />
    <p>
      (4) осуществлять действия, направленные на нарушение функционирования Приложения или персональных страниц
      Пользователей;
    </p>
    <br />
    <p>
      (5) загружать, публиковать, распространять и предоставлять доступ или иным образом использовать вирусы, трояны и
      другие вредоносные программы;
    </p>
    <br />
    <p>(6) использовать программное обеспечение для сбора информации о Приложении и его функционале;</p>
    <br />
    <p>(7) любым способом пытаться незаконно получить доступ к логину и паролю другого Пользователя;</p>
    <br />
    <p>(8) осуществлять незаконный сбор и обработку персональных данных Пользователей;</p>
    <br />
    <p>
      (9) воспроизводить, дублировать, копировать, продавать, осуществлять торговые операции и перепродавать доступ к
      Приложению.
    </p>
    <br />
    <p>
      9. Если Пользователь нарушает Соглашение, Компания вправе прекратить доступ к Приложению Пользователя
      (деактивация).
    </p>
    <br />
    <p>10. Пользователь вправе обратиться в Компанию с требованием об удалении данных о нём.</p>
  </div>
  <div class="contents__content">
    <h1>Компания</h1>
    <p>
      11. Компания предоставляет Пользователю право доступа к Приложению на условиях простой (неисключительной) лицензии
      без сохранения за Пользователем права передачи лицензии другим лицам (Пользовательская лицензия).
    </p>
    <p>
      12. Пользовательская лицензия прекращает действовать с момента удаления Приложения Пользователем или деактивации.
    </p>
    <p>
      13. Компания вправе изменить, модифицировать Приложение или прервать, прекратить работу Приложение в любой момент
      без уведомления Пользователя.
    </p>
    <p>
      14. Компания не несет ответственность перед Пользователями или третьими лицами за любые произведенные изменения,
      модификации, прекращения или перерывы в работе Приложения.
    </p>
    <p>
      15. Компания обеспечивает функционирование и работоспособность Приложения и обязуется оперативно восстанавливать
      его работоспособность в случае технических сбоев и перерывов.
    </p>
    <p>
      16. Компания не гарантирует, что Приложение будут доступными Пользователю постоянно. Иногда Приложение будет
      функционировать с неполадками.
    </p>
    <p>
      17. Компания не несет ответственности за временные сбои и перерывы в работе Приложения и вызванные ими потерю
      информации.
    </p>
    <p>
      18. Компания не несет ответственности перед Пользователями или третьими лицами за какие-либо убытки, ущерб или
      неудобства, вызванные отсутствием у Пользователя доступа к Приложению.
    </p>
    <p>19. Компания не несет ответственности за нарушение Пользователем Соглашения.</p>
    <p>
      20. Компания вправе по собственному усмотрению, а также при получении информации от других пользователей либо
      третьих лиц о нарушении Пользователем Соглашения:
    </p>
    <p>
      (1) изменять, блокировать или удалять любую публикуемую Пользователем информацию, нарушающую запреты,
      установленные Соглашением;
    </p>
    <p>(2) приостанавливать, ограничивать или прекращать доступ Пользователя к Приложению.</p>
    <p>
      21. Ни при каких обстоятельствах Компания не несет ответственность перед Пользователем или перед любыми третьими
      лицами за любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или потерянные данные, вред
      чести, достоинству или деловой репутации, вызванный в связи с использованием Приложения, его содержимого или иных
      материалов, к которым Пользователь или иные лица получили доступ с помощью Приложения.
    </p>
    <p>22. Пользователь несет личную ответственность за любой Контент или иную информацию, которые он загружает.</p>
    <p>
      23. Компания не обещает, не гарантирует, не подразумевает, что функционал Приложения может подходить или не
      подходить потребностям, целям, ожиданиям, и в связи с чем не гарантирует какого-либо специфического результата или
      последствия в результате использования Приложения.
    </p>
  </div>
  <div class="contents__content">
    <h1>Интеллектуальная собственность</h1>
    <p>
      24. ПО Приложения - программное обеспечение, разработанное Компанией (и/или третьими лицами по поручению Компании)
      для Приложения, включая, но не ограничиваясь, весь софт, скрипты, коды (HTML коды), программы, программные
      комплексы и т.п.
    </p>
    <p>25. Компании принадлежат все исключительные права на ПО Приложения.</p>
    <p>
      26. Компании принадлежат все исключительные права на Контент (товарные знаки, дизайн, текст, графические
      изображения, иллюстрации, видео, скрипты, программы, музыка, звуки и другие объекты интеллектуальной
      собственности) в Приложении.
    </p>
    <p>
      27. Пользователь, размещая в Приложении принадлежащий ему Контент, предоставляет другим Пользователям
      неисключительное право использовать его в рамках предоставляемого Приложением функционала с целью личного
      некоммерческого использования.
    </p>
    <p>
      28. Пользователь, размещая в Приложении принадлежащий ему Контент, предоставляет Компании неисключительное право
      использовать на безвозмездной основе принадлежащий ему на законных основаниях Контент в целях обеспечения
      функционирования Приложения.
    </p>
    <p>
      29. Неисключительное право предоставляется на срок размещения Контента в Приложении и распространяет свое действие
      на территории стран всего мира.
    </p>
    <p>
      30. Окончание срока размещения Контента в Приложении и/или срока действия неисключительного права не влечет за
      собой необходимость изъятия из оборота Контента Компании.
    </p>
    <p>31. Компания вправе передавать права третьим лицам.</p>
    <p>
      32. Компания вправе использовать функциональные и технические возможности Приложения, обеспечивающие отображение
      размещаемого Пользователем Контента, предназначенные для целей отображения Контента.
    </p>
    <p>
      33. Пользователь не вправе использовать Контент другого Пользователя, Компании, если такое использование причиняет
      или может причинить вред интересам правообладателей объектов интеллектуальной собственности.
    </p>
    <p>
      34. Неисключительное право считается автоматически отозванным с момента удаления Контента Пользователя из
      Приложения.
    </p>
    <p>
      35. Компания вправе в случае необходимости, обусловленной техническими особенностями работы Приложения, сохранять
      архивные копии Контента в течение необходимого срока.
    </p>
  </div>
  <div class="contents__content">
    <h1>Заключительные положения</h1>
    <p>
      36. При изменении Соглашения, к отношениям возникшим до изменения, применяются условия актуальной редакции
      Соглашения.
    </p>
    <p>37. Компания вправе не уведомлять Пользователя об изменениях или дополнениях Соглашения.</p>
    <p>38. Пользователь самостоятельно знакомится с актуальной версией Соглашения, размещенной в Приложении.</p>
    <p>
      39. Соглашение вступают в силу для Пользователя с момента его принятия и действуют в течение неопределенного
      срока.
    </p>
  </div>

  <div class="contents__content">
    <h1>ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
    <p>Дата принятия: 16.12.2021.</p>
    <p>
      Политика обработки персональных данных устанавливает правила обработки персональных данных пользователей
      приложения “ClubFirst”.
    </p>
  </div>
  <div class="contents__content">
    <h1>Термины и определения</h1>
    <p>1. Оператор - ООО “КМС”.</p>
    <p>2. Приложение - программное обеспечение “ClubFirst”, разработанное Оператором.</p>
    <p>3. Пользователь - физическое лицо, использующее Приложение.</p>
  </div>
  <div class="contents__content">
    <h1>Цели и принципы обработки</h1>
    <p>
      4. Целью Политики является обеспечение защиты прав и свобод человека и гражданина при обработке его персональных
      данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну, четкое и
      неукоснительное соблюдение требований российского и международного законодательства в области персональных данных.
    </p>
    <p>
      5. Действие Политики распространяется на все персональные данные субъектов, обрабатываемые Оператором с
      применением средств автоматизации и без применения таких средств.
    </p>
    <p>6. Оператор размещает актуальную версию Политики в Приложении.</p>
    <p>7. Оператор вправе вносить изменения в Политику по мере необходимости.</p>
    <p>8. Целями обработки персональных данных Оператором являются:</p>
    <p><li>идентификация Пользователя;</li></p>
    <p><li>предоставления Пользователю доступа к персонализированным данным Приложения;</li></p>
    <p>
      <li>
        установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования
        Приложения, обработки запросов и заявок от Пользователя;
      </li>
    </p>
    <p>
      <li>определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества;</li>
    </p>
    <p><li>подтверждения достоверности и полноты персональных данных, предоставленных Пользователем;</li></p>
    <p>
      <li>
        создания учетной записи для использования Приложения, если Пользователь дал согласие на создание учетной записи;
      </li>
    </p>
    <p>◦ уведомления Пользователя по электронной почте, мобильному телефону.</p>
  </div>
  <div class="contents__content">
    <h1>Перечень персональных данных</h1>
    <p>
      9. Пользователь принимает решение о предоставлении Оператору своих персональных данных свободно, своей волей и в
      своем интересе путем проставления специальной галочки в отведенном поле при первом запуске Приложения.
    </p>
    <p>10. Персональные данные, обрабатываемые Оператором:</p>
    <p><li>изображение физического лица;</li></p>
    <p><li>фамилия, имя, отчество;</li></p>
    <p><li>пол;</li></p>
    <p><li>дата рождения;</li></p>
    <p><li>семейное положение;</li></p>
    <p><li>страна и город проживания;</li></p>
    <p><li>сведения об управлении юридическим лицом или трудоустройстве;</li></p>
    <p><li>сведения о навыках, умениях, специализации и т.п.</li></p>
    <p><li>место рождения;</li></p>
    <p><li>хобби, увлечения;</li></p>
    <p><li>контактный телефон;</li></p>
    <p><li>адрес электронной почты (e-mail);</li></p>
    <p><li>образование;</li></p>
    <p><li>сведения об аккаунтах в социальных сетях</li></p>

    <p><li>IP-адреса;</li></p>
    <p><li>информация о пользовательских действиях в Приложении.</li></p>
  </div>
  <div class="contents__content">
    <h1>Обработка персональных данных​</h1>
    <p>11. Сбор персональных данных осуществляется следующими способами:</p>
    <p><li>предоставление персональных данных пользователем при заполнении форм в Приложении;</li></p>
    <p><li>автоматический сбор информации о пользователе с помощью технологий и сервисов.</li></p>
    <p>
      12. Персональные данные пользователя хранятся исключительно на электронных носителях и обрабатываются с
      использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка
      персональных данных необходима в связи с исполнением требований законодательства.
    </p>
    <p>
      13. Оператор вправе хранить личную информацию до тех пор, пока это необходимо для выполнения целей, для которых
      были собраны данные, в зависимости от правовой базы, для которой были получены эти данные, и/или какие
      дополнительные юридические/нормативные обязательства предусматривают, что хранение информации необходимо в течение
      периода времени, который требуется и/или допустим в соответствии с применимым законом.
    </p>
    <p>
      14. В случае удаления аккаунта Оператор будет хранить на своих электронных носителях данные Пользователей в
      течение необходимого и установленного применимым законодательством срока.
    </p>
    <p>
      15. Оператор при обработке персональных данных пользователя обеспечивает использование баз данных, находящихся на
      территории России.
    </p>
    <p>16. Оператор вправе передавать персональные данные Пользователей третьим лицам в следующих случаях:</p>
    <p><li>пользователь явно выразил свое согласие на такие действия;</li></p>
    <p><li>передача необходима для нормального функционирования Приложения;</li></p>
    <p><li>передача предусмотрена действующим законодательством.</li></p>
    <p>17. Оператор уничтожает персональные данные пользователя в следующих случаях:</p>
    <p><li>наличия угрозы безопасности Приложения;</li></p>
    <p><li>истечение срока хранения персональных данных;</li></p>
    <p><li>на основании запроса Пользователя;</li></p>
    <p><li>на основании запроса органа государственной власти.</li></p>
  </div>
  <div class="contents__content">
    <h1>Обязательства</h1>
    18. Оператор обязан:
    <p><li>предоставлять по запросу пользователя информацию об обработке его персональных данных;</li></p>
    <p><li>принимать меры, необходимые и достаточные для обеспечения выполнения обязанностей;</li></p>
    <p>
      <li>
        по требованию пользователя уточнять обрабатываемые персональные данные, блокировать или удалять, если они
        являются неполными, устаревшими, неточными, незаконно полученными или ненужными для заявленной цели обработки;
      </li>
    </p>
    <p><li>обеспечить правомерность обработки персональных данных;</li></p>
    <p>
      <li>
        в случае, если обеспечить правомерность обработки персональных данных невозможно, Оператор в срок, не
        превышающий 10 рабочих дней с даты выявления неправомерной обработки персональных данных, обязан уничтожить или
        обеспечить их уничтожение;
      </li>
    </p>
    <p>
      <li>
        в случае отзыва пользователем согласия на обработку персональных данных прекратить их обработку и уничтожить в
        срок, не превышающий 30 дней с даты поступления указанного отзыва, за исключением случаев, когда обработка может
        быть продолжена в соответствии с законодательством.
      </li>
    </p>
    <p></p>
    <p>19. Пользователь вправе получать информацию об обработке персональных данных, содержащую:</p>
    <p><li>подтверждение факта обработки персональных данных;</li></p>
    <p><li>правовые основания обработки персональных данных;</li></p>
    <p><li>цели и применяемые Оператором способы обработки персональных данных;</li></p>
    <p>
      <li>
        обрабатываемые персональные данные, относящиеся к субъекту персональных данных, источник их получения, если иной
        порядок представления таких данных не предусмотрен российским и международным законодательством;
      </li>
    </p>
    <p><li>сроки обработки персональных данных, в том числе сроки их хранения;</li></p>
    <p><li>порядок осуществления прав, предусмотренных российским и международным законодательством;</li></p>
    <p>
      <li>
        сведения о лицах, которым могут быть раскрыты персональные данные на основании договора с Оператором или в
        соответствии с российским и международным законодательством;
      </li>
    </p>
    <p>
      <li>
        наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению
        Оператора, если обработка поручена или будет поручена такому лицу;
      </li>
    </p>
    <p><li>иные сведения, предусмотренные российским и международным законодательством.</li></p>
    <p><li>Пользователь вправе получать сведения неограниченное количество раз, направив Оператору запрос.</li></p>
  </div>

  <div class="contents__content">
    <h1>Требования к защите персональных данных</h1>
    <p>
      20. Защита персональных данных, обрабатываемых Оператором, обеспечивается реализацией правовых, организационных и
      технических мер, необходимых и достаточных для обеспечения требований законодательства в области защиты
      персональных данных.
    </p>
    <p>21. Правовые меры включают в себя:</p>
    <p>
      <li>
        разработку локальных актов Оператора, реализующих требования российского и международного законодательства, в
        том числе Политики обработки и защиты персональных данных, и размещение ее в Приложении;
      </li>
    </p>
    <p>
      <li>
        отказ от любых способов обработки персональных данных, не соответствующих целям, заранее предопределенным
        Оператором.
      </li>
    </p>
    <p>22. Организационные меры включают в себя:</p>
    <p><li>назначение лица, ответственного за организацию обработки персональных данных;</li></p>
    <p>
      <li>
        ограничение состава работников Оператора, имеющих доступ к персональным данным, и организацию разрешительной
        системы доступа к ним;
      </li>
    </p>
    <p>
      <li>
        ознакомление работников Оператора с положениями российского и международного законодательства о персональных
        данных, в том числе с требованиями к защите персональных данных, с локальными актами Оператора по вопросам
        обработки персональных данных, обучение указанных работников.
      </li>
    </p>
  </div>
  <div class="contents__content">
    <h1>Ограничение действия Политики​</h1>
    <p>
      23. Действие Политики распространяется исключительно на Приложение, и не применяется к другим ресурсам, в том
      числе ресурсам Оператора.
    </p>
    <p>
      24. Пользователь обязан разумно и ответственно подходить к размещению собственных персональных данных в
      Приложении.
    </p>
    <p>
      25. Оператор не несет ответственности за действия третьих лиц, получивших доступ к персональным данным
      Пользователя по вине последнего.
    </p>
  </div>
  <div class="contents__content">
    <h1>Обращения Пользователя​</h1>
    <p>
      26. Пользователь вправе направлять Оператору свои запросы, в том числе запросы относительно использования его
      персональных данных.
    </p>
    <p>27. Способы направления запросов:</p>
    <p><li>по электронной почте;</li></p>
    <p><li>почтовым отправлением;</li></p>
    <p><li>обращение к представителю Оператора.</li></p>
    <p>28. Запрос должен содержать следующую информацию:</p>
    <p><li>номер основного документа, удостоверяющего личность пользователя;</li></p>
    <p><li>сведения о дате выдачи указанного документа и выдавшем его органе;</li></p>
    <p><li>сведения, подтверждающие участие пользователя в отношениях с Оператором;</li></p>
    <p><li>подпись Пользователя.</li></p>
    <p>
      29. Оператор обязуется рассмотреть и направить ответ на поступивший запрос в течение 30 дней с момента поступления
      обращения.
    </p>
    <p>
      30. Вся корреспонденция, полученная Оператором от пользователя в письменной или электронной форме, относится к
      информации ограниченного доступа и не разглашается без его письменного согласия.
    </p>
  </div>
  <div class="contents__content">
    <h1>Заключительные положения</h1>
    <p>
      31. Используя и предоставляя свои персональные данные в Приложении, пользователь выражает свое согласие и дает
      разрешение на обработку своих персональных данных в порядке, предусмотренном Политикой.
    </p>
    <p>32. При изменении Политики, к отношениям возникшим до изменения, применяются условия актуальной редакции.</p>
    <p>
      33. Пользователь вправе отказаться от принятия изменений в Политику. Такой отказ будет означать отказ от
      использования Приложения, а также от всех предоставленных ранее прав. В случае несогласия Пользователя с условиями
      Политики использование Приложения должно быть немедленно прекращено.
    </p>
    <p>34. Компания вправе не уведомлять Пользователя об изменениях или дополнениях Политики.</p>
    <p>35. Пользователь самостоятельно знакомится с актуальной версией Политики, размещенной в Приложении.</p>
    <p>36. Согласие на обработку персональных данных может быть отозвано субъектом персональных данных в порядке.</p>

    <br />
  </div>
</div>
