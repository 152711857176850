import { FormValidationService } from '@core/services/business/utils/form-validation.service';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BaseClass } from '../base/base.class';

export abstract class BaseFormComponent extends BaseClass {
  protected constructor(protected formValidationService: FormValidationService) {
    super();
  }

  getValidatorErrorMessage(field: AbstractControl, specificErrorText?: string) {
    return this.formValidationService.getValidatorErrorMessage(field, specificErrorText);
  }

  validateAllFormFields(formGroup: FormGroup) {
    this.formValidationService.validateAllFormFields(formGroup);
  }

  validateFormControl(control: AbstractControl) {
    this.formValidationService.validateFormControl(control);
  }

  isControlInvalid(control: AbstractControl): boolean {
    return this.formValidationService.isControlInvalid(control);
  }

  isControlInvalidReactive(control: AbstractControl): boolean {
    return this.formValidationService.isControlInvalidReactive(control);
  }
}
