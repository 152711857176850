import { Injectable } from '@angular/core';
import { ForumBranchHttpService } from '@core/services/api/forums/forum-branch-http.service';
import { IdName } from '@shared/models/id-name.model';
import { KeyValue } from '@shared/models/key-value.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ForumBranchService {
  industries: IdName[] = [];

  constructor(private forumBranchHttpService: ForumBranchHttpService) {}

  getBranchesAndAddToBranches(targetBranches?: IdName[], params?: KeyValue<string>, paramsString?: string): Observable<IdName[]> {
    return this.forumBranchHttpService.getBranches(params, paramsString).pipe(
      map((response) => {
        if (!targetBranches) {
          targetBranches = [];
        }
        targetBranches.push(...response.results);

        return targetBranches;
      }),
    );
  }

  getById(id: number): Observable<IdName> {
    return this.forumBranchHttpService.getBranchById(id);
  }
}
