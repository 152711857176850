export enum ForumsTypeKey {
  MyForumsStaff = '1',
  MyForumsMember = '2',
  Finished = '3',
  Published = '4',
  AllForums = '5',
}

export enum ForumStateKey {
  Draft = '0',
  Published = '1',
  Finished = '2',
  Cancelled = '3',
}

export enum ForumMemberStateKey {
  Consist = '0',
  Refused = '1',
  CameOut = '2',
  WaitAnswer = '3',
  SentRequest = '4',
  Invited = '5',
  Denied = '6',
}

export enum ForumOrMeetingType {
  General = '0',
  Industry = '1',
}

export enum ForumRelatedFieldKey {
  /**
   * Data fields that exist in the backend
   */
  Administrator = 'administrator',
  Avatar = 'avatar',
  Branch = 'branch',
  Constitution = 'constitution',
  ConstitutionFilename = 'constitution_filename',
  ConstitutionSize = 'constitution_size',
  Created = 'created',
  Creator = 'creator',
  Curator = 'curator',
  Description = 'description',
  Editors = 'editors',
  FinishedAt = 'finished_at',
  FinishedMeetingsCount = 'finished_meetings_count',
  ForumYear = 'forum_year',
  Id = 'id',
  Industry = 'industry',
  LastLaunchStartedAt = 'last_launch_started_at',
  MeetingsCount = 'meetings_count',
  MeetingsLeftCount = 'meetings_left_count',
  MembersCount = 'members_count',
  Moderator = 'moderator',
  MonthsLeft = 'months_left',
  Name = 'name',
  Specific = 'specific',
  StartedAt = 'started_at',
  State = 'state',
  Type = 'type',
  UniqueBranchNumber = 'unique_branch_number',
  Updated = 'updated',

  /**
   * Data fields that exist only in the web interface
   */
  BranchRequired = 'branchRequired',
  EditPageRoute = 'editPageRoute',
  Files = 'files',
  IsDeleting = 'isDeleting',
  IsEditing = 'isEditing',
  IsLoading = 'isLoading',
  ForumPageRoute = 'forumPageRoute',
  MenuOptionsInList = 'menuOptionsInList',
  MenuOptionsOnProfilePage = 'menuOptionsOnProfilePage',
  Links = 'links',

  /**
   * Fields are used only for filters, exist in the backend
   */
  Limit = 'limit',
  Offset = 'offset',
  User = 'user',

  /**
   * Fields are used only for filters, exist only in the web interface
   */
  AdministratorFromDialog = 'administratorFromDialog',
  ForumType = 'forum_type',
  NameFromDialog = 'nameFromDialog',
  StateFromDialog = 'stateFromDialog',
  UserFromDialog = 'userFromDialog',
}
