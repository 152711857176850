<div class="dp__item" [class.custom-error]="ngControl.hasError('customError')" [formGroup]="dateTime" >
  <mat-form-field appearance="outline" class="no-padding custom-width no-focus">
    <mat-label>{{dateLabel}}</mat-label>

    <input
      matInput
      [matDatepicker]="dp"
      formControlName="date"
      placeholder="дд.мм.гггг"
      kpOnlyNumbersAndSeparator
    />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="custom-width no-focus" >
    <mat-label>{{timeLabel}}</mat-label>

    <input
      matInput
      type="text"
      mask="00:00"
      [clearIfNotMatch]="true"
      formControlName="time"
      placeholder={{timeLabel}}
      (blur)="onBlur()"
    />

    <kp-svg class="dp__item--clock-icon" key="clockIcon" size="20px"></kp-svg>
  </mat-form-field>

  <mat-error class="dp__item--error-msg" *ngIf="ngControl.hasError('customError')">{{ngControl.errors.customError}}</mat-error>
</div>

