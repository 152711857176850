export enum NotificationTypeKey {
  Default = '0',
  Access = '1',
  Posts = '2',
  Forums = '3',
  Events = '4',
  Meetings = '5',
  Projects = '6',
  Payment = '7',
  Gratitudes = '8',
}

export enum NotificationRelatedFieldKey {
  /**
   * Data fields that exist in the backend
   */
  Category = 'category',
  CreateDate = 'create_date',
  Detail = 'detail',
  IsRead = 'is_read',
  Recipient = 'recipient',
  ShortDescriptoin = 'short_description',
  TaskId = 'task_id',
  NotificationType = 'notification_type',

  /**
   * Fields are used only for filters, exist in the backend
   */
  Limit = 'limit',
  Offset = 'offset',
  Type = 'type',
}

export enum NotificationsWsEventType {
  NotificationNew = 1,
  Pong = 25,
}
