import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from '@core/services/business/utils/utils.service';
import { IFile, IFileDto } from '@shared/models/file.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostCommentFileHttpService {
  private readonly _apiPath = '/newsline/comment-file/';

  constructor(private http: HttpService, private utilsService: UtilsService) {}

  getFiles(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IFile>> {
    return this.http.get<ArrayPayload<IFile>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getFileById(id: number): Observable<IFile> {
    return this.http.get<IFile>(`${this._apiPath}${id}/`);
  }

  createFile(file: IFileDto): Observable<IFile> {
    const fileFD = this.utilsService.transformObjectToFormData(file);
    return this.http.post<IFile>(this._apiPath, fileFD);
  }

  updateFileById(id: number, file: IFileDto): Observable<IFile> {
    const fileFD = this.utilsService.transformObjectToFormData(file);
    return this.http.patch<IFile>(`${this._apiPath}${id}/`, fileFD);
  }

  deleteFileById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }
}
