import { NotificationTypeKey } from '../enums/keys.enum';

export const NOTIFICATION_TYPE_KEY_TO_VIEW_NAME: Record<NotificationTypeKey, string> = {
  [NotificationTypeKey.Default]: 'Все',
  [NotificationTypeKey.Access]: 'Участники',
  [NotificationTypeKey.Posts]: 'Посты',
  [NotificationTypeKey.Forums]: 'Форумы',
  [NotificationTypeKey.Events]: 'Мероприятия',
  [NotificationTypeKey.Meetings]: 'Встречи',
  [NotificationTypeKey.Projects]: 'Проекты',
  [NotificationTypeKey.Payment]: 'Оплата',
  [NotificationTypeKey.Gratitudes]: 'Благодарности',
};
