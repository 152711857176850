<div class="form-control" [ngClass]="{ 'form-control--invalid': ngControl.invalid && ngControl.touched }">
  <div class="form-control__input" [style.height]="inputHeight">
    <kp-svg *ngIf="icon" [key]="icon" size="14px" [color]="iconColor"></kp-svg>

    <input
      #input
      [attr.placeholder]="inputPlaceholder"
      type="text"
      [ngModel]="model"
      (ngModelChange)="onModelChange($event)"
    />

    <kp-svg
      *ngIf="allowClearButton && model?.length > 0"
      (click)="onClear()"
      [key]="'xIcon2'"
      size="24px"
      color="#A1A4AD"
    ></kp-svg>
  </div>

  <div class="form-control__error" *ngIf="ngControl.invalid && ngControl.touched">
    {{ getValidatorErrorMessage(ngControl) }}
  </div>
</div>
