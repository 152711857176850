import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kp-stuff-list',
  templateUrl: './stuff-list.html',
  styleUrls: ['./stuff-list.scss'],
})
export class StuffListComponent implements OnInit {
  file = { filename: 'test-name', file_size: '35325' };
  link = { linkname: 'Диаграмма Ганта', link_rout: 'www.test.ru' };

  constructor() {}

  ngOnInit(): void {}
}
