import { Component, Input } from '@angular/core';

@Component({
  selector: 'kp-last-activity-indicator',
  templateUrl: './last-activity-indicator.component.html',
  styleUrls: ['./last-activity-indicator.component.scss'],
})
export class LastActivityIndicatorComponent {
  @Input() public value: string = '';
}
