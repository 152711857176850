import { EventStateKey } from '../enums/keys.enum';

export const EVENT_STATE_KEY_TO_VIEW_NAME: Record<EventStateKey, string> = {
  [EventStateKey.Draft]: 'Черновик',
  [EventStateKey.Published]: 'Опубликовано',
  [EventStateKey.Finished]: 'Завершено',
  [EventStateKey.Cancelled]: 'Отменено',
};

export interface IEventState {
  color: string;
  id: string;
  statusName: string;
}

export const EVENT_STATE: IEventState[] = [
  {
    id: EventStateKey.Draft,
    color: 'lightgray',
    statusName: EVENT_STATE_KEY_TO_VIEW_NAME[0],
  },
  {
    id: EventStateKey.Published,

    color: 'linear-gradient(90deg, #2a5d45 0.12%, #73ffbd 100.3%)',
    statusName: EVENT_STATE_KEY_TO_VIEW_NAME[1],
  },
  {
    id: EventStateKey.Finished,
    color: 'linear-gradient(270deg, rgba(224, 113, 255, 0.8) -0.44%, #522A5D 99.75%)',
    statusName: EVENT_STATE_KEY_TO_VIEW_NAME[2],
  },
  {
    id: EventStateKey.Cancelled,
    color: 'linear-gradient(90deg, #D12036 0.12%, #FFADB7 100.3%)',
    statusName: EVENT_STATE_KEY_TO_VIEW_NAME[3],
  },
];
