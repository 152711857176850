<div class="header">
  <div *ngIf="title" class="title">{{ title }}</div>

  <kp-svg
    class="close-modal"
    color="#ffffff"
    [key]="'xIcon2'"
    size="24px"
    [cursor]="'pointer'"
    (click)="onCloseModal()"
  ></kp-svg>
</div>

<div class="container">
  <image-cropper
    format="png"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    [cropperMinWidth]="230"
    [cropperMinHeight]="230"
    [imageFile]="imageFile"
    [roundCropper]="true"
    (imageCropped)="croppedImageBase64 = $event.base64"
  ></image-cropper>

  <div class="buttons-container">
    <button type="button" class="button button--cancel" (click)="closeDialog()">Отменить</button>
    <button type="button" class="button" (click)="closeDialog(true)">Установить фото</button>
  </div>
</div>
