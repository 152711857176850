import { Component, Input } from '@angular/core';
import svgIcons from '../../../../assets/svg/svg-icons';

@Component({
  selector: 'kp-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
  @Input() avatarUrl: string;
  @Input()
  avatarSize: number = 46;
  @Input()
  placeholderIcon: keyof typeof svgIcons | null = null;
  @Input()
  placeholderColor: string | null = 'white';
  @Input() username: string = '';
}
