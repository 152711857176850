<table
  class="table"
  [ngClass]="{
    'table--ml-first-th-and-td': !config.rowsSelectEnabled
  }"
>
  <tr class="table-head">
    <!-- Хидер для стобца с чекбоксами -->
    <th class="table-head__th-content table-head__th-content--action" *ngIf="config.rowsSelectEnabled">
      <mat-checkbox
        *ngIf="config.canSelectAllRows"
        class="checkbox checkbox--inline-block"
        [indeterminate]="isSomeRowsSelected"
        [checked]="config.isAllRowsSelected"
        (change)="emitAllRowsSelectedStateSwitchedEvent()"
      ></mat-checkbox>
    </th>

    <!-- Хидеры столбцов с данными -->
    <th
      *ngFor="let col of config.columns"
      class="table-head__th-content {{ col.customClass }}"
      [ngClass]="{ 'table-head__th-content--interactive': col.sortEnabled }"
      [ngStyle]="col.styles"
      (click)="sortByColumn(col)"
    >
      <div class="table-head__th-text-and-icon">
        <div class="table-head__th-text">
          {{ col.headerName }}
        </div>

        <div class="table-head__sort-icons" *ngIf="col.sortingType">
          <kp-svg
            key="triangle"
            height="5px"
            width="7px"
            class="table-head__sort-icon"
            [ngClass]="{ 'table-head__sort-icon--active': col.sortingType === sortType.Ascending }"
          ></kp-svg>
          <kp-svg
            key="triangle"
            height="5px"
            width="7px"
            class="table-head__sort-icon"
            [ngClass]="{ 'table-head__sort-icon--active': col.sortingType === sortType.Descending }"
          ></kp-svg>
        </div>
      </div>
    </th>

    <!-- Хидер для столбца с контекстными меню данных. Содержит в себе иконку для настройки такблицы -->
    <th class="table-head__th-content table-head__th-content--action" *ngIf="config.rowsSettingsEnabled">
      <kp-svg key="settings" size="16px" class="table-head__settins-icon" (click)="colSettingsIconClickedEvent.emit()"></kp-svg>
    </th>
  </tr>

  <tr
    class="table-row"
    [class]="row.customClass"
    [ngClass]="{ 'table-row--clickable': config.rowsClickable }"
    [ngStyle]="row.styles"
    *ngFor="let row of config.rows"
    (click)="emitRowClickedEvent(row)"
  >
    <!-- Столбец с чекбоксами -->
    <td class="table-row__td-content table-row__td-content--action" *ngIf="config.rowsSelectEnabled">
      <mat-checkbox
        class="checkbox checkbox--inline-block"
        [checked]="isRowSelected(row)"
        (change)="switchRowSelectState(row)"
        (click)="$event.stopPropagation()"
      ></mat-checkbox>
    </td>

    <!-- Столбцы с данными -->
    <td
      *ngFor="let col of config.columns"
      class="table-row__td-content {{ col.customClass }}"
      [ngStyle]="getTrTdContentStyles(col, row, getRowDataItemByKey(row, col.dataKey))"
    >
      <!-- Если значение ячейки = ссылка на другую страницу сайта -->
      <a
        class="table-row__td-content-item table-row__td-content-item--link"
        [class]="getRowDataItemCustomClass(col, row)"
        *ngIf="col.dataItemIsRoute && !getRouterLink(col, row, col.keyOfParameterWithRouteInData).isEmpty; else rowItemContentTemplateImage"
        [routerLink]="[getRouterLink(col, row, col.keyOfParameterWithRouteInData).value]"
      >
        <ng-container *ngTemplateOutlet="rowItemContentTemplate"></ng-container>
      </a>

      <!-- Если значение ячейки = картинка -->
      <ng-template #rowItemContentTemplateImage>
        <img
          class="circle-img circle-img--md"
          [class]="getRowDataItemCustomClass(col, row)"
          *ngIf="
            col.dataIsImage && !getRowDataValueItemByKey(col, row, col.dataKey, col.subDataKey).isEmpty;
            else rowItemContentTemplateSelect
          "
          [src]="getRowDataValueItemByKey(col, row, col.dataKey, col.subDataKey).value"
        />
      </ng-template>

      <!-- Если значение ячейки = селект -->
      <ng-template #rowItemContentTemplateSelect>
        <kp-select
          *ngIf="col.dataItemIsSelectOption && col.selectOptions?.length; else rowItemContentTemplate"
          [selectedOptionValue]="
            !getRowDataValueItemByKey(col, row, col.dataKey, col.subDataKey).isEmpty
              ? getRowDataValueItemByKey(col, row, col.dataKey, col.subDataKey).value
              : null
          "
          [optionsUpdatorField]="selectUpdatorField.Key"
          [options]="col.selectOptions"
          [barViewMode]="selectBarViewMode.Light"
          [barHeight]="'32px'"
          (selectedOptionChangeEvent)="optionSelected(col, row, $event)"
        ></kp-select>
      </ng-template>

      <!-- Если значение ячейки = обычный текст -->
      <ng-template #rowItemContentTemplate>
        <div
          ngxCut
          [size]="3"
          [class]="getRowDataItemCustomClass(col, row)"
          (click)="col.isCellsClickEmitData ? cellClickedEventEmitter(col, row) : null"
        >
          {{ getRowDataValueItemByKey(col, row, col.dataKey, col.subDataKey).value }}
        </div>
      </ng-template>
    </td>

    <!-- Столбец с контекстными меню -->
    <td class="table-row__td-content table-row__td-content--action" *ngIf="config.rowsSettingsEnabled">
      <kp-select
        *ngIf="row.rowMenuOptions?.length || config.rowsMenuOptions?.length"
        [options]="row.rowMenuOptions || config.rowsMenuOptions"
        barIconKey="threeDots"
        barIconHeight="18px"
        barIconWidth="18px"
        [mode]="selectMode.Menu"
        (selectedOptionChangeEvent)="rowMenuSelectedEventEmitter(row, $event)"
        (click)="$event.stopPropagation()"
      ></kp-select>
    </td>
  </tr>
</table>
