import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'kp-block-placeholder',
  templateUrl: './block-placeholder.component.html',
  styleUrls: ['./block-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockPlaceholderComponent {
  constructor() {}
}
