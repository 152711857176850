export enum OptionSourceKey {
  BusinessesTurnovers = 'businessesTurnovers',
  Industries = 'industries',
  Forums = 'forums',
  ForumLocations = 'forumLocations',
  Meetings = 'meetings',
  MeetingAddress = 'meetingAddress',
  StaffMembers = 'staffMembers',
  Users = 'users',
  ForumMemberReasonsForLeaving = 'forumMemberReasonsForLeaving',
  MeetingMemberReasonsOfRefusal = 'meetingMemberReasonsOfRefusal',
}
