import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormValidationService } from '@core/services/business/utils/form-validation.service';
import { BaseFormComponent } from '@shared/components/base-form/base-form.component';
import { IconTextDialogComponent } from '@shared/dialogs/icon-text-dialog/icon-text-dialog.component';

@Component({
  selector: 'kp-profile-recommend-member-dialog',
  templateUrl: './profile-recommend-member-dialog.component.html',
  styleUrls: ['./profile-recommend-member-dialog.component.scss'],
})
export class ProfileRecommendMemberDialogComponent extends BaseFormComponent implements OnInit {
  constructor(
    protected formValidationService: FormValidationService,
    private dialogRef: MatDialogRef<ProfileRecommendMemberDialogComponent>,
    private dialog: MatDialog,
  ) {
    super(formValidationService);
  }

  ngOnInit() {}

  closeDialog(success?: boolean) {
    this.dialogRef.close();

    if (success) {
      this._openSuccessResultDialog();
    }
  }

  private _openSuccessResultDialog() {
    this.dialog.open(IconTextDialogComponent, {
      data: { text: 'Ваша рекомендация отправлена на рассмотрение администрации' },
    });
  }
}
