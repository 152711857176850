import { EventEmitter, Injectable } from '@angular/core';
import { FilterSeparator } from '@shared/enums/filter/filter-separator.enum';
import { SortType } from '@shared/enums/filter/sort-type.enum';
import { CommonFilterKey } from '@shared/enums/keys.enum';
import { TableRowCellMode } from '@shared/enums/table.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ITableColumn } from '@shared/models/table/table-column.model';
import { ITableRow, ITableRowDataItem, TableRowDataItemValueType } from '@shared/models/table/table-row.model';
import * as _ from 'lodash';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  readonly rowDefaultStyles = {
    height: '72px',
  };

  constructor(private filterService: FilterService) {}

  transformArrayToTableRows(
    data: any[],
    optionsKeyInDataItem?: string,
    rowDefaultStyles = this.rowDefaultStyles,
    tableRowsCustomData?: ITableRowDataItem[],
  ): ITableRow[] {
    return data.map(
      (item) =>
        ({
          data: Object.entries(item).map(([key, value]) => {
            const customData = _.cloneDeep(tableRowsCustomData?.find((d) => d.key === key));

            if (customData) {
              customData.value = value as TableRowDataItemValueType;
              return customData;
            }

            return {
              mode: TableRowCellMode.Default,
              key,
              value,
            } as ITableRowDataItem;
          }),
          enabled: true,
          rowMenuOptions: item[optionsKeyInDataItem],
          styles: rowDefaultStyles,
        } as ITableRow),
    );
  }

  transformTableRowsToArray(rows: ITableRow[]): any[] {
    return rows.map((row) => this.transformTableRowToDataItem(row));
  }

  transformTableRowToDataItem(row: ITableRow): any {
    const dataItem: KeyValue<any> = {};
    row.data.forEach((item) => {
      dataItem[item.key] = item.value;
    });
    return dataItem;
  }

  checkAndSetSortingStateByFilterService(cols: ITableColumn[]) {
    this.resetColsSortingType(cols);

    let currentOrderingKey = this.filterService.filters.find((f) => f.field === CommonFilterKey.Ordering)?.value;
    if (currentOrderingKey) {
      let currentSortingType: SortType;

      if (currentOrderingKey[0] === FilterSeparator.DescendingOrderingKey) {
        currentSortingType = SortType.Descending;
        currentOrderingKey = currentOrderingKey.slice(1);
      } else {
        currentSortingType = SortType.Ascending;
      }

      const targetColIdx = cols.findIndex((col) => col.dataKey === currentOrderingKey);
      if (targetColIdx !== -1 && cols[targetColIdx].sortEnabled) {
        cols[targetColIdx].sortingType = currentSortingType;
      }
    }
  }

  sortByColumn(cols: ITableColumn[], col: ITableColumn, emitter: EventEmitter<IFilter>): void {
    if (!col.sortEnabled) {
      return;
    }

    this.resetColsSortingType(cols, [col]);

    if (!col.sortingType) {
      col.sortingType = SortType.Ascending;
    } else if (col.sortingType === SortType.Ascending) {
      col.sortingType = SortType.Descending;
    } else {
      col.sortingType = null;
    }

    const filter = this.getOrderingFilterByTableColumn(col);
    emitter.emit(filter);
  }

  resetColsSortingType(targetCols: ITableColumn[], excludeCols?: ITableColumn[]) {
    targetCols?.forEach((c) => {
      if (!excludeCols?.some((col) => col.headerKey === c.headerKey)) {
        c.sortingType = null;
      }
    });
  }

  getOrderingFilterByTableColumn(col: ITableColumn): IFilter {
    const filter: IFilter = {
      field: CommonFilterKey.Ordering,
      value: null,
    };

    if (col.sortingType === SortType.Ascending) {
      filter.value = col.dataKey;
    } else if (col.sortingType === SortType.Descending) {
      filter.value = `-${col.dataKey}`;
    }

    return filter;
  }

  transformValueToViewContent(col: ITableColumn, value: TableRowDataItemValueType): { value: string; isEmpty: boolean } {
    let result: string;

    if (col.dataIsArray) {
      result = (value as [])?.join(col.dataArrayItemsConnector);
    } else if (col.dataItemIsDate && col.dateFormat) {
      result = (value as moment.Moment)?.format(col.dateFormat);
    } else if (col.dataKeyOfEnum && col.dataEnum) {
      result = col.dataEnum[value as string];
    } else {
      result = value?.toString();
    }

    return { value: result || '—', isEmpty: !result };
  }

  getTrTdContentStyles(col: ITableColumn, row: ITableRow, item: ITableRowDataItem): KeyValue<string> {
    return {
      height: row.styles?.height,
      ...col.itemsStyles,
      ...item?.styles,
    };
  }

  getRowDataValueItemByKey(col: ITableColumn, row: ITableRow, dataKey?: string, subDataKey?: string): { value: string; isEmpty: boolean } {
    let value = this.getRowDataItemByKey(row, dataKey)?.value;

    if (value && subDataKey) {
      value = (value as KeyValue<any>)[subDataKey];
    }

    return this.transformValueToViewContent(col, value);
  }

  getRouterLink(col: ITableColumn, row: ITableRow, keyOfParameterWithRouteInData: string): { value: string; isEmpty: boolean } {
    if (col.subDataKey) {
      return this.getRowDataValueItemByKey(col, row, col.dataKey, keyOfParameterWithRouteInData);
    }
    return this.getRowDataValueItemByKey(col, row, keyOfParameterWithRouteInData);
  }

  getRowDataItemCustomClass(col: ITableColumn, row: ITableRow): string {
    const rowDataItem = this.getRowDataItemByKey(row, col.dataKey);
    return `${col.itemsCustomClass} ${(rowDataItem as ITableRowDataItem)?.customClass}`;
  }

  getRowDataItemByKey(row: ITableRow, dataKey?: string): ITableRowDataItem {
    return row.data.find((item) => item.key === dataKey);
  }

  isRowSelected(row: ITableRow): boolean {
    return row.data.some((item) => item.key === 'isSelected' && ((item.value as unknown) as boolean) === true);
  }

  isSomeRowsSelected(rows: ITableRow[]): boolean {
    return rows.some((row) => this.isRowSelected(row));
  }
}
