import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { IMessageUploadProcessing } from '@shared/models/messages/dto/chat-message-dto.model';
import { map } from 'rxjs/operators';
import { getPercentage } from '@shared/utils/utils';

@Component({
  selector: 'kp-chat-message-files-upload-progress-snackbar',
  templateUrl: './chat-message-files-upload-progress-snackbar.component.html',
})
export class ChatMessageFilesUploadProgressSnackbarComponent {
  public readonly processingPercentage$: Observable<string> = this.data.done.pipe(
    map((done: number) => getPercentage(done, this.data.total) as string),
  );

  constructor(@Inject(MAT_SNACK_BAR_DATA) private readonly data: IMessageUploadProcessing) {}
}
