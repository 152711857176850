import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  convertBase64ToFile(base64: string, fileName: string, mymeType: string): File {
    const blob = this.dataURItoBlob(base64, mymeType);
    const file = new File([blob], fileName, { type: mymeType });
    return file;
  }

  dataURItoBlob(dataURI: string, mymeType: string): Blob {
    const startIndex = dataURI.indexOf('base64,') + 7;
    const b64 = dataURI.substr(startIndex);

    const byteString = window.atob(b64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const byteArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i += 1) {
      byteArray[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([byteArray], { type: mymeType });
    return blob;
  }

  prepareAndDownloadFile(response: Blob, fileName: string) {
    const newBlob = new Blob([response], { type: response.type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      window.URL.revokeObjectURL(data);
      link.remove();
    });
  }
}
