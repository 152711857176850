import { Locale } from '@shared/declarations/locale.type';

export const EMOJI_RU_LOCALE: Locale = {
  search: 'Поиск',
  emojilist: 'Список Emoji',
  notfound: 'Emoji не найдены',
  clear: 'Очистить',
  categories: {
    search: 'Результаты поиска',
    recent: 'Часто используемые',
    people: 'Люди и лица',
    nature: 'Животные и природа',
    foods: 'Еда и напитки',
    activity: 'Активности',
    places: 'Места и путешествия',
    objects: 'Объекты',
    symbols: 'Символы',
    flags: 'Флаги',
    custom: 'Персонализированные',
  },
};
