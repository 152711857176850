<div class="calendarcontainer container">
  <div class="row">
    <div *ngFor="let Month of calendar; let m = index" class="col-md-3 col-sm-6 col-xs-12">
      <div class="monthcontainer">
        <p class="monthname">{{ Month.date | date: 'LLLL' }}</p>
        <div class="flexdays week-days">
          <div class="day" *ngFor="let label of days">
            {{ label }}
          </div>
        </div>
        <div *ngFor="let week of Month.days" class="flexdays">
          <div
            *ngFor="let day of week; let i = index"
            [ngClass]="day ? (day.istoday ? 'todayclass' : day.nb > 0 ? 'haveevents' : '') : 'void_day'"
            [style.background-image]="day ? 'linear-gradient(120deg, ' + day.colors + ',#fff)' : ''"
            class="day"
            placement="right"
            (onShown)="dayindex = i; getTodayEvents(day, m)"
          >
            <!--
              17 строка
              [outsideClick]="true"
            [popover]="yearcalendarpoptemplate" -->
            {{ day?.day }}

            <!-- Здесь место для отображения выделения, есть ли встреча в дне -->
            <kp-svg
              *ngIf="meetingDates?.includes(day?.dayDate)"
              [key]="'isMeetIcon'"
               class="notification-circle-icon"
              size="5px"
              style="padding: 0px 0px 5px 1px"
            ></kp-svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #yearcalendarpoptemplate>
  <div class="col-md-12 col-calendar">
    <p [ngClass]="daydetails.istoday ? 'todaytext' : ''" class="pop_year_day">{{ days[dayindex] }}.</p>
    <p [ngClass]="daydetails.istoday ? 'todaytext' : ''" class="pop_year_day_number">{{ daydetails?.day }}</p>
    <div *ngIf="!loader">
      <div *ngFor="let event of daydetails.events">
        <a
          [style.color]="event.color.primary + '!importants'"
          class="icon-action-calendar"
          *ngFor="let action of event.actions"
          [innerHTML]="action.label"
          (click)="actionClickedFn(action.name, event)"
        >
        </a>
        <div
          class="circle_day_color"
          [style.background]="event.color.secondary"
          [style.border-color]="event.color.primary"
        ></div>
        <p class="pop_year_event_title" (click)="eventClickedFn(event)">
          <span>
            {{ event.start | date: 'HH:mm' }}
          </span>
          {{ event.title }}
        </p>
      </div>
    </div>
    <div class="progressbar_popyear" *ngIf="!daydetails.events || (daydetails.events.length == 0 && !loader)">
      There are no events scheduled that day.
    </div>
    <div [hidden]="!loader" class="loader_popover_calendar"></div>
  </div>
</ng-template>
