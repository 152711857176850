import { Injectable } from '@angular/core';
import { MeetingAddressHttpService } from '@core/services/api/meetings/meeting-address-http.service';
import { IdName } from '@shared/models/id-name.model';
import { KeyValue } from '@shared/models/key-value.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeetingAddressService {
  industries: IdName[] = [];

  constructor(private meetingAddressHttpService: MeetingAddressHttpService) {}

  getAddressesAndAddToАddresses(targetАddresses?: IdName[], params?: KeyValue<string>, paramsString?: string): Observable<IdName[]> {
    return this.meetingAddressHttpService.getАddresses(params, paramsString).pipe(
      map((response) => {
        if (!targetАddresses) {
          targetАddresses = [];
        }
        targetАddresses.push(...response.results);

        return targetАddresses;
      }),
    );
  }

  getAddressById(id: number): Observable<IdName> {
    return this.meetingAddressHttpService.getАddressById(id);
  }
}
