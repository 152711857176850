import { MeetingTypeKey } from '@modules/meetings/enums/keys.enum';
import { PostTypeKey } from '@modules/newsline/enums/keys.enum';
import { DATE_FULL_MONTH_WORD } from '@shared/constants/common.const';
import { NewslineScrollSelector, UserRelatedFieldKey } from '@shared/enums/keys.enum';
import { IFilter } from '@shared/models/filter/filter.model';
import { IForumAndMeetingModulesConfig } from '@shared/models/forums/view/forum-meeting-config.model';
import { IForumMemberTileViewConfig } from '@shared/models/forums/view/forum-member.model';
import { INewslineConfig } from '@shared/models/newsline/view/newsline-config.model';
import { ITableColumn } from '@shared/models/table/table-column.model';
import { ForumOrMeetingType, ForumRelatedFieldKey, ForumsTypeKey } from '../enums/keys.enum';
import { FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME, FORUM_STATE_KEY_TO_VIEW_NAME } from './mappers.const';

/**
 * Конфиг для модуля ОБЫЧНЫХ форумов
 */
export const FORUM_AND_MEETING_CONFIG_COMMON: IForumAndMeetingModulesConfig = {
  pathToForumsBootstrap: '/forums',
  pathToMeetingsBootstrap: '/meetings',
  mainForumsListTitle: 'Форумы',
  mainMeetingsListTitle: 'Встречи',

  forumOrMeetingType: ForumOrMeetingType.General,
  isCurrentTypeCommon: true,
  isCurrentTypeIndustry: false,

  isForumNameRequired: false,
  isForumHaveFirstMeetVote: true,
  isForumHaveIndustry: false,
  isForumHaveSpecific: false,
  isForumHaveModerator: true,
  isForumHaveCurator: true,
  isForumHaveConstitution: true,

  isMeetingNameRequired: false,
  isUserCanSetMeetingType: true,
};

/**
 * Конфиг для модуля ОТРАСЛЕВЫХ форумов
 */
export const FORUM_AND_MEETING_CONFIG_INDUSTRY: IForumAndMeetingModulesConfig = {
  pathToForumsBootstrap: '/industry-forums',
  pathToMeetingsBootstrap: '/industry-meetings',
  mainForumsListTitle: 'Отраслевые форумы',
  mainMeetingsListTitle: 'Отраслевые встречи',

  forumOrMeetingType: ForumOrMeetingType.Industry,
  isCurrentTypeCommon: false,
  isCurrentTypeIndustry: true,

  isForumNameRequired: true,
  isForumHaveFirstMeetVote: false,
  isForumHaveIndustry: true,
  isForumHaveSpecific: true,
  isForumHaveModerator: false,
  isForumHaveCurator: false,
  isForumHaveConstitution: false,

  isMeetingNameRequired: true,
  isUserCanSetMeetingType: false,
  specifiedMeetingType: MeetingTypeKey.Common,
};

export const FORUM_AND_MEETING_CONFIG: { config?: IForumAndMeetingModulesConfig } = {};

/**
 * Конфиг ленты новостей для ОБЫЧНОГО форума
 */
export const COMMON_FORUM_NEWSLINE_CONFIG: INewslineConfig = {
  scrollEventsTargetClassName: NewslineScrollSelector.ForumPage,
  pagePaddingsEnabled: false,
  showNewslineTitle: false,
  showPostsPeriodFilter: false,
  showAfishaPostTypeFilter: false,
  showAdvertisingBanner: false,
  showUpcomingEvents: false,
  showNoDataIfNoPosts: false,
  setFiltersInUrl: false,
  isForumHaveFirstMeetVote: true,

  showAfishaPostTypeInput: false,
  showTargetObjectInput: false,

  postTypeKey: PostTypeKey.Forums,
};

/**
 * Конфиг ленты новостей для ОТРАСЛЕВОГО форума
 */
export const INDUSTRY_FORUM_NEWSLINE_CONFIG: INewslineConfig = { ...COMMON_FORUM_NEWSLINE_CONFIG, isForumHaveFirstMeetVote: false };

export const FORUM_MEMBERS_FILTERS_TILES_IGNORE_URL: UserRelatedFieldKey[] = [UserRelatedFieldKey.Limit, UserRelatedFieldKey.Offset];

export const FORUMS_FILTERS_IGNORE_URL: ForumRelatedFieldKey[] = [ForumRelatedFieldKey.Limit];

export const FORUMS_LIST_DEFAULT_OFFSET = '0';
export const FORUMS_LIST_DEFAULT_LIMIT = '8';

export const FORUMS_MEMBERS_LIST_DEFAULT_OFFSET = '0';
export const FORUM_MEMBERS_LIST_DEFAULT_LIMIT = '20';

const FORUM_MEMBERS_PAGE_FILTERS: IFilter[] = [
  {
    field: ForumRelatedFieldKey.Offset,
    value: FORUMS_MEMBERS_LIST_DEFAULT_OFFSET,
  },
];

export const FORUM_MEMBERS_PAGE_FILTERS_TILES: IFilter[] = [
  ...FORUM_MEMBERS_PAGE_FILTERS,
  {
    field: ForumRelatedFieldKey.Limit,
    value: FORUM_MEMBERS_LIST_DEFAULT_LIMIT,
  },
];

export const FORUM_MEMBERS_DEFAULT_FILTERS_TILES: IFilter[] = [...FORUM_MEMBERS_PAGE_FILTERS_TILES];

export const FORUMS_PAGE_FILTERS: IFilter[] = [
  {
    field: ForumRelatedFieldKey.Offset,
    value: FORUMS_LIST_DEFAULT_OFFSET,
  },
  {
    field: ForumRelatedFieldKey.Limit,
    value: FORUMS_LIST_DEFAULT_LIMIT,
  },
];

export const FORUMS_DEFAULT_FILTERS: IFilter[] = [
  ...FORUMS_PAGE_FILTERS,
  { groupField: ForumRelatedFieldKey.ForumType, groupValue: ForumsTypeKey.AllForums },
];

export const FORUMS_TABLE_COLUMNS: ITableColumn[] = [
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.UniqueBranchNumber],
    headerKey: ForumRelatedFieldKey.UniqueBranchNumber,
    dataKey: ForumRelatedFieldKey.UniqueBranchNumber,
    isRequired: true,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Industry],
    headerKey: ForumRelatedFieldKey.Industry,
    dataKey: ForumRelatedFieldKey.Industry,
    subDataKey: 'name',
    isRequired: true,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Avatar],
    headerKey: ForumRelatedFieldKey.Avatar,
    dataKey: ForumRelatedFieldKey.Avatar,
    dataIsImage: true,
    styles: {
      width: '80px',
    },
    itemsStyles: {
      width: '80px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Branch],
    headerKey: ForumRelatedFieldKey.Branch,
    dataKey: ForumRelatedFieldKey.Branch,
    subDataKey: 'name',
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Branch],
    headerKey: ForumRelatedFieldKey.BranchRequired,
    dataKey: ForumRelatedFieldKey.Branch,
    subDataKey: 'name',
    isRequired: true,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Name],
    headerKey: ForumRelatedFieldKey.Name,
    dataKey: ForumRelatedFieldKey.Name,
    isRequired: true,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: ForumRelatedFieldKey.ForumPageRoute,
    sortEnabled: true,
    styles: {
      width: '200px',
    },
    itemsStyles: {
      'font-size': '14px',
      'font-weight': 'normal',
      width: '200px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Specific],
    headerKey: ForumRelatedFieldKey.Specific,
    dataKey: ForumRelatedFieldKey.Specific,
    subDataKey: 'name',
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.LastLaunchStartedAt],
    headerKey: ForumRelatedFieldKey.LastLaunchStartedAt,
    dataKey: ForumRelatedFieldKey.LastLaunchStartedAt,
    dataItemIsDate: true,
    dateFormat: DATE_FULL_MONTH_WORD,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.ForumYear],
    headerKey: ForumRelatedFieldKey.ForumYear,
    dataKey: ForumRelatedFieldKey.ForumYear,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.State],
    headerKey: ForumRelatedFieldKey.State,
    dataKey: ForumRelatedFieldKey.State,
    dataKeyOfEnum: true,
    dataEnum: FORUM_STATE_KEY_TO_VIEW_NAME,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Administrator],
    headerKey: ForumRelatedFieldKey.Administrator,
    dataKey: ForumRelatedFieldKey.Administrator,
    subDataKey: UserRelatedFieldKey.FirstAndLastName,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: UserRelatedFieldKey.ProfilePageRoute,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.FinishedMeetingsCount],
    headerKey: ForumRelatedFieldKey.FinishedMeetingsCount,
    dataKey: ForumRelatedFieldKey.FinishedMeetingsCount,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.MeetingsLeftCount],
    headerKey: ForumRelatedFieldKey.MeetingsLeftCount,
    dataKey: ForumRelatedFieldKey.MeetingsLeftCount,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.MonthsLeft],
    headerKey: ForumRelatedFieldKey.MonthsLeft,
    dataKey: ForumRelatedFieldKey.MonthsLeft,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsStyles: {
      width: '100px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.StartedAt],
    headerKey: ForumRelatedFieldKey.StartedAt,
    dataKey: ForumRelatedFieldKey.StartedAt,
    dataItemIsDate: true,
    dateFormat: DATE_FULL_MONTH_WORD,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.User],
    headerKey: ForumRelatedFieldKey.MembersCount,
    dataKey: ForumRelatedFieldKey.MembersCount,
    isCellsClickEmitData: true,
    sortEnabled: true,
    styles: {
      width: '100px',
    },
    itemsCustomClass: 'table-row__td-content-item--action-text',
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Creator],
    headerKey: ForumRelatedFieldKey.Creator,
    dataKey: ForumRelatedFieldKey.Creator,
    subDataKey: UserRelatedFieldKey.FirstAndLastName,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: UserRelatedFieldKey.ProfilePageRoute,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Editors],
    headerKey: ForumRelatedFieldKey.Editors,
    dataKey: ForumRelatedFieldKey.Editors,
    subDataKey: UserRelatedFieldKey.FirstAndLastName,
    keyOfParameterWithRouteInData: UserRelatedFieldKey.ProfilePageRoute,
    dataItemIsRoute: true,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Created],
    headerKey: ForumRelatedFieldKey.Created,
    dataKey: ForumRelatedFieldKey.Created,
    dataItemIsDate: true,
    dateFormat: DATE_FULL_MONTH_WORD,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Updated],
    headerKey: ForumRelatedFieldKey.Updated,
    dataKey: ForumRelatedFieldKey.Updated,
    dataItemIsDate: true,
    dateFormat: DATE_FULL_MONTH_WORD,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Moderator],
    headerKey: ForumRelatedFieldKey.Moderator,
    dataKey: ForumRelatedFieldKey.Moderator,
    subDataKey: UserRelatedFieldKey.FirstAndLastName,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: UserRelatedFieldKey.ProfilePageRoute,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.FinishedAt],
    headerKey: ForumRelatedFieldKey.FinishedAt,
    dataKey: ForumRelatedFieldKey.FinishedAt,
    dataItemIsDate: true,
    dateFormat: DATE_FULL_MONTH_WORD,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
  {
    headerName: FORUM_RELATED_FIELD_KEY_TO_VIEW_NAME[ForumRelatedFieldKey.Curator],
    headerKey: ForumRelatedFieldKey.Curator,
    dataKey: ForumRelatedFieldKey.Curator,
    subDataKey: UserRelatedFieldKey.FirstAndLastName,
    dataItemIsRoute: true,
    keyOfParameterWithRouteInData: UserRelatedFieldKey.ProfilePageRoute,
    sortEnabled: true,
    styles: {
      width: '170px',
    },
    itemsStyles: {
      width: '170px',
    },
  },
];

/**
 * Список ключей дефолтных колонок ОБЫЧНЫХ форумов для роли "СОТРУДНИК"
 */
const COMMON_FORUMS_TABLE_STAFF_DEFAULT_COLUMNS_KEYS: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.UniqueBranchNumber,
  ForumRelatedFieldKey.Avatar,
  ForumRelatedFieldKey.BranchRequired,
  ForumRelatedFieldKey.Name,
  ForumRelatedFieldKey.LastLaunchStartedAt,
  ForumRelatedFieldKey.ForumYear,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.State,
  ForumRelatedFieldKey.Administrator,
  ForumRelatedFieldKey.MeetingsLeftCount,
  ForumRelatedFieldKey.MonthsLeft,
];

/**
 * Список дефолтных колонок ОБЫЧНЫХ форумов для роли "СОТРУДНИК"
 */
export const COMMON_FORUMS_TABLE_STAFF_DEFAULT_COLUMNS = FORUMS_TABLE_COLUMNS.filter((col) =>
  COMMON_FORUMS_TABLE_STAFF_DEFAULT_COLUMNS_KEYS.includes(col.headerKey as ForumRelatedFieldKey),
);

/**
 * Список ключей дефолтных колонок ОБЫЧНЫХ форумов для роли "УЧАСТНИК"
 */
const COMMON_FORUMS_TABLE_MEMBER_DEFAULT_COLUMNS_KEYS: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.UniqueBranchNumber,
  ForumRelatedFieldKey.Avatar,
  ForumRelatedFieldKey.BranchRequired,
  ForumRelatedFieldKey.Name,
  ForumRelatedFieldKey.LastLaunchStartedAt,
  ForumRelatedFieldKey.ForumYear,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.Administrator,
  ForumRelatedFieldKey.MeetingsLeftCount,
];

/**
 * Список дефолтных колонок ОБЫЧНЫХ форумов для роли "УЧАСТНИК"
 */
export const COMMON_FORUMS_TABLE_MEMBER_DEFAULT_COLUMNS = FORUMS_TABLE_COLUMNS.filter((col) =>
  COMMON_FORUMS_TABLE_MEMBER_DEFAULT_COLUMNS_KEYS.includes(col.headerKey as ForumRelatedFieldKey),
);

/**
 * Список ключей дефолтных колонок ОТРАСЛЕВЫХ форумов для роли "СОТРУДНИК"
 */
export const INDUSTRY_FORUMS_TABLE_STAFF_DEFAULT_COLUMNS_KEYS: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.Industry,
  ForumRelatedFieldKey.Avatar,
  ForumRelatedFieldKey.Name,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.State,
  ForumRelatedFieldKey.Administrator,
  ForumRelatedFieldKey.FinishedMeetingsCount,
];

/**
 * Список дефолтных колонок ОТРАСЛЕВЫХ форумов для роли "СОТРУДНИК"
 */
export const INDUSTRY_FORUMS_TABLE_STAFF_DEFAULT_COLUMNS = FORUMS_TABLE_COLUMNS.filter((col) =>
  INDUSTRY_FORUMS_TABLE_STAFF_DEFAULT_COLUMNS_KEYS.includes(col.headerKey as ForumRelatedFieldKey),
);

/**
 * Список ключей дефолтных колонок ОТРАСЛЕВЫХ форумов для роли "УЧАСТНИК"
 */
export const INDUSTRY_FORUMS_TABLE_MEMBER_DEFAULT_COLUMNS_KEYS: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.Industry,
  ForumRelatedFieldKey.Avatar,
  ForumRelatedFieldKey.Name,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.Administrator,
  ForumRelatedFieldKey.FinishedMeetingsCount,
];

/**
 * Список дефолтных колонок ОТРАСЛЕВЫХ форумов для роли "УЧАСТНИК"
 */
export const INDUSTRY_FORUMS_TABLE_MEMBER_DEFAULT_COLUMNS = FORUMS_TABLE_COLUMNS.filter((col) =>
  INDUSTRY_FORUMS_TABLE_MEMBER_DEFAULT_COLUMNS_KEYS.includes(col.headerKey as ForumRelatedFieldKey),
);

/**
 * Список ключей колонок доступных роли "СОТРУДНИК" для ОБЫЧНЫХ форумов
 */
export const COMMON_FORUMS_TABLE_STAFF_COLUMNS_KEYS: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.UniqueBranchNumber,
  ForumRelatedFieldKey.Avatar,
  ForumRelatedFieldKey.BranchRequired,
  ForumRelatedFieldKey.Name,
  ForumRelatedFieldKey.LastLaunchStartedAt,
  ForumRelatedFieldKey.ForumYear,
  ForumRelatedFieldKey.State,
  ForumRelatedFieldKey.Administrator,
  ForumRelatedFieldKey.MeetingsLeftCount,
  ForumRelatedFieldKey.MonthsLeft,
  ForumRelatedFieldKey.StartedAt,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.Creator,
  ForumRelatedFieldKey.Editors,
  ForumRelatedFieldKey.Created,
  ForumRelatedFieldKey.Updated,
  ForumRelatedFieldKey.Moderator,
  ForumRelatedFieldKey.FinishedAt,
  ForumRelatedFieldKey.Curator,
];

/**
 * Список ключей колонок доступных роли "УЧАСТНИК" для ОБЫЧНЫХ форумов
 */
export const COMMON_FORUMS_TABLE_MEMBER_COLUMNS_KEYS: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.UniqueBranchNumber,
  ForumRelatedFieldKey.Avatar,
  ForumRelatedFieldKey.BranchRequired,
  ForumRelatedFieldKey.Name,
  ForumRelatedFieldKey.LastLaunchStartedAt,
  ForumRelatedFieldKey.ForumYear,
  ForumRelatedFieldKey.Administrator,
  ForumRelatedFieldKey.MeetingsLeftCount,
  ForumRelatedFieldKey.StartedAt,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.Moderator,
  ForumRelatedFieldKey.FinishedAt,
  ForumRelatedFieldKey.Curator,
];

/**
 * Список ключей колонок доступных роли "СОТРУДНИК" для ОТРАСЛЕВЫХ форумов
 */
export const INDUSTRY_FORUMS_TABLE_STAFF_COLUMNS_KEYS: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.Industry,
  ForumRelatedFieldKey.Avatar,
  ForumRelatedFieldKey.Branch,
  ForumRelatedFieldKey.Name,
  ForumRelatedFieldKey.Specific,
  ForumRelatedFieldKey.ForumYear,
  ForumRelatedFieldKey.State,
  ForumRelatedFieldKey.Administrator,
  ForumRelatedFieldKey.FinishedMeetingsCount,
  ForumRelatedFieldKey.StartedAt,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.Creator,
  ForumRelatedFieldKey.Editors,
  ForumRelatedFieldKey.Created,
  ForumRelatedFieldKey.Updated,
  ForumRelatedFieldKey.FinishedAt,
];

/**
 * Список ключей колонок доступных роли "УЧАСТНИК" для ОТРАСЛЕВЫХ форумов
 */
export const INDUSTRY_FORUMS_TABLE_MEMBER_COLUMNS_KEYS: ForumRelatedFieldKey[] = [
  ForumRelatedFieldKey.Industry,
  ForumRelatedFieldKey.Avatar,
  ForumRelatedFieldKey.Branch,
  ForumRelatedFieldKey.Name,
  ForumRelatedFieldKey.Specific,
  ForumRelatedFieldKey.ForumYear,
  ForumRelatedFieldKey.Administrator,
  ForumRelatedFieldKey.FinishedMeetingsCount,
  ForumRelatedFieldKey.StartedAt,
  ForumRelatedFieldKey.MembersCount,
  ForumRelatedFieldKey.FinishedAt,
];

export const FORUM_MEMBER_TILE_VIEW_CONFIG: IForumMemberTileViewConfig = {
  full_name: true,
  company: true,
};
