export enum SelectRelatedFieldKey {
  ActionType = 'actionType',
  ConfirmActionText = 'confirmActionText',
  Data = 'data',
  Files = 'files',
  FilesMimeType = 'filesMimeType',
  IconKey = 'iconKey',
  IconColor = 'iconColor',
  TextColor = 'textColor',
  TextSize = 'textSize',
  IconRotateAngle = 'iconRotateAngle',
  Id = 'id',
  IsRequired = 'isRequired',
  IsJustText = 'isJustText',
  IsSelected = 'isSelected',
  Key = 'key',
  TooltipText = 'tooltipText',
  ViewName = 'viewName',
  WaitForFileUpload = 'waitForFileUpload',

  FilterAdditionalFields = 'filterAdditionalFields',
  FilterAdditionalFieldsIsNotForUrl = 'filterAdditionalFieldsIsNotForUrl',
  FilterAvailableRulesOptions = 'filterAvailableRulesOptions',
  FilterChildrenFilters = 'filterChildrenFilters',
  FilterChildrenFiltersIsNotForApi = 'filterChildrenFiltersIsNotForApi',
  FilterChildrenFiltersIsNotForUrl = 'filterChildrenFiltersIsNotForUrl',
  FilterChildrenFiltersIsNotUnique = 'filterChildrenFiltersIsNotUnique',
  FilterField = 'filterField',
  FilterFieldIsNotForApi = 'filterFieldIsNotForApi',
  FilterFieldIsNotForUrl = 'filterFieldIsNotForUrl',
  FilterGroupField = 'filterGroupField',
  FilterGroupValue = 'filterGroupValue',
  FilterIsArray = 'filterIsArray',
  FilterIsDialogParent = 'filterIsDialogParent',
  FilterIsGroupNotUnique = 'filterIsGroupNotUnique',
  FilterIsNotUnique = 'filterIsNotUnique',
  FilterRule = 'filterRule',
  FilterValue = 'filterValue',
  FilterValueDateFormat = 'filterValueDateFormat',
  FilterValueInputType = 'filterValueInputType',
  FilterValueOptions = 'filterValueOptions',
  FilterValueOptionsSourceKey = 'filterValueOptionsSourceKey',
  FilterValues = 'filterValues',
  FilterValueSelectMode = 'filterValueSelectMode',
  FilterValueValidators = 'filterValueValidators',

  Color = 'color',
}
