<div class="page-container">
  <div class="flex space-between align-center">
    <kp-page-title title="Участники" [navigationEnabled]="true"></kp-page-title>
  </div>

  <!-- Members controls:start -->
  <div class="actions-bar">
    <div class="form-control actions-bar__search-field actions-bar__items-control--mr-default">
      <div class="form-control__input">
        <input placeholder="Поиск..." type="text" [formControl]="searchControl" />
        <kp-svg
          [key]="searchControl.value?.trim() ? 'xCircle' : 'search'"
          size="16px"
          [cursor]="searchControl.value?.trim() ? 'pointer' : 'default'"
          (click)="searchControl.value?.trim() ? searchControl.setValue('') : null"
        ></kp-svg>
      </div>
    </div>

    <kp-filter-dialog-controller
      class="actions-bar__filter-icon"
      [filtersOptions]="membersUtilsService.membersModalFiltersOptions"
      (submittedFiltersEvent)="setFiltersAndLoadItems(filterType.DataFilters, $event)"
    ></kp-filter-dialog-controller>
  </div>
  <!-- Members controls:end -->

  <div *ngIf="membersUtilsService.members?.length > 0; else noDataTemplate" [@inAnimation] class="table-wrapper">
    <kp-table [config]="membersUtilsService.tableConfig" (rowSelectedStateSwitchedEvent)="memberSelectedStateSwitched($event)"></kp-table>

    <div class="table-wrapper__paginator-container">
      <kp-paginator
        class="table-wrapper__paginator table-wrapper__paginator--align-left"
        [itemsCount]="membersUtilsService.usersCount"
        [offset]="+offset"
        [limit]="+limit"
        (offsetFilterChangedEvent)="setFiltersAndLoadItems(filterType.OffsetFromPagination, $event)"
      ></kp-paginator>

      <div class="buttons-container" [@inOutAnimation] *ngIf="selectedToCreate.length || selectedToDelete.length">
        <button type="button" class="button button--white" [class.disabled]="isSelectionsUpdating" (click)="cancelSelections()">
          Отмена
        </button>
        <button type="button" class="button button" [class.disabled]="isSelectionsUpdating" (click)="saveSelections()">Сохранить</button>
      </div>
    </div>
  </div>
</div>

<ng-template #noDataTemplate> <kp-no-data [@inAnimation] *ngIf="!membersUtilsService.isListUsersLoading"></kp-no-data></ng-template>
