import { NotificationsHttpService } from '@core/services/api/notifications/notifications-http.service';
import { IPushNotificationDevice } from '@shared/models/notifications/dto/notification-dto.model';
import { NotificationTypeEnum } from '@modules/profile/enums/keys.enum';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { catchError, switchMap } from 'rxjs/operators';
import { FirebaseApp } from '@angular/fire';
import { Injectable } from '@angular/core';
import * as uuid from 'uuid';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsPushService {
  fire: FirebaseApp;

  public isPushTokenExist$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public firebaseToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private notificationsHttpService: NotificationsHttpService,
  ) {}

  // Регистрация нового устройста на бэке
  public registerDeviceOnServer() {
    return this.angularFireMessaging.requestToken.pipe(
      switchMap((token) => {
        if (!token) {
          return of(null);
        }

        localStorage.setItem('firebaseToken', token);

        const config = {
          name: 'web-app',
          registration_id: token,
          device_id: uuid.v4(),
          active: true,
          type: 'web',
          is_allowed_by_user: true,
          allowed_types: [
            NotificationTypeEnum.DEFAULT,
            NotificationTypeEnum.MEMBERS,
            NotificationTypeEnum.POSTS,
            NotificationTypeEnum.FORUMS,
            NotificationTypeEnum.EVENTS,
            NotificationTypeEnum.MEETINGS,
            NotificationTypeEnum.CHATS,
            NotificationTypeEnum.PAYMENT,
            NotificationTypeEnum.GRATITUDE,
          ],
        };
        return this.notificationsHttpService.postFireBaseToken(config).pipe(
          catchError((error) => {
            console.error('Error in postFireBaseToken', error);
            throw error;
          }),
        );
      }),
    );
  }

  // Патч состояний пушей пользователя
  public deletePushOfDeviceUser() {
    return this.getDeviceByToken().pipe(
      switchMap((device) => {
        if (!device) {
          return of(null);
        }

        return this.notificationsHttpService.deletePushNotificaton(device.id);
      }),
      switchMap(() => {
        return this.deleteServiceToken();
      }),
    );
  }

  // Получает устройство по регистрационному токену
  public getDeviceByToken() {
    return this.angularFireMessaging.getToken.pipe(
      switchMap((token) => {
        if (!token) {
          return of(null);
        }

        return this.notificationsHttpService.getDeviceByToken(token);
      }),
    );
  }

  // Удалить firebase sw token
  public deleteServiceToken() {
    return this.angularFireMessaging.getToken.pipe(
      switchMap((token) => {
        if (!token) {
          return of(null);
        }

        return this.angularFireMessaging.deleteToken(token);
      }),
    );
  }

  // Обновление состояния активности пуш уведомлений у участника/настройка пушей
  public patchPushOfDeviceUser(id: number, device: Partial<IPushNotificationDevice>) {
    return this.notificationsHttpService.patchPushNotificationsForDevice(id, device);
  }
}
