import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationRelatedFieldKey, NotificationTypeKey } from '@modules/notifications/enums/keys.enum';
import { KeyValue } from '@shared/models/key-value.model';
import {
  INotificationDto,
  INotificationsUnreadInfoDto,
  IPushNotificationDevice,
} from '@shared/models/notifications/dto/notification-dto.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { QueryParams } from '@shared/models/query-params.model';
import { prepareQuery } from '@shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class NotificationsHttpService {
  private readonly _apiPath = '/notifications/';

  constructor(private http: HttpService) {}

  getAll(params?: QueryParams): Observable<ArrayPayload<INotificationDto>> {
    return this.http.get<ArrayPayload<INotificationDto>>(this._apiPath, {
      params: prepareQuery(params),
    });
  }

  getById(id: number): Observable<INotificationDto> {
    return this.http.get<INotificationDto>(`${this._apiPath}${id}/`);
  }

  getUnreadInfo(): Observable<INotificationsUnreadInfoDto[]> {
    return this.http.get<INotificationsUnreadInfoDto[]>(`${this._apiPath}unread-notifs/`);
  }

  readNotifications(type?: string): Observable<void> {
    if (!type) {
      return this.http.post<void>(`${this._apiPath}read-group/`, null);
    }

    return this.http.post<void>(`${this._apiPath}read-group/?${NotificationRelatedFieldKey.Type}=${type}`, null);
  }

  // Отправка объекта, в которого включен firebase токен на бэк
  postFireBaseToken(config: IPushNotificationDevice): Observable<void> {
    return this.http.post<void>(`/members/devices/`, config);
  }

  // Обновление состояния активности пуш уведомлений у участника/настройка пушей
  patchPushNotificationsForDevice(
    id: number,
    device: Partial<IPushNotificationDevice>,
  ): Observable<IPushNotificationDevice> {
    return this.http.patch<IPushNotificationDevice>(`/members/devices/${id}/`, device);
  }

  // Удалить девайс с бэка, по его айди
  deletePushNotificaton(deviceId: number): Observable<void> {
    return this.http.delete<void>(`/members/devices/${deviceId}/`);
  }

  // Возвращает определенный девайс по registration_id (токену регистрации в firebase)
  getDeviceByToken(id: string): Observable<IPushNotificationDevice> {
    return this.http.get<IPushNotificationDevice>(`/members/devices/by-registration-id/${id}/`);
  }
}
