import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

export const shouldReuse = 'shouldReuse';

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) {
    return (
      super.shouldReuseRoute(future, curr) ||
      (Boolean(curr.parent?.data?.[shouldReuse]) && curr.component === future.component)
    );
  }
}
