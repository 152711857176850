import { Pipe, PipeTransform } from '@angular/core';
import { ChatCurrentUserMembershipState } from '@modules/chats/enums/keys.enum';
import { IChat } from '../models/messages/view/chat.model';

export enum ParticipationState {
  Participant,
  RequiredRequest,
  FreeRequest,
  RequestSend,
}

@Pipe({
  name: 'chatParticipationState',
})
export class ChatParticipationStatePipe implements PipeTransform {
  transform(value: IChat): ParticipationState {
    if (value.participant_info.state === ChatCurrentUserMembershipState.Active) {
      return ParticipationState.Participant;
    }

    return value.is_invitation_required ? ParticipationState.RequiredRequest : ParticipationState.FreeRequest;
  }
}
