import { Pipe, PipeTransform } from '@angular/core';
import { IUserBusinessExperience } from '@shared/models/user/view/user-business-experience.model';

@Pipe({
  name: 'businessDate',
})
export class BusinessDatePipe implements PipeTransform {
  constructor() {}

  transform(business: IUserBusinessExperience) {
    if (business?.start_experience && business?.end_experience) {
      return (
        business?.start_experience?.slice(0, 4) +
        ' - ' +
        (business?.until_now ? 'Настоящее время' : business?.end_experience?.slice(0, 4))
      );
    } else if (business?.start_experience) {
      return business?.until_now
        ? business?.start_experience?.slice(0, 4) + ' - ' + 'Настоящее время'
        : 'c ' + business?.start_experience?.slice(0, 4);
    } else if (business?.end_experience) {
      return business?.until_now ? 'по Настоящее время' : 'по ' + business?.end_experience?.slice(0, 4);
    } else if (business?.until_now) {
      return 'по Настоящее время';
    }
  }
}
