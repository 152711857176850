export enum PostMenuOptionViewName {
  Delete = 'Удалить',
  Edit = 'Редактировать',
  Pin = 'Закрепить',
  Unpin = 'Открепить',
}

export enum PostAttachmentMenuOptionViewName {
  Image = 'Изображение',
  Document = 'Документ',
  Vote = 'Голосование',
  Geo = 'Местоположение',
}
