import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { KeyValue } from '@shared/models/key-value.model';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  getValidatorErrorMessage(field: AbstractControl, specificErrorText?: string): string | void {
    const errorKey = Object.keys(field.errors)[0];
    if (errorKey) {
      const config: KeyValue<string> = {
        required: 'Поле обязательно для заполнения',
        email: 'Введите корректный e-mail',
        passwordMismatch: 'Пароли не совпадают',
        maxlength: `Максимальное количество символов: ${field.errors.maxlength?.requiredLength}`,
        minlength: `Минимальное количество символов: ${field.errors.minlength?.requiredLength}`,
        min: `Минимальное значение: ${field.errors.min?.min}`,
        max: `Максимальное значение: ${field.errors.max?.max}`,
        incorrectDate: 'Введите корректную дату',
        dateLessThanMinimum: `Минимальная дата: ${field.errors.dateLessThanMinimum?.minDate}`,
        dateGreaterThanMaximum: `Максимальная дата: ${field.errors.dateGreaterThanMaximum?.maxDate}`,
        onlymultipleOf15InTime: 'Количество минут должно быть кратно 15',
        specialСharacters: 'Пароль должен содержать цифры и специальные символы (! “ # $ % ‘ () *)',
        nonUnique: specificErrorText || 'Значение должно быть уникальным',
        pattern: specificErrorText,
        maxCountInTagsArr: 'Макс. количество тегов не должно превышать 10',
        maxPollLength: '*Поле не может содержать более 100 символов',
        compareEndAndStartDatetime: 'Время окончания должно быть больше времени начала'
      };

      return config[errorKey];
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        this.validateFormControl(control);
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((c) => {
          if (c instanceof FormGroup) {
            this.validateAllFormFields(c);
          } else if (c instanceof FormControl) {
            this.validateFormControl(c);
          }
        });
      }
    });
  }

  validateFormControl(control: AbstractControl) {
    control.markAsTouched({ onlySelf: true });
  }

  getInvalidControls(formGroup: FormGroup): AbstractControl[] {
    const invalidControls: AbstractControl[] = [];

    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control.invalid) {
        invalidControls.push(control);
      }
    });

    return invalidControls;
  }

  isControlInvalid(control: AbstractControl): boolean {
    return control?.invalid && control?.touched;
  }

  isControlInvalidReactive(control: AbstractControl): boolean {
    return control?.invalid && (control?.dirty || control?.touched);
  }
}
