import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubscriptionLike, timer } from 'rxjs';

export interface IChatScrollConfig {
  /**
   * Положение скролла по умолчанию (наверху/внизу)
   */
  initState: 'top' | 'bottom';

  /**
   * Расстояние в процентах с которого начинается диспатч
   * событий о достижении границы (scrolledUp/scrolledDown)
   */
  scrollDistancePercents: number;

  /**
   * Откуда будет догружаться контент при скролле (сверху/снизу)
   */
  saveUserScrollOnBoundGrow: 'top' | 'bottom';

  /**
   * Пропускать ли изначально установленную прокрутку
   */
  skipInitialScroll?: boolean;
}

/**
 * Для того, чтобы директива работала необходимо вручную оборачивать
 * контент в дополнительный div.
 *
 * Пример:
 * <div [kpChatScroll]="config" (scrolledDown)="onScroll()">
 *     <div> <--- дополнительный div
 *         <div *ngFor="let item of items">
 *             {{ item }}
 *         </div>
 *     </div>
 * </div>
 */
@UntilDestroy()
@Directive({
  selector: '[kpChatScroll]',
})
export class ChatScrollDirective implements OnInit, OnDestroy {
  @Input('kpChatScroll') config: IChatScrollConfig;
  @Output() scrolledUp = new EventEmitter<void>();
  @Output() scrolledDown = new EventEmitter<void>();
  @Output() isShowScroll = new EventEmitter<boolean>();
  @Output() contentProcessed: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Задержка для диспатчеров.
   *
   * Объяснение: за указанное миллисекунд будет испущено
   * не большего одного события скролла (scrolledUp/scrolledDown)
   */
  private readonly _emitterDebounceMs = 500;

  /**
   * Задержка для события скролла
   */
  private readonly _scrollDebounceMs = 50;

  private _emitterDebounceSub: SubscriptionLike;
  private _scrollDebounceSub: SubscriptionLike;
  private _emitterDisabled: boolean;

  /**
   * Сохранять ли текущую позицию скролла относительно контента
   */
  private _saveUserScroll: boolean;

  /**
   * Произошло ли изменение размера контейнера
   * из-за добавившегося контента сверху
   */
  private _isResizeByContentAbove: boolean;

  /**
   * Произошло ли изменение размера контейнера
   * из-за добавившегося контента снизу
   */
  private _isResizeByContentBottom: boolean;

  private _previousScrollHeight: number;
  private _previousScrollTop: number;

  /**
   * Слушатель изменения размера хоста
   */
  // @ts-ignore
  private _hostRo = new ResizeObserver(() => {
    if (!this._saveUserScroll && !this.config?.skipInitialScroll) {
      this._setInitScrollPosition();
    }
  });

  /**
   * Слушатель изменения размера контента
   */
  // @ts-ignore
  private _contentRo = new ResizeObserver(() => {
    if (!this._saveUserScroll && !this.config?.skipInitialScroll) {
      this._setInitScrollPosition();
    } else if (this._isResizeByContentAbove) {
      this._isResizeByContentAbove = false;

      const heightDelta = this._scrollHeight - this._previousScrollHeight;
      this._setYScrollbarPosition(heightDelta + this._previousScrollTop);
    } else if (this._isResizeByContentBottom) {
      this._isResizeByContentBottom = false;

      this._setYScrollbarPosition(this._previousScrollTop);
    }
    this.contentProcessed.emit();
  });

  isScrolledToTop = false;
  isScrolledToBottom = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this._onInit();
  }

  ngOnDestroy(): void {
    this._onDestroy();
  }

  private _onInit = (): void => {
    this._emitterDisabled = false;
    this._saveUserScroll = false;
    this._isResizeByContentAbove = false;
    this._isResizeByContentBottom = false;
    this._previousScrollHeight = null;
    this._previousScrollTop = null;

    this.renderer.setStyle(this.elementRef.nativeElement, 'overflow-y', 'scroll');
    this.renderer.setStyle(this.elementRef.nativeElement, 'overflow-anchor', 'none');

    setTimeout(() => {
      this._contentRo.observe(this.elementRef.nativeElement.firstElementChild);
      this._hostRo.observe(this.elementRef.nativeElement);
    }, 0);
  };

  private _onDestroy(): void {
    this._contentRo.unobserve(this.elementRef.nativeElement.firstElementChild);
    this._contentRo.disconnect();
    this._hostRo.unobserve(this.elementRef.nativeElement);
    this._hostRo.disconnect();
    this._emitterDebounceSub?.unsubscribe();
  }

  @HostListener('scroll')
  private _onHostScroll = (): void => {
    this.isShowScroll.emit(true);

    this._scrollDebounceSub?.unsubscribe();

    this._scrollDebounceSub = timer(this._scrollDebounceMs)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const isScrolledToBottom = this._scrollHeight - this._offsetHeight - this._scrollTop < 1;

        if (isScrolledToBottom) {
          this._saveUserScroll = this.config.saveUserScrollOnBoundGrow === 'bottom';
          this.isScrolledToTop = false;
          this.isScrolledToBottom = true;
        } else if (this._scrollTop === 0) {
          this._saveUserScroll = this.config.saveUserScrollOnBoundGrow === 'top';
          this.isScrolledToTop = true;
          this.isScrolledToBottom = false;
        } else {
          this._saveUserScroll = true;
          this.isScrolledToTop = false;
          this.isScrolledToBottom = false;
        }

        this._checkAndEmitReachBoundEvent();
      });
  };

  private _checkAndEmitReachBoundEvent(): void {
    const scrollPositionInPercents =
      (this._scrollTop / (this._scrollHeight - this.elementRef.nativeElement.clientHeight)) * 100;
    const isScrollDistanceReachedBottom = scrollPositionInPercents > 100 - this.config.scrollDistancePercents;
    const isScrollDistanceReachedTop = scrollPositionInPercents < this.config.scrollDistancePercents;

    if ((isScrollDistanceReachedBottom || isScrollDistanceReachedTop) && !this._emitterDisabled) {
      this._emitterDebounceSub?.unsubscribe();
      this._emitterDisabled = true;

      isScrollDistanceReachedBottom ? this.scrolledDown.emit() : this.scrolledUp.emit();

      this._emitterDebounceSub = timer(this._emitterDebounceMs)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this._emitterDisabled = false;
        });
    }
  }

  prepareForAddContentAbove = (): void => {
    this._saveUserScroll = true;
    this._previousScrollHeight = this._scrollHeight;
    this._previousScrollTop = this._scrollTop;
    this._isResizeByContentAbove = true;
  };

  prepareForAddContentBottom = (): void => {
    this._saveUserScroll = true;
    this._previousScrollHeight = this._scrollHeight;
    this._previousScrollTop = this._scrollTop;
    this._isResizeByContentBottom = true;
  };

  reloadDirective(): void {
    this._onDestroy();
    this._onInit();
  }

  private _setInitScrollPosition = (): void => {
    if (this.config.initState === 'top') {
      this._setYScrollbarPosition(0);
      this.isScrolledToBottom = false;
      this.isScrolledToTop = true;
    } else {
      this._setYScrollbarPosition(this._scrollHeight);
      this.isScrolledToBottom = true;
      this.isScrolledToTop = false;
    }
  };

  private _setYScrollbarPosition(y: number): void {
    this.elementRef.nativeElement.scrollTop = y;
  }

  private get _scrollTop(): number {
    return this.elementRef.nativeElement.scrollTop;
  }

  private get _scrollHeight(): number {
    return this.elementRef.nativeElement.scrollHeight;
  }

  private get _offsetHeight(): number {
    return this.elementRef.nativeElement.offsetHeight;
  }
}
