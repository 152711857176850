import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSlideToggleChange } from '@angular/material/slide-toggle/slide-toggle';

@Component({
  selector: 'kp-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitcherComponent {
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() disableRipple: boolean;
  @Output() change = new EventEmitter<MatSlideToggleChange>();

  onChange(val: MatSlideToggleChange, toggle: MatSlideToggle) {
    // todo replace with ngModel (in template) after materialUi updates
    // crunch to make switch controlled
    toggle.checked = this.checked;
    this.change.emit(val);
  }
}
