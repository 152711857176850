import { Pipe, PipeTransform } from '@angular/core';
import { IChatParticipant } from '@shared/models/messages/dto/chat-participant-dto.model';
import { PRIMARY_COLOR } from '@shared/constants/constants.const';

@Pipe({
  name: 'participantMention',
  pure: true,
})
export class ParticipantMentionPipe implements PipeTransform {
  constructor() {}

  transform(messageText: string, mentionUsers: IChatParticipant[]) {
    return this._replaceUserNameByMentionTag(messageText, mentionUsers);
  }

  private _replaceUserNameByMentionTag(messageText: string, mentionUsers: IChatParticipant[]) {
    const messageWordsSplitBySpace: string[] = messageText.split(' ');

    /** Первый проход - дробим по словам, второй - по переносам строки */
    return messageWordsSplitBySpace
      .map((wordWithoutSpace: string) =>
        wordWithoutSpace
          .split('\n')
          .map((cleanWord: string) => this._replaceUsernameInWord(cleanWord, mentionUsers))
          .join('\n'),
      )
      .join(' ');
  }

  private _replaceUsernameInWord(word: string, mentionUsers: IChatParticipant[]): string {
    const regexp: RegExp = /^@[A-Za-z0-9_]{1,25}$/;

    if (word.match(regexp)) {
      const participant: IChatParticipant | undefined = mentionUsers.find(
        (markedParticipant: IChatParticipant) => markedParticipant.user.username === word.slice(1),
      );
      if (participant) {
        return `<font color=${PRIMARY_COLOR}><a href="/profile/${participant.user.id}">${participant.user.first_name} ${participant.user.last_name}</a></font>`;
      }
    }

    return word;
  }
}
