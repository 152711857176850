import { Injectable } from '@angular/core';
import { ForumLinkHttpService } from '@core/services/api/forums/forum-link-http.service';
import { IForum, IForumLink } from '@shared/models/forums/view/forum.model';
import { KeyValue } from '@shared/models/key-value.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ForumLinkService {
  links: IForumLink[] = [];

  constructor(private forumLinkHttpService: ForumLinkHttpService) {}

  getAllByTargetIdAndAddToTarget(
    targetId: number,
    target?: IForum,
    params?: KeyValue<string>,
    paramsString?: string,
  ): Observable<ArrayPayload<IForumLink>> {
    const filters = { forum: targetId.toString(), ...params };
    const filtersInString = `forum=${targetId}`;

    params = params ? { ...params, ...filters } : null;
    paramsString = paramsString ? `${paramsString}&${filtersInString}` : filtersInString;

    return this.forumLinkHttpService.getAll(params, paramsString).pipe(
      tap((response) => {
        target.links = response.results;
      }),
    );
  }

  getById(id: number): Observable<IForumLink> {
    return this.forumLinkHttpService.getById(id);
  }

  createAndAddToTarget(dto: IForumLink, target?: IForum): Observable<IForumLink> {
    return this.forumLinkHttpService.create(dto).pipe(
      tap((createdItem) => {
        if (target) {
          if (!target.links?.length) {
            target.links = [];
          }
          target.links.push(createdItem);
        }
      }),
    );
  }

  updateByIdAndReplaceInTarget(id: number, dto: IForumLink, target?: IForum): Observable<IForumLink> {
    return this.forumLinkHttpService.updateById(id, dto).pipe(
      tap((updatedItem) => {
        if (target?.links) {
          const targetItemIdx = target.links.findIndex((item) => item.id === updatedItem.id);
          if (targetItemIdx !== -1) {
            target.links[targetItemIdx] = updatedItem;
          }
        }
      }),
    );
  }

  deleteByIdAndRemoveFromTarget(id: number, target?: IForum): Observable<void> {
    const itemToDeleteIdx = target?.links?.findIndex((p) => p.id === id);
    if (itemToDeleteIdx !== -1) {
      target.links[itemToDeleteIdx].isDeleting = true;
    }

    return this.forumLinkHttpService.deleteById(id).pipe(
      tap(
        () => {
          if (target.links) {
            target.links = target.links?.filter((item) => item.id !== id);
          }
        },
        () => {
          if (itemToDeleteIdx !== -1) {
            target.links[itemToDeleteIdx].isDeleting = false;
          }
        },
      ),
    );
  }
}
