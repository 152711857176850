import {
  MeetingMemberStateKey,
  MeetingMemberRelatedFieldKey,
  MeetingRelatedFieldKey,
  MeetingsFormatKey,
  MeetingStateKey,
  MeetingsTypeKey,
  MeetingsTypeOfPaidKey,
  MeetingTypeKey,
  MeetingMemberPaymentStateKey,
} from '../enums/keys.enum';

export const MEETINGS_TYPE_KEY_TO_VIEW_NAME: Record<MeetingsTypeKey, string> = {
  [MeetingsTypeKey.AllMeetings]: 'Все встречи',
  [MeetingsTypeKey.MyMeetings]: 'Мои встречи',
};

export const MEETINGS_TYPE_OF_TYPES_KEY_TO_VIEW_NAME: Record<MeetingTypeKey, string> = {
  [MeetingTypeKey.Setup]: 'Настроечная встреча',
  [MeetingTypeKey.Common]: 'Обычная встреча',
};

export const MEETING_STATE_KEY_TO_VIEW_NAME: Record<MeetingStateKey, string> = {
  [MeetingStateKey.Draft]: 'Черновик',
  [MeetingStateKey.Published]: 'Опубликована',
  [MeetingStateKey.Finished]: 'Завершена',
  [MeetingStateKey.Cancelled]: 'Отменена',
  [MeetingStateKey.InProgress]: 'В процессе',
};

export const MEETING_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME: Record<MeetingStateKey, string> = {
  [MeetingStateKey.Draft]: 'primary-text primary-text--dark-gray',
  [MeetingStateKey.Published]: 'primary-text primary-text--turquoise',
  [MeetingStateKey.Finished]: 'primary-text primary-text--accent ',
  [MeetingStateKey.Cancelled]: 'primary-text primary-text--pastel-pink',
  [MeetingStateKey.InProgress]: 'primary-text primary-text--yellow',
};

export const MEETING_STATE_KEY_TO_PRIMARY_INFO_CLASS_NAME: Record<MeetingStateKey, string> = {
  [MeetingStateKey.Draft]: 'primary-info primary-info--gray',
  [MeetingStateKey.Published]: 'primary-info primary-info--green',
  [MeetingStateKey.Finished]: 'primary-info primary-info--gray',
  [MeetingStateKey.Cancelled]: 'primary-info primary-info--red',
  [MeetingStateKey.InProgress]: 'primary-info primary-info--green',
};

export const MEETINGS_TYPE_OF_PAID_KEY_TO_VIEW_NAME: Record<MeetingsTypeOfPaidKey, string> = {
  [MeetingsTypeOfPaidKey.Free]: 'Бесплатно',
  [MeetingsTypeOfPaidKey.Paid]: 'Платно',
};

export const MEETINGS_FORMAT_KEY_TO_VIEW_NAME: Record<MeetingsFormatKey, string> = {
  [MeetingsFormatKey.Offline]: 'Офлайн',
  [MeetingsFormatKey.Online]: 'Онлайн',
};

export const MEETING_MEMBER_STATE_KEY_TO_VIEW_NAME: Record<MeetingMemberStateKey, string> = {
  [MeetingMemberStateKey.MustVisit]: 'Должен посетить',
  [MeetingMemberStateKey.ConfirmedParticipation]: 'Подтвердил участие',
  [MeetingMemberStateKey.ReminderSent]: 'Напоминание отправлено',
  [MeetingMemberStateKey.Visited]: 'Посетил',
  [MeetingMemberStateKey.Refusal]: 'Отказ',
  [MeetingMemberStateKey.PaymentPending]: 'Ожидается оплата',
  [MeetingMemberStateKey.Missed]: 'Пропустил',
  [MeetingMemberStateKey.MeetingCanceled]: 'Встреча отменена',
  [MeetingMemberStateKey.NotInForum]: 'Не состоит в форуме',
};

export const MEETING_MEMBER_PAYMENT_STATE_KEY_TO_VIEW_NAME: Record<MeetingMemberPaymentStateKey, string> = {
  [MeetingMemberPaymentStateKey.Paid]: 'Оплачено',
  [MeetingMemberPaymentStateKey.NotPaid]: 'Не оплачено',
  [MeetingMemberPaymentStateKey.Free]: 'Не требует оплаты',
};

export const MEETING_MEMBER_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME: Record<MeetingMemberStateKey, string> = {
  [MeetingMemberStateKey.MustVisit]: 'primary-text primary-text--dark-gray',
  [MeetingMemberStateKey.ConfirmedParticipation]: 'primary-text primary-text--dark-gray',
  [MeetingMemberStateKey.ReminderSent]: 'primary-text primary-text--dark-gray',
  [MeetingMemberStateKey.Visited]: 'primary-text primary-text--accent',
  [MeetingMemberStateKey.Refusal]: 'primary-text primary-text--dark-gray',
  [MeetingMemberStateKey.PaymentPending]: 'primary-text primary-text--dark-gray',
  [MeetingMemberStateKey.Missed]: 'primary-text primary-text--pastel-pink',
  [MeetingMemberStateKey.MeetingCanceled]: 'primary-text primary-text--pastel-pink',
  [MeetingMemberStateKey.NotInForum]: 'primary-text primary-text--dark-gray',
};

export const MEETING_MEMBER_PAYMENT_STATE_KEY_TO_PRIMARY_TEXT_CLASS_NAME: Record<MeetingMemberPaymentStateKey, string> = {
  [MeetingMemberPaymentStateKey.Free]: 'primary-text primary-text--yellow',
  [MeetingMemberPaymentStateKey.NotPaid]: 'primary-text primary-text--pastel-pink',
  [MeetingMemberPaymentStateKey.Paid]: 'primary-text primary-text--turquoise',
};

export const MEETING_RELATED_FIELD_KEY_TO_VIEW_NAME: Partial<Record<MeetingRelatedFieldKey, string>> = {
  [MeetingRelatedFieldKey.Address]: 'Адрес встречи ',
  [MeetingRelatedFieldKey.Avatar]: 'Аватар',
  [MeetingRelatedFieldKey.Cost]: 'Стоимость',
  [MeetingRelatedFieldKey.Created]: 'Дата создания',
  [MeetingRelatedFieldKey.Creator]: 'Создал',
  [MeetingRelatedFieldKey.FinishedAt]: 'Завершение',
  [MeetingRelatedFieldKey.Format]: 'Формат',
  [MeetingRelatedFieldKey.MeetingFormat]: 'Формат',
  [MeetingRelatedFieldKey.Forum]: 'Форум',
  [MeetingRelatedFieldKey.ForumBranchNumber]: '№ форума',
  [MeetingRelatedFieldKey.ForumLocation]: 'Филиал',
  [MeetingRelatedFieldKey.ForumBranch]: 'Локация',
  [MeetingRelatedFieldKey.MembersCount]: 'Число участников',
  [MeetingRelatedFieldKey.Moderator]: 'Администратор',
  [MeetingRelatedFieldKey.ModeratorFromDialog]: 'Администратор',
  [MeetingRelatedFieldKey.Name]: 'Название',
  [MeetingRelatedFieldKey.NameFromDialog]: 'Название',
  [MeetingRelatedFieldKey.Number]: '№ встречи',
  [MeetingRelatedFieldKey.PaymentType]: 'Тип оплаты',
  [MeetingRelatedFieldKey.RefusedMembersCount]: 'Отказало',
  [MeetingRelatedFieldKey.StartedAt]: 'Дата проведения',
  [MeetingRelatedFieldKey.State]: 'Состояние',
  [MeetingRelatedFieldKey.Updated]: 'Дата изменения',
  [MeetingRelatedFieldKey.User]: 'Участники',
  [MeetingRelatedFieldKey.VisitedMembersCount]: 'Посетило',
};

export const MEETING_MEMBERS_RELATED_FIELD_KEY_TO_VIEW_NAME: Partial<Record<MeetingMemberRelatedFieldKey, string>> = {
  [MeetingMemberRelatedFieldKey.User]: 'Пользователь',
  [MeetingMemberRelatedFieldKey.State]: 'Состояние участника',
  [MeetingMemberRelatedFieldKey.PaymentState]: 'Состояние Оплаты',
};
