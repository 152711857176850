export enum EventType {
  ONLINE = 0,
  TOP = 1,
  CLUBS = 2,
  TRIPS = 3,
  TRAINING = 4,
  SUMMIT = 5,
  MAIN_FOR_CALENDAR = 6,
  BUSINESS = 7,
}

export enum EventStateKey {
  Draft = '0',
  Published = '1',
  Finished = '2',
  Cancelled = '3',
}

export enum EventMemberState {
  OnEvent = '0',
  Coming = '1',
  Interesting = '2',
  Refusal = '3',
  RequestSent = '4',
}

export enum EventMaterialLinkType {
  Photo = 0,
  Video = 1,
  Other = 2,
}

export enum EventSortingType {
  AscName = 'name',
  DescName = '-name',
  AscDateStart = 'start_datetime',
  DescDateStart = '-start_datetime',
  AscDateEnd = 'end_datetime',
  DescDateEnd = '-end_datetime',
  AscAvailableSeats = 'available_seats',
  DescAvailableSeats = '-available_seats',
}

export enum EventMembersSortingType {
  AscUpdated = 'state_update',
  DescUpdated = '-state_update',
  AscLastName = 'last_name',
  DescLastName = '-last_name',
}

export enum EventFilterKey {
  City = 'city',
  GreaterThan = 'start__gte',
  LowerThan = 'start__lte',
  Ordering = 'ordering',
  Search = 'search',
  State = 'state',
  TargetGroups = 'target_group',
  ThemeBlocks = 'theme_blocks',
}

export enum EventCalendarDownloadingStatus {
  Succsess = 0,
  LimitError = 1,
}
