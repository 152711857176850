import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { INewslinePostAttachmentState } from '@shared/models/newsline/view/newsline-post-attachment-state.model';
import { ISelectOption } from '@shared/models/select.model';
import { PostAttachmentMenuOptionViewName } from '@modules/newsline/enums/view-names.enum';
import { AbstractControl, FormGroup } from '@angular/forms';
import { INewslinePostImageDto } from '@shared/models/newsline/dto/newsline-post-image-dto.model';
import { INewslinePostCommentImageDto } from '@shared/models/newsline/dto/newsline-post-comment-image-dto.model';
import { PostFilterField, PostTypeKey } from '@modules/newsline/enums/keys.enum';
import { DatePeriodKey } from '@shared/enums/keys.enum';
import { FilterService } from '../utils/filter.service';
import {
  POST_PUBLICATION_PERIOD_OPTIONS,
  POST_TYPE_OPTIONS,
  POST_TYPES_WITHOUT_OBJECT,
} from '@modules/newsline/constants/selects-options.const';
import { IFilter } from '@shared/models/filter/filter.model';
import { POSTS_DEFAULT_FILTERS, POSTS_LIST_DEFAULT_LIMIT } from '@modules/newsline/constants/common.const';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { IUser, IUserWithProfile } from '@shared/models/user/view/user.model';
import { BaseClass } from '@shared/components/base/base.class';
import { NewslinePostService } from './newsline-post.service';
import { transformWordByCount } from '@shared/utils/utils';
import { TitleCasePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MembersListDialogComponent } from '@shared/dialogs/members-list-dialog/members-list-dialog.component';
import { IFileDto } from '@shared/models/file.model';
import { INewslineConfig } from '@shared/models/newsline/view/newsline-config.model';
import { IUsersDialogConfig } from '@shared/models/user/members-dialog-config.model';

@Injectable({
  providedIn: 'root',
})
export class NewslineUtilsService extends BaseClass {
  moduleConfig: INewslineConfig;

  maxFileSize = 52428800; // 50MB in bytes
  postTypeOptions = POST_TYPE_OPTIONS;
  publicationPeriodOptions = POST_PUBLICATION_PERIOD_OPTIONS;

  constructor(
    private snackBar: MatSnackBar,
    private filterService: FilterService,
    private authService: AuthService,
    private newslinePostService: NewslinePostService,
    private titleCasePipe: TitleCasePipe,
    private dialog: MatDialog,
  ) {
    super();
  }

  setNewslineModuleConfig(config: INewslineConfig) {
    this.moduleConfig = _.cloneDeep(config);

    if (!this.moduleConfig.filters) {
      this.moduleConfig.filters = [];
    }

    const postsTypeFilter: IFilter = {
      field: PostFilterField.Type,
      value: config.postTypeKey,
      isNotForUrl: true,
    };
    this.filterService.mergeFiltersArrays([postsTypeFilter], this.moduleConfig.filters);
  }

  attachMenuOptionSelected(
    selectOption: ISelectOption,
    targetForm: FormGroup,
    attachmentState: INewslinePostAttachmentState,
  ) {
    targetForm.markAsDirty();

    switch (selectOption.viewName) {
      case PostAttachmentMenuOptionViewName.Image: {
        const targetFormControl = targetForm.get('images');
        const images = (targetFormControl.value ? targetFormControl.value : []) as
          | INewslinePostImageDto[]
          | INewslinePostCommentImageDto[];
        const newImages = Array.from(selectOption.files)
          .map(
            (image) =>
              ({
                image,
                isNewFile: true,
              } as INewslinePostImageDto | INewslinePostCommentImageDto),
          )
          .filter(
            (image) =>
              !images?.some(
                (i: INewslinePostImageDto | INewslinePostCommentImageDto) => i.image.name === image.image.name,
              ),
          ) as INewslinePostImageDto[] | INewslinePostCommentImageDto[];

        const resultValue = [...images, ...newImages];
        targetFormControl.setValue(resultValue);

        attachmentState.isImageAttached = true;
        break;
      }
      case PostAttachmentMenuOptionViewName.Geo: {
        attachmentState.isGeoAttached = true;
        break;
      }
      case PostAttachmentMenuOptionViewName.Document: {
        const targetFormControl = targetForm.get('files');
        const files = (targetFormControl.value ? targetFormControl.value : []) as IFileDto[];
        const newfiles = Array.from(selectOption.files)
          .map(
            (file) =>
              ({
                file,
                isNewFile: true,
              } as IFileDto),
          )
          .filter((file) => !files?.some((f: IFileDto) => f.file.name === file.file.name)) as IFileDto[];

        if (selectOption.files[0].size >= this.maxFileSize || files?.length >= 10) {
          this.snackBar.open(
            'Размер файла не должен превышать 50 Mb. Количество возможных загружаемых файлов: 10',
            null,
            {
              duration: 3000,
            },
          );
        } else {
          targetFormControl.setValue([...files, ...newfiles]);
        }

        attachmentState.isFileAttached = true;
        break;
      }
      case PostAttachmentMenuOptionViewName.Vote: {
        const targetFormControl = targetForm.get('vote');
        targetFormControl.setValue(true);
        attachmentState.isVoteAttached = true;
        break;
      }
    }
  }

  unattachVote(targetFormControl: AbstractControl, attachmentState: INewslinePostAttachmentState) {
    attachmentState.isVoteAttached = false;
    targetFormControl.reset();
  }

  unattachFile(targetFormControl: AbstractControl, file: IFileDto, attachmentState: INewslinePostAttachmentState) {
    if (file.id) {
      file.isMarkedForDelete = true;
    } else {
      (targetFormControl.value as IFileDto[]).splice(targetFormControl.value.indexOf(file), 1);
      attachmentState.isFileAttached = false;
    }
  }

  unattachImage(
    targetFormControl: AbstractControl,
    image: INewslinePostImageDto | INewslinePostCommentImageDto,
    attachmentState: INewslinePostAttachmentState,
  ) {
    if (image.id) {
      image.isMarkedForDelete = true;
    } else {
      (targetFormControl.value as INewslinePostImageDto[] | INewslinePostCommentImageDto[]).splice(
        targetFormControl.value.indexOf(image),
        1,
      );
      attachmentState.isImageAttached = !!targetFormControl.value.length;
    }
  }

  unattachGeolocation(attachmentState: INewslinePostAttachmentState) {
    attachmentState.isGeoAttached = false;
  }

  setFiltersOptionsStateByFilters(filters: IFilter[]) {
    this.postTypeOptions = this.filterService.selectOptionsByFilters(
      this.postTypeOptions,
      filters,
      null,
      PostFilterField.PublicationType,
    );
    this.publicationPeriodOptions = this.filterService.selectOptionsByFilters(
      this.publicationPeriodOptions,
      filters,
      null,
      PostFilterField.CreatedPeriodKey,
    );
  }

  initCreatedOptionsAndFilters() {
    this.publicationPeriodOptions.forEach((option) => {
      option.filterChildrenFilters = this.filterService.getPeriodFiltersByDatePeriodKey(
        PostFilterField.Created,
        option.filterValue as DatePeriodKey,
      );
    });

    const selectedPeriodFilterValue = this.filterService.filters.find(
      (f) => f.field === PostFilterField.CreatedPeriodKey,
    ).value;
    const targetOption = this.publicationPeriodOptions.find((o) => o.filterValue === selectedPeriodFilterValue);
    if (targetOption) {
      targetOption.isSelected = true;
      this.filterService.setFilterFromSelectOption(targetOption);
    }
  }

  setPageFilters(postsCountTotal: number, postCountCurrent: number) {
    if (postsCountTotal > postCountCurrent) {
      const targetFilterIdx = this.filterService.filters.findIndex((f) => f.field === PostFilterField.Offset);
      this.filterService.filters[targetFilterIdx].value = (
        +this.filterService.filters[targetFilterIdx].value + +POSTS_LIST_DEFAULT_LIMIT
      ).toString();
    }
  }

  // initPostsAndFiltersState(checkPinnedPost = false, initCurrentFilterByDefault = false) {
  //   const posts = checkPinnedPost ? this.newslinePostService.posts?.filter((p) => p.pinned) : null;
  //   this.newslinePostService.posts = posts || [];
  //   this.newslinePostService.postsFilteredCount = 0;
  //
  //   this.filterService.defaultFilters = _.cloneDeep(POSTS_DEFAULT_FILTERS);
  //   this.filterService.mergeFiltersArrays(this.moduleConfig.filters, this.filterService.defaultFilters);
  //
  //   if (initCurrentFilterByDefault) {
  //     this.filterService.filters = _.cloneDeep(this.filterService.defaultFilters);
  //   }
  // }

  isPostnTypeWithObject(publicationType: PostTypeKey): boolean {
    return !POST_TYPES_WITHOUT_OBJECT.includes(publicationType);
  }

  getUsersTooltipString(usersForString: IUserWithProfile[], globalCount: number, actionWord: string): string {
    if (!usersForString?.length || !globalCount) {
      return;
    }

    const firstWordWithAmount = this.titleCasePipe.transform(transformWordByCount(globalCount, actionWord, true));
    const peopleCount = transformWordByCount(globalCount, 'человек');
    const usersInString = usersForString.map((user) => user.firstAndLastName).join(', ');
    let result = `${firstWordWithAmount} ${peopleCount}: ${usersInString}`;

    if (globalCount > usersForString.length) {
      result = `${result}, +${globalCount - usersForString.length}`;
    }
    return result;
  }

  openUsersListDialog(likesCount: number, membersIds: number[], actionWord: string) {
    const likedWord = this.titleCasePipe.transform(transformWordByCount(likesCount, actionWord, true));
    const peopleCount = transformWordByCount(likesCount, 'человек');

    const config: IUsersDialogConfig = {
      membersIds,
      title: `${likedWord} ${peopleCount}`,
    };

    this.dialog.open(MembersListDialogComponent, {
      data: { config },
    });
  }

  get isAfishaNewsline(): boolean {
    return PostTypeKey.Afisha === this.moduleConfig.postTypeKey;
  }

  get currentUser(): IUser {
    return this.authService.currentUser;
  }
}
