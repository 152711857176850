import { Injectable } from '@angular/core';
import { UtilsService } from '@core/services/business/utils/utils.service';
import {
  ChatMediaData,
  RetrieveMessageFile,
  RetrieveMessageLink,
} from '@shared/models/messages/view/chat-message.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { prepareQuery } from '@shared/utils/utils';
import { QueryParams } from '@shared/models/query-params.model';
import { ILocalMessageAttachment } from '@shared/models/file.model';

@Injectable({
  providedIn: 'root',
})
export class ChatMessageFileHttpService {
  private readonly _apiPath = '/chat/chat/';

  constructor(private http: HttpService, private utilsService: UtilsService) {}

  getFiles(chatId: number, params?: QueryParams): Observable<ArrayPayload<RetrieveMessageFile>> {
    return this.http.get<ArrayPayload<RetrieveMessageFile>>(`${this._apiPath}${chatId}/files/`, {
      params: prepareQuery(params),
    });
  }

  public getFileById(chatId: number, fileId: number): Observable<RetrieveMessageFile> {
    return this.http.get<RetrieveMessageFile>(`${this._apiPath}${chatId}/files/${fileId}/`);
  }

  public getMediaIdsByChatId(chatId: number): Observable<ChatMediaData> {
    return this.http.get<ChatMediaData>(`${this._apiPath}${chatId}/files/media-ids/`);
  }

  public createFile(file: Partial<ILocalMessageAttachment>, chatId: number): Observable<RetrieveMessageFile> {
    const fileFD: FormData = this.utilsService.transformObjectToFormData(file);

    return this.http.post<RetrieveMessageFile>(`${this._apiPath}${chatId}/files/`, fileFD);
  }

  public getLinks(chatId: number, params?: QueryParams): Observable<ArrayPayload<RetrieveMessageLink>> {
    return this.http.get<ArrayPayload<RetrieveMessageLink>>(`${this._apiPath}${chatId}/links/`, {
      params: prepareQuery(params),
    });
  }

  public postLinksBulk(chatId: number, messageId: number, links: string[]): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${chatId}/links/bulk_create/`, { message: messageId, links });
  }
}
